import React, { useEffect, useState } from 'react'
import DialogCard, { DialogProps } from '../../../../components/DialogCard/DialogCard'
import { useForm } from 'react-hook-form'
import { formFieldsInfo, getLabelControlType } from '../../Hooks/formFieldInfo'
import { Col, Row, Tabs, TabsProps, message } from 'antd'
import LabelControl from '../../LabelControl/LabelControl'
import { FormTypes } from '../../Models/FormTypes.enum'
import { getCustomerGroup, insertCustomerToGroup, updateCustomerInfo } from '../../../../api/response'
import { useAppDispatch, useAppSelector } from '../../../../redux/reduxHooks'
import { customerInfoData, setCustomerChoose, setCustomerDisplay } from '../../../../redux/reducers/customerInfo_reducer'

interface Props extends DialogProps {
  openType: 'edit' | 'add'
}

const Customer: React.FC<Omit<Props, 'handleOk'>> = (props) => {
  const { open, handleCancel, headerText, openType } = props
  const [customerGroup, setCustomerGroup] = useState([])
  const [loading, setLoading] = useState(false)
  const { customerChoose } = useAppSelector(customerInfoData)
  const dispatch = useAppDispatch()
  const { handleSubmit, control, reset } = useForm({
    defaultValues:
      openType === 'edit'
        ? {
            customerName: customerChoose.firstName,
            nickname: customerChoose.nickName,
            surname: customerChoose.lastName,
            email: customerChoose.email,
            phone: customerChoose.phone,
            type: customerChoose.customerGroupId,
            displayName: customerChoose.displayName,
            displayAddress: customerChoose.displayAddress,
            managerComment: customerChoose.managerComment,
            emailVerified: customerChoose.emailVerified,
            pointUpdateNotification: customerChoose.pointUpdateNotification,
            pointExpirationNotification: customerChoose.pointExpirationNotification,
          }
        : {
            type: 1
          },
    reValidateMode: 'onChange',
    mode: 'onChange'
  })
  const submitForm = () => {
    handleSubmit((data) => {
      const params = {
        active: 1,
        customerGroupId: data.type,
        isReferrer: 0,
        nickName: data.nickname,
        firstName: data.customerName,
        lastName: data.surname,
        email: data.email,
        phone: data.phone,
        displayName: data.displayName,
        displayAddress: data.displayAddress,
        managerComment: data.managerComment,
        emailVerified: data.emailVerified,
        pointUpdateNotification: data.pointUpdateNotification,
        pointExpirationNotification: data.pointExpirationNotification,
        areaGroup: 0,
        areaCode: '',
        country: '',
        city: '',
        address: '',
        zipCode: ''
      }
      setLoading(true)
      if (openType === 'add') {
        insertCustomerToGroup(params).then((res) => {
          if (res.success) {
            setLoading(false)

            message.success(res.message)
            dispatch(setCustomerChoose({ data: res.data }))
            const value = res.data
            dispatch(
              setCustomerDisplay({
                name: `${value?.customerNo || ''} ${value?.firstName || ''}${value?.lastName || ''} ${value?.nickName || ''} ${
                  value?.phone || ''
                } ${value?.email.includes('wx.domain.com') ? '' : value?.email || '' || ''} `
              })
            )
            handleCancel()
          } else {
            message.error(res.message)
            setLoading(false)
          }
        })
      } else {
        updateCustomerInfo(
          {
            ...params,
            state: '',
          } as any,
          customerChoose.id
        ).then((res) => {
          if (res.success) {
            setLoading(false)

            message.success(res.message)
            const value = res.data
            dispatch(setCustomerChoose({ data: res.data }))
            dispatch(
              setCustomerDisplay({
                name: `${value?.customerNo || ''} ${value?.firstName || ''}${value?.lastName || ''} ${value?.nickName || ''} ${value?.phone || ''
                  } ${value?.email.includes('wx.domain.com') ? '' : value?.email || '' || ''} `
              })
            )
            handleCancel()
          } else {
            message.error(res.message)
            setLoading(false)
          }
        })
      }
    })()
  }
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `信息`,
      children: (
        <Row align='middle' gutter={[16, 16]}>
          {formFieldsInfo[FormTypes.Customer].map((item) => {
            return (
              <Col span={11} key={item}>
                <LabelControl
                  key={item}
                  formControl={control}
                  type={getLabelControlType(item)}
                  formName={item}
                  label={item}
                  options={customerGroup.map((item) => ({ value: item.id, label: item.name }))}
                />
              </Col>
            )
          })}
        </Row>
      )
    },
    {
      key: '2',
      label: `充值`,
      children: `Content of Tab Pane 2`
    },
    {
      key: '3',
      label: `积分`,
      children: `Content of Tab Pane 3`
    }
  ]

  const customerGroupData = async () => {
    const res = await getCustomerGroup()
    if (res.success) {
      const { data } = res
      setCustomerGroup(data)
    } else {
      message.error(res.message)
    }
  }

  const onChange = (key: string) => {
    console.log(key)
  }

  useEffect(() => {
    customerGroupData()
  }, [])

  return (
    <DialogCard
      loading={loading}
      open={open}
      handleOk={submitForm}
      handleCancel={() => {
        reset()
        handleCancel()
      }}
      headerText={headerText}
    >
      <Tabs defaultActiveKey='1' items={items} onChange={onChange} style={{ marginTop: '-1rem', marginBottom: '1rem' }} />
    </DialogCard>
  )
}

export default Customer
