import React, { useState } from 'react'
import SliderLayout from '../components/Layout/siderLayout'
import PaymentProcess from '../components/payment/paymentProcess'

const PaymentMethods = (props) => {
  const [showCart, setShowCart] = useState(1)
  return (
    <div style={{ display: 'flex' }}>
      {showCart
        ? (
        <div style={{ flex: 1 }}>
          <SliderLayout
            isPayment={true}
            query={props.location.query}
          />
        </div>
          )
        : null}

      <div style={{ flex: 2, background: '#EFF2F5' }}>
        <PaymentProcess
          showCart={(value:number) => {
            setShowCart(value)
          }}
        />
      </div>
    </div>
  )
}

export default PaymentMethods
