const API_URL = {
	uri: 'https://api.buyanz.com.cn/api/v1',
	uri1: 'http://127.0.0.1:6001/api/v1',
	www: 'www.buyanz.com',
	cdn: 'https://nz.static.lensyun.com',
	gst: '125-152-112',
	bank: 'ANZ: 01-1839-0230586-01 Account: BUYANZ',
	wechat: '微信转账: buyanz01 姓名: 林泳杏',
	alipay: '支付宝转账: yvonne@kiwipainting.co.nz 姓名: 张继良',
	title: '新易购',
	subtitle: '最值得信赖的海淘平台',
	title_en: 'BUYANZ',
	subtitle_en: '',
	payment: 'ANZ: 01-1839-0230586-01 Account: BUYANZ',

}
export default API_URL
