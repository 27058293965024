import { Button, Col, Image, Input, Layout, message, Row, Space } from 'antd'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { sendMessageToCustomer } from '../../api/response'
import { setCartIncrease } from '../../redux/reducers/cartItems_reducer'
import { customerInfoData } from '../../redux/reducers/customerInfo_reducer'
import { orderData } from '../../redux/reducers/orders_reducer'
import { useAppDispatch, useAppSelector } from '../../redux/reduxHooks'

const { Header, Content, Footer } = Layout

const StyledHeader = styled(Header)`
  position: sticky;
  top: 0;
  z-index: 10;
  color: black;
  font-weight: 700;
  text-align: center;
  font-size: 20px;
  background-color: #fff !important;
`

const StyledContent = styled(Content)`
  margin: 16px;
  overflow: auto;
  height: 100vh;
  .ant-empty {
    display: none;
  }
`

const StyledLayoutFooter = styled(Footer)`
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 10;
  padding: 0;
`

const PaymentSuccess = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const storeState = useAppSelector(customerInfoData)
  const orderState = useAppSelector(orderData)
  return (
    <div>
      <Row wrap={false}>
        <Col flex={1}>
          <Layout style={{ maxHeight: '100vh', position: 'absolute', width: '100%' }}>
            <StyledHeader>
              <div>NZ$ {orderState.placeOrderPrice}</div>
            </StyledHeader>
            <StyledContent>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  fontWeight: 700,
                  fontSize: '1rem',
                  color: 'gray',
                  marginTop: '1%'
                }}
              >
                <Image preview={false} src='/icon/successbox.png' />
                <div>
                  <FormattedMessage id='orderSuccessful' />
                </div>
                <div>
                  <FormattedMessage id='orderId' />: #{orderState.orderIdInfo}
                </div>
                <div>
                  <Button
                    block
                    shape='round'
                    style={{
                      margin: '50px 10px',
                      height: '60px',
                      fontWeight: 700,
                      fontSize: '1rem',
                      backgroundColor: 'grey',
                      color: 'white'
                    }}
                    onClick={() => {
                      window.open(window.location.origin + `/print/${orderState.orderIdInfo}`)
                    }}
                  >
                    <Row align='middle'>
                      <Col span={5}>
                        <Image src='/icon/print.png' preview={false} width='40px' height='40px' />
                      </Col>
                      <Col span={12} offset={2}>
                        <FormattedMessage id='printOrder' />
                      </Col>
                    </Row>
                  </Button>
                </div>
                <div>
                  <Space direction='horizontal'>
                    <Input style={{ height: '40px' }} defaultValue={storeState.customerEmail} />
                    <Button
                      block
                      style={{
                        height: '40px',
                        fontWeight: 700,
                        fontSize: '1rem',
                        backgroundColor: 'grey',
                        color: 'white'
                      }}
                      onClick={() => {
                        const params = {
                          messageType: 'email',
                          messageTemplate: 'invoice',
                          receiver: storeState.customerEmail,
                          comment: ''
                        }
                        sendMessageToCustomer(params, +orderState.orderIdInfo).then((res) => {
                          if (res.success) {
                            message.success(res.message)
                          } else {
                            message.error('send failed')
                          }
                        })
                      }}
                    >
                      <FormattedMessage id='emailOrder' />
                    </Button>
                  </Space>
                </div>
                <div style={{ marginTop: '3%' }}>
                  <Button
                    type='primary'
                    onClick={() => {
                      history.push(`/order/${orderState.orderIdInfo}`)
                    }}
                  >
                    <FormattedMessage id='orderDetails'/>
                  </Button>
                </div>
              </div>
            </StyledContent>
            <StyledLayoutFooter>
              <div style={{ width: '100%' }}>
                <Button
                  block
                  style={{
                    height: '50px',
                    backgroundColor: 'orange',
                    color: 'white',
                    fontWeight: 700,
                    fontSize: '1rem'
                  }}
                  onClick={() => {
                    history.push('/')
                    dispatch(
                      setCartIncrease({
                        num: 1
                      })
                    )
                  }}
                >
                  <FormattedMessage id='createOrder'/>
                </Button>
              </div>
            </StyledLayoutFooter>
          </Layout>
        </Col>
      </Row>
    </div>
  )
}

export default PaymentSuccess
