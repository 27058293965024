const ZH_CN = {
  checkout: '结账',
  hold: '挂单',
  takeOnHold: '取单',
  cart: '购物车',
  categories: '热销种类',
  productTotal: '共 ',
  productUnit: ' 个',
  page: '页码',
  custom: '定制销售',
  accountInfo: '账户信息',
  changePwd: '修改密码',
  displayName: '公司',
  displayAddress: '地址',
  managerComment: '备注',
  email: '邮箱',
  lang: '语种',
  logo: '图标',
  nickname: '昵称',
  currPwd: '当前密码',
  newPwd: '新密码',
  cnfPwd: '确认密码',
  submit: '提交',
  search: '通过名字/电话/邮箱搜索',
  order: '提交订单',
  shipping: '物流',
  address: '地址',
  casher: '收银',
  subtotal: '小计',
  shippingFee: '物流',
  total: '总计',
  totalPaid: '已付',
  totalWeight: '总重',
  invoiceNo: '编号',
  invoiceDate: '创建',
  invoicePaidAt: '付款',
  invoiceGST: 'GST',
  invoiceGSTAmount: '税(GST):',
  invoiceFooter: '感谢惠顾，欢迎再次光临！',
  customer: '客户',
  billto: 'Bill To',
  customerCompany: '',
  customerAddress: '地址',
  memberShip: '会员',
  add: '新增',
  update: '修改',
  adjustment: '附加项',
  coupon: '优惠卷',
  point: '积分',
  discount: '折扣',
  customerPurchase: '下单购买',
  shipmentEdit: '修改',
  shipmentComplete: '完成',
  shipmentDelete: '删除',
  parcelShipmentCreate: '出单',
  parcelShipmentUpdate: '改单',
  parcelShipmentWeight: '称重',
  parcelShipmentCancel: '撤单',
  parcelShipmentPrint: '打单',
  parcelShipmentReprint: '重打',
  parcelShipmentCreateComment: '备注',
  parcelShipmentUpdateComment: '备注',
  parcelShipmentPrintComment: '附加信息',
  parcelShipmentPrintUrlTitle: '面单下载',
  parcelShipmentPrintButton: '打印面单',
  parcelShipmentCancelComment: '撤单原因',
  parcelShipmentItemsTitle: '自定义内件',
  parcelShipmentConsigneeName: '自定义收件人',
  parcelShipmentConsigneePhone: '电话',
  parcelShipmentConsigneeID: '身份证号',
  parcelShipmentConsigneeAddress: '收件地址',
  parcelShipmentItemsPlaceholder: 'Go Health 深海鱼油1000mg 200粒 * 2\r\nGood Health 卵磷脂1500mg 100粒 * 3\r\nComvita 麦卢卡蜂蜜 UMF10+ 500g * 1',
  parcelShipmentWeightTitle: '重量(kg)',
  idCardFront: '正面',
  idCardRear: '反面',
  surcharge: '附加费',
  productsTotalMoney: '商品总额',
  shipmentFee: '运费',
  totalMoney: '应付总额',
  specialPrice: '特价',
  wholesale: '批发',
  limited: '限量',
  onlyLeft: '仅剩',
  unit: '件',
  outOfStock: '缺货',
  market: '市场同步',
  afterOpen: '开封后',
  day: '天',
  month: '月',
  unlimited: '不限制',
  minUnit: '件起',
  shipmentMethod: '邮寄方式',
  payment: '付款',
  invoicePayment: '付款方式',
  cash: '现金',
  use: '使用',
  weight: '重量',
  item: '品名',
  qty: '数量',
  cashAmount: '填写收取现金的数量',
  deposit: '预存款',
  available: '可用',
  useAll: '全部',
  useCard: '刷卡',
  offlinePayment: '离线',
  other: '其他',
  orderNumNote: '#付款请备注订单号#',
  bankTransfer: 'New Zealand 银行转账',
  anzAccount: 'ANZ账号: 06-0229-0894524-01 户名: BUYANZ',
  wechatPay: '微信转账: buyanz01 姓名: 林泳杏',
  aliPay: '支付宝转账: yvonne@kiwipainting.co.nz 姓名: 张继良',
  paymentScreenshoot: '付款截图: (请上传付款凭证, 以免无法对账导致订单无效)',
  attention: '注意：只有确认收款后方可点击Payment按钮 ！！ 提交后订单无法取消',
  backToOrder: '返回订单页面',
  paymentReceive: '收到付款',
  remainMoney: '剩余价钱',
  partialShip: '部分发货',
  partialReceive: '部分配货',
  printOrder: '打印订单',
  confirm: '确认',
  cancel: '取消',
  changeAmount: '填写找零现金的数量',
  changeRequired: '找零',
  changeReceived: '已找',
  cashReceived: '已收现金',
  totalDue: '待付',
  orderNum: '单号',
  cancelOrder: '取消',
  orderPayment: '支付',
  orderRefund: '退货',
  orderLock: '锁定',
  orderPickup: '自提',
  orderDelivery: '物流',
  orderPrint: '打印',
  reOrder: '重购',
  orderNote: '备注',
  freeShipment: '包邮',
  store: '门店',
  warehouse: '仓库',
  orderSuccessful: '订单成功创建成功',
  orderId: '订单号',
  emailOrder: '邮寄订单',
  orderDetails: '订单详情',
  createOrder: '创建订单',
  itemsOrder: '数量',
  infinity: '无限制',
  max: '最多购买',
  amount: '数量',
  describe: '描述',
  stock: '库存',
  price: '价格',
  expired: '效期',
  purchase: '立即购买',
  hasStock: '库存充足',
  checkCashBox: '点验',
  checkLater: '稍后',
  checkNow: '确认',
  upload: '上传图片',
  oldCashBoxTotal: '系统',
  newCashBoxTotal: '点验',
  remainCashBox: '差额',
  comment: '留言',
  wrongCashBox: '钱数不符，请上传照片',
  account: '账户信息',
  cashBoxInfo: '钱箱信息',
  code: '钱箱编号',
  status: '钱箱状态',
  storeId: '店铺 Id',
  managerId: '管理员 Id',
  cashBoxName: '钱箱名字',
  currency: '钱箱币种',
  createdAt: '钱箱创建时间',
  updatedAt: '钱箱更新时间',
  cashBoxWithdraw: '取款',
  cashBoxDeposit: '存款',
  searchAll: '全局搜索',
  depositCashBox: '存入',
  afterDepositCashBox: '存后',
  withdrawCashBox: '取出',
  afterWithdrawCashBox: '剩余',
  currentCash: '当前',
  managerName: '管理员名字',
  storeName: '店铺名字',

  // New slider layout
  addOneFee: '费用',
  couponCode: '优惠码',
  couponAmount: '费用',
  note: '备注',
  name: '名字',
  customerName: '名字',
  type: '等级',
  surname: '姓氏',
  phone: '电话',
  shipmentareas: '区域',
  street: '街道/门牌',
  city: '城市',
  zipCode: '邮编',
  idCard: '身份证',
  cover: '证件照片',
  defaultAddress: '默认地址',
  searchAddress: '搜索地址',
  up: "上一页",
  down: '下一页',
  totalPage: '总数',
  pickup: '自提点',
  addone: '附加费',
  fee: '费用',

  notice: '注意',
  shipmentArea: '收货地区',
  shippingMethod: '收货方式'
}
export default ZH_CN
