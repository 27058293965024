const style = {
  height: '20px',
  width: '20px'
}

const menuList = [
  {
    name: 'Sales',
    key: 'sales',
    children: [
      {
        title: 'Checkout',
        icon: <img src='/icon/checkout.png' style={style} alt='checkout' />,
        path: '/',
        color: '#2C3340'
      },
      {
        title: 'Orders',
        icon: <img src='/icon/orderhistory.png' style={style} alt='orderhistory' />,
        path: '/order',
        color: '#2C3340'
      }
    ]
  },
  {
    name: 'Customers',
    key: 'money',
    children: [
      {
        title: 'Coupon',
        icon: <img src='/icon/orderhistory.png' style={style} alt='orderhistory' />,
        path: '/coupon',
        color: '#2C3340'
      },
      {
        title: 'List',
        icon: <img src='/icon/deposit.png' style={style} alt='deposit' />,
        path: '/customer_management',
        color: '#2C3340'
      },
    ]
  },
  {
    name: 'Transaction',
    key: 'trans',
    children: [
      
      {
        title: 'Points',
        icon: <img src='/icon/orderhistory.png' style={style} alt='orderhistory'  />,
        path: '/pointHistory',
        color: '#2C3340'
      },
      {
        title: 'Payments',
        icon: <img src='/icon/orderhistory.png' style={style} alt='orderhistory' />,
        path: '/paymentHistory',
        color: '#2C3340'
      },
      {
        title: 'Credits',
        icon: <img src='/icon/orderhistory.png' style={style} alt='orderhistory' />,
        path: '/creditHistory',
        color: '#2C3340'
      },
      {
        title: 'Cash Box',
        icon: <img src='/icon/deposit.png' style={style} alt='cashBox' />,
        path: '/cashBoxList',
        color: '#2C3340'
      }
    ]
  },{
    name: 'Reporting',
    key: 'report',
    children: [
      
      {
        title: 'Payments',
        icon: <img src='/icon/orderhistory.png' style={style} alt='orderhistory'  />,
        path: '/report/payments',
        color: '#2C3340'
      },
      {
        title: 'Products',
        icon: <img src='/icon/orderhistory.png' style={style} alt='orderhistory' />,
        path: '/report/products',
        color: '#2C3340'
      },
      {
        title: 'Order',
        icon: <img src='/icon/orderhistory.png' style={style} alt='orderhistory' />,
        path: '/report/orders',
        color: '#2C3340'
      }
    ]
  },
  {
    name: 'Setting',
    key: 'setting',
    children: [
      {
        title: 'Account',
        icon: <img src='/icon/setting.png' style={style} alt='setting' />,
        path: '/settings/account',
        color: '#2C3340'
      }
    ]
  },  
  {
    icon: <img src='/icon/logout.png' style={style} alt='logout' />,
    title: 'Logout',
    path: 'logout',
    color: '#2C3340'
  }
]

export { menuList }
