import { useEffect, useMemo, useState, ChangeEvent, useRef } from 'react'
import { deleteCustomerAddress, getCustomerAddressArea, shippingAddress } from '../../../api/response'
import { message } from 'antd'
import { CustomerCreateAddressResTypes } from '../../../models/customerTypes'
import { useAppDispatch, useAppSelector } from '../../../redux/reduxHooks'
import { setChoosedAddress } from '../../../redux/reducers/orders_reducer'
import { customerInfoData, getShipmentAreaAndGroupId } from '../../../redux/reducers/customerInfo_reducer'
import { debounce } from 'lodash'
import { useForm } from 'react-hook-form'
import { PlaceOrderReqTypes } from '../../../models/order/placeorder'
import { useSelector } from 'react-redux'

interface Option {
  value: string | number
  label: string
  children?: { value: string | number; label: string }[]
}

interface Props {
  getPlaceOrderInfoBack: (val) => void
}

const useAddress = ({ getPlaceOrderInfoBack }: Props) => {
  const [areaOptions, setAreaOptions] = useState<Option[]>([])
  const [shipmentAddress, setShipmentAddress] = useState<CustomerCreateAddressResTypes[]>([])
  const [hasMore, setHasMore] = useState(true)
  const [clickedItemId, setClickedItemId] = useState('')
  const [total, setTotal] = useState(0)
  const [pagination, setPagination] = useState({ limit: 4, page: 1 })
  const [totalPage, setTotalPage] = useState(0)
  const [searchQ, setSearchQ] = useState('')

  const { areaCode, groupId } = useSelector(getShipmentAreaAndGroupId)
  const PictureRef = useRef(null)
  const PictureRef2 = useRef(null)
  const itemClicked = useMemo(() => {
    if (shipmentAddress.length === 0) {
      return
    }
    const address = shipmentAddress.find((item) => item.id.toString() === clickedItemId)
    PictureRef.current.setFileList([])
    PictureRef2.current.setFileList([])

    if (address?.idCardImage) {
      PictureRef.current.setFileList([address.idCardImage])
    }
    if (address?.idCardImage2) {
      PictureRef2.current.setFileList([address.idCardImage2])
    }
    return address
  }, [clickedItemId, shipmentAddress])

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {},
    values: {
      shipmentareas: itemClicked?.areaGroup ? [itemClicked?.areaGroup, itemClicked?.areaCode] : [groupId, areaCode],
      surname: itemClicked?.lastName ?? '',
      name: itemClicked?.firstName ?? '',
      zipCode: itemClicked?.postcode,
      ...itemClicked
    },
    reValidateMode: 'onChange',
    mode: 'onChange'
  })
  const { customerId, shipmentAreaCode: chosenAreaCode, shipmentGroupId: chosenGroupId } = useAppSelector(customerInfoData)
  const dispatch = useAppDispatch()

  const fetchData = (param) => {
    setHasMore(true)

    shippingAddress(param).then((res) => {
      if (res.success) {
        const { totalCount } = res.data.pagination
        const { items } = res.data
        const arr = [...shipmentAddress, ...items]
        setTotalPage(res.data.pagination.pageCount)
        let address =
          items.find((item) => {
            return item.isDefault === 1
          }) || items[0]

        if (address?.areaCode !== chosenAreaCode || address?.areaGroup !== chosenGroupId) {
          address = items.find((item) => {
            return item?.areaCode === chosenAreaCode && item.areaGroup === chosenGroupId
          })
        }

        if (
          items.find((item) => {
            return item.isDefault === 1
          })?.id
        ) {
          let address =
            items.find((item) => {
              return item.isDefault === 1
            }) || items[0]

          if (chosenAreaCode && chosenGroupId) {
            if (address?.areaCode !== chosenAreaCode && address?.areaGroup !== chosenGroupId) {
              address = items.find((item) => {
                return item?.areaCode === chosenAreaCode && item.areaGroup === chosenGroupId
              })
            }
          }
          dispatch(
            setChoosedAddress({
              address: `${address?.firstName || ''} ${address?.phone || ''} ${address?.street || ''}${address?.city || ''} ${
                address?.state || ''
              }`
            })
          )
          // if (areaCode) {
          //   areaCode(address?.areaCode)
          // }
        }
        if (arr.length >= totalCount) {
          setHasMore(false)
          setShipmentAddress(items)
          setTotal(totalCount)
          return
        }
        setHasMore(false)
        setShipmentAddress(items)
        setTotal(totalCount)
      } else {
        setShipmentAddress(undefined)
      }
    })
  }

  useEffect(() => {
    getCustomerAddressArea().then((res) => {
      if (res.success) {
        const shipmentArea = res.data
          .sort((a, b) => b.position - a.position)
          .map((item) => {
            return {
              value: item.shipmentGroupId,
              label: item.name,
              children: [
                ...item.items.map((child) => {
                  return { value: child.code, label: child.name }
                })
              ]
            }
          })
        setAreaOptions(shipmentArea)
      } else {
        message.error(res.message)
      }
    })

  }, [])
  useEffect(() => {
    const param = {
      customerId: customerId,
      storeId: +sessionStorage.getItem('storeId'),
      pageSize: pagination.limit,
      currentPage: pagination.page,
      areaGroup: groupId,
      areaCode: areaCode,
      q: searchQ
    }
    fetchData(param)
  }, [customerId, pagination.page, areaCode, groupId, searchQ])

  const onCLickHandler = (id: string) => {
    setClickedItemId(id)
  }

  const debouncedChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {

    setPagination({ limit: 4, page: 1 })
    setSearchQ(e.currentTarget.value)
  }

  const debouncedKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code !== 'Enter') {
      return
    }

    setPagination({ limit: 4, page: 1 })
    setSearchQ(e.currentTarget.value)
  }

  const deleteHandler = (e, item: CustomerCreateAddressResTypes) => {
    e.stopPropagation()
    deleteCustomerAddress(item.id.toString()).then((res) => {
      if (res.data) {
        setShipmentAddress((pre) => {
          const filteredArr = pre.filter((a) => a.id !== item.id)

          return filteredArr
        })
        if (clickedItemId === item.id.toString()) setClickedItemId('')
        message.success('success')
      }
    })
  }

  const manipulateAddressHandler = (value, imageList, imageList2) => {
    imageList = JSON.parse(JSON.stringify(imageList))
    imageList2 = JSON.parse(JSON.stringify(imageList2))

    const addressEditParam: Partial<PlaceOrderReqTypes> = {
      shippingAddressId: value.id,
      shipmentDefaultAddress: value?.defaultAddress ?? false,
      consigneeFirstName: value?.name || '',
      consigneeLastName: value?.surname || '',
      consigneePhone: value?.phone || '',
      shipmentCity: value?.city || '',
      shipmentGroupId: value.shipmentareas[0],
      shipmentAreaCode: value.shipmentareas[1],
      consigneeAddress: value.email,
      shipmentStreet: value?.street || '',
      shipmentPostcode: value?.zipCode || '',
      consigneeID: value?.idCard || '',
      consigneeIDPhoto: imageList[0] ?? '',
      consigneeIDPhoto2: imageList2[0] ?? ''
    }
    return addressEditParam
  }

  const submitForm = () => {
    handleSubmit((data) => {
      const frontImage = PictureRef.current.getFileName().map((item) => item.name)
      const backImage = PictureRef2.current.getFileName().map((item) => item.name)
      // if (typeof edit === 'boolean') {
      //   manipulateAddressHandler(data, edit, frontImage, backImage)
      // }
      getPlaceOrderInfoBack(manipulateAddressHandler(data, frontImage, backImage))
    })()
  }
  return {
    areaOptions,
    total,
    setPagination,
    pagination,
    totalPage,
    shipmentAddress,
    onCLickHandler,
    clickedItemId,
    hasMore,
    debouncedChangeHandler,
    debouncedKeyPressHandler,
    deleteHandler,
    manipulateAddressHandler,
    itemClicked,
    submitForm,
    PictureRef,
    PictureRef2,
    reset,
    control
  }
}

export default useAddress
