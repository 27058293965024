import React, { useEffect } from 'react'
import MenuLayout from '../components/Layout/menuLayout'
import Report from '../components/reportList'
import { setMenuDisplay } from '../redux/reducers/menu_reducer'
import { useAppDispatch } from '../redux/reduxHooks'

const ReportList = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(
      setMenuDisplay({
        menu: false
      })
    )
  }, [])
  return (
    <div>
      <MenuLayout />
      <Report />
    </div>
  )
}

export default ReportList
