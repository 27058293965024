import { Select, Input, Modal, Tabs, Button, Form } from 'antd'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

const { TabPane } = Tabs
const { Option } = Select

const ModalFormSubmit = styled(Form.Item)`
  position: absolute;
  bottom: 0;
  right: 8em;
  margin-bottom: 10px;
`

interface Props {
  isModalVisible: boolean
  finished: (value: any) => void
  close: () => void
  adjustment: (value: number) => void
  discount: (value: number) => void
}

export default function DiscountCard(prop: Props) {
  const { isModalVisible, finished, close, adjustment, discount } = prop
  const [isDiscount, setIsDiscount] = useState(false)

  const [form] = Form.useForm()

  // const numInputHandler = (e) => {
  //   setText(text + e.target.innerText);
  // };
  const selectAfter = (
    <Select
      defaultValue='$'
      /* onSelect={(value) => {
        if (value === '%') {
          setIsDiscount(true)
        } else {
          setIsDiscount(false)
        }
      }} */
    >
      {/*  <Option value='%'>%</Option> */}
      <Option value='$'>$</Option>
    </Select>
  )

  return (
    <Modal visible={isModalVisible} centered onCancel={close} footer={<></>} destroyOnClose>
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ offset: 1 }}
        form={form}
        onFinish={(values) => {
          discount(values.amount)
          adjustment(values.adjustment)
          finished(values)
        }}
      >
        <Tabs defaultActiveKey='1'>
          <TabPane tab={<FormattedMessage id='discount' />} key='1'>
            <Form.Item label={<FormattedMessage id='discount' />} name='amount'>
              <Input addonAfter={selectAfter} type='number' min='0.00' step='0.01' />
            </Form.Item>
            <Form.Item label={<FormattedMessage id='describe' />} name='description'>
              <Input type='text' />
            </Form.Item>

            {isDiscount ? <span style={{ marginLeft: '30%', color: 'grey' }}>Discount - Maximum 100%</span> : null}
          </TabPane>
          <TabPane tab={<FormattedMessage id='coupon' />} key='2' forceRender={true}>
            <Form.Item label={<FormattedMessage id='coupon' />} name='coupon'>
              <Input type='text' />
            </Form.Item>
          </TabPane>
          <TabPane tab={<FormattedMessage id='adjustment' />} key='3' forceRender={true}>
            <Form.Item label={<FormattedMessage id='adjustment' />} name='adjustment'>
              <Input type='number' min='0.00' step='0.01' />
            </Form.Item>
            <Form.Item label={<FormattedMessage id='describe' />} name='adjustmentComment'>
              <Input type='text' />
            </Form.Item>
          </TabPane>
        </Tabs>
        <Form.Item wrapperCol={{ offset: 19 }} shouldUpdate={true}>
          <Button type='primary' htmlType='submit'>
            {<FormattedMessage id='submit' />}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
