import { ConfigProvider, message } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import zhCN from 'antd/lib/locale/zh_CN'
import enUS from 'antd/lib/locale/en_US'
import FrontendAuth from './config/frontendAuth'
import routeMap from './config/routeMap'
import Main from './config/main'
import './App.css'
import { useAppSelector } from './redux/reduxHooks'
import { homePageData } from './redux/reducers/homePage_reducer'
import { refreshToken } from './api/response'

function App() {
  const storeState = useAppSelector(homePageData)
  dayjs.locale('zh-cn')
  useEffect(() => {
    const timerId = setInterval(() => {
      const tokenRefresh = localStorage.getItem('refreshToken')
      const tokenExpireAt = Number(localStorage.getItem('tokenExpireAt'))
      if (tokenRefresh) {
        if (dayjs().add(180, 'second') > dayjs(tokenExpireAt)) {
          refreshToken({ refreshToken: tokenRefresh }).then((res) => {
            if (res.success) {
              localStorage.setItem('token', res.data.token)
              localStorage.setItem('refreshToken', res.data.refreshToken)
              localStorage.setItem('tokenExpireAt', dayjs().add(res.data.expireTime, 'second') + '')
            } else {
              console.log('refresh token failed: ' + res.message)
            }
          })
        }
      }
    }, 30000)
    return () => {
      console.log('clear timer ' + timerId)
      clearInterval(timerId)
    }
  }, [])
  return (
    <Router>
      <ConfigProvider
        locale={storeState.lang === 'zh-CN' ? (localStorage.getItem('lang') === 'zh-CN' ? zhCN : enUS) : enUS}
        theme={{
          token: {
            colorPrimary: '#ffa500',
            colorLink: '#ffa500',
            colorIcon: '#ffa500',
            //ipad screen 1024x738 -> 1366
            screenMD: 900,
            screenMDMin: 900,
            screenMDMax: 1366,
            //laptop 1440
            screenLG: 1367,
            screenLGMin: 1367,
            screenLGMax: 1440,
            //desktop 1440=>
            screenXL: 1441,
            screenXLMin: 1441,
            screenXLMax: 1920,
            // 4k desktop
            screenXXL:1921,
            screenXXLMin: 1921
          }
        }}
      >
        <Main>
          <Switch>
            <FrontendAuth routerConfig={routeMap} />
          </Switch>
        </Main>
      </ConfigProvider>
    </Router>
  )
}

export default App
