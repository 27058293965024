import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

type initialStateTypes = {
  menu: boolean
}

const initialState: initialStateTypes = {
  menu: false
}


export const menuInfo = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenuDisplay: (state, action: PayloadAction<{ menu:boolean }>) => {
      state.menu = action.payload.menu
    },

  },

})

export const { setMenuDisplay } = menuInfo.actions //= connect(dispatch)

export const menuData = (state: RootState) => state.menu // = connect(state)

export default menuInfo.reducer
