import { Checkbox, Col, Input, Row, Switch, Tabs, TabsProps } from 'antd'
import React, { useEffect, useState } from 'react'
import DialogCard from '../../../../components/DialogCard'
import { DialogProps } from '../../../../components/DialogCard/DialogCard'
import {
  CouponInfo,
  MainContainer,
  CouponCode,
  CouponCondition,
  CouponCard,
  CouponPriceInfo,
  CouponDetails,
  StyledCheckCircleFilled
} from './AddCoupon.style'
import dayjs from 'dayjs'
import { useAddCoupon } from '../../Hooks/useAddCoupon'
import { CouponDetailTypes } from '../../../../models/coupon/couponListTypes'
import { useAppDispatch } from '../../../../redux/reduxHooks'
import { setCouponInfo } from '../../../../redux/reducers/cartItems_reducer'
import { FormattedMessage } from 'react-intl'

interface Props extends Omit<DialogProps, 'handleOk'> {
  handleOk: (val: { code: string; details: CouponDetailTypes }) => void
  setHasError: (v) => void
  hasError: string
}

const AddCoupon: React.FC<Props> = (props) => {
  const { handleOk, handleCancel, open, headerText, setHasError, hasError } = props
  const { couponDetails, chosenItem, itemClicked, insertCouponCode, validateCoupon, couponCode, couponInfo } = useAddCoupon({ setHasError })
  const dispatch = useAppDispatch()
  const items: TabsProps['items'] = [
    {
      key: '1',
      label:localStorage.getItem('lang') === 'zh-CN'? `未使用`:'Unused',
      style: { height: '15rem', overflowY: 'auto', overflowX: 'hidden' },
      children: (
        <Row gutter={[6, 16]}>
          {couponDetails.length > 0 &&
            couponDetails.map((item) => {
              if (item.status === 'enable') {
                return (
                  <Col span={8} key={item.id}>
                    <CouponCard
                      itemChosen={chosenItem === item.id}
                      onClick={() => {
                        itemClicked(item.id, item.code)
                      }}
                    >
                      <CouponPriceInfo>
                        <div style={{ fontSize: '2.1rem', color: 'orange' }}>${item.amount}</div>
                        <div style={{ fontSize: '0.8rem', color: 'gray' }}>{item.subtotal > 0 ? `满$${item.subtotal}可用` : '无门槛'}</div>
                      </CouponPriceInfo>
                      <CouponDetails>
                        <div style={{ fontSize: '1rem', fontWeight: 700, width: '80%', color: 'grey' }}>
                          {item.display.name.split(' ')[0]}
                        </div>
                        <div style={{ fontSize: '0.8rem', color: 'gray' }}>有效期:{dayjs(item.expiredAt).format('D/MM/YYYY HH:mm')}</div>
                        <div style={{ fontSize: '0.8rem', marginTop: '0.5rem', color: 'gray' }}>
                          {item.scope === 'all'
                            ? '全场可用'
                            : item.scopeList.map((item) => {
                                return item.name
                              })}
                        </div>
                      </CouponDetails>
                      {chosenItem === item.id && <StyledCheckCircleFilled />}
                    </CouponCard>
                  </Col>
                )
              }
              return null
            })}
        </Row>
      )
    },
    {
      key: '2',
      label: localStorage.getItem('lang') === 'zh-CN'? `已使用`:'Used',
      style: { height: '15rem', overflowY: 'auto', overflowX: 'hidden' },
      children: (
        <Row gutter={[6, 16]}>
          {couponDetails.length > 0 &&
            couponDetails.map((item) => {
              if (item.status === 'redeemed') {
                return (
                  <Col span={8} key={item.id}>
                    <CouponCard itemChosen={false}>
                      <CouponPriceInfo>
                        <div style={{ fontSize: '2.1rem', color: 'orange' }}>${item.amount}</div>
                        <div style={{ fontSize: '0.8rem', color: 'gray' }}>{item.subtotal > 0 ? `满$${item.subtotal}可用` : '无门槛'}</div>
                      </CouponPriceInfo>
                      <CouponDetails>
                        <div style={{ fontSize: '1rem', fontWeight: 700, width: '80%', color: 'grey' }}>
                          {item.display.name.split(' ')[0]}
                        </div>
                        <div style={{ fontSize: '0.8rem', color: 'gray' }}>有效期:{dayjs(item.expiredAt).format('D/MM/YYYY HH:mm')}</div>
                        <div style={{ fontSize: '0.8rem', marginTop: '0.5rem', color: 'gray' }}>
                          {item.scope === 'all'
                            ? '全场可用'
                            : item.scopeList.map((item) => {
                                return item.name
                              })}
                        </div>
                      </CouponDetails>
                      {chosenItem === item.id && <StyledCheckCircleFilled />}
                    </CouponCard>
                  </Col>
                )
              }
              return null
            })}
        </Row>
      )
    },
    {
      key: '3',
      label: localStorage.getItem('lang') === 'zh-CN'? `已失效`:'Expired',
      style: { height: '15rem', overflowY: 'auto', overflowX: 'hidden' },
      children: (
        <Row gutter={[6, 16]}>
          {couponDetails.length > 0 &&
            couponDetails.map((item) => {
              if (item.status === 'expired') {
                return (
                  <Col span={8} key={item.id}>
                    <CouponCard itemChosen={false}>
                      <CouponPriceInfo>
                        <div style={{ fontSize: '2.1rem', color: 'orange' }}>${item.amount}</div>
                        <div style={{ fontSize: '0.8rem', color: 'gray' }}>{item.subtotal > 0 ? `满$${item.subtotal}可用` : '无门槛'}</div>
                      </CouponPriceInfo>
                      <CouponDetails>
                        <div style={{ fontSize: '1rem', fontWeight: 700, width: '80%', color: 'grey' }}>
                          {item.display.name.split(' ')[0]}
                        </div>
                        <div style={{ fontSize: '0.8rem', color: 'gray' }}>有效期:{dayjs(item.expiredAt).format('D/MM/YYYY HH:mm')}</div>
                        <div style={{ fontSize: '0.8rem', marginTop: '0.5rem', color: 'gray' }}>
                          {item.scope === 'all'
                            ? '全场可用'
                            : item.scopeList.map((item) => {
                                return item.name
                              })}
                        </div>
                      </CouponDetails>
                      {chosenItem === item.id && <StyledCheckCircleFilled />}
                    </CouponCard>
                  </Col>
                )
              }
              return null
            })}
        </Row>
      )
    }
  ]
  return (
    <DialogCard
      open={open}
      handleOk={() => {
        if (hasError === '') {
          handleOk({ code: couponCode, details: couponInfo })
        }
      }}
      handleCancel={handleCancel}
      headerText={headerText}
    >
      <MainContainer>
        <CouponInfo>
          <CouponCode>
            {' '}
            <FormattedMessage id='coupon' />:
          </CouponCode>
          <Input
            allowClear
            size='small'
            style={{ width: '10rem' }}
            onChange={insertCouponCode}
            value={couponCode}
            onBlur={() => validateCoupon()}
            status={hasError !== '' ? 'error' : ''}
            onPressEnter={() => validateCoupon()}
          />
          {hasError !== '' && <span style={{ margin: '0 0.5rem 0 0.25rem', color: 'red' }}>{hasError}</span>}
          {!!couponInfo && (
            <CouponCondition>
              {couponInfo.display.name.split(' ')[0]}
              <span style={{ margin: '0 0.25rem 0 0.5rem', color: 'red' }}>{couponInfo.display.name.split(' ')[1]}</span>

              <span style={{ margin: '0 0.5rem 0 0.25rem', color: 'lightgrey' }}>
                {couponInfo.scope === 'all'
                  ? '全场可用'
                  : couponInfo.scopeList.map((item) => {
                      return item.name
                    })}
              </span>
            </CouponCondition>
          )}
        </CouponInfo>
        <Tabs defaultActiveKey='1' items={items} onChange={() => {}} />
      </MainContainer>
    </DialogCard>
  )
}

export default AddCoupon
