export const resources = {
  addOneFee: '费用',
  note: '备注',
  name: '名字',
  customerName: '名字',
  type: '等级',
  surname: '姓氏',
  nickname: '昵称',
  phone: '电话',
  shipmentareas : '区域',
  street : '街道/门牌',
  city : '城市',
  zipCode : '邮编',
  idCard : '身份证',
  cover : '证件照片',
  defaultAddress : '默认地址'
}

export const resources_eng = {
  ddOneFee: 'Adjustment',
  note: 'Note',
  name: 'Name',
  customerName: 'Name',
  type: 'Type',
  surname: 'Surname',
  phone: 'Phone',
  shipmentareas : 'Areas',
  street : 'Street',
  city : 'City',
  zipCode : 'PostCode',
  idCard : 'Id Card',
  cover : 'Id Photos',
  defaultAddress : 'Default'
}