import React, { useEffect } from 'react'
import CustomerInfo from '../components/customerCenter/customerInfo';
import MenuLayout from '../components/Layout/menuLayout'
import { setMenuDisplay } from '../redux/reducers/menu_reducer';
import { useAppDispatch } from '../redux/reduxHooks';

const CustomerCenter = () => {
  const dispatch  = useAppDispatch()

  useEffect(() => {
    dispatch(setMenuDisplay({
      menu: false
    }))
  }, [])

  return (
    <div>
      <MenuLayout />

      <CustomerInfo/>
    </div>
  )
}

export default CustomerCenter