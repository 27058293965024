/* eslint-disable react/display-name */
import { Tooltip, List, Image, Typography, Button, Col, Row } from 'antd'
import dayjs from 'dayjs'
import * as R from 'ramda'
import { ColumnsType } from 'antd/es/table'
import { Expiration, Item, ProductInventoryData } from '../models/productQuery'
import { Discount, Logging, OrderItemsTypes, Payment } from '../models/order/orderDetail'
import { CustomerCreditDataTypes } from '../models/customerCenterTypes/customerCreditTypes'
import { CustomerPointTypes } from '../models/customerCenterTypes/customerPointTypes'
import { PriceTier } from '../models/shoppingCartItem'
import { PriceTypes, PriceTypes_en } from './priceTypes.enum'
import api from '../config/api'
import {
  OrderReportDailyDataTypes,
  OrderReportMonthlyDataTypes,
  OrderReportWeeklyDataTypes,
  PaymentReportDailyDataTypes,
  PaymentReportMonthlyDataTypes,
  PaymentReportWeeklyDataTypes,
  ProductReportDailyDataTypes
} from '../models/reporting/reportingTypes'
import { toDecimal } from '../utils/ToDecimal'
import { FormattedMessage } from 'react-intl'
import { CashBoxLogItem, CashBoxQueryResTypes, CashItems } from '../models/cashBoxTypes'
import { useHistory } from 'react-router-dom'

const { Title, Paragraph, Text, Link } = Typography
export const logHistory: ColumnsType<Logging> = [
  {
    title: <div style={{ color: 'orange' }}>Id</div>,
    dataIndex: 'id',
    width: '2rem',
    render: (text, _record, index) => (
      <Tooltip title={text.toString()}>
        <div>{index + 1}</div>
      </Tooltip>
    )
  },
  {
    title: <div style={{ color: 'orange' }}>Description</div>,
    dataIndex: 'status',
    align: 'left',
    render: (text, record, index) => {
      return (
        <Col>
          <Row>Visible: {record.visible} status: {record.status} {record.managerName}({record.managerId}) comment: {record.comment}</Row>
        </Col>
      )
    }
  },
  {
    title: <div style={{ color: 'orange' }}>Created</div>,
    width: '6rem',
    align: 'center',
    dataIndex: 'createdAt',
    render: (text, record, index) => {
      return (<Col>
          <Row>{dayjs(record.createdAt).format(dayjs(record.createdAt).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</Row>
        </Col>
      )
    }
  },
  {
    title: <div style={{ color: 'orange' }}>Updated</div>,
    width: '6rem',
    align: 'center',
    dataIndex: 'updatedAt',
    render: (text, record, index) => {
      return (<Col>
          <Row>{dayjs(record.updatedAt).format(dayjs(record.updatedAt).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</Row>
        </Col>
      )
    }
  }
  
]
export const discountHistory: ColumnsType<Discount> = [
  {
    title: <div style={{ color: 'orange' }}>Id</div>,
    dataIndex: 'id',
    width: '2rem',
    showSorterTooltip: true,
    render: (text, _record, index) => (
      <Tooltip title={text.toString()}>
        <div>{index + 1}</div>
      </Tooltip>
    )
  },
  {
    title: <div style={{ color: 'orange' }}>Type</div>,
    align: 'center',
    width: '4rem',
    dataIndex: 'discountType'
  },
  {
    title: <div style={{ color: 'orange' }}>Discount</div>,
    align: 'center',
    dataIndex: 'discountAmount',
    width: '6rem',
    render: (text) => <div>$ {text}</div>
  },
  {
    title: <div style={{ color: 'orange' }}>Points</div>,
    align: 'center',
    width: '4rem',
    dataIndex: 'points'
  },
  {
    title: <div style={{ color: 'orange' }}>Coupon</div>,
    align: 'center',
    width: '4rem',
    dataIndex: 'coupon'
  },
  {
    title: <div style={{ color: 'orange' }}>Comment</div>,
    align: 'center',
    width: '4rem',
    dataIndex: 'comment'
  },
  {
    title: <div style={{ color: 'orange' }}>For</div>,
    align: 'center',
    width: '4rem',
    dataIndex: 'discountFor'
  },
  {
    title: <div style={{ color: 'orange' }}>Create Time</div>,
    align: 'center',
    width: '9rem',
    dataIndex: 'createdAt',
    render: (text) => {
      return (
        <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')} autoAdjustOverflow>
          <div>{dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</div>
        </Tooltip>
      )
    }
  },
  {
    title: <div style={{ color: 'orange' }}>Update Time</div>,
    align: 'center',
    width: '9rem',
    dataIndex: 'updatedAt',
    render: (text) => {
      return (
        <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')} autoAdjustOverflow>
          <div>{dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</div>
        </Tooltip>
      )
    }
  }
]

const imageUrl = (img) => {
  let url = img
  if (!!img && (img.slice(0, 7) == 'http://' || img.slice(0, 8) == 'https://'))
    url = img
  else
    url = `${api.cdn}/${img}?x-oss-process=style/640w`
  return url
}

export const paymentHistoryColumns: ColumnsType<Payment> = [
  {
    title: <div style={{ color: 'orange' }}>Id</div>,
    dataIndex: 'id',
    width: '3rem',
    render: (text, _record, index) => (
      <Tooltip title={text.toString()}>
        <div>{index + 1}</div>
      </Tooltip>
    )
  },
  {
    title: <div style={{ color: 'orange' }}>Description</div>,
    align: 'left',
    ellipsis: false,
    dataIndex: 'transactionId',
    showSorterTooltip: false,
    render: (text, record) => {
      return (
        <Col>
          <Row>storeId: {record.storeId} method: {record.method} {record.comment} manager:{record.managerId}</Row>
          <Row>{record.transactionId} {record.transactionRefundId} {record.refund||''}</Row>
        </Col>
      )
    }
  },
  {
    title: <div style={{ color: 'orange' }}>Amount</div>,
    align: 'right',
    width: '8rem',
    dataIndex: 'amount',
    render: (text, record) => (
      <div>
        ${text}
      </div>
    )
  },
  {
    title: <div style={{ color: 'orange' }}>Image</div>,
    align: 'center',
    width: '8rem',
    dataIndex: 'screenshoot',
    render: (text, record) => {
      if (record.screenshot) {
        return (
          <div style={{ display: 'flex' }}>
            <Image src={`${imageUrl(record.screenshot)}`} alt={`${record.screenshot}`} />
            <div>{text}</div>
          </div>
        )
      } else {
        return <div>{text}</div>
      }
    }
  },
  {
    title: <div style={{ color: 'orange' }}>Date Time</div>,
    align: 'center',
    width: '8rem',
    dataIndex: 'createdAt',
    render: (text) => {
      return (
        <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')}>
          <div>{dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</div>
        </Tooltip>
      )
    }
  }
]

export const dispatchListColumns: ColumnsType<Dispatch> = [
  {
    title: <div style={{ color: 'orange' }}>Id</div>,
    dataIndex: 'id',
    render: (text, _record, index) => (
      <Tooltip title={text.toString()}>
        <div>{index + 1}</div>
      </Tooltip>
    )
  },
  {
    title: <div style={{ color: 'orange' }}>Name</div>,
    dataIndex: 'name',
    align: 'center'
  },
  {
    title: <div style={{ color: 'orange' }}>SKU</div>,
    align: 'center',
    dataIndex: 'barcode'
  },
  {
    title: <div style={{ color: 'orange' }}>Shopping Weight</div>,
    align: 'center',
    dataIndex: 'shippingWeight'
  },
  {
    title: <div style={{ color: 'orange' }}>Courier Weight</div>,
    align: 'center',
    dataIndex: 'courierWeight'
  },
  {
    title: <div style={{ color: 'orange' }}>Price</div>,
    align: 'center',
    dataIndex: 'price'
  },
  {
    title: <div style={{ color: 'orange' }}>Price Type</div>,
    align: 'center',
    dataIndex: 'finalPriceType'
  },
  {
    title: <div style={{ color: 'orange' }}>Final Price</div>,
    align: 'center',
    dataIndex: 'finalPrice'
  },
  {
    title: <div style={{ color: 'orange' }}>Qty Buy</div>,
    align: 'center',
    width: '8rem',
    dataIndex: 'qtyBuy'
  },
  {
    title: <div style={{ color: 'orange' }}>Qty Allocated</div>,
    align: 'center',
    dataIndex: 'qtyAllocated'
  },
  {
    title: <div style={{ color: 'orange' }}>Create Time</div>,
    align: 'center',
    dataIndex: 'createdAt',
    render: (text) => {
      return (
        <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')}>
          <div>{dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</div>
        </Tooltip>
      )
    }
  }
]

export const printColumns: ColumnsType<OrderItemsTypes> = [
  {
    title: <div><FormattedMessage id="item" /></div>,
    dataIndex: 'shippingName',
    width: '60%',
    render: (text, record) => {
      if (record.productTypeExtraItems?.length > 0) {
        return (
          <List
            split={false}
            size='small'
            header={
              <div>
                {record.sku} {record.shippingName} {record.skuName} {record.weight ? record.weight.toFixed(3) + 'kg' : null} {record.freeShipping ? <FormattedMessage id="freeShipment" /> : null}
              </div >
            }
            dataSource={record.productTypeExtraItems}
            renderItem={(item) => (
              <List.Item>
                {item.sku} {item.shippingName} {item.skuName || ''} {record.expiration || ''} * {item.selectionQty}
              </List.Item>
            )}
          />
        )
      } else {
        return (
          <div>
            {record.sku} {record.shippingName} {record.skuName} {record.expiration || ''} {record.weight ? record.weight.toFixed(3) + 'kg' : null} {record.freeShipping ? <FormattedMessage id="freeShipment" /> : null}
          </div>
        )
      }
    }
  },
  {
    title: <div><FormattedMessage id="weight" /></div>,
    dataIndex: 'weight',
    align: 'center',
    width: '10%',
    render: (text, record) => (
      <div> {record.weight > 0 ? record.weight.toFixed(3) + 'kg' : '-'} </div>
    )
  },
  {
    title: <div><FormattedMessage id="price" /></div>,
    dataIndex: 'finalPrice',
    align: 'center',
    width: '10%',
    render: (text, record) => (
      <div> ${record.finalPrice.toFixed(2)} </div>
    )
  },
  {
    title: <div><FormattedMessage id="qty" /></div>,
    dataIndex: 'qtyOrdered',
    width: '10%',
    align: 'center'
  },
  {
    title: <div><FormattedMessage id="subtotal" /></div>,
    dataIndex: 'rowTotal',
    width: '10%',
    align: 'center',
    render: (text) => <div>${text.toFixed(2)}</div>
  }
]

export const depositHistoryColumns: ColumnsType<CustomerCreditDataTypes> = [
  {
    title: <div>ID</div>,
    dataIndex: 'id',
    align: 'center',
    render: (text) => text.toString()
  },
  {
    title: <div>Type</div>,
    dataIndex: 'typeName',
    width: '10%',
    align: 'center'
  },
  {
    title: <div>Amount</div>,
    dataIndex: 'amount',
    width: '10%',
    align: 'center'
  },
  {
    title: <div>Balance</div>,
    dataIndex: 'balance',
    width: '10%',
    align: 'center'
  },
  {
    title: <div>Description</div>,
    dataIndex: 'description',
    align: 'center'
  },
  {
    title: <div>Created</div>,
    dataIndex: 'createdAt',
    align: 'center',
    width: '10%',
    render: (text) => {
      return (
        <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')} autoAdjustOverflow>
          <div>{dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</div>
        </Tooltip>
      )
    }
  }
]

export const pointHistoryColumns: ColumnsType<CustomerPointTypes> = [
  {
    title: <div>Id</div>,
    dataIndex: 'id',
    align: 'center'
  },
  {
    title: <div>Action</div>,
    dataIndex: 'title',
    align: 'center'
  },
  {
    title: <div>Change</div>,
    dataIndex: 'points',
    align: 'center'
  },
  {
    title: <div>Spent</div>,
    dataIndex: 'spent',
    align: 'center'
  },
  {
    title: <div>Description</div>,
    dataIndex: 'description',
    width: '18%',
    align: 'center',
    ellipsis: true,
    showSorterTooltip: true
  },
  {
    title: <div>Expiration</div>,
    dataIndex: 'expiredAt',
    align: 'center',
    render: (text) => {
      return (
        <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')} autoAdjustOverflow>
          <div>{text > 0 ? dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY') : ''}</div>
        </Tooltip>
      )
    }
  },
  {
    title: <div>Created</div>,
    dataIndex: 'createdAt',
    align: 'center',
    render: (text) => {
      return (
        <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')} autoAdjustOverflow>
          <div>{dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</div>
        </Tooltip>
      )
    }
  },
  {
    title: <div>Updated</div>,
    dataIndex: 'updatedAt',
    align: 'center',
    render: (text) => {
      return (
        <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')} autoAdjustOverflow>
          <div>{dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</div>
        </Tooltip>
      )
    }
  }
]

export const pointHistoryColumnsSimple: ColumnsType<CustomerPointTypes> = [
  {
    title: <div>Id</div>,
    dataIndex: 'id',
    align: 'center'
  },
  {
    title: <div>Customer</div>,
    dataIndex: 'customerId',
    align: 'center'
  },
  {
    title: <div>Action</div>,
    dataIndex: 'title',
    align: 'center'
  },
  {
    title: <div>Points</div>,
    dataIndex: 'points',
    align: 'center'
  },
  {
    title: <div>Balance</div>,
    dataIndex: 'balance',
    align: 'center'
  },
  {
    title: <div>Spent</div>,
    dataIndex: 'spent',
    align: 'center'
  },
  {
    title: <div>Description</div>,
    dataIndex: 'description',
    width: '20%',
    align: 'center',
    ellipsis: true,
    showSorterTooltip: true
  },
  {
    title: <div>Expiration</div>,
    dataIndex: 'expiredAt',
    align: 'center',
    render: (text) => {
      return (
        <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')} autoAdjustOverflow>
          <div>{text > 0 ? dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY') : ''}</div>
        </Tooltip>
      )
    }
  },
  {
    title: <div>Created</div>,
    dataIndex: 'createdAt',
    align: 'center',
    render: (text) => {
      return (
        <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')} autoAdjustOverflow>
          <div>{dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</div>
        </Tooltip>
      )
    }
  },
  {
    title: <div>Updated</div>,
    dataIndex: 'updatedAt',
    align: 'center',
    render: (text) => {
      return (
        <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')} autoAdjustOverflow>
          <div>{dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</div>
        </Tooltip>
      )
    }
  }
]

export const creditHistoryColumns: ColumnsType<CustomerCreditDataTypes> = [
  {
    title: <div>Id</div>,
    dataIndex: 'id',
    width: '20%',
    render: (text) => text.toString()
  },
  {
    title: <div>Customer Id</div>,
    dataIndex: 'customerId',
    width: '10%'
  },
  {
    title: <div>Type</div>,
    dataIndex: 'typeName',
    width: '10%',
    align: 'center'
  },
  {
    title: <div>Description</div>,
    dataIndex: 'description',
    width: '20%',
    align: 'center'
  },
  {
    title: <div>Amount</div>,
    dataIndex: 'amount',
    width: '10%',
    align: 'center'
  },
  {
    title: <div>Balance</div>,
    dataIndex: 'balance',
    width: '10%',
    align: 'center'
  },
  {
    title: <div>Time</div>,
    dataIndex: 'createdAt',
    align: 'center',
    width: '10%',
    render: (text) => {
      return (
        <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')} autoAdjustOverflow>
          <div>{dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</div>
        </Tooltip>
      )
    }
  }
]

export const productInventoryColumns: ColumnsType<Item> = [
  {
    title: <div>SKU</div>,
    dataIndex: 'sku',
    align: 'center',
    render: (_a, item) => {
      if (!!item.skuName) {
        return (
          <>
            <div> {item.skuName}</div>
            <div style={{ fontSize: '12px', color: 'lightgray' }}>SKU: {item.sku}</div>
          </>
        )
      }
      return item.sku ? ` SKU: ${item.sku}` : ''
    }
  },
  {
    title: <div>Expiration</div>,
    dataIndex: 'expiration',
    align: 'center',
    render: (text: Expiration) => {
      switch (text.type) {
        case 0:
          return 'None'
        case 1:
          return <FormattedMessage id='market' />
        case 2:
          return `${dayjs(text.date).format('D/M/YYYY')}`
        case 3:
          return `${dayjs(text.date).format('M/YYYY')}`
        case 4:
          return (
            <>
              <FormattedMessage id='afterOpen' />
              {dayjs(text.date).format('D')}
              <FormattedMessage id='day' />
            </>
          )
        case 5:
          return (
            <>
              <FormattedMessage id='afterOpen' />
              {dayjs(text.date).format('MM')}
              <FormattedMessage id='month' />
            </>
          )
        default:
          break
      }
    }
  },

  {
    title: <div>Price Tires</div>,
    dataIndex: 'priceTiers',
    align: 'center',
    render: (text: PriceTier[]) => {
      const exchangeRate = sessionStorage.getItem('exchangeRate')
      if (!text.length) {
        return <></>
      }
      return text.map((item, index) => {
        return (
          <div key={index}>
            {item.unitMin} <FormattedMessage id='minUnit' /> {item.price}￥{(item.price * +exchangeRate).toFixed(2)}
          </div>
        )
      })
    }
  },
  {
    title: <div>Final Price</div>,
    dataIndex: 'finalPrice',
    align: 'center',
    render: (_a, item) => {
      const exchangeRate = sessionStorage.getItem('exchangeRate')
      return (
        <>
          <div>
            <span>
              {sessionStorage.getItem('lang') === 'zh-CN'
                ? `${PriceTypes[item.finalPriceType]} $${item.finalPrice}`
                : `${PriceTypes_en[item.finalPriceType]} $${item.finalPrice}`}
            </span>
            <span>
              {item.priceUnderlined > 0 ? (
                <del style={{ fontSize: '12px', color: 'lightgray', marginLeft: '5px' }}>{`$${item.priceUnderlined}`}</del>
              ) : null}
            </span>
          </div>
          <div>
            <span>{`￥${(item.finalPrice * +exchangeRate).toFixed(2)}`}</span>
            <span>
              {item.priceUnderlined > 0 ? (
                <del style={{ fontSize: '12px', color: 'lightgray', marginLeft: '5px' }}>{`￥${(
                  item.priceUnderlined * +exchangeRate
                ).toFixed(2)}`}</del>
              ) : null}{' '}
            </span>
          </div>
        </>
      )
    }
  },
  {
    title: <div></div>,
    dataIndex: 'unitMin',
    align: 'center',
    render: (_a, item) => {
      return (
        <div>
          {item.unitMin > 1 ? <span>Min: {item.unitMin}</span> : null}
          {item.unitMax > 0 ? <span>Max: {item.unitMax}</span> : null}
        </div>
      )
    }
  },
  {
    title: <div>Location</div>,
    dataIndex: 'stockLocationName',
    align: 'center'
  },
  {
    title: <div>Quantity</div>,
    dataIndex: 'stock',
    align: 'center',
    render: (_a, item) => {
      if (item.stockVisible) {
        return item.stock
      }
      return <FormattedMessage id='hasStock' />
    }
  },
  {
    title: <div>Last Sold</div>,
    dataIndex: 'soldAt',
    align: 'center',
    render: (text) => {
      if (text > 0)
        return (
          <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')}>
            <div>{dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</div>
          </Tooltip>
        )
      else return '-'
    }
  }
]

export const productColumns: ColumnsType<OrderItemsTypes> = [
  {
    title: <div style={{ color: 'orange' }}>Item</div>,
    key: 'productName',
    render: (_text, record) => {
      if (record.productTypeExtraItems?.length > 0) {
        return (
          <List
            split={false}
            size='small'
            header={
              <div>
                [{record.priceClass}] [{record.vendorName}] [{record.locationName}]
                <span style={{ marginLeft: '5px', color: 'gray' }}>{record.isSelfFulfillment ? <FormattedMessage id='store' /> : <FormattedMessage id='warehouse' />}</span>
                {R.isEmpty(record.shelf) || R.isNil(record.shelf) ? null : (<span>[{record.shelf}]</span>)} {record.sku || ''} {record.shippingName} {record.skuName || ''} {record.weight ? record.weight.toFixed(3) + 'kg' : ''} {record.freeShipping ? <FormattedMessage id="freeShipment" /> : ''} 
              </div>
            }
            dataSource={record.productTypeExtraItems}
            renderItem={(item) => (
              <List.Item>
                {item.sku} {item.shippingName} {item.skuName || ''} {record.expiration || ''} * {item.selectionQty}
              </List.Item>
            )}
          />
        )
      } else {
        return (
          <div>
            [{record.priceClass}] [{record.vendorName}] [{record.locationName}]
            <span style={{ marginLeft: '5px', color: 'gray' }}>{record.isSelfFulfillment ? <FormattedMessage id='store' /> : <FormattedMessage id='warehouse' />}</span>
            {R.isEmpty(record.shelf) || R.isNil(record.shelf) ? null : (<span>[{record.shelf}]</span>)} {record.freeShipping ? <FormattedMessage id="freeShipment" /> : null} {record.sku || ''} {record.expiration || ''} {record.weight ? record.weight.toFixed(3) + 'kg' : null}<br />
            {record.shippingName} {record.skuName || ''}
          </div>
        )
      }
    }
  },
  {
    title: <div style={{ color: 'orange' }}>Price</div>,
    dataIndex: 'finalPrice',
    align: 'center',
    render: (_text, record) => {
      return (
        <div>
          <span>${record.finalPrice.toFixed(2)}</span>
          <br />
          <span style={{ textDecoration: 'line-through', color: 'gray', marginLeft: '5px' }}>${record.price.toFixed(2)}</span>
        </div>
      )
    }
  },
  {
    title: <div style={{ color: 'orange' }}>Qty</div>,
    dataIndex: 'qtyOrdered',
    align: 'center',
  },
  {
    title: <div style={{ color: 'orange' }}>Subtotal</div>,
    dataIndex: 'rowTotal',
    align: 'center',
    render: (text) => <div>${text.toFixed(2)}</div>
  },
  {
    title: <div style={{ color: 'orange' }}>Last Sold</div>,
    dataIndex: 'lastSoldAt',
    align: 'center',
    render: (text) => {
      if (text && text > 0)
        return (
          <Tooltip title={dayjs(text).format('DD/MM/YYYY HH:mm')} autoAdjustOverflow>
            <div style={{ color: dayjs(text).isBefore(dayjs().subtract(30, 'day')) ? 'red' : 'green' }}>{dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</div>
          </Tooltip>
        )
      else
        return '-'
    }
  }
]

export const paymentReportDailyColumns: ColumnsType<PaymentReportDailyDataTypes> = [
  {
    title: <div>Day</div>,
    dataIndex: 'day',
    align: 'center',
    render: (text) => (
      <>
        <div>{dayjs(text).format('DD/MM/YYYY')}</div>
      </>
    )
  },
  {
    title: <div>Store</div>,
    dataIndex: 'storeName',
    ellipsis: true,
    align: 'center',
    render: (_text, item) => `${item.storeName || ''} ${item.storeId ? `(${item.storeId})` : ''}`
  },
  {
    title: <div>Manager</div>,
    dataIndex: 'managerName',
    ellipsis: true,
    align: 'center',
    render: (_text, item) => `${item.managerName || ''} ${item.managerId ? `(${item.managerId})` : ''}`
  },
  {
    title: <div>Amount</div>,
    dataIndex: 'amount',
    align: 'center'
  },
  {
    title: <div>Detail</div>,
    dataIndex: 'detail',
    render: (items) => (
      <>
        {items.map((item) => {
          return (
            <Text>
              {item.method} ${item.amount}{' '}
            </Text>
          )
        })}
      </>
    )
  }
]

export const paymentReportWeeklyColumns: ColumnsType<PaymentReportWeeklyDataTypes> = [
  {
    title: <div>Year</div>,
    dataIndex: 'year',
    align: 'center'
  },
  {
    title: <div>Week</div>,
    dataIndex: 'week',
    align: 'center'
  },
  {
    title: <div>Store</div>,
    dataIndex: 'storeName',
    ellipsis: true,
    align: 'center',
    render: (_text, item) => `${item.storeName || ''} ${item.storeId ? `(${item.storeId})` : ''}`
  },
  {
    title: <div>Manager</div>,
    dataIndex: 'managerName',
    ellipsis: true,
    align: 'center',
    render: (_text, item) => `${item.managerName || ''} ${item.managerId ? `(${item.managerId})` : ''}`
  },
  {
    title: <div>Amount</div>,
    dataIndex: 'amount',
    align: 'center'
  },
  {
    title: <div>Detail</div>,
    dataIndex: 'detail',
    render: (items) => (
      <>
        {items.map((item) => {
          return (
            <Text>
              {item.method} ${item.amount}{' '}
            </Text>
          )
        })}
      </>
    )
  }
]

export const paymentReportMonthlyColumns: ColumnsType<PaymentReportMonthlyDataTypes> = [
  {
    title: <div>Year</div>,
    dataIndex: 'year',
    align: 'center'
  },
  {
    title: <div>Month</div>,
    dataIndex: 'month',
    align: 'center'
  },
  {
    title: <div>Store</div>,
    dataIndex: 'storeName',
    ellipsis: true,
    align: 'center',
    render: (_text, item) => `${item.storeName || ''} ${item.storeId ? `(${item.storeId})` : ''}`
  },
  {
    title: <div>Manager</div>,
    dataIndex: 'managerName',
    ellipsis: true,
    align: 'center',
    render: (_text, item) => `${item.managerName || ''} ${item.managerId ? `(${item.managerId})` : ''}`
  },
  {
    title: <div>Amount</div>,
    dataIndex: 'amount',
    align: 'center'
  },
  {
    title: <div>Detail</div>,
    dataIndex: 'detail',
    render: (items) => (
      <>
        {items.map((item) => {
          return (
            <Text>
              {item.method} ${item.amount}{' '}
            </Text>
          )
        })}
      </>
    )
  }
]

export const productReportDailyColumns: ColumnsType<ProductReportDailyDataTypes> = [
  {
    title: <div>Day</div>,
    dataIndex: 'day',
    align: 'center',
    render: (text) => (
      <>
        <div>{dayjs(text).format('DD/MM/YYYY')}</div>
      </>
    )
  },
  {
    title: <div>Store</div>,
    dataIndex: 'storeName',
    ellipsis: true,
    align: 'center',
    render: (_text, item) => `${item.storeName || ''} ${item.storeId ? `(${item.storeId})` : ''}`
  },
  {
    title: <div>Manager</div>,
    dataIndex: 'managerName',
    ellipsis: true,
    align: 'center',
    render: (_text, item) => `${item.managerName || ''} ${item.managerId ? `(${item.managerId})` : ''}`
  },
  {
    title: <div>Description</div>,
    dataIndex: 'productName',
    ellipsis: false,
    align: 'center',
    render: (_a, item) => {
      if (!!item.skuName) {
        return (
          <div style={{ whiteSpace: 'normal' }}>
            {item.productName} - {item.skuName}
          </div>
        )
      }
      return item.skuId ? (
        <div style={{ whiteSpace: 'normal' }}>
          {item.productName} - {item.skuId}
        </div>
      ) : (
        <div style={{ whiteSpace: 'normal' }}>{item.productName}</div>
      )
    }
  },
  {
    title: <div>Profit</div>,
    dataIndex: 'profit',
    ellipsis: true,
    align: 'center',
    render: (text: number) => `$ ${text.toFixed(2)}`
  },
  {
    title: <div>Bonus</div>,
    dataIndex: 'bonus',
    ellipsis: true,
    align: 'center',
    render: (text: number) => `$ ${text.toFixed(2)}`
  },
  {
    title: <div>Amount</div>,
    dataIndex: 'amount',
    align: 'center',
    render: (text, item) => (item.amountRemoved ? `${text} - ${item.amountRemoved} = ${text - item.amountRemoved}` : text)
  }
]

export const productReportWeeklyColumns: ColumnsType<ProductReportDailyDataTypes> = [
  {
    title: <div>Year</div>,
    dataIndex: 'year',
    align: 'center'
  },
  {
    title: <div>Week</div>,
    dataIndex: 'week',
    align: 'center'
  },
  {
    title: <div>Store</div>,
    dataIndex: 'storeName',
    ellipsis: true,
    align: 'center',
    render: (_text, item) => `${item.storeName || ''} ${item.storeId ? `(${item.storeId})` : ''}`
  },
  {
    title: <div>Manager</div>,
    dataIndex: 'managerName',
    ellipsis: true,
    align: 'center',
    render: (_text, item) => `${item.managerName || ''} ${item.managerId ? `(${item.managerId})` : ''}`
  },
  {
    title: <div>Description</div>,
    dataIndex: 'productName',
    ellipsis: false,
    align: 'center',
    render: (_a, item) => {
      if (!!item.skuName) {
        return (
          <div style={{ whiteSpace: 'normal' }}>
            {item.productName} - {item.skuName}
          </div>
        )
      }
      return item.skuId ? (
        <div style={{ whiteSpace: 'normal' }}>
          {item.productName} - {item.skuId}
        </div>
      ) : (
        <div style={{ whiteSpace: 'normal' }}>{item.productName}</div>
      )
    }
  },
  {
    title: <div>Profit</div>,
    dataIndex: 'profit',
    ellipsis: true,
    align: 'center',
    render: (text: number) => `$ ${text.toFixed(2)}`
  },
  {
    title: <div>Bonus</div>,
    dataIndex: 'bonus',
    ellipsis: true,
    align: 'center',
    render: (text: number) => `$ ${text.toFixed(2)}`
  },
  {
    title: <div>Amount</div>,
    dataIndex: 'amount',
    align: 'center',
    render: (text, item) => (item.amountRemoved ? `${text} - ${item.amountRemoved} = ${text - item.amountRemoved}` : text)
  }
]
export const productReportMonthlyColumns: ColumnsType<ProductReportDailyDataTypes> = [
  {
    title: <div>Year</div>,
    dataIndex: 'year',
    align: 'center'
  },
  {
    title: <div>Month</div>,
    dataIndex: 'month',
    align: 'center'
  },
  {
    title: <div>Store</div>,
    dataIndex: 'storeName',
    ellipsis: true,
    align: 'center',
    render: (_text, item) => `${item.storeName || ''} ${item.storeId ? `(${item.storeId})` : ''}`
  },
  {
    title: <div>Manager</div>,
    dataIndex: 'managerName',
    ellipsis: true,
    align: 'center',
    render: (_text, item) => `${item.managerName || ''} ${item.managerId ? `(${item.managerId})` : ''}`
  },
  {
    title: <div>Description</div>,
    dataIndex: 'productName',
    ellipsis: false,
    align: 'center',
    render: (_a, item) => {
      if (!!item.skuName) {
        return (
          <div style={{ whiteSpace: 'normal' }}>
            {item.productName} - {item.skuName}
          </div>
        )
      }
      return item.skuId ? (
        <div style={{ whiteSpace: 'normal' }}>
          {item.productName} - {item.skuId}
        </div>
      ) : (
        <div style={{ whiteSpace: 'normal' }}>{item.productName}</div>
      )
    }
  },
  {
    title: <div>Profit</div>,
    dataIndex: 'profit',
    ellipsis: true,
    align: 'center',
    render: (text: number) => `$ ${text.toFixed(2)}`
  },
  {
    title: <div>Bonus</div>,
    dataIndex: 'bonus',
    ellipsis: true,
    align: 'center',
    render: (text: number) => `$ ${text.toFixed(2)}`
  },
  {
    title: <div>Amount</div>,
    dataIndex: 'amount',
    align: 'center',
    render: (text, item) => (item.amountRemoved ? `${text} - ${item.amountRemoved} = ${text - item.amountRemoved}` : text)
  }
]

export const orderReportDailyColumns: ColumnsType<OrderReportDailyDataTypes> = [
  {
    title: <div>Day</div>,
    dataIndex: 'day',
    align: 'center',
    render: (text) => (
      <>
        <div>{dayjs(text).format('DD/MM/YYYY')}</div>
      </>
    )
  },
  {
    title: <div>Store</div>,
    dataIndex: 'storeName',
    ellipsis: true,
    align: 'center',
    render: (_text, item) => `${item.storeName || ''} ${item.storeId ? `(${item.storeId})` : ''}`
  },
  {
    title: <div>Manager</div>,
    dataIndex: 'managerName',
    ellipsis: true,
    align: 'center',
    render: (_text, item) => `${item.managerName || ''} ${item.managerId ? `(${item.managerId})` : ''}`
  },
  {
    title: <div>Revenue</div>,
    dataIndex: 'total',
    ellipsis: true,
    align: 'center',
    render: (text) => `$ ${text.toFixed(2)}`
  },
  {
    title: <div>Profit</div>,
    dataIndex: 'profit',
    ellipsis: true,
    align: 'center',
    render: (text: number) => `$ ${text.toFixed(2)}`
  },
  {
    title: <div>Bonus</div>,
    dataIndex: 'bonus',
    align: 'center',
    render: (text) => `$ ${text.toFixed(2)}`
  }
]

export const orderReportWeeklyColumns: ColumnsType<OrderReportWeeklyDataTypes> = [
  {
    title: <div>Year</div>,
    dataIndex: 'year',
    align: 'center'
  },
  {
    title: <div>Week</div>,
    dataIndex: 'week',
    align: 'center'
  },
  {
    title: <div>Store</div>,
    dataIndex: 'storeName',
    ellipsis: true,
    align: 'center',
    render: (_text, item) => `${item.storeName || ''} ${item.storeId ? `(${item.storeId})` : ''}`
  },
  {
    title: <div>Manager</div>,
    dataIndex: 'managerName',
    ellipsis: true,
    align: 'center',
    render: (_text, item) => `${item.managerName || ''} ${item.managerId ? `(${item.managerId})` : ''}`
  },
  {
    title: <div>Revenue</div>,
    dataIndex: 'total',
    ellipsis: true,
    align: 'center',
    render: (text) => `$ ${text.toFixed(2)}`
  },
  {
    title: <div>Profit</div>,
    dataIndex: 'profit',
    ellipsis: true,
    align: 'center',
    render: (text: number) => `$ ${text.toFixed(2)}`
  },
  {
    title: <div>Bonus</div>,
    dataIndex: 'bonus',
    align: 'center',
    render: (text) => `$ ${text.toFixed(2)}`
  }
]
export const orderReportMonthlyColumns: ColumnsType<OrderReportMonthlyDataTypes> = [
  {
    title: <div>Year</div>,
    dataIndex: 'year',
    align: 'center'
  },
  {
    title: <div>Month</div>,
    dataIndex: 'month',
    align: 'center'
  },
  {
    title: <div>Store</div>,
    dataIndex: 'storeName',
    ellipsis: true,
    align: 'center',
    render: (_text, item) => `${item.storeName || ''} ${item.storeId ? `(${item.storeId})` : ''}`
  },
  {
    title: <div>Manager</div>,
    dataIndex: 'managerName',
    ellipsis: true,
    align: 'center',
    render: (_text, item) => `${item.managerName || ''} ${item.managerId ? `(${item.managerId})` : ''}`
  },
  {
    title: <div>Revenue</div>,
    dataIndex: 'total',
    ellipsis: true,
    align: 'center',
    render: (text) => `$ ${text.toFixed(2)}`
  },
  {
    title: <div>Profit</div>,
    dataIndex: 'profit',
    ellipsis: true,
    align: 'center',
    render: (text: number) => `$ ${text.toFixed(2)}`
  },
  {
    title: <div>Bonus</div>,
    dataIndex: 'bonus',
    align: 'center',
    render: (text) => `$ ${text.toFixed(2)}`
  }
]
export const cashBoxHistoryColumns: ColumnsType<CashBoxLogItem> = [
  {
    title: <div>Id</div>,
    dataIndex: 'id',
    align: 'center'
  },
  {
    title: <div>Box</div>,
    dataIndex: 'boxId',
    align: 'center'
  },
  {
    title: <div>Store Name</div>,
    dataIndex: 'storeName',
    align: 'center',
    render: (_text, item) => `${item.storeName || ''} ${item.storeId ? `(${item.storeId})` : ''}`
  },
  {
    title: <div>Manager Name</div>,
    dataIndex: 'managerName',
    align: 'center',
    render: (_text, item) => `${item.managerName || ''} ${item.managerId ? `(${item.managerId})` : ''}`
  },
  {
    title: <div>Type</div>,
    dataIndex: 'type',
    align: 'center'
  },
  {
    title: <div>Order Id</div>,
    dataIndex: 'orderId',
    align: 'center'
  },
  {
    title: <div>Begin</div>,
    dataIndex: 'begin',
    align: 'center'
  },
  {
    title: <div>End</div>,
    dataIndex: 'end',
    align: 'center'
  },
  {
    title: <div>Amount</div>,
    dataIndex: 'amount',
    align: 'center'
  },
  {
    title: <div>Items</div>,
    dataIndex: 'items',
    align: 'center',
    render: (text: CashItems[]) => {
      return (
        <div>
          {text.map((item, index) => (
            <div key={index}>
              ${item.value} - {item.quantity}
            </div>
          ))}
        </div>
      )
    }
  },
  {
    title: <div>comment</div>,
    dataIndex: 'comment',
    align: 'center'
  },
  {
    title: <div>Images</div>,
    dataIndex: 'images',
    align: 'center',
    render: (text, record) => {
      if (record.images.length > 0) {
        record.images.map((item) => {
          return (
            <div style={{ display: 'flex' }} key={item}>
              <Image src={`${imageUrl(item)}`} alt='image1' />
            </div>
          )
        })
      } else {
        return null
      }
    }
  },

  {
    title: <div>Created</div>,
    dataIndex: 'createdAt',
    align: 'center',
    render: (text) => {
      return (
        <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')} autoAdjustOverflow>
          <div>{text > 0 ? dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY') : ''}</div>
        </Tooltip>
      )
    }
  },
  {
    title: <div>Updated</div>,
    dataIndex: 'updatedAt',
    align: 'center',
    render: (text) => {
      return (
        <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')} autoAdjustOverflow>
          <div>{dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</div>
        </Tooltip>
      )
    }
  }
]
