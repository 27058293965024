import { Cascader, Col, Row, message } from 'antd'
import React from 'react'
import DialogCard from '../../../../components/DialogCard'
import { DialogProps } from '../../../../components/DialogCard/DialogCard'
import { setShipmentMethodInfo, shoppingCartData } from '../../../../redux/reducers/cartItems_reducer'
import { useAppDispatch, useAppSelector } from '../../../../redux/reduxHooks'
import { useAddShipment } from '../../Hooks/useAddShipment'
import { MainContainer, ShipmentCard, StyledCheckCircleFilled } from './AddShipment.style'
import { ShipmentMethodTypes } from '../../../../models/shipping/shippingMethod'
import { getShipmentAreaAndGroupId } from '../../../../redux/reducers/customerInfo_reducer'
import { FormattedMessage } from 'react-intl'

interface Props extends Omit<DialogProps, 'handleOk'> {
  shipmentType: string
  handleOk: (val: ShipmentMethodTypes) => void
}

const AddShipment: React.FC<Props> = (props) => {
  const { handleOk, handleCancel, open, headerText, shipmentType } = props
  const shoppingCartInfo = useAppSelector(shoppingCartData)
  const { convertDataToOptions, shipmentAreaChangeHandler, shipmentMethods, itemClicked, chosenItem, loading } = useAddShipment({
    shipmentType
  })
  const { areaCode, groupId } = useAppSelector(getShipmentAreaAndGroupId)
  const dispatch = useAppDispatch()

  return (
    <DialogCard
      open={open}
      handleOk={() => {
        if (!chosenItem) {
          message.error('No shipment method selected')
          return
        }
        dispatch(setShipmentMethodInfo({ info: shipmentMethods.find((item) => item.id === chosenItem) }))
        handleOk(shipmentMethods.find((item) => item.id === chosenItem))
      }}
      handleCancel={handleCancel}
      headerText={headerText}
    >
      <MainContainer>
        <Row wrap={false} style={{ width: '100%' }}>
          <Col span={3} style={{ color: 'orange' }}>
            <FormattedMessage id='shipmentArea' />
          </Col>
          <Col span={21}>
            <Cascader
              options={convertDataToOptions}
              onChange={shipmentAreaChangeHandler}
              placeholder='Please select'
              loading={loading}
              value={loading || groupId === 0 ? null : [groupId, areaCode]}
            />
          </Col>
        </Row>
        <Row wrap={false}>
          <Col span={3} style={{ marginTop: '1rem', color: 'orange', verticalAlign: 'top' }}>
            <FormattedMessage id='shippingMethod' />
          </Col>
          <Col span={21} style={{ display: 'flex', flexDirection: 'column', width: '80%', height: '20rem', overflow: 'auto' }}>
            {shipmentMethods.length > 0 &&
              shipmentMethods.map((item) => {
                return (
                  <ShipmentCard chosen={chosenItem === item.id} onClick={() => itemClicked(item.id)} key={item.id}>
                    <div style={{ padding: '0.5rem 1rem' }}>
                      <div style={{ color: 'orange' }}>
                        {item.name}
                        {item.additionalPrice > 0 ? (
                          <span style={{ marginLeft: '0.5rem', color: 'red' }}>
                            <FormattedMessage id="surcharge" />: {item.additionalPrice.toFixed(2)}
                          </span>
                        ) : null}
                      </div>
                      <div style={{ color: 'red' }}>
                        {item.freeshipEnable && item.freeshipSubtotal > 0
                          ? `$${item.freeshipSubtotal.toFixed(2)}/￥${(item.freeshipSubtotal * shoppingCartInfo.currencyRate).toFixed(2)}+ `
                          : null}<FormattedMessage id="freeShipment" />
                      </div>
                      {!!item.notice && (
                        <Row>
                          <FormattedMessage id="notice" />:{item.notice}
                        </Row>
                      )}
                    </div>
                    {chosenItem === item.id && <StyledCheckCircleFilled />}
                  </ShipmentCard>
                )
              })}
          </Col>
        </Row>
      </MainContainer>
    </DialogCard>
  )
}

export default AddShipment
