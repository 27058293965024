/* eslint-disable multiline-ternary */
/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Layout,
  List,
  message,
  Row,
  Space,
  Table,
  Tooltip,
  InputNumber,
  Select,
  Image,
  Radio
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage,IntlShape } from 'react-intl'
import MenuLayout from '../components/Layout/menuLayout'
import styled from 'styled-components'
import {
  orderDetail,
  putItemToCart,
  updateShippingAddress,
  sendMessageToCustomer,
  resSelfPicking,
  cancelOrderRes,
  orderProcessingRes,
  orderShipmentRes,
  getCourierInfo,
  updateShipmentInfo,
  parcelShipment,
  completeShipment,
  deleteShipmentInfo,
  parcelShipment2,
  orderRefund,
  updateShipmentMethod
} from '../api/response'
import dayjs from 'dayjs'
import * as R from 'ramda'
import isToday from 'dayjs/plugin/isToday'
import TextArea from 'antd/lib/input/TextArea'
import { logHistory, discountHistory, paymentHistoryColumns, productColumns } from '../data/tableColumns'
import CustomerShippingAddress from '../components/manageCustomer/customerAddress'
import ModalForm from '../components/common/modal-form'
import PicturesWall from '../components/common/picture_wall'
import Text from 'antd/lib/typography/Text'
import copy from 'copy-to-clipboard'
import NotifyModal from '../components/common/notifyModal'
import {
  getCurrencyRate,
  setCartChange,
  setCartIncrease,
  setCartItems,
  setTotalPrice,
  shoppingCartData
} from '../redux/reducers/cartItems_reducer'
import { OrderDetailResTypes, OrderItemsTypes, Payment, Shipment, PickingItem } from '../models/order/orderDetail'
import { setMenuDisplay } from '../redux/reducers/menu_reducer'
import { homePageData } from '../redux/reducers/homePage_reducer'
import { setCustomerInfo } from '../redux/reducers/customerInfo_reducer'
import { ColumnsType } from 'antd/es/table'
import { setPlaceorderSuccess } from '../redux/reducers/orders_reducer'
import { useAppDispatch, useAppSelector } from '../redux/reduxHooks'
import OrderList from '../components/order/orderList'
import { OrderCourierTypes } from '../models/order/orderHistory'
import api from '../config/api'
import ShipmentDialog from '../components/shipment'
import { ShipmentMethodTypes } from '../models/shipping/shippingMethod'
import { customerInfo } from '../utils/CustomerInfo'

const { Content } = Layout
const { Option } = Select
dayjs.extend(isToday)

const StyledContent = styled(Content)`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  .ant-empty {
    display: none;
  }
`
const StyledHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  height: 65px;
  color: black;
  font-weight: 600;
  background-color: #fff;
  display:flex;
  align-items: center;
`

const CardStyle = styled(Card)`
  .ant-card-head > .ant-card-head-wrapper {
    margin-left: 10px;
    margin-righ: 10px;
  }
`
const CardRow = styled(Row)`
  margin-right: -6px;
  .ant-card ant-card-bordered ant-card-small > .ant-card-head > .ant-card-head-wrapper {
    margin-left: -10px;
  }
  height: fit-content;
  margin-bottom: 1rem;
  width: 99%;
`

const OrderHistory = (props) => {
  const [chooseId, setChooseId] = useState()
  const [choosePrice, setChoosePrice] = useState<number>()
  const [orderCreateTime, setOrderCreateTime] = useState<number | string>()
  const [orderStatus, setOrderStatus] = useState('')
  const [orderDetails, setOrderDetails] = useState<OrderDetailResTypes>()
  const [paymentList, setPaymentList] = useState<Payment[]>([])
  const [orderItems, setOrderItems] = useState<OrderItemsTypes[]>([])
  const [pickingItems, setPickingItems] = useState<PickingItem[]>([])
  const [shipmentList, setShipmentList] = useState<Shipment[]>([])
  const [editAddress, setEditAddress] = useState(false)
  const [editShipmentMethod, setEditShipmentMethod] = useState(false)
  const [editShipmentId, setEditShipmentId] = useState('0')
  const [modalDeleteShipmentShow, setModalDeleteShipmentShow] = useState(false)
  const [deleteShipmentComment, setDeleteShipmentComment] = useState('')
  const [deleteShipmentId, setDeleteShipmentId] = useState('0')
  const [modalCompleteShipmentShow, setModalCompleteShipmentShow] = useState(false)
  const [completeShipmentComment, setCompleteShipmentComment] = useState('')
  const [completeShipmentId, setCompleteShipmentId] = useState('0')
  const [modalParcelShipmentCreateShow, setModalParcelShipmentCreateShow] = useState(false)
  const [modalParcelShipmentUpdateShow, setModalParcelShipmentUpdateShow] = useState(false)
  const [modalParcelShipmentPrintShow, setModalParcelShipmentPrintShow] = useState(false)
  const [modalParcelShipmentWeightShow, setModalParcelShipmentWeightShow] = useState(false)
  const [modalParcelShipmentCancelShow, setModalParcelShipmentCancelShow] = useState(false)
  const [parcelShipmentCreateLoading, setParcelShipmentCreateLoading] = useState(false)
  const [parcelShipmentUpdateLoading, setParcelShipmentUpdateLoading] = useState(false)
  const [parcelShipmentPrintLoading, setParcelShipmentPrintLoading] = useState(false)
  const [parcelShipmentPrintButtonLoading, setParcelShipmentPrintButtonLoading] = useState(false)
  const [parcelShipmentWeightLoading, setParcelShipmentWeightLoading] = useState(false)
  const [parcelShipmentCancelLoading, setParcelShipmentCancelLoading] = useState(false)
  const [parcelShipmentId, setParcelShipmentId] = useState('0')
  const [parcelShipmentComment, setParcelShipmentComment] = useState('')
  const [parcelShipmentItems, setParcelShipmentItems] = useState('')
  const [parcelShipmentReprint, setParcelShipmentReprint] = useState(false)
  const [parcelShipmentConsigneeName, setParcelShipmentConsigneeName] = useState('')
  const [parcelShipmentConsigneePhone, setParcelShipmentConsigneePhone] = useState('')
  const [parcelShipmentConsigneeAddress, setParcelShipmentConsigneeAddress] = useState('')
  const [parcelShipmentConsigneeID, setParcelShipmentConsigneeID] = useState('')
  const [parcelTotalWeight, setParcelTotalWeight] = useState(0)
  const [parcelShipmentPrintUrl, setParcelShipmentPrintUrl] = useState('')
  const [sendMessage, setSendMessage] = useState(false)
  const [editLoggingInfo, setEditLoggingInfo] = useState(false)
  const [currentStatus, setCurrentStatus] = useState('')
  const [description, setDescription] = useState('')
  const [managerComment, setManagerComment] = useState('')
  const [dispatchOrderInfo, setDispatchOrderInfo] = useState<OrderDetailResTypes>()
  const [addressId, setAddressId] = useState(0)
  const [emailAddress, setEmailAddress] = useState('')
  const [courierCode, setCourierCode] = useState('goex')
  const [shipmentInfo, setShipmentInfo] = useState<
    {
      productId: number
      skuId: number
      quantity: number
    }[]
  >()
  const [trackingNo, setTrackingNo] = useState<string>()
  const [productResult, setProductResult] = useState([])
  const [shipmentComment, setShipmentComment] = useState('')
  const [courierWeight, setCourierWeight] = useState<number>(0)
  const [orderNo, setOrderNo] = useState('')
  const [courierList, setCourierList] = useState<OrderCourierTypes[]>([])
  const [updateDispatch, setUpdateDispatch] = useState(false)
  const [orderRefundDisplay, setOrderRefundDisplay] = useState(false)
  const [refundAmount, setRefundAmount] = useState(0)
  const [refundMethod, setRefundMethod] = useState('credit')
  const [stockMethod, setStockMethod] = useState('manual')
  const [refundCommon, setRefundCommon] = useState('')
  const [shipmentMethodId, setShipmentMethodId] = useState(0)
  const [showGrossProfit, setShowGrossProfit] = useState(false)
  const [showSalesBonus, setShowSalesBonus] = useState(false)
  const [shipmentMethod, setShipmentMethod] = useState<ShipmentMethodTypes>()
  const pictureWallRef = useRef<PicturesWall>(null)
  const dispatch = useAppDispatch()
  const storeState = useAppSelector(homePageData)
  const shoppingCartState = useAppSelector(shoppingCartData)

  const imageUrl = (img) => {
    let url = img
    if (!!img && (img.slice(0, 7) === 'http://' || img.slice(0, 8) === 'https://'))
      url = img
    else
      url = `${api.cdn}/${img}?x-oss-process=style/640w`
    return url
  }

  const columsShipmentList: ColumnsType<Shipment> = [
    {
      title: <div style={{ color: 'orange' }}>Id</div>,
      dataIndex: 'id',
      width: '3rem',
      render: (text, _record, index) => (
        <Tooltip title={text.toString()}>
          <div>{index + 1}</div>
        </Tooltip>
      )
    },
    {
      title: <div style={{ color: 'orange' }}>Status</div>,
      dataIndex: 'status',
      align: 'center',
      width: '8rem'
    },
    {
      title: <div style={{ color: 'orange' }}>Courier</div>,
      key: 'transactionId',
      align: 'center',
      width: '8rem',
      render: (_text, record) => <div>{`${record.courierName || ''} [${record.courierCode || ''}] ${record.trackingNo || ''} ${record.courierWeight || '0.000'}kg `}</div>
    },
    {
      title: <div style={{ color: 'orange' }}>Items</div>,
      align: 'center',
      dataIndex: 'items',
      // width: '11rem',
      render: (text, _record) =>
        text.map((item) => {
          return <div key={item.id}>{`${item.barcode} ${item.shippingName} * ${item.quantity}`}</div>
        })
    },
    {
      title: <div style={{ color: 'orange' }}>Photos</div>,
      align: 'center',
      width: '8rem',
      key: 'image',
      render: (_text, record) => {
        return (
          <div style={{ display: 'flex' }}>
            {record.image ? <Image src={`${imageUrl(record.image)}`} /> : null}
            {record.image2 ? <Image src={`${imageUrl(record.image2)}`} /> : null}
          </div>
        )
      }
    },
    {
      title: <div style={{ color: 'orange' }}>Comment</div>,
      align: 'center',
      // width: '15rem',
      // ellipsis: true,
      showSorterTooltip: true,
      render: (_text, record) => <div>
        <Tooltip title={dayjs(record.createdAt).format('D/MM/YYYY h:mm A')}>
          <div>{dayjs(record.createdAt).format(dayjs(record.createdAt).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</div>
        </Tooltip>
        {`${record.comment || ''}`}
      </div>
    },
    {
      title: <div style={{ color: 'orange' }}></div>,
      key: 'Action',
      align: 'center',
      width: '9rem',
      render: (_text, record) => {
        return (
          <Col>
            <Row>
              <Button.Group size="small">
                <Button
                  type='link' size='small'
                  onClick={() => {
                    setParcelShipmentId(record.id.toString())
                    setParcelShipmentPrintUrl('')
                    setParcelShipmentComment('')
                    setParcelShipmentItems('')
                    setParcelShipmentReprint(false)
                    setParcelShipmentConsigneeName('')
                    setParcelShipmentConsigneePhone('')
                    setParcelShipmentConsigneeAddress('')
                    setParcelShipmentConsigneeID('')
                    setModalParcelShipmentCreateShow(true)
                  }}
                >
                  <FormattedMessage id='parcelShipmentCreate' />
                </Button>
                <Button
                  type='link' size='small'
                  onClick={() => {
                    setParcelShipmentId(record.id.toString())
                    setParcelShipmentPrintUrl('')
                    setParcelShipmentComment('')
                    setParcelShipmentItems('')
                    setParcelShipmentReprint(false)
                    setParcelShipmentConsigneeName('')
                    setParcelShipmentConsigneePhone('')
                    setParcelShipmentConsigneeAddress('')
                    setParcelShipmentConsigneeID('')
                    setModalParcelShipmentUpdateShow(true)
                  }}
                >
                  <FormattedMessage id='parcelShipmentUpdate' />
                </Button>
                <Button
                  type='link' size='small'
                  onClick={() => {
                    setParcelShipmentId(record.id.toString())
                    setParcelShipmentPrintUrl('')
                    setParcelShipmentComment('')
                    setParcelShipmentItems('')
                    setParcelShipmentReprint(false)
                    setParcelShipmentConsigneeName('')
                    setParcelShipmentConsigneePhone('')
                    setParcelShipmentConsigneeAddress('')
                    setParcelShipmentConsigneeID('')
                    setModalParcelShipmentCancelShow(true)
                  }}
                >
                  <FormattedMessage id='parcelShipmentCancel' />
                </Button>
              </Button.Group>
            </Row>
            <Row>
              <Button.Group size="small">
                <Button
                  disabled = { record.packStatus === 'new' ? false : true}
                  type='link' size='small'
                  onClick={() => {
                    setParcelShipmentId(record.id.toString())
                    setParcelShipmentPrintUrl(record.pdf)
                    setParcelShipmentComment('')
                    setParcelShipmentItems('')
                    setParcelShipmentReprint(false)
                    setParcelShipmentConsigneeName('')
                    setParcelShipmentConsigneePhone('')
                    setParcelShipmentConsigneeAddress('')
                    setParcelShipmentConsigneeID('')
                    setModalParcelShipmentPrintShow(true)
                  }}
                >
                  <FormattedMessage id='parcelShipmentPrint' />
                </Button>
                <Button
                  disabled = { record.packStatus === 'printed' ? false : true}
                  type='link' size='small'
                  onClick={() => {
                    setParcelShipmentId(record.id.toString())
                    setParcelShipmentPrintUrl(record.pdf)
                    setParcelShipmentComment('')
                    setParcelShipmentItems('')
                    setParcelShipmentReprint(true)
                    setParcelShipmentConsigneeName('')
                    setParcelShipmentConsigneePhone('')
                    setParcelShipmentConsigneeAddress('')
                    setParcelShipmentConsigneeID('')
                    setModalParcelShipmentPrintShow(true)
                  }}
                >
                  <FormattedMessage id='parcelShipmentReprint' />
                </Button>
                <Button
                  type='link' size='small'
                  onClick={() => {
                    setParcelShipmentId(record.id.toString())
                    setParcelShipmentPrintUrl('')
                    setParcelShipmentComment('')
                    setParcelShipmentItems('')
                    setParcelShipmentReprint(false)
                    setParcelShipmentConsigneeName('')
                    setParcelShipmentConsigneePhone('')
                    setParcelShipmentConsigneeAddress('')
                    setParcelShipmentConsigneeID('')
                    setModalParcelShipmentWeightShow(true)
                  }}
                >
                  <FormattedMessage id='parcelShipmentWeight' />
                </Button>
              </Button.Group>
            </Row>
            <Row>              
              <Button.Group size="small">
                <Button
                  type='link' size='small'
                  onClick={() => {
                    getCourierInfo().then((res) => {
                      if (res.success) {
                        setCourierList(res.data)
                        setOrderDetails(undefined)
                        setUpdateDispatch(true)
                        setCourierWeight(record.courierWeight)
                        setCourierCode(record.courierCode ? record.courierCode : 'other')
                        setEditShipmentId(record.id.toString())
                        setTrackingNo(record.trackingNo)
                        setShipmentComment(record.comment)
                        const path = {
                          pathname: `/order/${props.match.params.id}/dispatch`,
                          query: { record }
                        }
                        props.history.push(path)
                      } else {
                        message.error(res.message)
                      }
                    })

                  }}
                >
                  <FormattedMessage id='shipmentEdit' />
                </Button>
                <Button
                  type='link' size='small'
                  onClick={() => {
                    setCompleteShipmentId(record.id.toString())
                    setCompleteShipmentComment('')
                    setModalCompleteShipmentShow(true)
                  }}
                >
                  <FormattedMessage id='shipmentComplete' />
                </Button>
                <Button
                  type='link' size='small'
                  onClick={() => {
                    setDeleteShipmentId(record.id.toString())
                    setDeleteShipmentComment('')
                    setModalDeleteShipmentShow(true)
                  }}
                >
                  <FormattedMessage id='shipmentDelete' />
                </Button>
              </Button.Group>
            </Row>
          </Col>
        )
      }
    }
  ]

  useEffect(() => {
    dispatch(getCurrencyRate())
  }, [])

  const columnsPickingItems: ColumnsType<PickingItem> = [
    {
      width: '3rem',
      title: <div style={{ color: 'orange' }}>#</div>,
      showSorterTooltip: true,
      dataIndex: 'id',
      render: (_text, record, index) => (<div>{index + 1}</div>)
    },
    {
      title: <div style={{ color: 'orange' }}>Description</div>,
      ellipsis: true,
      showSorterTooltip: true,
      dataIndex: 'barcode',
      render: (_text, record) => <Col>
        <Row>[{record.locationName || record.locationId}]
        {record.gtin ? " "+record.gtin :''} {record.courierWeight.toFixed(3)}kg {record.shippingWeight === 0 ? '包邮' : ''} ${record.finalPrice.toFixed(2)} </Row>
        <Row>{record.name || 'No Name'}</Row>
      </Col>
    },
    {
      title: <div style={{ color: 'orange' }}>总仓</div>,
      width: '4rem',
      align: 'center',
      dataIndex: 'qtyVendorStock',
    },
    {
      title: <div style={{ color: 'orange' }}>库存</div>,
      width: '8rem',
      align: 'center',
      dataIndex: 'qtyStock',
      render: (_text, record) => <Row wrap={false} align='center'>
        {record.isSelfFulfillment ? " [门店]" :' [仓库]'}{record.shelf ? " ["+record.shelf+"]" :''} {record.qtyStock}
      </Row>
    },
    {
      title: <div style={{ color: 'orange' }}>数量</div>,
      width: '5rem',
      align: 'center',
      dataIndex: 'qtyBuy'
    },
    {
      title: <div style={{ color: 'orange' }}>已发</div>,
      width: '5rem',
      align: 'center',
      dataIndex: 'qtyAllocated'
    },
    {
      title: <div style={{ color: 'orange' }}>待发</div>,
      width: '8rem',
      align: 'center',
      key: 'nonAllocated',
      render: (_value, record) => {
        return (
          <InputNumber
            size="small"
            min={0}
            max={record.qtyBuy - record.qtyAllocated}
            defaultValue={record.qtyAllocated === record.qtyBuy ? 0 : record.qtyBuy - record.qtyAllocated}
            readOnly={record.qtyAllocated === record.qtyBuy}
            onChange={(num) => {
              if (record.qtyAllocated === record.qtyBuy) return
              if (
                record.qtyBuy - record.qtyAllocated === +num &&
                shipmentInfo.find((item) => {
                  return item.productId === record.productId && item.skuId === record.skuId
                })
              ) {
                return
              }
              let newarr = shipmentInfo
              newarr = newarr.filter((element) => {
                return element.skuId !== record.skuId
              })
              setShipmentInfo([
                ...newarr,
                {
                  productId: record.productId,
                  skuId: record.skuId,
                  quantity: +num
                }
              ])
            }}
          />
        )
      }
    },
    {
      title: <div style={{ color: 'orange' }}>结果</div>,
      width: '9rem',
      align: 'center',
      key: 'result',
      render: (_value, record) => {
        const resultStr = productResult.find((item) => item.productId === record.productId)?.result || ''
        return <span style={{ color: 'red' }}>{resultStr}</span>
      }
    }
  ]

  const leftFooter = [
    'Gross Profit',
    'SubTotal',
    'Subtotal Discount',
    'Subtotal Refunded',
    'Weight',
    'Shipping Fee',
    'ShippingFee Discount',
    'Shipping Refunded'
  ]

  const rightFooter = ['Sales Bonus', 'Credit Spent', 'Points Earned', 'Points Spent', 'Surcharge', 'Total', 'Total Due', 'Total Paid']
  const leftFooterData = [
    'grossProfit',
    'subtotal',
    'subtotalDiscount',
    'subtotalRefunded',
    'shippingWeight',
    'shippingFee',
    'shippingFeeDiscount',
    'shippingRefunded'
  ]
  const rightFooterData = ['salesBonus', 'credit', 'pointsEarned', 'pointsSpent', 'surcharge', 'total', 'totalDue', 'totalPaid']

  useEffect(() => {
    dispatch(setMenuDisplay({ menu: false }))
    if (props.location.query) {
      if (!props.location.query?.record) {
        setCurrentStatus(props.location.query)
      }
    }

    if (props.match.params?.id) {
      const orderId = props.match.params.id
      if (R.isEmpty(orderId)) {
        message.error('order id is empty')
        return
      }
      setChooseId(orderId)
      if (props.location.pathname.includes('dispatch')) {
        if (courierList.length === 0) {
          getCourierInfo().then((res) => {
            if (res.success) {
              setCourierList(res.data)
              setOrderDetails(undefined)
              setUpdateDispatch(false)
              props.history.push(`/order/${orderId}/dispatch`)
            } else {
              message.error(res.message)
            }
          })
        }
        orderDetail(orderId).then((res) => {
          if (res.success) {
            // setOrderDetails(res.data)
            const dispatchesArr = res.data.pickingItems.map((item) => {
              return {
                productId: item.productId,
                skuId: item.skuId,
                quantity: item.qtyBuy - item.qtyAllocated
              }
            })
            let newarr = dispatchesArr
            newarr = newarr.filter((element) => {
              return element.quantity !== 0
            })
            setShipmentInfo([...newarr])
            if (props.location?.query?.record) {
              const info = res.data.shipments.find((item) => item.id.toString() === props.location.query.record.id.toString())
              setTrackingNo(info.trackingNo)
              setCourierWeight(info.courierWeight)
              setCourierCode(info.courierCode)
              setShipmentComment(info.comment)
              if (info.image !== '' && info.image2 !== '') {
                pictureWallRef.current?.setFileList([info.image, info.image2])
              } else if (info.image2 !== '') {
                pictureWallRef.current?.setFileList([info.image2])
              } else if (info.image !== '') {
                pictureWallRef.current?.setFileList([info.image])
              }
            } else {
              setTrackingNo('')
              setCourierWeight(0)
              setCourierCode('nsf')
              setShipmentComment('')
              pictureWallRef.current?.setFileList([])
            }
            setDispatchOrderInfo(res.data)
            setEmailAddress(res.data.customerEmail)
            setPickingItems(res.data.pickingItems)
            setOrderItems(undefined)
            setOrderCreateTime(res.data.createdAt)
            setOrderStatus(res.data.status)
            setChoosePrice(res.data.total)
          } else {
            setDispatchOrderInfo(undefined)
            setOrderItems(undefined)
          }
        })
      } else {
        refreshOrderDetail(orderId)
      }
    }
  }, [props.location, shoppingCartState?.count])

  const refreshOrderDetail = (orderId) => {
    orderDetail(orderId).then((res) => {
      if (res.success) {
        setOrderDetails(res.data)
        setOrderItems(res.data.items)
        setEmailAddress(res.data.customerEmail)
        setPaymentList(res.data.payments)
        setShipmentList(res.data.shipments)
        setPickingItems(res.data.pickingItems)
        setChoosePrice(res.data.total)
        setOrderCreateTime(res.data.createdAt)
        setOrderStatus(res.data.status)
        setDescription(res.data.description)
        setManagerComment(res.data.managerComment)
        setOrderNo(res.data.orderNo)
      } else {
        setOrderDetails(undefined)
      }
    })
  }

  const refundOptions = [
    { label: '退到预存', value: 'credit' },
    { label: '原路退回', value: 'return' }
  ]
  const stockOptions = [
    { label: '全部归还自动入库', value: 'all' },
    { label: '部分归还人工入库', value: 'manual' }
  ]

  function imageGroup(image1, image2) {
    if (image1 && image2) {
      return (
        <div style={{ display: 'flex' }}>
          <Image width={100} src={`${imageUrl(image1)}`} alt='image1' />
          <Image width={100} src={`${imageUrl(image2)}`} alt='image2' />
        </div>
      )
    } else if (image2) {
      return <Image width={100} src={`${imageUrl(image2)}`} alt='image2' />
    } else if (image1) {
      return <Image width={100} src={`${imageUrl(image1)}`} alt='image2' />
    } else {
      return <div></div>
    }
  }

  return (
    <Row wrap={false}>
      <MenuLayout />
      <Col span={7}>
        <OrderList />
      </Col>
      <Col span={17}>
        <Layout style={{ height: '100vh', position: 'absolute', width: '100%' }}>
          <StyledHeader>
            <Row style={{ width: '100%',alignItems: 'center' }}>
              <Col span={12} style={{paddingLeft: '10px'}}>
                Order # {orderNo || ''}
                <Button
                  type='link'
                  onClick={() => {
                    if (copy(orderNo)) {
                      message.success('copy success')
                    } else {
                      message.warning('copy fails')
                    }
                  }}
                >
                  复制
                </Button>
              </Col>
              <Col span={6}>
                <Row style={{fontSize: '1rem', fontWeight:500}}>
                  Status: {orderStatus}
                </Row>
                <Row>
                  <Col style={{fontSize: '0.8rem', fontWeight:300}}>{orderCreateTime === '' ? '' : dayjs(orderCreateTime).format('DD/MM/YYYY hh:mm:ss')}</Col>
                </Row>
              </Col>
              <Col span={6} style={{textAlign:'right', paddingRight: '10px', fontSize:'1rem'}}>NZ${choosePrice?.toFixed(2)}</Col>
            </Row>
          </StyledHeader>

          <StyledContent>
            {orderDetails ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingTop: '6px',
                    paddingBottom: '6px',
                    position: 'sticky',
                    top: '1px',
                    zIndex: 1,
                    backgroundColor: 'white'
                  }}
                >
                  <Space direction='horizontal'>
                    <Button
                      type='primary'
                      style={{ borderRadius: '10px' }}
                      onClick={() => {
                        cancelOrderRes(chooseId || props.match.params.id).then((res) => {
                          if (res.success) {
                            message.success(res.message)
                            dispatch(
                              setCartIncrease({
                                num: 1
                              })
                            )
                          } else {
                            message.error(res.message)
                          }
                        })
                      }}
                      disabled={orderStatus !== 'pending' && orderStatus !== 'awaitpayment'}
                    >
                      <FormattedMessage id='cancelOrder' />
                    </Button>
                    <Button
                      type='primary'
                      onClick={() => {
                        const data = {
                          id: chooseId || props.match.params.id,
                          price: orderDetails.totalDue,
                          credit: storeState.credit,
                          cart: orderDetails.items,
                          discountList: orderDetails.discounts
                        }

                        dispatch(setCartItems({ cartItems: orderDetails.items as any[] }))
                        dispatch(setTotalPrice({ totalPrice: orderDetails.totalDue }))
                        const path = {
                          pathname: `/payment/${chooseId || props.match.params.id}`,
                          query: data
                        }
                        dispatch(setPlaceorderSuccess({ success: true }))
                        dispatch(
                          setCustomerInfo({
                            customerId: orderDetails.customerId,
                            customerGroupId: orderDetails.customerGroupId,
                            customerEmail: orderDetails.customerEmail
                          })
                        )
                        sessionStorage.setItem('customerIdFromOrder', orderDetails.customerId + '')
                        /* dispatch(
                          deleteAllShoppingCart({ customerId: customerState.customerId, storeId: +sessionStorage.getItem('storeId') })
                        ) */

                        props.history.push(path)
                      }}
                      style={{ borderRadius: '10px' }}
                      disabled={orderStatus !== 'pending' && orderStatus !== 'awaitpayment'}
                    >
                      <FormattedMessage id='orderPayment' />
                    </Button>
                    {orderStatus === 'paid' ? (
                      <Button
                        type='primary'
                        onClick={() => {
                          orderProcessingRes(chooseId || props.match.params.id).then((res) => {
                            if (res.success) {
                              dispatch(
                                setCartIncrease({ num: 1 })
                              )
                              message.success(res.message)
                            } else {
                              message.error(res.message)
                            }
                          })
                        }}
                        style={{ borderRadius: '10px' }}
                      >
                        <FormattedMessage id='orderLock' />
                      </Button>
                    ) : orderStatus === 'awaitpickup' ? (
                      <Button
                        type='primary'
                        onClick={() => {
                          setOrderDetails(undefined)
                          props.history.push(`/order/${chooseId || props.match.params.id}/dispatch`)
                        }}
                        style={{ borderRadius: '10px' }}
                      >
                        <FormattedMessage id='orderPickup' />
                      </Button>
                    ) : orderStatus === 'processing' ? (
                      <Button
                        type='primary'
                        style={{ borderRadius: '10px' }}
                        disabled={orderStatus !== 'processing'}
                        onClick={() => {
                          getCourierInfo().then((res) => {
                            if (res.success) {
                              setCourierList(res.data)
                              setOrderDetails(undefined)
                              setUpdateDispatch(false)
                              props.history.push(`/order/${chooseId || props.match.params.id}/dispatch`)
                            } else {
                              message.error(res.message)
                            }
                          })
                        }}
                      >
                        <FormattedMessage id='orderDelivery' />
                      </Button>
                    ) : null}

                    <Button
                      type='primary'
                      disabled={orderStatus !== 'paid' && orderStatus !== 'complete'}
                      style={{ borderRadius: '10px' }}
                      onClick={() => {
                        setOrderRefundDisplay(true)
                      }}
                    >
                      <FormattedMessage id='orderRefund' />
                    </Button>
                    <Button
                      type='primary'
                      style={{ borderRadius: '10px' }}
                      onClick={() => {
                        const storeId = sessionStorage.getItem('storeId')
                        const putIntoCart = async (element) => {
                          const param = {
                            customerId: orderDetails.customerId,
                            customerGroupId: orderDetails.customerGroupId,
                            storeId: +storeId,
                            productId: element.productId,
                            quantity: element.qtyOrdered,
                            skuId: element.skuId
                          }
                          await putItemToCart(param)
                        }
                        Promise.all(orderDetails.items.map((item) => putIntoCart(item))).then((val) => {
                          dispatch(
                            setCustomerInfo({
                              customerId: orderDetails.customerId,
                              customerGroupId: orderDetails.customerGroupId,
                              customerEmail: orderDetails.customerEmail
                            })
                          )
                          props.history.push('/')

                          return val
                        })
                      }}
                    >
                      <FormattedMessage id='reOrder' />
                    </Button>
                    <Button
                      type='primary'
                      style={{ borderRadius: '10px' }}
                      onClick={() => {
                        setEditLoggingInfo(true)
                      }}
                    >
                      <FormattedMessage id='orderNote' />
                    </Button>
                    <Button
                      type='primary'
                      style={{ borderRadius: '10px' }}
                      onClick={() => {
                        window.open(window.location.origin + `/print/${chooseId || props.match.params.id}`)
                      }}
                    >
                      <FormattedMessage id='orderPrint' />
                    </Button>
                  </Space>
                </div>

                <CardRow>
                  <Col span={12}>
                    <CardStyle
                      size='small'
                      headStyle={{ borderBottom: '1px solid orange', marginLeft: '10px', marginRight: '0.5rem' }}
                      title={
                        <div style={{ color: 'orange', fontSize: '1rem' }}>
                          {orderDetails.shipmentMethodName}[{orderDetails.shippingTerm}]: {orderDetails.shipmentGroupName} {orderDetails.shipmentAreaName}
                        </div>
                      }
                      extra={
                        <div
                          style={{ cursor: 'pointer', color: 'orange' }}
                          onClick={() => {
                            if (orderDetails.shippingTerm === 'pickup') {
                              setEditShipmentMethod(true)
                            } else {
                              setEditAddress(true)
                            }
                          }}
                        >
                          Edit
                        </div>
                      }
                      style={{ width: '100%', margin: '6px', height: '100%' }}
                    >
                      {orderDetails.shippingTerm === 'pickup' ? (
                        <Col>
                          <Row style={{ fontSize: '1rem' }}>
                            {orderDetails.pickupStoreName || ''}, {orderDetails.pickupStorePhone || ''}
                          </Row>
                          <Row style={{ fontSize: '1rem' }}>
                            {orderDetails.pickupStoreAddress || ''}
                          </Row>
                        </Col>
                      ) : (
                        <Col>
                          <Row style={{ fontSize: '1rem' }}>
                            {orderDetails.consigneeName || ''} {orderDetails.consigneePhone || ''} {orderDetails.consigneeID || ''}
                          </Row>
                          <Row style={{ fontSize: '1rem' }}>{orderDetails.consigneeAddress}</Row>
                          <Row>{imageGroup(orderDetails.consigneeIDPhoto, orderDetails.consigneeIDPhoto2)}</Row>
                        </Col>
                      )}
                    </CardStyle>
                  </Col>
                  <Col span={6}>
                    <CardStyle
                      size='small'
                      headStyle={{ borderBottom: '1px solid orange', marginLeft: '10px' }}
                      title={<div style={{ color: 'orange', fontSize: '1rem' }}>Customer</div>}
                      style={{ width: '100%', margin: '6px', height: '100%' }}
                    >
                      <div style={{ fontSize: '1rem' }}>
                        {customerInfo(orderDetails, sessionStorage.getItem('customerGroup'))}
                      </div>
                    </CardStyle>
                  </Col>
                  <Col span={6}>
                    <CardStyle
                      size='small'
                      headStyle={{ borderBottom: '1px solid orange', marginLeft: '10px' }}
                      title={<div style={{ color: 'orange', fontSize: '1rem' }}>Manager</div>}
                      style={{ width: '100%', margin: '6px', height: '100%' }}
                    >
                      <div style={{ fontSize: '1rem' }}>{orderDetails.managerName}({orderDetails.managerId}) {orderDetails.storeName}({orderDetails.storeId})</div>
                      {orderDetails.referrerId ? <div style={{ fontSize: '1rem' }}>{orderDetails.referrerName}({orderDetails.referrerId})</div> : null}
                    </CardStyle>
                  </Col>
                </CardRow>

                <Table
                  size='small'
                  rowKey='id'
                  columns={productColumns}
                  dataSource={orderItems}
                  style={{ minWidth: 'fit-content', width: '100%', margin: '6px' }}
                  pagination={false}
                  footer={() => {
                    return (
                      <Row style={{ borderTop: '1px solid orange', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                        <Col span={5}>
                          <div style={{ color: 'orange', fontSize: '1.2rem', padding: '7px 0' }}>Customer Remark</div>
                          <TextArea style={{ resize: 'none', height: '81%' }} value={description} />
                        </Col>
                        <Col span={5} offset={1}>
                          <div style={{ color: 'orange', fontSize: '1.2rem', padding: '7px 0' }}>Manager Remark</div>
                          <TextArea style={{ resize: 'none', height: '81%' }} value={managerComment} />
                        </Col>
                        <Col span={12}>
                          <Row style={{ width: '100%' }}>
                            <Col span={12}>
                              <List
                                style={{ width: '100%' }}
                                size='small'
                                dataSource={leftFooter}
                                renderItem={(item, index) => {
                                  if (index === 0) {
                                    return (
                                      <List.Item
                                        onMouseEnter={() => setShowGrossProfit(true)}
                                        onMouseLeave={() => setShowGrossProfit(false)}
                                      >
                                        <div>{item}</div>
                                        <div> {showGrossProfit ? `$ ${orderDetails[leftFooterData[index]]}` : '$ ***'}</div>
                                      </List.Item>
                                    )
                                  }
                                  else if (index === 4) {
                                    return (<List.Item>
                                      <div>{item}</div>
                                      <div>{orderDetails[leftFooterData[index]] ? `${orderDetails[leftFooterData[index]].toFixed(3)}kg` : '0.000kg'}</div>
                                    </List.Item>)
                                  }
                                  else
                                    return (<List.Item>
                                      <div>{item}</div>
                                      <div>{orderDetails[leftFooterData[index]] ? `$${orderDetails[leftFooterData[index]].toFixed(2)}` : '$0.00'}</div>
                                    </List.Item>)
                                }}
                              />
                            </Col>
                            <Col span={11} offset={1}>
                              <List
                                style={{ width: '100%' }}
                                size='small'
                                dataSource={rightFooter}
                                renderItem={(item, index) => {
                                  if (index === 2 || index === 3) {
                                    return (
                                      <List.Item>
                                        <div>{item}</div>
                                        <div> {orderDetails[rightFooterData[index]]}</div>
                                      </List.Item>
                                    )
                                  } else if (index === 1 || index === 4 || index === 5 || index === 6 || index === 7) {
                                    return (
                                      <List.Item>
                                        <div>{item}</div>
                                        <div>{orderDetails[rightFooterData[index]] && orderDetails[rightFooterData[index]] > 0 ? `$${orderDetails[rightFooterData[index]].toFixed(2)}` : '$0.00'}</div>
                                      </List.Item>
                                    )
                                  } else {
                                    return (
                                      <List.Item
                                        onMouseEnter={() => setShowSalesBonus(true)}
                                        onMouseLeave={() => setShowSalesBonus(false)}
                                      >
                                        <div>{item}</div>
                                        <div> {showSalesBonus ? `$ ${orderDetails[rightFooterData[index]]}` : '$ ***'}</div>
                                      </List.Item>
                                    )
                                  }
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )
                  }}
                />
                {shipmentList?.length > 0 ? (
                  <CardStyle
                    bordered={false}
                    size='small'
                    headStyle={{ borderBottom: '1px solid orange', marginLeft: '10px', marginRight: '10px' }}
                    title={<div style={{ color: 'orange', fontSize: '1rem', fontWeight: 700 }}>Parcels</div>}
                    style={{ minWidth: 'fit-content', margin: '6px'}}
                  >
                    <Table
                      size='small'
                      rowKey='id'
                      columns={columsShipmentList}
                      dataSource={shipmentList}
                      pagination={false}
                      style={{ width: '100%' }}
                    />
                  </CardStyle>
                ) : null}
                <CardStyle
                  bordered={false}
                  size='small'
                  headStyle={{ borderBottom: '1px solid orange', marginLeft: '10px', marginRight: '10px' }}
                  title={<div style={{ color: 'orange', fontSize: '1rem', fontWeight: 700 }}>Payments</div>}
                  style={{ minWidth: 'fit-content', margin: '6px'}}
                >
                  <Table
                    size='small'
                    rowKey='id'
                    columns={paymentHistoryColumns}
                    dataSource={paymentList}
                    pagination={false}
                    style={{ width: '100%' }}
                  />
                </CardStyle>
                {orderDetails.discounts?.length > 0 ? (
                  <CardStyle
                    bordered={false}
                    size='small'
                    headStyle={{ borderBottom: '1px solid orange', marginLeft: '10px', marginRight: '10px' }}
                    title={<div style={{ color: 'orange', fontSize: '1rem', fontWeight: 700 }}>Discount history</div>}
                    style={{ minWidth: 'fit-content', margin: '6px' }}
                  >
                    <Table
                      size='small'
                      rowKey='id'
                      columns={discountHistory}
                      dataSource={orderDetails.discounts}
                      pagination={false}
                      style={{ width: '100%' }}
                    />
                  </CardStyle>
                ) : null}
                {orderDetails.logging?.length>0 ?(
                <CardStyle
                  size='small'
                  headStyle={{ borderBottom: '1px solid orange', marginLeft: '10px', marginRight: '10px' }}
                  title={<div style={{ color: 'orange', fontSize: '1rem', fontWeight: 700 }}>Logging</div>}
                  style={{ minWidth: 'fit-content', margin: '6px' }}
                >
                  <Table
                    size='small'
                    rowKey='id'
                    columns={logHistory}
                    dataSource={orderDetails.logging}
                    style={{ width: '100%', background: 'white' }}
                    pagination={false}
                  />
                  </CardStyle>
                ):null}
              </>
            ) : dispatchOrderInfo ? (
              <>
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '6px' }}></div>
                <div style={{ backgroundColor: 'white' }}>
                  <CardStyle
                    bordered={false}
                    size='small'
                    headStyle={{ borderBottom: '1px solid orange', marginLeft: '10px', marginRight: '10px' }}
                    title={<div style={{ color: 'orange', fontSize: '1rem', fontWeight: 700 }}>{dispatchOrderInfo.shippingTerm === 'pickup' ? '自提单' : '包裹单'}</div>}
                    style={{ width: '100%', margin: '6px'}}
                  >
                    <Row>
                      <Col>
                        物流方式: {dispatchOrderInfo.shipmentMethodName}
                      </Col>
                    </Row>

                    {dispatchOrderInfo.shippingTerm === 'pickup' ? (
                      <Row>
                        <Col span={24}>
                          提货人: #{dispatchOrderInfo.customerNo || ''}, {dispatchOrderInfo.customerName || ''}, {dispatchOrderInfo.customerPhone || ''}, {dispatchOrderInfo.customerEmail || ''}
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col span={12}>
                          收件人 :{dispatchOrderInfo.consigneeName || ''}, {dispatchOrderInfo.consigneePhone || ''}, {dispatchOrderInfo.consigneeID || ''}, {dispatchOrderInfo.consigneeAddress || ''}
                        </Col>
                        <Col span={12}>
                          发件人: #{dispatchOrderInfo.customerNo || ''}, {dispatchOrderInfo.customerName || ''}, {dispatchOrderInfo.customerPhone || ''}, {dispatchOrderInfo.customerEmail || ''}
                        </Col>
                      </Row>
                    )}
                    <Divider style={{ marginTop: '15px', marginBottom: '15px', color: 'orange' }} />
                    {dispatchOrderInfo.shippingTerm === 'pickup' ? null : (
                      <Row gutter={[6, 16]}>
                        <Col span={16}>
                          <Row>
                            <Col span={8}>
                              公司: <Select
                                style={{ width: '100%' }}
                                value={courierCode}
                                onChange={(value) => {
                                  setCourierCode(value)
                                }}
                              >
                                {courierList.map((item, index) => {
                                  return (
                                    <Option value={item.code} key={index}>
                                      [{item.code}] {item.name}
                                    </Option>
                                  )
                                })}
                              </Select>
                            </Col>
                            <Col span={12}>
                              单号: <Input
                                value={trackingNo}
                                onChange={(e) => {
                                  setTrackingNo(e.target.value)
                                }}
                              />
                            </Col>
                            <Col span={4}>
                              重量(kg): <InputNumber min={0.01} defaultValue={courierWeight} onChange={(e) => {setCourierWeight(e)}} />
                            </Col>
                          </Row>
                        </Col>
                        <Col span={8}>
                          <PicturesWall number={2} ref={pictureWallRef} />
                        </Col>
                      </Row>
                    )}
                  </CardStyle>
                  <CardStyle
                    bordered={false}
                    size='small'
                    headStyle={{ borderBottom: '1px solid orange', marginLeft: '10px', marginRight: '10px' }}
                    title={<div style={{ color: 'orange', fontSize: '1rem', fontWeight: 700 }}>Picking Items</div>}
                    style={{ width: '100%', margin: '6px'}}
                  >
                    <Table
                    rowKey='id'
                    size='small'
                    pagination={false}
                    columns={columnsPickingItems}
                    dataSource={pickingItems}
                    footer={() => {
                      return (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          Comment:
                          <TextArea
                            defaultValue={shipmentComment}
                            rows={3}
                            allowClear
                            style={{ flex: '3' }}
                            onChange={(e) => {
                              setShipmentComment(e.target.value)
                            }}
                          />
                          <Button
                            type='primary'
                            style={{ borderRadius: '10px', flex: '1', marginLeft: '1rem' }}
                            onClick={async () => {
                              const pictures = JSON.parse(JSON.stringify(pictureWallRef.current?.getFileName() || '') || '')
                              if (props.location?.query?.record) {
                                const params = {
                                  courierCode,
                                  courierWeight,
                                  trackingNo,
                                  comment: shipmentComment,
                                  image: pictures[0]?.name || '',
                                  image2: pictures[1]?.name || ''
                                }
                                const res = await updateShipmentInfo(
                                  params,
                                  props.location.query.record.orderId.toString(),
                                  editShipmentId
                                )
                                if (res.success) {
                                  message.success(res.message)
                                  const path = {
                                    pathname: `/order/${chooseId || props.match.params.id}`,
                                    query: currentStatus
                                  }
                                  props.history.push(path)
                                }
                              } else {
                                if (dispatchOrderInfo.shippingTerm === 'pickup') {
                                  const selfPickParams = {
                                    comment: shipmentComment,
                                    image: pictures[0]?.name || '',
                                    image2: pictures[1]?.name || '',
                                    items: [...shipmentInfo]
                                  }
                                  resSelfPicking(selfPickParams, chooseId || props.match.params.id).then((res) => {
                                    if (res.success) {
                                      message.success(res.message)
                                      const path = {
                                        pathname: `/order/${chooseId || props.match.params.id}`,
                                        query: currentStatus
                                      }
                                      props.history.push(path)
                                    } else {
                                      message.error(res.message)
                                      const productResultArr = []
                                      res.data.forEach((element) => {
                                        /* const query = `${orderItems.find((item) => item.productId === element.productId).name}-${
                                        element.result
                                      }` */
                                        productResultArr.push({
                                          productId: element.productId,
                                          result: element.result
                                        })

                                        // message.error(query)
                                      })
                                      setProductResult(productResultArr)
                                    }
                                  })
                                } else {
                                  const params = {
                                    courierCode,
                                    courierWeight,
                                    trackingNo,
                                    comment: shipmentComment,
                                    image: pictures[0]?.name || '',
                                    image2: pictures[1]?.name || '',
                                    items: [...shipmentInfo]
                                  }
                                  orderShipmentRes(params, chooseId || props.match.params.id).then((res) => {
                                    if (res.success) {
                                      message.success(res.message)
                                      const path = {
                                        pathname: `/order/${chooseId || props.match.params.id}`,
                                        query: currentStatus
                                      }
                                      props.history.push(path)
                                    } else {
                                      message.error(res.message)
                                    }
                                  })
                                }
                                refreshOrderDetail(chooseId || props.match.params.id)
                              }
                            }}
                          >
                            {updateDispatch ? '修改' : '确认'}
                          </Button>
                        </div>
                      )
                    }}
                  />
                  </CardStyle>
                  <CardStyle
                    bordered={false}
                    size='small'
                    headStyle={{ borderBottom: '1px solid orange', marginLeft: '10px', marginRight: '10px' }}
                    title={<div style={{ color: 'orange', fontSize: '1rem', fontWeight: 700 }}>Parcels</div>}
                    style={{ width: '100%', margin: '6px'}}
                  >
                    <Table
                      size='small'
                      rowKey='id'
                      columns={columsShipmentList}
                      dataSource={shipmentList}
                      pagination={false}
                      style={{ width: '100%' }}
                    />
                  </CardStyle>
                </div>
              </>
            ) : null}
          </StyledContent>
        </Layout>
      </Col>

      <ModalForm
        visible={editAddress}
        style={{top: '10vh'}}
        width='80vw'
        closable
        onCancel={() => {
          setEditAddress(false)
        }}
        onOk={() => {
          const params = {
            shippingAddressId: addressId.toString(),
            comment: ''
          }
          updateShippingAddress(params, orderDetails.orderNo).then((res) => {
            if (res.success) {
              setAddressId(0)
              dispatch(setCartIncrease({ num: 1 }))
              message.success(res.message)
            } else {
              message.error(res.message)
            }
          })
          setEditAddress(false)
        }}
      >
        <CustomerShippingAddress
          orderDetails={orderDetails}
          currentAddressId={orderDetails?.shippingAddressId}
          addressId={(id) => {setAddressId(id)}}
        />
      </ModalForm>
      <ModalForm
        visible={editShipmentMethod}
        style={{top: '20vh'}}
        width='50vw'
        closable
        onCancel={() => {
          setEditShipmentMethod(false)
        }}
        onOk={async () => {
          const res = await updateShipmentMethod({
            id: orderDetails.id,
            storeId: shipmentMethod.pickup.id,
            methodId: shipmentMethodId,
            comment: ''
          })
          if (res.success) {
            message.success(res.message)
            setEditShipmentMethod(false)
          } else {
            message.error(res.message)
          }
        }}
      >
        <ShipmentDialog
          shipmentMethodId={shipmentMethodId}
          setShipmentMethodId={(data) => {
            setShipmentMethodId(data)
            console.log(data)
          }}
          addressArea={orderDetails?.shipmentAreaCode}
          addressGroup={orderDetails?.shipmentGroupId}
          shipmentMethod={(data) => setShipmentMethod(data)}
        />
      </ModalForm>
      <ModalForm
        visible={sendMessage}
        style={{top: '20vh'}}
        width='50vw'
        closable
        okText='Send'
        onCancel={() => {
          setSendMessage(false)
        }}
        onOk={() => {
          const params = {
            messageType: 'email',
            messageTemplate: 'invoice',
            receiver: emailAddress,
            comment: ''
          }
          sendMessageToCustomer(params, chooseId || props.match.params.id).then((res) => {
            if (res.success) {
              message.success(res.message)
              setSendMessage(false)
            } else {
              message.error(res.message)
            }
          })
        }}
      >
        <Row justify='space-between' style={{ width: '40%', marginLeft: '45px' }}>
          <Col>Email:</Col>
          <Col>
            <Input
              autoFocus
              value={emailAddress}
              onChange={(e) => {
                setEmailAddress(e.target.value)
              }}
            />
          </Col>
        </Row>
      </ModalForm>
      <ModalForm
        style={{top: '20vh'}}
        width='50vw'
        closable
        title={<FormattedMessage id='shipmentDestroy' />}
        visible={modalDeleteShipmentShow}
        onCancel={() => {
          setDeleteShipmentId('0')
          setDeleteShipmentComment('')
          setModalDeleteShipmentShow(false)
        }}
        onOk={() => {
          if (R.isEmpty(deleteShipmentComment)) {
            message.error('you must input the reason ')
            return
          }
          const params = {
            code: "delete",
            comment: deleteShipmentComment
          }
          deleteShipmentInfo(params, orderNo, deleteShipmentId.toString()).then((res) => {
            console.log(res)
            if (!res.success) {
              message.error(res.message)
              return
            }
            setDeleteShipmentId('0')
            setDeleteShipmentComment('')
            setModalDeleteShipmentShow(false)
            refreshOrderDetail(orderNo)
          })

        }}
      >
        <TextArea
          rows={4}
          allowClear
          style={{ height: 'auto' }}
          onChange={(e) => {
            setDeleteShipmentComment(e.target.value)
          }}
        />
      </ModalForm>
      <ModalForm
        style={{top: '20vh'}}
        width='50vw'
        closable
        title={<FormattedMessage id='parcelShipmentCreate' />}
        visible={modalParcelShipmentCreateShow}
        onCancel={() => {
          setParcelShipmentId('0')
          setParcelShipmentComment('')
          setParcelShipmentPrintUrl('')
          setParcelShipmentItems('')
          setParcelShipmentReprint(false)
          setParcelShipmentConsigneeName('')
          setParcelShipmentConsigneePhone('')
          setParcelShipmentConsigneeAddress('')
          setParcelShipmentConsigneeID('')
          setModalParcelShipmentCreateShow(false)
        }}
        confirmLoading={parcelShipmentCreateLoading}
        onOk={() => {
          // if (R.isEmpty(parcelShipmentComment)) {
          //   message.error('you must input the comment ')
          //   return
          // }
          setParcelShipmentCreateLoading(true)
          const params = {
            code: 'parcel_create',
            consigneeName: parcelShipmentConsigneeName,
            consigneePhone: parcelShipmentConsigneePhone,
            consigneeID: parcelShipmentConsigneeID,
            consigneeAddress: parcelShipmentConsigneeAddress,
            parcelItems: parcelShipmentItems,
            comment: parcelShipmentComment
          }
          parcelShipment2(params, orderNo, parcelShipmentId.toString()).then((res) => {
            setParcelShipmentCreateLoading(false)
            if (!res.success) {
              message.error(res.message)
              return
            }
            setParcelShipmentId('0')
            setParcelShipmentComment('')
            setParcelShipmentPrintUrl('')
            setParcelShipmentItems('')
            setParcelShipmentReprint(false)
            setParcelShipmentConsigneeName('')
            setParcelShipmentConsigneePhone('')
            setParcelShipmentConsigneeAddress('')
            setParcelShipmentConsigneeID('')
            setModalParcelShipmentCreateShow(false)
            refreshOrderDetail(orderNo)
          })

        }}
      >
        <Row>
          <Col span={6}>
        <FormattedMessage id='parcelShipmentConsigneeName' />
        <Input
          allowClear
          style={{ height: 'auto' }}
          onChange={(e) => {
            setParcelShipmentConsigneeName(e.target.value)
          }}
        />
        </Col>
        <Col span={8} style={{paddingLeft: '10px'}}>
        <FormattedMessage id='parcelShipmentConsigneePhone' />
        <Input
          allowClear
          style={{ height: 'auto' }}
          onChange={(e) => {
            setParcelShipmentConsigneePhone(e.target.value)
          }}
        />
        </Col>
          <Col span={10} style={{paddingLeft: '10px'}}>
          <FormattedMessage id='parcelShipmentConsigneeID' />
        <Input
          allowClear
          style={{ height: 'auto' }}
          onChange={(e) => {
            setParcelShipmentConsigneeID(e.target.value)
          }}
        />
        </Col>
        </Row>
        <FormattedMessage id='parcelShipmentConsigneeAddress' />
        <Input
          allowClear
          style={{ height: 'auto' }}
          onChange={(e) => {
            setParcelShipmentConsigneeAddress(e.target.value)
          }}
        />
        <FormattedMessage id='parcelShipmentItemsTitle' />
        <TextArea
          rows={5}
          allowClear
          placeholder={`${<FormattedMessage id='parcelShipmentItemsPlaceholder' />}`}
          style={{ height: 'auto' }}
          onChange={(e) => {
            setParcelShipmentItems(e.target.value)
          }}
        />
        <FormattedMessage id='parcelShipmentCreateComment' />
        <TextArea
          rows={2}
          allowClear
          style={{ height: 'auto' }}
          onChange={(e) => {
            setParcelShipmentComment(e.target.value)
          }}
        />
      </ModalForm>
      <ModalForm
        style={{top: '20vh'}}
        width='50vw'
        closable
        title={<FormattedMessage id='parcelShipmentUpdate' />}
        visible={modalParcelShipmentUpdateShow}
        onCancel={() => {
          setParcelShipmentId('0')
          setParcelShipmentComment('')
          setParcelShipmentPrintUrl('')
          setParcelShipmentItems('')
          setParcelShipmentReprint(false)
          setParcelShipmentConsigneeName('')
          setParcelShipmentConsigneePhone('')
          setParcelShipmentConsigneeAddress('')
          setParcelShipmentConsigneeID('')
          setModalParcelShipmentUpdateShow(false)
        }}
        confirmLoading={parcelShipmentUpdateLoading}
        onOk={() => {
          // if (R.isEmpty(parcelShipmentComment)) {
          //   message.error('you must input the comment ')
          //   return
          // }
          const params = {
            code: 'parcel_update',
            consigneeName: parcelShipmentConsigneeName,
            consigneePhone: parcelShipmentConsigneePhone,
            consigneeID: parcelShipmentConsigneeID,
            consigneeAddress: parcelShipmentConsigneeAddress,
            parcelItems: parcelShipmentItems,
            comment: parcelShipmentComment
          }
          setParcelShipmentUpdateLoading(true)
          parcelShipment2(params, orderNo, parcelShipmentId.toString()).then((res) => {
            setParcelShipmentUpdateLoading(false)
            if (!res.success) {
              message.error(res.message)
              return
            }
            setParcelShipmentId('0')
            setParcelShipmentComment('')
            setParcelShipmentPrintUrl('')
            setParcelShipmentItems('')
            setParcelShipmentReprint(false)
            setParcelShipmentConsigneeName('')
            setParcelShipmentConsigneePhone('')
            setParcelShipmentConsigneeAddress('')
            setParcelShipmentConsigneeID('')
            setModalParcelShipmentUpdateShow(false)
            refreshOrderDetail(orderNo)
          })

        }}
      ><Row>
          <Col span={6}>
        <FormattedMessage id='parcelShipmentConsigneeName' />
        <Input
          allowClear
          style={{ height: 'auto' }}
          onChange={(e) => {
            setParcelShipmentConsigneeName(e.target.value)
          }}
        />
        </Col>
        <Col span={8} style={{paddingLeft: '10px'}}>
        <FormattedMessage id='parcelShipmentConsigneePhone' />
        <Input
          allowClear
          style={{ height: 'auto' }}
          onChange={(e) => {
            setParcelShipmentConsigneePhone(e.target.value)
          }}
        />
        </Col>
          <Col span={10} style={{paddingLeft: '10px'}}>
          <FormattedMessage id='parcelShipmentConsigneeID' />
        <Input
          allowClear
          style={{ height: 'auto' }}
          onChange={(e) => {
            setParcelShipmentConsigneeID(e.target.value)
          }}
        />
        </Col>
        </Row>
        <FormattedMessage id='parcelShipmentConsigneeAddress' />
        <Input
          allowClear
          style={{ height: 'auto' }}
          onChange={(e) => {
            setParcelShipmentConsigneeAddress(e.target.value)
          }}
        />
        <FormattedMessage id='parcelShipmentItemsTitle' />
        <TextArea
          rows={5}
          allowClear
          placeholder={`${<FormattedMessage id='parcelShipmentItemsPlaceholder' />}`}
          style={{ height: 'auto' }}
          onChange={(e) => {
            setParcelShipmentItems(e.target.value)
          }}
        />
        <FormattedMessage id='parcelShipmentUpdateComment' />
        <TextArea
          rows={2}
          allowClear
          style={{ height: 'auto' }}
          onChange={(e) => {
            setParcelShipmentComment(e.target.value)
          }}
        />
      </ModalForm>
      <ModalForm
        style={{top: '20vh'}}
        width='50vw'
        closable
        title={<FormattedMessage id='parcelShipmentCancel' />}
        visible={modalParcelShipmentCancelShow}
        onCancel={() => {
          setParcelShipmentId('0')
          setParcelShipmentComment('')
          setParcelShipmentPrintUrl('')
          setParcelShipmentItems('')
          setParcelShipmentReprint(false)
          setParcelShipmentConsigneeName('')
          setParcelShipmentConsigneePhone('')
          setParcelShipmentConsigneeAddress('')
          setParcelShipmentConsigneeID('')
          setModalParcelShipmentCancelShow(false)
        }}
        confirmLoading={parcelShipmentCancelLoading}
        onOk={() => {
          if (R.isEmpty(parcelShipmentComment)) {
            message.error('you must input the comment ')
            return
          }
          const params = {
            code: 'parcel_cancel',
            comment: parcelShipmentComment
          }
          setParcelShipmentCancelLoading(true)
          parcelShipment2(params, orderNo, parcelShipmentId.toString()).then((res) => {
            setParcelShipmentCancelLoading(false)
            if (!res.success) {
              message.error(res.message)
              return
            }
            setParcelShipmentId('0')
            setParcelShipmentComment('')
            setParcelShipmentPrintUrl('')
            setParcelShipmentItems('')
            setParcelShipmentReprint(false)
            setParcelShipmentConsigneeName('')
            setParcelShipmentConsigneePhone('')
            setParcelShipmentConsigneeAddress('')
            setParcelShipmentConsigneeID('')
            setModalParcelShipmentCancelShow(false)
            refreshOrderDetail(orderNo)
          })

        }}
      >
        <FormattedMessage id='parcelShipmentCancelComment' />
        <TextArea
          rows={3}
          allowClear
          style={{ height: 'auto' }}
          onChange={(e) => {
            setParcelShipmentComment(e.target.value)
          }}
        />
      </ModalForm>
      <ModalForm
        style={{top: '20vh'}}
        width='50vw'
        closable
        title={<FormattedMessage id='parcelShipmentWeight' />}
        visible={modalParcelShipmentWeightShow}
        onCancel={() => {
          setParcelShipmentId('0')
          setParcelShipmentComment('')
          setParcelShipmentPrintUrl('')
          setParcelShipmentItems('')
          setParcelShipmentReprint(false)
          setParcelShipmentConsigneeName('')
          setParcelShipmentConsigneePhone('')
          setParcelShipmentConsigneeAddress('')
          setParcelShipmentConsigneeID('')
          setModalParcelShipmentWeightShow(false)
        }}
        confirmLoading={parcelShipmentWeightLoading}
        onOk={() => {
          const params = {
            code: 'parcel_weight',
            totalWeight: parcelTotalWeight
          }
          setParcelShipmentWeightLoading(true)
          parcelShipment2(params, orderNo, parcelShipmentId.toString()).then((res) => {
            setParcelShipmentWeightLoading(false)
            if (!res.success) {
              message.error(res.message)
              return
            }
            setParcelShipmentId('0')
            setParcelShipmentPrintUrl('')
            setParcelShipmentComment('')
            setModalParcelShipmentWeightShow(false)
            refreshOrderDetail(orderNo)
          })

        }}
      ><FormattedMessage id='parcelShipmentWeightTitle' />
        <InputNumber min={0.01} defaultValue={parcelTotalWeight} onChange={(e) => {setParcelTotalWeight(e)}} />
      </ModalForm>
      <ModalForm
        style={{top: '20vh'}}
        width='50vw'
        closable
        title={<FormattedMessage id='parcelShipmentPrint' />}
        visible={modalParcelShipmentPrintShow}
        onCancel={() => {
          setParcelShipmentId('0')
          setParcelShipmentPrintUrl('')
          setParcelShipmentComment('')
          setParcelShipmentItems('')
          setParcelShipmentReprint(false)
          setParcelShipmentConsigneeName('')
          setParcelShipmentConsigneePhone('')
          setParcelShipmentConsigneeAddress('')
          setParcelShipmentConsigneeID('')
          setModalParcelShipmentPrintShow(false)
          setParcelShipmentPrintLoading(false)
        }}
        confirmLoading={parcelShipmentPrintLoading}
        onOk={() => {
          // if (R.isEmpty(parcelShipmentComment)) {
          //   message.error('you must input the comment ')
          //   return
          // }
          if (!parcelShipmentReprint) {
            const params = {
              code: 'parcel_print',
              reprintLabel: parcelShipmentReprint,
              comment: parcelShipmentComment
            }
            setParcelShipmentPrintLoading(true)
            parcelShipment2(params, orderNo, parcelShipmentId.toString()).then((res) => {
              setParcelShipmentPrintLoading(false)
              if (!res.success) {
                message.error(res.message)
                setParcelShipmentPrintLoading(false)
                return
              }
              // window.open(`${api.cdn}${res.data}`)
              setParcelShipmentId('0')
              setParcelShipmentComment('')
              setParcelShipmentPrintUrl('')
              setParcelShipmentItems('')
              setParcelShipmentReprint(false)
              setParcelShipmentConsigneeName('')
              setParcelShipmentConsigneePhone('')
              setParcelShipmentConsigneeAddress('')
              setParcelShipmentConsigneeID('')
              setModalParcelShipmentPrintShow(false)
              refreshOrderDetail(orderNo)
            })
          } else {
            setParcelShipmentId('0')
            setParcelShipmentComment('')
            setParcelShipmentPrintUrl('')
            setParcelShipmentItems('')
            setParcelShipmentReprint(false)
            setParcelShipmentConsigneeName('')
            setParcelShipmentConsigneePhone('')
            setParcelShipmentConsigneeAddress('')
            setParcelShipmentConsigneeID('')
            setModalParcelShipmentPrintShow(false)
          }
        }}
      >
        {parcelShipmentReprint ? 
        <Row>
          <Col span={4}><FormattedMessage id='parcelShipmentPrintUrlTitle' />:</Col>
          <Col span={20}>
            <a target='_blank' href={`${api.cdn}${parcelShipmentPrintUrl}`}>{parcelShipmentPrintUrl||'无'}</a>
              <Button type="primary" size='small' loading={parcelShipmentPrintButtonLoading}
                style={{marginLeft: '20px'}}
                onClick={() => {
                  const params = {
                    code: 'parcel_print',
                    reprintLabel: parcelShipmentReprint,
                    comment: parcelShipmentComment
                  }
                  setParcelShipmentPrintButtonLoading(true)
                  parcelShipment2(params, orderNo, parcelShipmentId.toString()).then((res) => {
                    setParcelShipmentPrintButtonLoading(false)
                    if (!res.success) {
                      message.error(res.message)
                      return
                    } else message.success(res.message)
                    refreshOrderDetail(orderNo)
                  })
                }} > <FormattedMessage id='parcelShipmentReprint' /> </Button>
          </Col>
        </Row>
        :null}
        <FormattedMessage id='parcelShipmentPrintComment' />
        <TextArea
          rows={2}
          allowClear
          style={{ height: 'auto' }}
          onChange={(e) => {
            setParcelShipmentComment(e.target.value)
          }}
        />
      </ModalForm>
      <ModalForm
        style={{top: '20vh'}}
        width='50vw'
        closable
        title={<FormattedMessage id='shipmentComplete' />}
        visible={modalCompleteShipmentShow}
        onCancel={() => {
          setCompleteShipmentId('0')
          setCompleteShipmentComment('')
          setModalCompleteShipmentShow(false)
        }}
        onOk={() => {
          // if (R.isEmpty(completeShipmentComment)) {
          //   message.error('you must input the comment ')
          //   return
          // }
          const params = {
            code: 'complete',
            comment: completeShipmentComment
          }
          completeShipment(params, orderNo, completeShipmentId.toString()).then((res) => {
            console.log(res)
            if (!res.success) {
              message.error(res.message)
              return
            }
            setCompleteShipmentId('')
            setCompleteShipmentComment('')
            setModalCompleteShipmentShow(false)
            refreshOrderDetail(orderNo)
          })
        }}
      >
        <TextArea
          rows={4}
          allowClear
          style={{ height: 'auto' }}
          onChange={(e) => {
            setCompleteShipmentComment(e.target.value)
          }}
        />
      </ModalForm>
      <ModalForm
        visible={orderRefundDisplay}
        style={{top: '20vh'}}
        width='50vw'
        closable
        okText='Submit'
        onCancel={() => {
          setOrderRefundDisplay(false)
        }}
        onOk={() => {
          const params = {
            refundAmount,
            stockIn: stockMethod,
            refundTo: refundMethod,
            comment: refundCommon
          }
          orderRefund(params, props.match.params.id).then((res) => {
            if (res.success) {
              message.success(res.message)
              dispatch(
                setCartIncrease({ num: 1 })
              )
              setOrderRefundDisplay(false)
            } else {
              message.error(res.message)
            }
          })
        }}
      >
        <Row justify='space-between' style={{ width: '80%' }} gutter={[6, 16]}>
          <Col span={10}>Stock In:</Col>
          <Col span={14}>
            <Radio.Group
              options={stockOptions}
              onChange={(e) => {
                setStockMethod(e.target.value)
              }}
              value={stockMethod}
              optionType='button'
              buttonStyle='solid'
            />
          </Col>
          <Col span={10}>Refund To:</Col>
          <Col span={14}>
            <Radio.Group
              options={refundOptions}
              onChange={(e) => {
                setRefundMethod(e.target.value)
              }}
              value={refundMethod}
              optionType='button'
              buttonStyle='solid'
            />
          </Col>
          <Col span={10}>Refund:</Col>
          <Col span={14}>
            <InputNumber
              autoFocus
              value={refundAmount}
              onChange={(num) => {
                setRefundAmount(+num)
              }}
            />
          </Col>
          <Col span={10}>Comment:</Col>
          <Col span={14}>
            <TextArea
              rows={7}
              allowClear
              style={{ height: 'auto' }}
              onChange={(e) => {
                setRefundCommon(e.target.value)
              }}
            />
          </Col>
        </Row>
      </ModalForm>
      <NotifyModal
        editLoggingInfo={editLoggingInfo}
        close={() => {
          setEditLoggingInfo(false)
        }}
        pointNotify={false}
      />
    </Row>
  )
}

export default OrderHistory
