export const breakpointValues = {
  desktop: {
    min: '1440px',
    max: '1920px',
    mid: '1520px'
  }
}

export const breakpoints = {
  desktop: `@media screen and (max-width: ${breakpointValues.desktop.min}) and (max-width: ${breakpointValues.desktop.max})`,
  // Specific - used to target multiple sizes
  desktopMinSize: `@media screen and (max-width: ${breakpointValues.desktop.min})`,
  desktopMidSize: `@media screen and (min-width: ${breakpointValues.desktop.min}) and (max-width: ${breakpointValues.desktop.mid})`
}
