import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { getShipmentMethod, getShipmentsFromTerm } from '../../../api/response'
import { Shipments } from '../../../models/shipping/shipment'
import { ShipmentMethodTypes } from '../../../models/shipping/shippingMethod'
import { useAppDispatch, useAppSelector } from '../../../redux/reduxHooks'
import { getShipmentAreaAndGroupId, setShipmentCodeAndGroupId } from '../../../redux/reducers/customerInfo_reducer'
import { getShipmentId, setShipmentId } from '../../../redux/reducers/cartItems_reducer'

interface Option {
  value: string | number
  label: string
  children?: Option[]
}
interface Props {
  shipmentType: string
}
export const useAddShipment = (props: Props) => {
  const { shipmentType } = props
  const [shipments, setShipments] = useState<Shipments[]>([])
  const [shipmentMethods, setShipmentMethods] = useState<ShipmentMethodTypes[]>([])
  const [chosenItem, setChosenItem] = useState<number>(0)
  const [loading, setLoading] = useState(true)

  const dispatch = useAppDispatch()
  const { areaCode, groupId } = useAppSelector(getShipmentAreaAndGroupId)
  const shipmentId = useAppSelector(getShipmentId)
  const getShippingArea = async () => {
    setLoading(true)
    const res = await getShipmentsFromTerm({ term: shipmentType })

    if (res.success) {
      setLoading(false)
      setShipments(res.data)
    }
  }

  useEffect(() => {
    getShippingArea()
  }, [shipmentType])

  useEffect(() => {
    if (groupId && areaCode) {
      const params = {
        areaCode: areaCode,
        groupId: groupId,
        shippingTerm: shipmentType
      }
      getShipmentMethod(params).then((res) => {
        if (res.success) {
          setShipmentMethods(res.data)
          if (shipmentId) {
            setChosenItem(shipmentId)
          }
        }
      })
    }
  }, [areaCode, groupId, shipmentId])

  const shipmentAreaChangeHandler = useCallback(
    (value) => {
      const params = {
        areaCode: value[1],
        groupId: value[0],
        shippingTerm: shipmentType
      }

      getShipmentMethod(params).then((res) => {
        if (res.success) {
          setShipmentMethods(res.data)
        }
      })
      dispatch(setShipmentCodeAndGroupId({ shipmentAreaCode: value[1], shipmentGroupId: value[0] }))
    },
    [dispatch, shipmentType]
  )

  const convertDataToOptions = useMemo(() => {
    const groupedShipments = shipments.reduce((acc, shipment) => {
      const key = shipment.groupId
      if (!acc[key]) {
        acc[key] = {
          groupId: shipment.groupId,
          groupName: shipment.groupName,
          children: []
        }
      }
      if (shipment.areaCode !== '000000') {
        acc[key].children.push(shipment)
      }
      return acc
    }, {} as { [key: number]: { groupId: number; groupName: string; children: Shipments[] } })
    return Object.values(groupedShipments).map((group) => ({
      value: group.groupId,
      label: group.groupName,
      children: group.children.map((shipment) => ({
        value: shipment.areaCode,
        label: shipment.areaName
      }))
    }))
  }, [shipments])

  const itemClicked = (id: number) => {
    setChosenItem(id)
    dispatch(setShipmentId({ id }))
  }

  return {
    convertDataToOptions,
    shipmentAreaChangeHandler,
    shipmentMethods,
    itemClicked,
    chosenItem,
    loading
  }
}
