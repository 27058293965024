export enum FormFields {
  AddOneFee = 'addOneFee',
  Note = 'note',
  Name = 'name',
  CustomerName = 'customerName',
  DisplayName = 'displayName',
  DisplayAddress = 'displayAddress',
  ManagerComment = 'managerComment',
  Type = 'type',
  Surname = 'surname',
  Nickname = 'nickname',
  Phone = 'phone',
  Shipmentareas = 'shipmentareas',
  Street = 'street',
  City = 'city',
  Email = 'email',
  ZipCode = 'zipCode',
  IdCard = 'idCard',
  Cover = 'cover',
  DefaultAddress = 'defaultAddress',
  EmailVerified = 'emailVerified',
  PointUpdateNotification = 'pointUpdateNotification',
  PointExpirationNotification = 'pointExpirationNotification',
}
