import { Row } from 'antd'
import styled from 'styled-components'
import { CloseOutlined, MinusCircleOutlined, CloseCircleOutlined, PlusCircleFilled } from '@ant-design/icons'

export const StyledRows = styled(Row)`
  height: auto;
`

export const CartItemTitle = styled.div`
  // text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 400;
  font-size: 0.9rem;
  // display: -webkit-box !important;
  // -webkit-box-orient: vertical;
  // -webkit-line-clamp: 1;
`
export const CartItemSubTitle = styled.div`
  margin-top: 0.1rem;
  font-size: 0.8rem;
  color: gray;
`
export const CartItemPrice = styled.div`
  display:flex;
  font-size: 0.8rem;
  margin-top: 0.1rem;
`
export const CartItemTotalPrice = styled.div`
  text-align: center;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
`
export const CartItemCount = styled.div`
  display: flex;
  font-size: 1rem;
  flex-direction: space-around;
`
export const StyledPlusCircleOutlined = styled(PlusCircleFilled)`
  font-size: 1.3rem;
  color: orangered;
`

export const StyledMinusCircleOutlined = styled(MinusCircleOutlined)`
  font-size: 1.3rem;
  color: orange;
`
export const StyledCloseCircleOutlined = styled(CloseOutlined)`
  font-size: 0.6rem;
  color: gray;
`
export const CartItemCountNumber = styled.span`
  flex-grow: 1;
  text-align: center;
  text-deco
  margin: 0 15px;
`
export const H6Price = styled.span`
  font-size: 0.8rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  color: black;
`
export const H6PriceVIP = styled.span`
  font-size: 0.8rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  color: orange;
`
export const H6PriceSpecial = styled.span`
  font-size: 0.8rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  color: red;
`
export const H6PriceWholesale = styled.span`
  font-size: 0.8rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  color: blue;
`
export const H6PriceTier = styled.span`
  font-size: 0.8rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  color: pink;
`


export const H6Bundled = styled.span`
  font-size: 0.8rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  color: gray;
`

export const H6PriceSymbol = styled.span`
  font-size: 0.6rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  margin-right: 2px;
  color: gray;
  line-height: 1;
`
