import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getCustomerInfo } from '../../api/response'
import { CustomerTypes } from '../../models/customerTypes'
import { RootState } from '../store'
import { setCredit } from './homePage_reducer'

type initialStateTypes = {
  customerId: number
  customerGroupId: number
  customerEmail: string
  name: string
  customerChoose: CustomerTypes | null
  cartIsEmpty: boolean
  shipmentAreaCode: string
  shipmentGroupId: number
  shipmentMethodForCustomer: string
  shipmentType: number
  customerDisplay: string
}

const initialState: initialStateTypes = {
  customerId: 1,
  customerGroupId: 0,
  customerEmail: '',
  name: '',
  customerChoose: null,
  cartIsEmpty: false,
  shipmentAreaCode: '',
  shipmentGroupId: 0,
  shipmentMethodForCustomer: 'pickup',
  shipmentType: 1,
  customerDisplay: ''
}

export const getOneCustomerInfo = createAsyncThunk('cart/getOneCustomerInfo', async (param: number, { dispatch }) => {
  const response = await getCustomerInfo(param)
  // The value we return becomes the `fulfilled` action payload
  if (response.success) {
    dispatch(setCustomerChoose({ data: response.data }))
    dispatch(setCredit({ credit: response.data.credit }))
    dispatch(
      setCustomerDisplay({
        name: `${response.data?.customerNo || ''} ${
          response.data?.displayName
            ? response.data?.displayName ?? ''
            : ` ${response.data?.firstName || ''}${response.data?.lastName || ''}  ${response.data?.nickName || ''} `
        } ${response.data?.phone || ''} ${response.data?.email.includes('wx.domain.com') ? '' : response.data?.email || '' || ''}`
      })
    )
  }
})

export const getOnHoldListCustomerInfo = createAsyncThunk('cart/getOnHoldListCustomerInfo', async (param: number, { dispatch }) => {
  const response = await getCustomerInfo(param)
  // The value we return becomes the `fulfilled` action payload
  if (response.success) {
    dispatch(setCustomerChoose({ data: response.data }))
    dispatch(
      setCustomerInfo({ customerId: response.data.id, customerGroupId: response.data.customerGroupId, customerEmail: response.data.email })
    )
    dispatch(setCartIsEmpty({ cartIsEmpty: false }))
    dispatch(
      setCustomerDisplay({
        name: `${response.data?.customerNo || ''} ${
          response.data?.displayName
            ? response.data?.displayName ?? ''
            : ` ${response.data?.firstName || ''}${response.data?.lastName || ''}  ${response.data?.nickName || ''} `
        } ${response.data?.phone || ''} ${response.data?.email.includes('wx.domain.com') ? '' : response.data?.email || '' || ''}`
      })
    )
  }
})

export const customerInfo = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomerInfo: (state, action: PayloadAction<{ customerId: number; customerGroupId: number; customerEmail?: string }>) => {
      state.customerId = action.payload.customerId
      state.customerGroupId = action.payload.customerGroupId
      state.customerEmail = action.payload.customerEmail
    },
    setCustomerName: (state, action: PayloadAction<{ name: string }>) => {
      state.name = action.payload.name
    },
    setCustomerChoose: (state, action: PayloadAction<{ data: CustomerTypes }>) => {
      state.customerChoose = action.payload.data
    },
    setCustomerId: (state, action: PayloadAction<{ id: number }>) => {
      state.customerId = action.payload.id
    },
    setCartIsEmpty: (state, action: PayloadAction<{ cartIsEmpty: boolean }>) => {
      state.cartIsEmpty = action.payload.cartIsEmpty
    },
    setShipmentMethodForCustomer: (state, action: PayloadAction<{ method: string }>) => {
      state.shipmentMethodForCustomer = action.payload.method
    },
    setShipmentType: (state, action: PayloadAction<{ type: number }>) => {
      state.shipmentType = action.payload.type
    },
    setCustomerDisplay: (state, action: PayloadAction<{ name: string }>) => {
      state.customerDisplay = action.payload.name
    },
    setShipmentCodeAndGroupId: (state, action: PayloadAction<{ shipmentAreaCode: string; shipmentGroupId: number }>) => {
      state.shipmentAreaCode = action.payload.shipmentAreaCode
      state.shipmentGroupId = action.payload.shipmentGroupId
    }
  }
})

export const {
  setCustomerInfo,
  setCustomerName,
  setCustomerChoose,
  setCustomerId,
  setCartIsEmpty,
  setShipmentCodeAndGroupId,
  setShipmentMethodForCustomer,
  setShipmentType,
  setCustomerDisplay
} = customerInfo.actions //= connect(dispatch)

export const customerInfoData = (state: RootState) => state.customer // = connect(state)
export const getShipmentAreaAndGroupId = (state: RootState) => ({
  areaCode: state.customer.shipmentAreaCode,
  groupId: state.customer.shipmentGroupId
}) // = connect(state)
export const getCustomerChooseInfo = (state: RootState) => state.customer.customerChoose // = connect(state)
export const getCustomerDisplay = (state: RootState) => state.customer.customerDisplay // = connect(state)
export const getShipmentTypeAndMethod = (state: RootState) => ({
  shipmentMethod: state.customer.shipmentMethodForCustomer,
  shipmentType: state.customer.shipmentType
}) // = connect(state)
export default customerInfo.reducer
