import { Button, Layout } from 'antd'
import { Header } from 'antd/es/layout/layout'
import styled from 'styled-components'
import { breakpoints } from '../../Theme/breakpoints'

export const MainLayout = styled(Layout)`
  max-height: 100vh;
  background-color: white;
`
export const HeaderLayout = styled(Header)`
  display: flex;
  background-color: orange !important;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0 !important;
`
export const SubHeaderLayout = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  height: 3rem;
  background-color: white;
  ${breakpoints.desktopMinSize} {
    height: 4rem;
  }
`

export const SubHeaderRightPart = styled.div`
  text-align: center;
`
export const SubHeaderMiddlePart = styled.div`
  width: 21rem;
  margin: 0 0.5rem;
  ${breakpoints.desktopMidSize} {
    width: 18rem;
  }
  ${breakpoints.desktopMinSize} {
    width: 18rem;
  }
`
export const SubHeaderLeftPart = styled.div`
  text-align: center;
`

export const FooterLayout = styled.div`
  position: sticky;
  align-self: flex-end;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  bottom: 0;
  width: 100%;
`
export const HeaderLeftSection = styled.div`
  flex: 2;
  display: flex;
  justify-content: space-around;
  align-items: center;
  & .ant-btn-default:disabled {
    background-color: white;
  }
`

export const HeaderRightSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  color: white;
  margin-right: 5px;
  min-width: 200px;
  & .Title {
    font-size: 1.1rem;
    margin-top: -0.5rem;
    height: 2rem;
    font-weight: 700;
    ${breakpoints.desktopMinSize} {
      font-size: 1.3rem;
    }
    ${breakpoints.desktopMidSize} {
      font-size: 1.2rem;
    }
  }
  & .SubTitle {
    font-size: 1rem;
    margin-top: -0.5rem;
    ${breakpoints.desktopMinSize} {
      font-size: 0.8rem;
    }
  }
`
export const CartItemsContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100vh;
  max-height: 100vh;
`
export const FooterButtons = styled.div`
  width: 100%;
  padding-right: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`
export const FooterButton = styled(Button)`
  color: orange;
  margin: 0 0.2rem;
  font-size: 1rem;
`
export const FooterCheckoutButton = styled(Button)<{ $lang: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${(props) => (props.$lang === 'zh-CN' ? '10rem' : '8rem')};
  ${breakpoints.desktopMinSize} {
    width: ${(props) => (props.$lang === 'zh-CN' ? '9rem' : '6rem')};
  }
`
