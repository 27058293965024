import { Modal } from 'antd'
import styled from 'styled-components'
import { breakpoints } from '../../Theme/breakpoints'

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const HeaderText = styled.div`
  font-size: 1rem;
  color: orange;
`

