import React, { useEffect } from 'react'
import MenuLayout from '../components/Layout/menuLayout'
import PointList from '../components/pointList'
import { setMenuDisplay } from '../redux/reducers/menu_reducer'
import { useAppDispatch } from '../redux/reduxHooks'

const PointHistory = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(setMenuDisplay({
      menu: false
    }))
  }, [])
  
  return (
    <div>
      <MenuLayout/>
      <PointList/>
    </div>
  )
}

export default PointHistory
