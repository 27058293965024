import React, { useEffect, useState } from 'react'
import { Row, Col, Table, Input, DatePicker, Button, Tooltip, Select, message } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { cashBoxStatus, cashBoxStoresInfo, exportCashBoxAsExcel, getCashBoxHistory } from '../../api/response'
import dayjs from 'dayjs'
import CommonListLayout from '../common/commonListLayout'
import { CashBoxQueryReqTypes, CashBoxQueryResTypes, CashBoxStatus, CashBoxStores, CashItems } from '../../models/cashBoxTypes'
import { useHistory } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table'

const { RangePicker } = DatePicker

const CashBoxList = () => {
  const [cashBoxHistory, setCashBoxHistory] = useState<CashBoxQueryResTypes[]>([])
  const [paginator, setPaginator] = useState({ limit: 25, page: 1 })
  const [total, setTotal] = useState(0)
  const [dateFrom, setDateForm] = useState(0)
  const [searchString, setSearchString] = useState('')
  const [cashBoxStoreId, setCashBoxStoreId] = useState<number>(0)
  const [cashBoxStatusId, setCashBoxStatusId] = useState<string>('1')
  const [cashBoxStores, setCashBoxStores] = useState<CashBoxStores[]>([])
  const [cashBoxStatuses, setCashBoxStatuses] = useState<CashBoxStatus[]>([])
  const [dateTo, setDateTo] = useState(0)
  const history = useHistory()
  const cashBoxHistoryColumns: ColumnsType<CashBoxQueryResTypes> = [
    {
      title: <div>Id</div>,
      dataIndex: 'id',
      align: 'center'
    },
    {
      title: <div>Code</div>,
      dataIndex: 'code',
      align: 'center'
    },
    {
      title: <div>Status</div>,
      dataIndex: 'status',
      align: 'center',
      render: (_text, item) => (item ? 'Enable' : 'Disable')
    },
    {
      title: <div>Store Name</div>,
      dataIndex: 'storeName',
      align: 'center',
      render: (_text, item) => `${item.storeName || ''} ${item.storeId ? `(${item.storeId})` : ''}`
    },
    {
      title: <div>Manager Name</div>,
      dataIndex: 'managerName',
      align: 'center',
      render: (_text, item) => `${item.managerName || ''} ${item.managerId ? `(${item.managerId})` : ''}`
    },
    {
      title: <div>Name</div>,
      dataIndex: 'name',
      align: 'center'
    },
    {
      title: <div>currency</div>,
      dataIndex: 'currency',
      align: 'center'
    },
    {
      title: <div>Items</div>,
      dataIndex: 'items',
      align: 'center',
      render: (text: CashItems[]) => {
        return (
          <div>
            {text.map((item, index) => (
              <div key={index}>
                ${item.value} - {item.quantity}
              </div>
            ))}
          </div>
        )
      }
    },
    {
      title: <div>Total</div>,
      dataIndex: 'total',
      align: 'center'
    },
    {
      title: <div>Created</div>,
      dataIndex: 'createdAt',
      align: 'center',
      render: (text) => {
        return (
          <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')} autoAdjustOverflow>
            <div>{text > 0 ? dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY') : ''}</div>
          </Tooltip>
        )
      }
    },
    {
      title: <div>Updated</div>,
      dataIndex: 'updatedAt',
      align: 'center',
      render: (text) => {
        return (
          <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')} autoAdjustOverflow>
            <div>{dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</div>
          </Tooltip>
        )
      }
    },
    {
      title: <div>Log</div>,
      align: 'center',
      render: (_text, row) => {
        return (
          <Button
            type='primary'
            onClick={() => {
              history.push(`/cashBoxList/${row.id}`)
            }}
          >
            Log
          </Button>
        )
      }
    }
  ]

  useEffect(() => {
    cashBoxStoresInfo().then((res) => {
      if (res.success) {
        setCashBoxStores(res.data)
      } else {
        message.error(res.message)
      }
    })
    cashBoxStatus().then((res) => {
      if (res.success) {
        setCashBoxStatuses(res.data)
      } else {
        message.error(res.message)
      }
    })
  }, [])
  useEffect(() => {
    const params = {
      pageSize: paginator.limit,
      currentPage: paginator.page,
      dateFrom: dateFrom,
      dateTo: dateTo,
      q: searchString,
      status: cashBoxStatusId,
      cashBox: '0001',
      storeId: cashBoxStoreId
    }
    getCashBoxHistory(params).then((res) => {
      if (res.success) {
        const { items } = res.data
        const { totalCount } = res.data.pagination
        const pointArr = [...cashBoxHistory, ...items]
        if (pointArr.length >= totalCount) {
          setTotal(totalCount)
          setCashBoxHistory(items)
          return
        }
        setCashBoxHistory(items)
        setTotal(totalCount)
      }
    })
  }, [paginator, cashBoxStatusId, cashBoxStoreId])

  return (
    <CommonListLayout title='Cash Box'>
      <Table
        bordered
        dataSource={cashBoxHistory}
        columns={cashBoxHistoryColumns}
        rowKey='id'
        title={() => {
          return (
            <Row justify='space-around'>
              <Col>
                <Select
                  defaultValue={0}
                  style={{ width: 120 }}
                  onChange={(value) => {
                    setCashBoxStoreId(value)
                  }}
                  options={
                    cashBoxStores.length > 0 && [
                      {
                        label: localStorage.getItem('lang') === 'zh-CN' ? '所有' : 'All',
                        options: [{ label: '所有店铺', value: 0 }]
                      },
                      {
                        label: localStorage.getItem('lang') === 'zh-CN' ?'分类':'Category',
                        options: [
                          ...cashBoxStores.map((item) => {
                            return { value: item.id, label: item.name }
                          })
                        ]
                      }
                    ]
                  }
                />
                <Select
                  defaultValue={'1'}
                  style={{ width: 120 }}
                  onChange={(value) => {
                    setCashBoxStatusId(value)
                  }}
                  options={
                    cashBoxStatuses.length > 0 &&
                    cashBoxStatuses.map((item) => {
                      return { value: item.code, label: item.name }
                    })
                  }
                />
              </Col>
              <Col span={8}>
                <Input
                  allowClear
                  defaultValue={null}
                  placeholder='Search'
                  autoFocus={true}
                  prefix={<SearchOutlined style={{ color: 'gray' }} />}
                  onChange={(e) => {
                    if (!e.target.value) {
                      setSearchString('')
                      setCashBoxHistory([])
                      setPaginator({ limit: 25, page: 1 })
                    }
                    setSearchString(e.target.value)
                    setCashBoxHistory([])
                    setPaginator({ limit: 25, page: 1 })
                  }}
                />
              </Col>
              <Col span={10}>
                <RangePicker
                  style={{ width: '75%' }}
                  onChange={(dates: any) => {
                    if (!dates) {
                      setDateForm(0)
                      setDateTo(0)
                      setCashBoxHistory([])
                      setPaginator({ limit: 25, page: 1 })

                      return
                    }
                    setDateForm(dayjs(dates[0]).valueOf())
                    setDateTo(dayjs(dates[1]).valueOf())
                    setCashBoxHistory([])

                    setPaginator({ limit: 25, page: 1 })
                  }}
                  showTime
                />
                <Button
                  type='primary'
                  style={{ marginLeft: '24px' }}
                  onClick={() => {
                    const params = {
                      pageSize: paginator.limit,
                      currentPage: paginator.page,
                      dateFrom: dateFrom,
                      dateTo: dateTo,
                      status: '',
                      q: searchString,
                      cashBox: '0001',
                      storeId: 1
                    } as CashBoxQueryReqTypes
                    exportCashBoxAsExcel(params).then((res) => {
                      if (res.success) {
                        window.open(res.data.url)
                      } else {
                        message.error(res.message)
                      }
                    })
                  }}
                >
                  Export
                </Button>
              </Col>
            </Row>
          )
        }}
        scroll={{ y: '68vh' }}
        pagination={{
          total,
          defaultPageSize: paginator.limit,
          current: paginator.page,
          onChange: (page, pageSize) => {
            setPaginator({ page, limit: pageSize })
          }
        }}
      />
    </CommonListLayout>
  )
}

export default CashBoxList
