/* eslint-disable multiline-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import {
  Col,
  Row,
  Layout,
  Space,
  Collapse,
  Card,
  Typography,
  Button,
  Switch,
  Tabs,
  Radio,
  message,
  Image,
  InputNumber,
  Checkbox,
  Divider,
  Modal,
  Input,
  Popconfirm
} from 'antd'
import styled from 'styled-components'
import { makePayment, orderDetail } from '../../api/response'
import { useHistory, useRouteMatch } from 'react-router-dom'
import PictureWall from '../common/picture_wall'
import {
  orderData,
  setChoosedAddress,
  setChooseDeliver,
  setOrderIdInfo,
  setPlaceOrderPrice,
  setPlaceorderSuccess
} from '../../redux/reducers/orders_reducer'
import { homePageData, setComment } from '../../redux/reducers/homePage_reducer'
import { shoppingCartData } from '../../redux/reducers/cartItems_reducer'
import { useAppDispatch, useAppSelector } from '../../redux/reduxHooks'
import { round } from 'lodash'
import { InfoCircleOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import CashBox from '../cashItemsLayout'
import api from '../../config/api'

const { Header, Content, Footer } = Layout
const { Panel } = Collapse
const { Text } = Typography

const StyledContent = styled(Content)`
  margin: 16px;
  overflow: auto;
  height: 100vh;
  .ant-empty {
    display: none;
  }
`


const StyledHeader = styled(Header)`
  position: sticky;
  top: 0;
  z-index: 10;
  color: black;
  font-weight: 700;
  text-align: center;
  font-size: 20px;
  background-color: #fff !important;
`
const StyledLayoutFooter = styled(Footer)`
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 10;
  padding: 0 !important;
`
const ListStyle = styled(Row)`
  position: relative;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  padding: 10px;
  :after {
    content: '';
    position: absolute;
    bottom: 0;
    background: #ccc;
    width: 100%;
    height: 100%;
    height: 1px;
  }
`
const ListStyleNoLine = styled(Row)`
  position: relative;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  padding: 10px;
`

const CardStyle = styled.div<{ choose: number }>`
  border: 1px solid grey;
  position: relative;
  transition: all 0.5s ease;
  ::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    border: ${(props) => (props.choose ? '18px solid orange' : 'none')};
    border-top-color: transparent;
    border-left-color: transparent;
  }
  ::after {
    content: '';
    width: 7px;
    height: 14px;
    position: absolute;
    right: 6px;
    bottom: 6px;
    border: ${(props) => (props.choose ? '2px solid white' : 'none')};
    border-top-color: transparent;
    border-left-color: transparent;
    transform: rotate(45deg);
  }
  border-color: ${(props) => (props.choose ? 'orange' : 'lightgrey')};
  :hover {
    cursor: pointer;
  }
`

const PaymentProcess = (props: { showCart: (value: number) => void }) => {
  const { showCart } = props

  const configData = useAppSelector(homePageData)
  const { credit } = useAppSelector(homePageData)
  const { placeOrderPrice, orderIdInfo, chooseDeliver, chooseAddress } = useAppSelector(orderData)
  const placeOrderPriceAfterFixed = round(placeOrderPrice, 2)
  const shoppingCartInfo = useAppSelector(shoppingCartData)
  const dispatch = useAppDispatch()
  const match = useRouteMatch<{ id: string }>()
  const history = useHistory()

  const [showShipmentAddress, setShowShipmentAddress] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState('card')
  const [visaSelected, setVisaSelected] = useState(false)
  const [aliSelected, setAliSelected] = useState(false)
  const [weChatSelected, setWeChatSelected] = useState(false)
  const [eftPosSelected, setEftPosSelected] = useState(true)
  const [unionPaySelected, setUnionPaySelected] = useState(false)
  const [useCredit, setUseCredit] = useState(false)
  const [creditAmount, setCreditAmount] = useState(0)
  const [cashAmount, setCashAmount] = useState(0)
  const [cardAmount, setCardAmount] = useState(0)
  const [creditInfo, setCreditInfo] = useState<number>()
  const [offlineMethod, setOfflineMethod] = useState('')
  const [offlineAmount, setOfflineAmount] = useState(0)
  const [markShipped, setMarkShipped] = useState(1)
  const [loading, setLoading] = useState(false)
  const [extraMoney, setExtraMoney] = useState(0)
  const [showChangeModal, setShowChangeModal] = useState(false)
  const [cashItems, setCashItems] = useState([
    { value: 100, quantity: 0 },
    { value: 50, quantity: 0 },
    { value: 20, quantity: 0 },
    { value: 10, quantity: 0 },
    { value: 5, quantity: 0 },
    { value: 2, quantity: 0 },
    { value: 1, quantity: 0 },
    { value: 0.5, quantity: 0 },
    { value: 0.2, quantity: 0 },
    { value: 0.1, quantity: 0 }
  ])
  const [chargeItems, setChargeItems] = useState([
    { value: 100, quantity: 0 },
    { value: 50, quantity: 0 },
    { value: 20, quantity: 0 },
    { value: 10, quantity: 0 },
    { value: 5, quantity: 0 },
    { value: 2, quantity: 0 },
    { value: 1, quantity: 0 },
    { value: 0.5, quantity: 0 },
    { value: 0.2, quantity: 0 },
    { value: 0.1, quantity: 0 }
  ])

  const PictureRef = useRef<PictureWall>(null)
  const chargeAmountGroup = [
    { value: 100, quantity: 0 },
    { value: 50, quantity: 0 },
    { value: 20, quantity: 0 },
    { value: 10, quantity: 0 },
    { value: 5, quantity: 0 },
    { value: 2, quantity: 0 },
    { value: 1, quantity: 0 },
    { value: 0.5, quantity: 0 },
    { value: 0.2, quantity: 0 },
    { value: 0.1, quantity: 0 }
  ]
  useEffect(() => {
    let isUnmount = false
    if (!isUnmount) {
      if (match.params?.id) {
        orderDetail(match.params.id).then((res) => {
          if (res.success) {
            dispatch(setChooseDeliver({ shipmentMethod: res.data.shipmentMethod.name }))

            if (res.data.shippingAddress) {
              setShowShipmentAddress(true)
              dispatch(setChoosedAddress({ address: res.data.shippingAddress.fullName }))
            }
            showCart(1)
            dispatch(setOrderIdInfo({ id: match.params.id.toString() }))
            setCreditInfo(credit)
            dispatch(setPlaceOrderPrice({ price: res.data.totalDue }))
            if (credit > 0 && placeOrderPrice - credit > 0) {
              setCreditAmount(credit)
              setCardAmount(+(placeOrderPrice - credit).toFixed(2))
            } else if (credit > 0 && placeOrderPrice - credit < 0) {
              setCreditAmount(placeOrderPrice)
              setCardAmount(0)
            } else if (credit === 0) {
              setCreditAmount(0)
              setCardAmount(placeOrderPrice)
            }
          }
        })
        return
      }
    }
    return () => {
      isUnmount = true
    }
  }, [configData.lang, match.params.id, placeOrderPrice, credit])

  useEffect(() => {
    setExtraMoney(
      cashAmount + cardAmount + offlineAmount + creditAmount - placeOrderPrice < 0
        ? 0
        : round(round(cashAmount + cardAmount + offlineAmount + creditAmount, 1) - round(placeOrderPrice, 1), 1)
    )
  }, [cardAmount, cashAmount, creditAmount, offlineAmount])

  return (
    <>
      <Row wrap={false}>
        <Col flex={1}>
          <Layout style={{ maxHeight: '100vh', position: 'absolute', width: '100%' }}>
            <StyledHeader>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div></div>
                <div>
                  <FormattedMessage id='orderNum' /> # {orderIdInfo}
                </div>
                <div>NZ$ {placeOrderPrice.toFixed(2)}</div>
              </div>
            </StyledHeader>

            <StyledContent>
              <Space direction='vertical' style={{ width: '100%' }}>
                <Collapse activeKey={null} style={{ width: '100%' }} ghost expandIconPosition='right' destroyInactivePanel>
                  <Panel
                    key='1'
                    style={{
                      width: '100%',
                      backgroundColor: '#fff',
                      textAlign: match.params.id ? null : 'center'
                    }}
                    header={
                      <div style={{ textAlign: match.params.id ? null : 'center' }}>
                        <span style={{ fontWeight: 700, color: 'orange' }}>
                          <FormattedMessage id='shipmentMethod' />
                        </span>
                        <span style={{ fontWeight: 700, marginLeft: '30px', color: 'black' }}>{chooseDeliver}</span>
                      </div>
                    }
                  ></Panel>
                </Collapse>

                {showShipmentAddress ? (
                  <Collapse defaultActiveKey={['1']} style={{ width: '100%' }} ghost expandIconPosition='right' activeKey={null}>
                    <Panel
                      key='1'
                      style={{ width: '100%', backgroundColor: '#fff' }}
                      header={
                        <Row justify={match.params.id ? 'start' : 'center'}>
                          {match.params.id ? (
                            <>
                              <Col span={1.5}>
                                <span style={{ fontWeight: 700, color: 'orange' }}>
                                  <FormattedMessage id='address' />
                                </span>
                              </Col>
                              <Col span={16}>
                                <span
                                  style={{
                                    fontWeight: 700,
                                    marginLeft: '30px',
                                    color: 'black'
                                  }}
                                >
                                  {chooseAddress}
                                </span>
                              </Col>
                            </>
                          ) : (
                            <Col span={24} style={{ textAlign: match.params.id ? null : 'center' }}>
                              <span>
                                <span style={{ fontWeight: 700, color: 'orange', textAlign: 'center' }}>
                                  <FormattedMessage id='address' />
                                </span>
                              </span>
                              <span style={{ marginLeft: '10px' }}>
                                <span
                                  style={{
                                    fontWeight: 700,
                                    marginLeft: '30px',
                                    color: 'black',
                                    textAlign: 'center'
                                  }}
                                >
                                  {chooseAddress}
                                </span>
                              </span>
                            </Col>
                          )}
                        </Row>
                      }
                    ></Panel>
                  </Collapse>
                ) : null}
                <div style={{ overflow: 'auto', backgroundColor: '#fff' }}>
                  <Collapse defaultActiveKey={['1']} style={{ width: '100%' }} ghost expandIconPosition='right'>
                    <Panel
                      key='1'
                      style={{ width: '100%' }}
                      header={
                        <>
                          <div style={{ fontWeight: 700, color: 'orange', textAlign: match.params.id ? null : 'center' }}>
                            <FormattedMessage id='payment' />
                          </div>
                        </>
                      }
                    >
                      <div style={{ marginTop: '20px', backgroundColor: 'white' }}>
                        <Row>
                          <Col span={24}>
                            <Row>
                              <Col>
                                <span style={{ paddingLeft: '24px' }}>
                                  <FormattedMessage id='cash' />:
                                </span>
                                <Popconfirm
                                  placement='right'
                                  title={
                                    <>
                                      <FormattedMessage id='cashAmount' />
                                      <br />
                                      <span style={{ fontSize: '0.8rem' }}>
                                        <FormattedMessage id='totalDue' />: ${' '}
                                      </span>
                                      {round(placeOrderPrice, 1)}
                                      <span style={{ fontSize: '0.8rem', marginLeft: '1rem' }}>
                                        <FormattedMessage id='cashReceived' />: ${' '}
                                      </span>
                                      {cashItems.reduce((pre, nex) => {
                                        return pre + nex.quantity * nex.value
                                      }, 0)}
                                    </>
                                  }
                                  description={
                                    <CashBox
                                      type='income'
                                      setCashItems={(items) => setCashItems(items)}
                                      setChargeItems={(items) => setChargeItems(items)}
                                      chargeItems={chargeItems}
                                      cashItems={cashItems}
                                    />
                                  }
                                  icon={<InfoCircleOutlined />}
                                  destroyTooltipOnHide
                                  onConfirm={() => {
                                    let totalCash = 0
                                    cashItems.forEach((cash) => {
                                      totalCash += cash.value * cash.quantity
                                    })
                                    if (placeOrderPrice > totalCash) {
                                      setCashAmount(totalCash)
                                      //  setExtraMoney(0)
                                      if (credit - (placeOrderPrice - totalCash) > 0) {
                                        setCreditAmount(+(placeOrderPrice - totalCash).toFixed(2))

                                        setCardAmount(0)
                                        setOfflineAmount(0)
                                        setOfflineMethod('')
                                      } else {
                                        setCreditAmount(+credit.toFixed(2))
                                        if (paymentMethods === 'card') {
                                          setCardAmount(+(placeOrderPriceAfterFixed - totalCash - +credit).toFixed(2))
                                          setOfflineAmount(0)
                                          setOfflineMethod('')
                                        } else {
                                          setOfflineAmount(+(placeOrderPriceAfterFixed - totalCash - +credit).toFixed(2))
                                          setCardAmount(0)
                                          setOfflineMethod('')
                                        }
                                      }

                                      return
                                    }
                                    if (placeOrderPrice <= totalCash) {
                                      // setExtraMoney(+e.target.value - placeOrderPriceAfterFixed)
                                      setCashAmount(totalCash)
                                      setCreditAmount(0)
                                      setCardAmount(0)
                                      setOfflineMethod('')
                                      setOfflineAmount(0)
                                      return
                                    }
                                  }}
                                  onCancel={() => {
                                    setCashItems(chargeAmountGroup)
                                    setExtraMoney(
                                      cashAmount + cardAmount + offlineAmount + creditAmount - placeOrderPrice < 0
                                        ? 0
                                        : cashAmount + cardAmount + offlineAmount + creditAmount - placeOrderPrice
                                    )
                                  }}
                                >
                                  <InputNumber
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => +value.replace(/\$\s?|(,*)/g, '')}
                                    style={{ marginLeft: '15px' }}
                                    min={0}
                                    readOnly
                                    value={cashAmount}
                                  />
                                </Popconfirm>
                              </Col>
                              <Col
                                style={{ color: 'grey', marginLeft: '10px'}}
                                onChange={() => {
                                  setUseCredit(!useCredit)
                                }}
                              >
                                <>
                                  <span>
                                    <FormattedMessage id='deposit' />:
                                  </span>
                                  <InputNumber
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    style={{ marginLeft: '5px' }}
                                    min={0}
                                    max={credit}
                                    value={creditAmount}
                                    onBlur={(e) => {
                                      if (+e.target.value > credit) {
                                        setCreditAmount(credit)
                                      } else {
                                        if (+e.target.value > credit) {
                                          message.warning('Maximum credit limit exceeded')
                                          setCreditAmount(credit)
                                        } else {
                                          setCreditAmount(+e.target.value)
                                        }
                                        if (paymentMethods === 'card') {
                                          if (+e.target.value + cashAmount < placeOrderPrice) {
                                            setCardAmount(+(placeOrderPrice - +e.target.value - cashAmount).toFixed(2))
                                          } else {
                                            setCardAmount(0)
                                          }
                                          setOfflineMethod('')
                                          setOfflineAmount(0)
                                        } else {
                                          if (+e.target.value + cashAmount < placeOrderPrice) {
                                            setOfflineAmount(+(placeOrderPrice - +e.target.value - cashAmount).toFixed(2))
                                          } else {
                                            setOfflineAmount(0)
                                          }
                                          setOfflineMethod('')
                                          setCardAmount(0)
                                        }
                                        // setExtraMoney(Math.abs(placeOrderPriceAfterFixed - +e.target.value - cashAmount))
                                      }
                                    }}
                                  />
                                  <span style={{ color: 'orange', marginLeft: '10px' }}>
                                    <FormattedMessage id='available' />{credit || creditInfo || 0}
                                  </span>
                                  <Checkbox
                                    name={'maxCredit'}
                                    style={{ color: 'orange', marginLeft: '10px' }}
                                    checked={creditAmount === credit}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        if (placeOrderPrice - cashAmount > 0) {
                                          if (credit > placeOrderPrice - cashAmount) {
                                            setCreditAmount(placeOrderPrice - cashAmount)
                                            setCardAmount(0)
                                            setOfflineAmount(0)
                                            //setExtraMoney(0)
                                          } else if (credit > 0) {
                                            setCreditAmount(credit)
                                            if (paymentMethods === 'offline') {
                                              setEftPosSelected(false)
                                              setUnionPaySelected(false)
                                              setVisaSelected(false)
                                              setAliSelected(false)
                                              setWeChatSelected(false)
                                              if (cashAmount > placeOrderPrice) {
                                                setOfflineAmount(0)
                                                setCardAmount(0)
                                                return
                                              }
                                              setOfflineAmount(+(placeOrderPrice - credit - cashAmount).toFixed(2))
                                              setCardAmount(0)
                                            } else if (paymentMethods === 'card') {
                                              setCardAmount(+(placeOrderPrice - credit - cashAmount).toFixed(2))
                                              setOfflineMethod('')
                                              setOfflineAmount(0)
                                            }
                                          }
                                          //setExtraMoney(placeOrderPriceAfterFixed - cashAmount - credit)
                                        } else {
                                          //setExtraMoney(cashAmount - placeOrderPriceAfterFixed)
                                        }
                                      } else {
                                        setCardAmount(placeOrderPrice - cashAmount)
                                        setCreditAmount(0)
                                      }
                                    }}
                                  >
                                    <FormattedMessage id='useAll' />
                                  </Checkbox>
                                </>
                              </Col>
                              <Divider />
                              <Tabs
                                style={{
                                  marginLeft: match.params.id ? '0px' : '390px'
                                }}
                                tabPosition='left'
                                onChange={(value) => {
                                  setPaymentMethods(value)
                                  if (value === 'offline') {
                                    setOfflineAmount(+(placeOrderPrice - creditAmount - cashAmount).toFixed(2))

                                    if (cashAmount > placeOrderPrice) {
                                      setOfflineAmount(0)
                                    }
                                    setEftPosSelected(false)
                                    setUnionPaySelected(false)
                                    setVisaSelected(false)
                                    setAliSelected(false)
                                    setWeChatSelected(false)
                                    setCardAmount(0)
                                  } else if (value === 'card') {
                                    setCardAmount(+(placeOrderPrice - creditAmount - cashAmount).toFixed(2))
                                    if (cashAmount > placeOrderPrice) {
                                      setCardAmount(0)
                                    }
                                    setOfflineMethod('')
                                    setOfflineAmount(0)
                                  }
                                }}
                              >
                                <Tabs.TabPane tab={<FormattedMessage id='useCard' />} key='card'>
                                  <InputNumber
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => +value.replace(/\$\s?|(,*)/g, '')}
                                    style={{ marginLeft: '10px' }}
                                    min={0}
                                    onBlur={(e) => {
                                      setCardAmount(+e.target.value)
                                    }}
                                    value={cardAmount}
                                  />
                                  <Row style={{ marginTop: '15px', marginLeft: '10px' }}>
                                    <Col span={3}>
                                      <CardStyle
                                        choose={eftPosSelected ? 1 : 0}
                                        onClick={() => {
                                          setEftPosSelected(true)
                                          setVisaSelected(false)
                                          setUnionPaySelected(false)
                                          setAliSelected(false)
                                          setWeChatSelected(false)
                                        }}
                                      >
                                        <Image
                                          preview={false}
                                          style={{ transform: 'scale(0.7)', height: '50px', objectFit: 'contain' }}
                                          alt='example'
                                          src='/icon/EftposNZ.png'
                                        />
                                      </CardStyle>
                                    </Col>
                                    <Col span={3}>
                                      <CardStyle
                                        choose={unionPaySelected ? 1 : 0}
                                        onClick={() => {
                                          setUnionPaySelected(true)
                                          setEftPosSelected(false)
                                          setVisaSelected(false)
                                          setAliSelected(false)
                                          setWeChatSelected(false)
                                        }}
                                      >
                                        <Image
                                          preview={false}
                                          style={{ transform: 'scale(0.7)', height: '50px', objectFit: 'contain' }}
                                          alt='example'
                                          src='/icon/unionpay.png'
                                        />
                                      </CardStyle>
                                    </Col>

                                    <Col span={3}>
                                      <CardStyle
                                        choose={visaSelected ? 1 : 0}
                                        onClick={() => {
                                          setVisaSelected(true)
                                          setAliSelected(false)
                                          setUnionPaySelected(false)
                                          setEftPosSelected(false)
                                          setWeChatSelected(false)
                                        }}
                                      >
                                        <Image
                                          preview={false}
                                          style={{ transform: 'scale(0.7)', height: '50px', objectFit: 'contain' }}
                                          alt='example'
                                          src='/icon/visa.png'
                                        />
                                      </CardStyle>
                                    </Col>

                                    <Col span={3}>
                                      <CardStyle
                                        choose={aliSelected ? 1 : 0}
                                        onClick={() => {
                                          setVisaSelected(false)
                                          setAliSelected(true)
                                          setUnionPaySelected(false)
                                          setEftPosSelected(false)
                                          setWeChatSelected(false)
                                        }}
                                      >
                                        <Image
                                          preview={false}
                                          style={{ transform: 'scale(0.7)', height: '50px', objectFit: 'contain' }}
                                          alt='example'
                                          src='/icon/alipay.png'
                                        />
                                      </CardStyle>
                                    </Col>
                                    <Col span={3}>
                                      <CardStyle
                                        choose={weChatSelected ? 1 : 0}
                                        onClick={() => {
                                          setVisaSelected(false)
                                          setAliSelected(false)
                                          setUnionPaySelected(false)
                                          setEftPosSelected(false)
                                          setWeChatSelected(true)
                                        }}
                                      >
                                        <Image
                                          preview={false}
                                          style={{ transform: 'scale(0.7)', height: '50px', objectFit: 'contain' }}
                                          alt='wechat'
                                          src='/icon/wechat-logo.png'
                                        />
                                      </CardStyle>
                                    </Col>
                                  </Row>
                                </Tabs.TabPane>

                                <Tabs.TabPane tab={<FormattedMessage id='offlinePayment' />} key='offline'>
                                  <InputNumber
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => +value.replace(/\$\s?|(,*)/g, '')}
                                    style={{ marginLeft: '10px' }}
                                    min={0}
                                    onBlur={(e) => {
                                      if (credit > 0 && placeOrderPrice - (+e.target.value + cashAmount) <= credit) {
                                        setOfflineAmount(+e.target.value)

                                        setCreditAmount(+(placeOrderPrice - +e.target.value - cashAmount).toFixed(2))
                                        //setExtraMoney(0)

                                        return
                                      }
                                      if (placeOrderPrice - +e.target.value === creditAmount + cashAmount) {
                                        setOfflineAmount(+e.target.value)
                                        //setExtraMoney(0)
                                        return
                                      }

                                      if (placeOrderPrice < +e.target.value + creditAmount + cashAmount) {
                                        setOfflineAmount(+e.target.value)
                                        //setExtraMoney(+e.target.value + creditAmount + cashAmount - placeOrderPriceAfterFixed)
                                        return
                                      }
                                    }}
                                    value={offlineAmount}
                                  />
                                  <div style={{ marginLeft: '10px', marginTop: '5px' }}>
                                    <Radio.Group
                                      onChange={(e) => {
                                        setOfflineMethod(e.target.value.toString())
                                      }}
                                    >
                                      <Row>
                                        <Col span={24}>
                                          <Radio value='anz'>
                                            {api.bank}
                                          </Radio>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col span={24}>
                                          <Radio value='wechat'>
                                            {api.wechat}
                                          </Radio>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col span={24}>
                                          <Radio value='alipay'>
                                            {api.alipay}
                                          </Radio>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col span={24}>
                                          <Radio value='other'>
                                            <FormattedMessage id='other' />
                                          </Radio>
                                        </Col>
                                      </Row>
                                    </Radio.Group>
                                    <div style={{ marginTop: '10px' }}>
                                      <FormattedMessage id='paymentScreenshoot' />
                                    </div>
                                    <div style={{ marginTop: '10px', width: '80px' }}>
                                      <PictureWall ref={PictureRef} number={1} />
                                    </div>
                                  </div>
                                </Tabs.TabPane>
                              </Tabs>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Panel>
                  </Collapse>
                  <Text
                    type='danger'
                    style={{
                      marginLeft: match.params.id ? '0px' : '35.5%'
                    }}
                  >
                    <FormattedMessage id='attention' />
                  </Text>
                </div>
              </Space>
            </StyledContent>
            <StyledLayoutFooter>
              <Card
                size='small'
                actions={[
                  <Button
                    style={{ backgroundColor: 'orange', color: 'white' }}
                    key={1}
                    type='primary'
                    shape='round'
                    size='large'
                    block
                    loading={loading}
                    onClick={() => {
                      history.push(`/order/${orderIdInfo}`)
                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <FormattedMessage id='backToOrder' />
                    </div>
                  </Button>,
                  <Button
                    style={{ backgroundColor: 'orange', color: 'white' }}
                    key={2}
                    type='primary'
                    shape='round'
                    size='large'
                    block
                    loading={loading}
                    onClick={() => {
                      setShowChangeModal(true)
                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <FormattedMessage id='paymentReceive' />
                    </div>
                  </Button>
                ]}
              >
                <>
                  <ListStyle justify='space-around'>
                    <Col>
                      <Text strong>
                        <FormattedMessage id='remainMoney' />
                      </Text>
                    </Col>
                    <Col>
                      <Text strong>
                        NZ${' '}
                        {placeOrderPrice - cardAmount - cashAmount - creditAmount - offlineAmount < 0
                          ? 0
                          : (placeOrderPrice - cardAmount - cashAmount - creditAmount - offlineAmount).toFixed(2)}{' '}
                        / ¥{' '}
                        {(placeOrderPrice - cardAmount - cashAmount - creditAmount - offlineAmount < 0
                          ? 0
                          : (placeOrderPrice - cardAmount - cashAmount - creditAmount - offlineAmount) * shoppingCartInfo.currencyRate
                        ).toFixed(2)}
                      </Text>
                    </Col>
                  </ListStyle>
                  <ListStyleNoLine>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'flex-end',
                        width: '100%',
                        marginLeft: '22px',
                        fontFamily: "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif"
                      }}
                      key={1}
                    >
                      <Text strong>
                        <FormattedMessage id='partialReceive' />
                        <Switch
                          style={{ marginLeft: '17px' }}
                          onChange={(checked) => {
                            if (checked) {
                              setMarkShipped(0)
                            } else {
                              setMarkShipped(1)
                            }
                          }}
                        />
                      </Text>

                      <Button
                        type='primary'
                        shape='round'
                        block
                        style={{ width: '50%' }}
                        onClick={() => {
                          window.open(window.location.origin + `/print/${orderIdInfo}`)
                        }}
                      >
                        <FormattedMessage id='printOrder' />
                      </Button>
                    </div>
                  </ListStyleNoLine>
                </>
              </Card>
            </StyledLayoutFooter>
          </Layout>
        </Col>
      </Row>
      <Modal
        open={showChangeModal}
        destroyOnClose
        onOk={() => {
          // let totalCash = 0
          // chargeItems.forEach((cash) => {
          //   totalCash += cash.value * cash.quantity
          // })
          // if (totalCash !== round(extraMoney, 1)) {
          //   message.warning('现金面值和总额不一致')
          //   return
          // }
          let storeId = parseInt(sessionStorage.getItem('storeId'))
          const picName = PictureRef.current?.getFileName()
          if (offlineMethod !== '' && picName.length === 0) {
            message.error('Please upload picture')
            return
          }
          setLoading(true)
          const res = {
            storeId,
            cash: +cashAmount,
            credit: +creditAmount,
            nonCash: +cardAmount,
            nonCashMethod: eftPosSelected
              ? 'eftpos'
              : visaSelected
                ? 'credit'
                : weChatSelected
                  ? 'wechat'
                  : aliSelected
                    ? 'alipay'
                    : unionPaySelected
                      ? 'unionpay'
                      : '',
            transfer: +offlineAmount,
            transferMethod: offlineMethod,
            shipping: markShipped,
            change: extraMoney,
            screenshot: picName ? picName[0]?.name : '',
            changeItems: chargeItems,
            cashItems
          }

          makePayment(res, orderIdInfo).then((res) => {
            if (res.success) {
              message.success(res.message)
              if (markShipped === 0) {
                history.push(`/order/${orderIdInfo}/dispatch`)
                return
              }
              history.push('/placeorder/paymentsuccess')
              setLoading(false)
              dispatch(setPlaceorderSuccess({ success: false }))
              sessionStorage.removeItem('customerIdFromOrder')
              dispatch(
                setComment({
                  comment: ''
                })
              )
              setChargeItems(chargeAmountGroup)
            } else {
              message.error(res.message)
              setLoading(false)
            }
          })
        }}
        onCancel={() => {
          setChargeItems(chargeAmountGroup)
          setShowChangeModal(false)
        }}
        okText={<FormattedMessage id='confirm' />}
        centered
        forceRender
        cancelText={<FormattedMessage id='cancel' />}
        width={'fit-content'}
      >
        {/* <span>找零: $ </span>

        <Input style={{ marginLeft: '10px', width: '17%' }} value={extraMoney.toFixed(1) + '0'} readOnly />
        {cashCodes && cashSelectorButton('charge')} */}
        <div style={{ fontSize: '1rem', marginBottom: '1rem' }}>
          <InfoCircleOutlined style={{ color: 'orange', paddingRight: '5px' }} />
          <FormattedMessage id='changeAmount' />
        </div>
        <span style={{ fontSize: '0.8rem' }}>
          <FormattedMessage id='changeRequired' />: ${' '}
        </span>
        <Input style={{ marginLeft: '10px', marginBottom: '1rem', width: '17%' }} value={extraMoney.toFixed(1) + '0'} readOnly />
        <span style={{ fontSize: '0.8rem', marginLeft: '1rem' }}>
          <FormattedMessage id='changeReceived' />: ${' '}
        </span>
        {chargeItems.reduce((pre, nex) => {
          return pre + nex.quantity * nex.value
        }, 0)}
        <CashBox
          type='charge'
          setCashItems={(items) => setCashItems(items)}
          setChargeItems={(items) => setChargeItems(items)}
          chargeItems={chargeItems}
          cashItems={cashItems}
        />
      </Modal>
    </>
  )
}

export default PaymentProcess
