/* eslint-disable react-hooks/exhaustive-deps */
import { Button, List, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { CustomerTypes } from '../../models/customerTypes'

interface Props {
  success: (value: CustomerTypes) => void
  onHoldList: any[]
  isEmpty: boolean
  currentlyChoose: CustomerTypes
  holdListVisible: boolean
  finished: () => void
}
const OnHoldList = (props: Props) => {
  const { success, onHoldList, isEmpty, currentlyChoose, holdListVisible, finished } = props
  const [holdList, setHoldList] = useState([])

  useEffect(() => {
    if (onHoldList) {
      setHoldList(onHoldList)
    }
  }, [onHoldList, isEmpty])

  useEffect(() => {
    if (isEmpty) {
      setHoldList(holdList.filter((item) => item.id !== currentlyChoose.id))
    }
  }, [isEmpty])

  return (
    <Modal
      visible={holdListVisible}
      onCancel={finished}
      centered
      maskClosable={false}
      footer={
        <Button key='back' onClick={finished}>
          Cancel
        </Button>
      }
    >
      <div style={{ marginTop: '20px' }} />
      <List
        size='small'
        header={<div style={{ textAlign: 'center', fontWeight: 700 }}>OnHold Customer List</div>}
        bordered
        dataSource={holdList}
        renderItem={(item) => (
          <List.Item
            style={{ cursor: 'pointer' }}
            onClick={() => {
              success(item)
            }}
          >
            {`${item.nickName || ''} ${item.firstName || ''} ${item.lastName || ''} ${item.phone || ''} ${item.email || ''}`}
          </List.Item>
        )}
      />
    </Modal>
  )
}

export default OnHoldList
