import { Col, Layout, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { setMenuDisplay } from '../../redux/reducers/menu_reducer'
import { useAppDispatch } from '../../redux/reduxHooks'

const { Header, Content } = Layout
const StyledContent = styled(Content)`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  .ant-empty {
    display: none;
  }
  background-color: white;
  padding: 5px;
`

const ListHeader = styled(Header)`
  position: sticky;
  top: 0;
  z-index: 10;
  color: black;
  height: 64px;
  font-weight: 700;
  text-align: center;
  font-size: 16px;
  background-color: orange !important;
`

const ColStyle = styled(Col)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const CommonListLayout: React.FC<{ title: string }> = (props) => {
  const dispatch = useAppDispatch()
  return (
    <Row>
      <Col span={24}>
        <Layout style={{ height: '100vh', position: 'absolute', width: '100%' }}>
          <ListHeader>
            <Row>
              <ColStyle
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(
                    setMenuDisplay({
                      menu: true
                    })
                  )
                }}
              >
                <img src='/icon/menu.png' width='35px' height='35px' alt='menu' />
              </ColStyle>

              <ColStyle flex={1} style={{ color: 'white', marginRight: '10px' }}>
                <div>{props.title}</div>
              </ColStyle>
            </Row>
          </ListHeader>
          <StyledContent>{props.children}</StyledContent>
        </Layout>
      </Col>
    </Row>
  )
}

export default CommonListLayout
