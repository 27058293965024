/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Title from 'antd/lib/typography/Title'
import { Layout, Row, Col, Input, List, Button, Dropdown, message, Form, Divider, Select, InputNumber, Pagination, Radio } from 'antd'
import { SearchOutlined, CalculatorOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import ProductCard from '../productCard/index'
import { ProductInfo, PutShoppingCartReq } from '../../models/shoppingCartItem'
import { useAppDispatch, useAppSelector } from '../../redux/reduxHooks'
import { customerInfoData, getShipmentAreaAndGroupId } from '../../redux/reducers/customerInfo_reducer'
import { setCartChange, setCartIncrease, shoppingCartData } from '../../redux/reducers/cartItems_reducer'
import Bundled from '../specialProductGroup/bundled'
import Grouped from '../specialProductGroup/grouped'
import ModalForm from '../common/modal-form'
import {
  getAllProducts,
  getBundleProductInfo,
  getGroupProductInfo,
  getProductInfo,
  productsData,
  setBundledVisible,
  setGroupModalVisible,
  setHasMore,
  setProductAttrVisible,
  setProductsEmpty
} from '../../redux/reducers/product_reducer'
import ProductAttrProducts from '../specialProductGroup/productAttr'
import { checkout, getSiteInfo, putItemToCart } from '../../api/response'
import MenuLayout from './menuLayout'
import { setMenuDisplay } from '../../redux/reducers/menu_reducer'
import { Navbar } from '../../models/siteInfoTypes'
import usePrevious from '../../utils/usePrevious'
import { isEqual } from 'lodash'
import { setLanguage, homePageData, lang, setCheckoutInfo } from '../../redux/reducers/homePage_reducer'
import dayjs from 'dayjs'
import { CheckoutReqTypes } from '../../models/checkoutTypes'

const { Header, Content, Footer } = Layout

const CalculatorIconStyle = styled(CalculatorOutlined)`
  font-size: 1.2rem;
  color: gray;
`

const ColStyle = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledContent = styled(Content)`
  margin: 0.3rem;
  // background-color: #fff;
  min-height: 100%;
  overflow: auto;
  overflow-x: hidden;
  // @media screen and (max-width: 1200px) {
  //   margin: 0.3rem;
  //   // background-color: #fff;
  //   min-height: 100%;
  //   overflow: auto;
  //   overflow-x: auto;
  // }
`

const StyledLayoutHeader = styled(Header)`
  position: sticky;
  margin: 0 0 0 0;
  top: 0;
  z-index: 10;
  background-color: #fff;
  height: fit-content !important;
  padding: 0 2rem !important;
`

const StyledList = styled(List)`
  min-height: 90vh;
  // min-width: 600px;
`

const LeftLayout = () => {
  const [searchQuery, setSearchQuery] = useState<string | undefined>('')
  const [searchConditionList, setSearchConditionList] = useState<Navbar[]>([])
  const [searchCondition, setSearchCondition] = useState<{ target: string; targetValue: string }>({ target: 'all', targetValue: 'all' })
  const [pageSize, setPageSize] = useState(16)
  const [pageNo, setPageNo] = useState(1)
  const [customerSale, setCustomerSale] = useState(false)
  const [attrSkuId, setAttrSkuId] = useState<number>()
  const [attrProductId, setAttrProductId] = useState<number>()
  const [attrProductQty, setAttrProductQty] = useState<number>()
  const [selectedCategory, setSelectedCategory] = useState<number>(0)
  const [quantity, setQuantity] = useState(1)
  const [inputType, setInputType] = useState('keydown')
  const searchInput = useRef(null)

  const productInfoSate = useAppSelector(productsData)
  const language = useAppSelector(lang)
  const customerInfoState = useAppSelector(customerInfoData)
  const shoppingCartInfo = useAppSelector(shoppingCartData)
  const dispatch = useAppDispatch()
  const previousSearchCondition = usePrevious(searchCondition)
  const previousCustomerId = usePrevious(customerInfoState.customerId)

  const getWebsiteInfo = async () => {
    const res = await getSiteInfo()
    if (res.success) {
      setSearchConditionList(res.data.posNavbar)
    }
  }
  useEffect(() => {
    getWebsiteInfo()
  }, [language, customerInfoState.customerId])
  useEffect(() => {
    // let isUnmount = false
    // if (
    //   (!isUnmount && inputType === 'keydown') ||
    //   !isEqual(previousSearchCondition, searchCondition) ||
    //   !isEqual(previousCustomerId, customerInfoState.customerId)
    // ) {
    // dispatch(setHasMore({ hasmore: true }))
    const tempPageSize = localStorage.getItem('pageSize')
    if (tempPageSize) setPageSize(tempPageSize)
    query()
    // }

    // return () => {
    //   isUnmount = true
    // }
  }, [pageSize, pageNo, language, customerInfoState.customerId, searchCondition])

  const query = () => {
    dispatch(
      getAllProducts({
        collection: searchCondition.target === 'collection' ? searchCondition.targetValue : '',
        categories: searchCondition.target === 'category' ? [+searchCondition.targetValue] : [],
        brands: searchCondition.target === 'brand' ? [+searchCondition.targetValue] : [],
        locations: searchCondition.target === 'location' ? [+searchCondition.targetValue] : [],
        customerId: customerInfoState.customerId,
        pageSize: pageSize,
        q: searchCondition.target === 'keyword' ? `${searchQuery ?? ''}${searchCondition.targetValue}` : searchQuery,
        currentPage: pageNo
      })
    )
  }

  const toggleCollapsed = () => {
    dispatch(
      setMenuDisplay({
        menu: true
      })
    )
  }
  const { areaCode, groupId } = useAppSelector(getShipmentAreaAndGroupId)
  const addItemToCart = async (value: number, item: ProductInfo) => {
    let storeId: number | string = sessionStorage.getItem('storeId')
    storeId = parseInt(storeId)
    const param = {
      customerId: customerInfoState.customerId === 1 ? 1 : customerInfoState.customerId,
      customerGroupId: customerInfoState.customerGroupId,
      storeId: storeId,
      productId: item.productId,
      quantity: value,
      lang: { lang: language }
    }
    const response = await putItemToCart(param)
    if (response.success) {
      if (!productInfoSate.hasMore) {
        searchInput.current.focus()
        setSearchQuery(undefined)
        // dispatch(setHasMore({ hasmore: false }))
        // debouncedChangeHandler({})
      }
      const params: Partial<CheckoutReqTypes> = {
        cartList: [],
        point: 0,
        coupon: '',
        customerId: customerInfoState.customerId,
        shipmentMethodId: shoppingCartInfo?.chosenShipmentMethod?.id ,
        shipmentAreaCode: areaCode,
        shipmentGroupId: groupId
      }
      checkout(params).then((res) => {
        if (res.success) {
          dispatch(setCheckoutInfo({ checkout: res.data }))
        } else {
          message.error(res.message)
        }
      })
      dispatch(setCartIncrease({ num: 1 }))
    } else {
      message.error(response.message)
    }
  }
  const addBundleItemsToCart = async (value: PutShoppingCartReq) => {
    let storeId: number | string = sessionStorage.getItem('storeId')
    storeId = parseInt(storeId)

    const param = {
      customerId: customerInfoState.customerId,
      customerGroupId: customerInfoState.customerGroupId,
      storeId: storeId,
      productId: value.productId,
      productExtra: value.productExtra,
      quantity: value.quantity
    }
    const response = await putItemToCart(param)
    if (response.success) {
      if (!productInfoSate.hasMore) {
        searchInput.current.focus()
        setSearchQuery(undefined)
        // dispatch(setHasMore({ hasmore: false }))
        // debouncedChangeHandler({})
      }
      dispatch(setCartIncrease({ num: 1 }))
      const params: Partial<CheckoutReqTypes> = {
        cartList: [],
        point: 0,
        coupon: '',
        customerId: customerInfoState.customerId,
        shipmentMethodId: shoppingCartInfo?.chosenShipmentMethod?.id,
        shipmentAreaCode: areaCode,
        shipmentGroupId: groupId
      }
      checkout(params).then((res) => {
        if (res.success) {
          dispatch(setCheckoutInfo({ checkout: res.data }))
        } else {
          message.error(res.message)
        }
      })
    } else {
      message.error(response.message)
    }
  }

  const layout = {
    labelCol: { span: 8 }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = (e: any) => {
    // setInputType(e.type)
    dispatch(setProductsEmpty())
    setPageNo(1)
    query()

    // if (e.target.value === '') {
    //   dispatch(setProductsEmpty())
    //   dispatch(setHasMore({ hasmore: false }))
    //   setSearchQuery(undefined)
    // }
  }

  const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: '${name} is required!',
    types: {
      // eslint-disable-next-line no-template-curly-in-string
      email: '${label} is not a valid email!'
    }
  }

  const onFinish = async () => {
    setCustomerSale(false)
  }

  return (
    <>
      <Row wrap={false}>
        <div style={{ maxHeight: '100%' }}>
          <MenuLayout />
        </div>
        <Col flex={1}>
          <Layout style={{ maxHeight: '100vh' }}>
            <StyledLayoutHeader style={{ backgroundColor: 'orange' }}>
              <Row align='middle' wrap={false}>
                <ColStyle style={{ cursor: 'pointer' }} onClick={toggleCollapsed}>
                  <img src='/icon/menu.png' width='35px' height='35px' alt='menu' />
                </ColStyle>
                <ColStyle
                  onClick={() => {
                    if (localStorage.getItem('lang') === 'zh-CN') {
                      dispatch(setLanguage({ lang: 'en-NZ' }))
                      localStorage.setItem('lang', 'en-NZ')
                      dayjs.locale('en')
                    } else {
                      dispatch(setLanguage({ lang: 'zh-CN' }))
                      localStorage.setItem('lang', 'zh-CN')
                      dayjs.locale('zh-cn')
                    }
                  }}
                >
                  <img src='/icon/translate.png' width='35px' height='35px' alt='translate' />
                </ColStyle>
                <Col>
                  <Input
                    size='middle'
                    onChange={(e) => {
                      setInputType(e.type)
                      if (e.target.value === '') {
                        setSearchQuery(undefined)
                      } else {
                        setSearchQuery(e.target.value)
                      }
                    }}
                    onPressEnter={debouncedChangeHandler}
                    ref={searchInput}
                    value={searchQuery}
                    placeholder='SKU, Barcode, Keyword'
                    suffix={
                      <SearchOutlined
                        style={{ color: 'orange' }}
                        onClick={() => {
                          setInputType('keydown')
                        }}
                      />
                    }
                  />
                </Col>
                <Col style={{ textAlign: 'left', marginLeft: '10px' }}>
                  {searchConditionList && (
                    <Radio.Group
                      buttonStyle='outline'
                      onChange={(e) => {
                        if (e.target.value === 'all') {
                          setSearchCondition({ target: 'all', targetValue: 'all' })
                          setPageNo(1)
                          setSelectedCategory(0)
                          return
                        }
                        setPageNo(1)
                        setSelectedCategory(+e.target.value)

                        setSearchCondition({
                          target: searchConditionList[+e.target.value].target,
                          targetValue: searchConditionList[+e.target.value].targetValue
                        })
                      }}
                      defaultValue={'all'}
                      size='middle'
                      optionType='button'
                    >
                      <Radio.Button value={'all'}>
                        <FormattedMessage id='searchAll' />
                      </Radio.Button>
                      {searchConditionList.map((item, key) => {
                        return (
                          <Radio.Button key={item.name} value={key}>
                            {item.name}
                          </Radio.Button>
                        )
                      })}
                    </Radio.Group>
                  )}
                </Col>
              </Row>
            </StyledLayoutHeader>

            <StyledContent>
              <StyledList
                size='small'
                grid={{
                  xs: 2,
                  sm: 2,
                  md: 2, //pad 1024x768 => 1336
                  lg: 3, //laptop 1366x800 => 1440
                  xl: 4, // desktop 1440x768 => 1920
                  xxl: 5 // 4K desktop 1920+
                }}
                // loading={productInfoSate.hasMore}
                dataSource={productInfoSate.products}
                renderItem={(item: any) => {
                  return (
                    <List.Item
                      style={{ height: '100%', margin: '5px', padding: '0px' }}
                      key={item.productId}
                      onClick={() => {
                        if (item.typeId === 'grouped') {
                          dispatch(getGroupProductInfo({ id: item.productId, item }))
                        } else if (item.typeId === 'bundle') {
                          dispatch(getBundleProductInfo({ id: item.productId, item, customerId: customerInfoState.customerId }))
                        } else if (item.typeId === 'variant') {
                          dispatch(getProductInfo({ id: item.productId, typeId: 'variant', customerId: customerInfoState.customerId }))
                        } else {
                          addItemToCart(1, item)
                          dispatch(setCartChange({ cartChanged: true }))
                        }
                      }}
                    >
                      <ProductCard data={item} items={shoppingCartInfo.cartItems} />
                    </List.Item>
                  )
                }}
              />
            </StyledContent>
            <Footer style={{padding: '10px 20px', height: '3.5rem'}}>
              <Row wrap={false} style={{display: 'flex', justifyContent: 'center'}}>
                <Col style={{display: 'flex', alignItems: 'center'}}><FormattedMessage id='productTotal' />{productInfoSate.totalCount}<FormattedMessage id='productUnit' />
                  <Pagination
                    total={productInfoSate.totalCount}
                    responsive
                    defaultPageSize={pageSize}
                    current={pageNo}
                    onChange={(page, pageSize) => {
                      setPageSize(pageSize)
                      localStorage.setItem('pageSize', pageSize+'')
                      setPageNo(page)
                    }}
                    showQuickJumper={false}
                    showSizeChanger
                    pageSizeOptions={['4', '6', '9','12','16', '20']}
                  />
                </Col>
              </Row>
            </Footer>
          </Layout>
        </Col>
      </Row>
      <ModalForm
        visible={productInfoSate.productAttrVisible}
        onCancel={() => {
          dispatch(setProductAttrVisible({ visible: false }))
        }}
        width={'50%'}
        footer={null}
      >
        <ProductAttrProducts
          addToCart={async () => {
            if (!attrProductId || !attrSkuId) {
              message.warning('Please select One item before add to cart')
              return
            }
            let storeId = parseInt(sessionStorage.getItem('storeId'))
            const response = await putItemToCart({
              customerId: customerInfoState.customerId === 1 ? 1 : customerInfoState.customerId,
              storeId: storeId,
              skuId: attrSkuId,
              productId: attrProductId,
              quantity: attrProductQty
            })
            if (response.success) {
              if (!productInfoSate.hasMore) {
                searchInput.current.focus()
                setSearchQuery(undefined)
                // dispatch(setHasMore({ hasmore: false }))
                // debouncedChangeHandler({})
              }

              dispatch(setCartIncrease({ num: 1 }))
              const params: Partial<CheckoutReqTypes> = {
                cartList: [],
                point: 0,
                coupon: '',
                customerId: customerInfoState.customerId,
                shipmentMethodId: shoppingCartInfo?.chosenShipmentMethod?.id,
                shipmentAreaCode: areaCode,
                shipmentGroupId: groupId
              }
              checkout(params).then((res) => {
                if (res.success) {
                  dispatch(setCheckoutInfo({ checkout: res.data }))
                } else {
                  message.error(res.message)
                }
              })
            } else {
              message.error(response.message)
            }

            dispatch(setProductAttrVisible({ visible: false }))
            setAttrProductId(undefined)
            setAttrProductQty(undefined)
            setAttrSkuId(undefined)
          }}
          itemChoose={(productId, skuId, quantity) => {
            setAttrProductId(productId)
            setAttrProductQty(quantity)
            setAttrSkuId(skuId)
            // addItemToCart(quantity, value);
          }}
        />
      </ModalForm>

      <ModalForm
        visible={productInfoSate.groupModalVisible}
        onCancel={() => {
          dispatch(setGroupModalVisible({ visible: false }))
          setQuantity(1)
        }}
        centered
        width={'50%'}
        footer={null}
      >
        <Grouped
          finished={async (groupAttrProductId, groupAttrSkuId) => {
            if (!groupAttrProductId || !groupAttrSkuId) {
              message.warning('Please select One item before add to cart')
              return
            }
            let storeId = parseInt(sessionStorage.getItem('storeId'))
            const response = await putItemToCart({
              customerId: customerInfoState.customerId === 1 ? 1 : customerInfoState.customerId,
              storeId: storeId,
              skuId: groupAttrSkuId,
              productId: groupAttrProductId,
              quantity: quantity
            })
            if (response.success) {
              if (!productInfoSate.hasMore) {
                searchInput.current.focus()
                setSearchQuery(undefined)
                // dispatch(setHasMore({ hasmore: false }))
                // debouncedChangeHandler({})
              }
              dispatch(setCartIncrease({ num: 1 }))
              const params: Partial<CheckoutReqTypes> = {
                cartList: [],
                point: 0,
                coupon: '',
                customerId: customerInfoState.customerId,
                shipmentMethodId: shoppingCartInfo?.chosenShipmentMethod?.id,
                shipmentAreaCode: areaCode,
                shipmentGroupId: groupId
              }
              checkout(params).then((res) => {
                if (res.success) {
                  dispatch(setCheckoutInfo({ checkout: res.data }))
                } else {
                  message.error(res.message)
                }
              })
            } else {
              message.error(response.message)
            }

            dispatch(setGroupModalVisible({ visible: false }))
            setQuantity(1)
          }}
          num={quantity}
          quantity={(value: number) => {
            setQuantity(value)
          }}
        />
      </ModalForm>
      <ModalForm
        width={'50%'}
        visible={productInfoSate.bundledProductVisible}
        onCancel={() => {
          dispatch(setBundledVisible({ visible: false }))
        }}
        footer={null}
      >
        <Bundled
          itemChoose={(items) => {
            dispatch(setBundledVisible({ visible: false }))

            addBundleItemsToCart(items)
          }}
        />
      </ModalForm>
    </>
  )
}
export default LeftLayout
