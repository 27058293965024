import { message } from 'antd'
import { includes, isNil, isUndefined, random } from 'lodash'
import { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { checkout, getCustomers, getShippingTerms, orderDetail, placeOrder, validateCouponCode } from '../../../api/response'
import { CheckoutReqTypes, CheckoutResTypes } from '../../../models/checkoutTypes'
import { CustomerTypes } from '../../../models/customerTypes'
import {
  deleteOneItemFromCart,
  getAllShoppingCartItems,
  getCurrencyRate,
  getShipmentId,
  getShoppingCartItems,
  resetAllAddInfo,
  setAdjustmentInfo,
  setAllAddInfo,
  setCartIncrease,
  setCartItems,
  setCouponInfo,
  setShipmentMethodInfo,
  setSubTotalPrice,
  setTotalPrice,
  shoppingCartData,
  updateShoppingCartQuantity
} from '../../../redux/reducers/cartItems_reducer'
import {
  customerInfoData,
  getOneCustomerInfo,
  getOnHoldListCustomerInfo,
  getShipmentAreaAndGroupId,
  getShipmentTypeAndMethod,
  setCartIsEmpty,
  setCustomerChoose,
  setCustomerDisplay,
  setCustomerInfo,
  setCustomerName,
  setShipmentMethodForCustomer,
  setShipmentType
} from '../../../redux/reducers/customerInfo_reducer'
import {
  getCheckoutInfo,
  getOnHoldList,
  getWalkinIds,
  setCheckoutInfo,
  setCoupon,
  setOnHoldList,
  setWalkinIds
} from '../../../redux/reducers/homePage_reducer'
import { setOrderIdInfo, setPlaceOrderPrice, setPlaceorderSuccess } from '../../../redux/reducers/orders_reducer'
import { useAppDispatch, useAppSelector } from '../../../redux/reduxHooks'
import { ShipmentMethodTypes } from '../../../models/shipping/shippingMethod'
import { setShipmentCodeAndGroupId } from '../../../redux/reducers/customerInfo_reducer'

const useSliderLayout = () => {
  const [searchDropDownList, setSearchDropDownList] = useState<{ value: number; text: string }[]>([])
  const [customerList, setCustomerList] = useState<CustomerTypes[]>([])
  const [customerSearchValue, setCustomerSearchValue] = useState<string>('')
  const [shipmentMethodOptions, setShipmentMethodOptions] = useState<{ value: string; label: string }[]>([])
  const [searchLoading, setSearchLoading] = useState<boolean>(false)
  const [showAddOneModal, setShowAddOneModal] = useState<boolean>(false)
  const [showAddCouponModal, setShowAddCouponModal] = useState<boolean>(false)
  const [showAddShipmentModal, setShowAddShipmentModal] = useState<boolean>(false)
  const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false)
  const [checkLoading, setCheckLoading] = useState<boolean>(false)
  const [clickOnHoldButton, setClickOnHoldButton] = useState<boolean>(false)
  const [customerModalType, setCustomerModalType] = useState<'edit' | 'add'>('add')
  const [hasError, setHasError] = useState('')
  const [paginator, setPaginator] = useState({ limit: 20, page: 1 })
  const [hasMore, setHasMore] = useState(true)
  const shoppingCartItems = useAppSelector(getShoppingCartItems)
  const walkinIds = [1, 2, 3, 4, 5]
  const dispatch = useAppDispatch()
  const {
    cartItems: itemSelected,
    count,
    currencyRate,
    chosenShipmentMethod: addShipmentInfo,
    couponInfo: addCouponInfo,
    adjustmentInfo: addOneInfo
  } = useAppSelector(shoppingCartData)
  const { customerChoose, customerId, customerGroupId } = useAppSelector(customerInfoData)
  const { areaCode, groupId } = useAppSelector(getShipmentAreaAndGroupId)
  const shipmentId = useAppSelector(getShipmentId)
  const onHoldList = useAppSelector(getOnHoldList)
  const walkinId = useAppSelector(getWalkinIds)
  const match = useRouteMatch<{ id: string }>()
  const history = useHistory()
  const { shipmentMethod, shipmentType } = useAppSelector(getShipmentTypeAndMethod)
  const checkoutRes = useAppSelector(getCheckoutInfo)

  const scrollDown = useCallback(
    (e) => {
      if (!hasMore) return
      const element = e.target
      const scrollTop = element.scrollTop
      // 判断滑动方向
      const scrollHeight = element.scrollHeight
      const clientHeight = element.clientHeight

      if (scrollTop + clientHeight >= scrollHeight) {
        // 滚动条已滚动到底部
        setPaginator((pre) => {
          return {
            limit: pre.limit,
            page: pre.page + 1
          }
        })
      }
    },
    [hasMore]
  )

  const getCustomerListInfo = async (query) => {
    setSearchLoading(true)
    setHasMore(true)
    const res = await getCustomers({ pageSize: paginator.limit, currentPage: paginator.page, q: query })
    if (res.success) {
      setSearchLoading(false)
      const { totalCount } = res.data.pagination
      const convertList = res.data.items.map((item) => {
        const text = `${item?.customerNo || ''} 
        ${item?.displayName ? item?.displayName ?? '' : ` ${item?.firstName || ''}${item?.lastName || ''}  ${item?.nickName || ''} `} ${
          item?.phone || ''
        } ${item?.email.includes('wx.domain.com') ? '' : item?.email || ''} `
        return { value: item.id, text }
      })

      const arr = [...customerList, ...res.data.items]
      const convertListArr = [...searchDropDownList, ...convertList]
      if (arr.length >= totalCount) {
        setHasMore(false)
        setCustomerList(arr)
        setSearchDropDownList(convertListArr)
        return
      }

      setSearchDropDownList(convertListArr)
      setCustomerList(arr)
    }
  }
  useEffect(() => {
    getCustomerListInfo(customerSearchValue)
  }, [customerSearchValue, paginator.page])

  useEffect(() => {
    getShippingTerms().then((res) => {
      setShipmentMethodOptions(res.data.map((res) => ({ value: res.code, label: res.name })))
    })
  }, [])

  useEffect(() => {
    let isUnmount = false
    if (!isUnmount) {
      if (match.path.includes('payment')) {
        if (itemSelected.length === 0) {
          orderDetail(match.params.id).then((res) => {
            dispatch(setTotalPrice({ totalPrice: res.data.totalDue }))
            dispatch(setSubTotalPrice({ subTotalPrice: res.data.subtotal }))
            dispatch(setPlaceorderSuccess({ success: true }))
            if (res.success) {
              dispatch(setCartItems({ cartItems: res.data.items as any[] }))
            }
          })
        }

        return
      }
      const storeId = +sessionStorage.getItem('storeId')
      dispatch(
        getAllShoppingCartItems({
          storeId,
          customerId,
          customerGroupId: customerGroupId
        })
      )
      dispatch(getCurrencyRate())
    }
    return () => {
      isUnmount = true
    }
  }, [count, customerGroupId, customerId, dispatch, itemSelected.length, match.params.id, match.path, localStorage.getItem('lang')])

  useEffect(() => {
    if (itemSelected.length > 0) {
      dispatch(setCartIsEmpty({ cartIsEmpty: false }))
    } else {
      dispatch(setCartIsEmpty({ cartIsEmpty: true }))

      dispatch(setOnHoldList({ list: onHoldList.filter((item) => item.id !== customerChoose.id) }))
    }
  }, [itemSelected])

  useEffect(() => {
    if (customerId !== 1 && isNil(sessionStorage.getItem('customerIdFromOrder'))) {
      dispatch(getOneCustomerInfo(customerId))

      return
    } else if (!isNil(sessionStorage.getItem('customerIdFromOrder'))) {
      dispatch(getOneCustomerInfo(+sessionStorage.getItem('customerIdFromOrder')))
      return
    }
    dispatch(getOneCustomerInfo(1))
  }, [])

  const increaseCountHandler = function () {
    setClickOnHoldButton(false)

    dispatch(
      updateShoppingCartQuantity({
        quantity: this.quantity + 1,
        id: this.id.toString()
      })
    )
  }
  const decreaseCountHandler = function () {
    setClickOnHoldButton(false)

    dispatch(
      updateShoppingCartQuantity({
        quantity: this.quantity - 1,
        id: this.id.toString()
      })
    )
  }
  useEffect(() => {
    if (shoppingCartItems.length === 0 && customerChoose?.id) {
      if (walkinIds.includes(customerChoose.id) && !walkinId.includes(customerChoose.id)) {
        dispatch(setWalkinIds({ ids: [...walkinId.filter((a) => a !== customerChoose.id), customerChoose.id] }))
      }
    }
    if (shoppingCartItems.length > 0 && !clickOnHoldButton) {
      const params: Partial<CheckoutReqTypes> = {
        cartList: [],
        point: 0,
        // coupon: coupon,
        customerId: customerId,
        shipmentMethodId: addShipmentInfo?.id,
        shipmentAreaCode: areaCode,
        shipmentGroupId: groupId
      }
      checkout(params).then((res) => {
        if (res.success) {
          setCheckLoading(false)
          dispatch(setCheckoutInfo({ checkout: res.data }))
        } else {
          message.error(res.message)
          setCheckLoading(false)
        }
      })
    }
  }, [shoppingCartItems, clickOnHoldButton])
  const deleteItemHandler = function () {
    dispatch(deleteOneItemFromCart(this.id.toString()))
    setClickOnHoldButton(false)
  }
  const onHoldHandler = () => {
    if (itemSelected.length > 0) {
      dispatch(
        setCartIncrease({
          num: 1
        })
      )
      setClickOnHoldButton(true)

      if (!walkinIds.includes(customerChoose.id)) {
        if (onHoldList.length > 0) {
          let onHolistWalkinIds = onHoldList.filter((a) => walkinIds.includes(a.id)).map((c) => c.id)

          if (onHolistWalkinIds.length === 5) {
            const adjustment = onHoldList[0].adjustment
            const coupon = onHoldList[0].coupon
            const shipment = onHoldList[0].shipment
            const type = onHoldList[0].shipmentType
            const method = onHoldList[0].shipmentMethod
            dispatch(
              setOnHoldList({
                list: [
                  ...onHoldList.filter((item) => item.id !== onHoldList[0].id && item.id !== customerChoose.id),
                  {
                    ...customerChoose,
                    adjustment: addOneInfo,
                    coupon: addCouponInfo,
                    shipment: addShipmentInfo,
                    areaCode: areaCode,
                    groupId: groupId,
                    shipmentMethod: shipmentMethod,
                    shipmentType: shipmentType,
                    checkoutRes: checkoutRes
                  }
                ]
              })
            )
            dispatch(setAllAddInfo({ adjustment: adjustment, coupon: coupon, shipment: shipment }))
            dispatch(setShipmentCodeAndGroupId({ shipmentAreaCode: onHoldList[0].areaCode, shipmentGroupId: onHoldList[0].groupId }))
            dispatch(setShipmentType({ type: type }))
            dispatch(setShipmentMethodForCustomer({ method: method }))
            dispatch(setCheckoutInfo({ checkout: onHoldList[0].checkoutRes }))
            dispatch(getOnHoldListCustomerInfo(onHoldList[0].id))

            if (walkinIds.includes(onHoldList[0].id)) {
              dispatch(
                setWalkinIds({
                  ids: [onHoldList[0].id]
                })
              )
            }
            return
          } else {
            dispatch(getOnHoldListCustomerInfo(walkinId[0]))
            dispatch(
              setOnHoldList({
                list: [
                  ...onHoldList.filter((item) => item.id !== walkinId[0] && item.id !== customerChoose.id),
                  {
                    ...customerChoose,
                    adjustment: addOneInfo,
                    coupon: addCouponInfo,
                    shipment: addShipmentInfo,
                    areaCode: areaCode,
                    groupId: groupId,
                    shipmentMethod: shipmentMethod,
                    shipmentType: shipmentType,
                    checkoutRes: checkoutRes
                  }
                ]
              })
            )
          }
          dispatch(resetAllAddInfo())
          dispatch(setShipmentCodeAndGroupId({ shipmentAreaCode: '', shipmentGroupId: 0 }))
          dispatch(setShipmentMethodForCustomer({ method: 'pickup' }))
          dispatch(setShipmentType({ type: 1 }))
          dispatch(setCheckoutInfo({ checkout: null }))
        } else {
          dispatch(getOnHoldListCustomerInfo(walkinId[0]))
          dispatch(
            setOnHoldList({
              list: [
                ...onHoldList.filter((item) => item.id !== walkinId[0] && item.id !== customerChoose.id),
                {
                  ...customerChoose,
                  adjustment: addOneInfo,
                  coupon: addCouponInfo,
                  shipment: addShipmentInfo,
                  areaCode: areaCode,
                  groupId: groupId,
                  shipmentMethod: shipmentMethod,
                  shipmentType: shipmentType,
                  checkoutRes: checkoutRes
                }
              ]
            })
          )
          dispatch(resetAllAddInfo())
          dispatch(setShipmentCodeAndGroupId({ shipmentAreaCode: '', shipmentGroupId: 0 }))
          dispatch(setShipmentMethodForCustomer({ method: 'pickup' }))
          dispatch(setShipmentType({ type: 1 }))
          dispatch(setCheckoutInfo({ checkout: null }))
          dispatch(
            setWalkinIds({
              ids: [...walkinId.filter((item) => item !== walkinId[0])]
            })
          )
        }

        return
      }
      if (walkinId.filter((id) => id !== customerChoose.id)[0]) {
        dispatch(
          setOnHoldList({
            list: [
              ...onHoldList.filter((item) => item.id !== customerChoose.id),
              {
                ...customerChoose,
                adjustment: addOneInfo,
                coupon: addCouponInfo,
                shipment: addShipmentInfo,
                areaCode: areaCode,
                groupId: groupId,
                shipmentMethod: shipmentMethod,
                shipmentType: shipmentType,
                checkoutRes: checkoutRes
              }
            ]
          })
        )

        dispatch(resetAllAddInfo())
        dispatch(setShipmentCodeAndGroupId({ shipmentAreaCode: '', shipmentGroupId: 0 }))
        dispatch(setShipmentMethodForCustomer({ method: 'pickup' }))
        dispatch(setShipmentType({ type: 1 }))
        dispatch(setCheckoutInfo({ checkout: null }))
        if (walkinId.length === 1) {
          dispatch(getOnHoldListCustomerInfo(walkinId[0]))
        } else {
          dispatch(getOnHoldListCustomerInfo(walkinId.filter((id) => id !== customerChoose.id)[0]))
        }
        if (walkinIds.includes(customerChoose.id)) {
          dispatch(setWalkinIds({ ids: walkinId.filter((id) => id !== customerChoose.id) }))
        }
      } else {
        dispatch(getOnHoldListCustomerInfo(onHoldList[0].id))
        if (walkinIds.includes(onHoldList[0].id) && walkinIds.includes(customerChoose.id)) {
          dispatch(setWalkinIds({ ids: [...walkinId.filter((id) => id !== customerChoose.id), onHoldList[0].id] }))
        }
        const adjustment = onHoldList[0].adjustment
        const coupon = onHoldList[0].coupon
        const shipment = onHoldList[0].shipment
        const type = onHoldList[0].shipmentType
        const method = onHoldList[0].shipmentMethod

        dispatch(
          setOnHoldList({
            list: [
              ...onHoldList.filter((item) => item.id !== onHoldList[0].id),
              {
                ...customerChoose,
                adjustment: addOneInfo,
                coupon: addCouponInfo,
                shipment: addShipmentInfo,
                areaCode: areaCode,
                groupId: groupId,
                shipmentMethod: shipmentMethod,
                shipmentType: shipmentType,
                checkoutRes: checkoutRes
              }
            ]
          })
        )
        dispatch(setAllAddInfo({ adjustment: adjustment, coupon: coupon, shipment: shipment }))
        dispatch(setShipmentCodeAndGroupId({ shipmentAreaCode: onHoldList[0].areaCode, shipmentGroupId: onHoldList[0].groupId }))
        dispatch(setShipmentType({ type: type }))
        dispatch(setShipmentMethodForCustomer({ method: method }))
        dispatch(setCheckoutInfo({ checkout: onHoldList[0].checkoutRes }))
      }
    } else {
      message.warning(' Please add item(s) to cart!')
    }
  }
  const handleSearch = (newValue: string) => {
    setSearchDropDownList([])
    setCustomerList([])

    setPaginator({ limit: 20, page: 1 })
    if (newValue) {
      setCustomerSearchValue(newValue)
    } else {
      setCustomerSearchValue('')
    }
  }

  const dropDownChange = (open: boolean) => {
    if (open) {
      getCustomerListInfo(customerSearchValue)
    } else {
      setSearchDropDownList([])
      setCustomerList([])
      setPaginator({ limit: 20, page: 1 })
    }
  }

  const handleChange = (valueId: any) => {
    if (!valueId) {
      if (!walkinId.filter((id) => id !== customerChoose.id)[0]) {
        message.warning('Walk in users run out')
      } else {
        if (walkinIds.includes(customerChoose.id)) {
          dispatch(setWalkinIds({ ids: [...walkinId.filter((a) => a !== customerChoose.id), customerChoose.id] }))
        }
        const item = onHoldList.filter((item) => item.id !== customerChoose.id)

        dispatch(setOnHoldList({ list: item }))
        if (walkinId.length === 1) {
          dispatch(getOnHoldListCustomerInfo(walkinId[0]))
        } else {
          dispatch(getOnHoldListCustomerInfo(walkinId.filter((id) => id !== customerChoose.id)[0]))
        }
        //dispatch(setCustomerDisplay({ name: undefined }))
      }

      return
    }
    const value = customerList.find((e) => e.id === valueId)
    if (valueId !== customerChoose.id) {
      dispatch(setCustomerChoose({ data: value }))
      dispatch(setCouponInfo({ info: { code: '', details: {} as any } }))
      dispatch(setShipmentCodeAndGroupId({ shipmentAreaCode: '', shipmentGroupId: 0 }))
      dispatch(setShipmentMethodInfo({ info: undefined }))
      dispatch(setAdjustmentInfo({ info: { amount: 0, note: '' } }))
      const itemInOnHoldList = onHoldList.find((item) => item.id === valueId)

      if (itemInOnHoldList) {
        const items = onHoldList.filter((item) => item.id !== valueId)
        const adjustment = itemInOnHoldList.adjustment
        const coupon = itemInOnHoldList.coupon
        const shipment = itemInOnHoldList.shipment
        const type = itemInOnHoldList.shipmentType
        const method = itemInOnHoldList.shipmentMethod
        dispatch(setOnHoldList({ list: items }))

        dispatch(setAllAddInfo({ adjustment: adjustment, coupon: coupon, shipment: shipment }))
        dispatch(setShipmentCodeAndGroupId({ shipmentAreaCode: itemInOnHoldList.areaCode, shipmentGroupId: itemInOnHoldList.groupId }))
        dispatch(setShipmentType({ type: type }))
        dispatch(setShipmentMethodForCustomer({ method: method }))
        dispatch(setCheckoutInfo({ checkout: itemInOnHoldList.checkoutRes }))
      }
      dispatch(
        setCustomerInfo({
          customerId: value.id,
          customerGroupId: value.customerGroupId,
          customerEmail: value.email
        })
      )
      dispatch(
        setCustomerName({
          name: `${value.firstName} ${value.lastName} ${value.phone} ${value.email}`
        })
      )
      dispatch(
        setCustomerDisplay({
          name: `${value?.customerNo || ''} ${
            value?.displayName ? value?.displayName ?? '' : ` ${value?.firstName || ''}${value?.lastName || ''}  ${value?.nickName || ''} `
          } ${value?.phone || ''} ${value?.email.includes('wx.domain.com') ? '' : value?.email || '' || ''} `
        })
      )
    }
  }

  const getOrderFromOnHoldList = () => {
    dispatch(setCustomerChoose({ data: onHoldList[0] }))
    dispatch(
      setCustomerInfo({
        customerId: onHoldList[0].id,
        customerGroupId: onHoldList[0].customerGroupId,
        customerEmail: onHoldList[0].email
      })
    )
    dispatch(
      setCustomerDisplay({
        name: `${onHoldList[0]?.customerNo || ''}
        ${
          onHoldList[0]?.displayName
            ? onHoldList[0]?.displayName ?? ''
            : ` ${onHoldList[0]?.firstName || ''}${onHoldList[0]?.lastName || ''}  ${onHoldList[0]?.nickName || ''} `
        }
        ${onHoldList[0]?.phone || ''} ${onHoldList[0]?.email.includes('wx.domain.com') ? '' : onHoldList[0]?.email || '' || ''} `
      })
    )
    let onHolistWalkinIds = onHoldList.filter((a) => walkinIds.includes(a.id)).map((c) => c.id)
    const item = onHoldList.filter((item) => item.id !== onHoldList[0].id)
    const adjustment = onHoldList[0].adjustment
    const coupon = onHoldList[0].coupon
    const shipment = onHoldList[0].shipment
    const type = onHoldList[0].shipmentType
    const method = onHoldList[0].shipmentMethod
    if (shoppingCartItems.length > 0) {
      setClickOnHoldButton(true)

      dispatch(
        setOnHoldList({
          list: [
            ...item,
            {
              ...customerChoose,
              adjustment: addOneInfo,
              coupon: addCouponInfo,
              shipment: addShipmentInfo,
              areaCode: areaCode,
              groupId: groupId,
              shipmentMethod: shipmentMethod,
              shipmentType: shipmentType,
              checkoutRes: checkoutRes
            }
          ]
        })
      )

      dispatch(setAllAddInfo({ adjustment: adjustment, coupon: coupon, shipment: shipment }))
      dispatch(setShipmentCodeAndGroupId({ shipmentAreaCode: onHoldList[0].areaCode, shipmentGroupId: onHoldList[0].groupId }))
      dispatch(setShipmentType({ type: type }))
      dispatch(setShipmentMethodForCustomer({ method: method }))
      dispatch(setCheckoutInfo({ checkout: onHoldList[0].checkoutRes }))
      if (walkinIds.includes(onHoldList[0].id) && onHolistWalkinIds.length === 5) {
        dispatch(
          setWalkinIds({
            ids: [onHoldList[0].id]
          })
        )
      } else {
        dispatch(setWalkinIds({ ids: walkinId.filter((id) => id !== customerChoose.id) }))
      }
    } else {
      if (walkinIds.includes(customerChoose.id)) {
        dispatch(setWalkinIds({ ids: [...walkinId.filter((a) => a !== customerChoose.id), customerChoose.id] }))
      }
      dispatch(setAllAddInfo({ adjustment: adjustment, coupon: coupon, shipment: shipment }))
      dispatch(setShipmentCodeAndGroupId({ shipmentAreaCode: onHoldList[0].areaCode, shipmentGroupId: onHoldList[0].groupId }))
      dispatch(setShipmentType({ type: type }))
      dispatch(setShipmentMethodForCustomer({ method: method }))
      dispatch(setCheckoutInfo({ checkout: onHoldList[0].checkoutRes }))
      dispatch(setOnHoldList({ list: item }))
    }
  }

  const addShipmentClickHandler = useCallback(
    (val: ShipmentMethodTypes) => {
      setShowAddShipmentModal(false)

      dispatch(setShipmentMethodInfo({ info: val }))
    },
    [customerId]
  )

  useEffect(() => {
    checkoutHandler(addOneInfo)
  }, [addOneInfo.amount, addOneInfo.note])

  const checkoutHandler = async (val) => {
    
    if (shoppingCartItems.length > 0) {
      const params = {
        cartList: [],
        point: 0,
        coupon: addCouponInfo.code ?? '',
        customerId: customerId,
        adjustment: addOneInfo.amount,
        adjustmentComment: addOneInfo.note,
        shipmentMethodId: shipmentId,
        shipmentAreaCode: areaCode,
        shipmentGroupId: groupId
      }
      // console.log(params)
      checkout(params).then((res) => {
        if (res.success) {
          setCheckLoading(false)
          setShowAddCouponModal(false)
          setShowAddOneModal(false)
          if (showAddShipmentModal) {
            addShipmentClickHandler(val)
          }
          dispatch(setCheckoutInfo({ checkout: res.data }))
        } else {
          message.error(res.message)
          setCheckLoading(false)
        }
      })
    } else {
      setCheckLoading(false)
      setShowAddCouponModal(false)
      setShowAddOneModal(false)

      if (showAddShipmentModal) {
        addShipmentClickHandler(val)
      }
    }
  }

  const checkoutHandlerCoupon = async (val) => {

    if (shoppingCartItems.length > 0) {
      const params = {
        cartList: [],
        point: 0,
        coupon: val.code,
        customerId: customerId,
        adjustment: addOneInfo.amount,
        adjustmentComment: addOneInfo.note,
        shipmentMethodId: shipmentId,
        shipmentAreaCode: areaCode,
        shipmentGroupId: groupId
      }
      // console.log(params)
      checkout(params).then((res) => {
        if (res.success) {
          setCheckLoading(false)
          setShowAddCouponModal(false)
          dispatch(setCouponInfo({ info: val }))
          dispatch(setCoupon({ coupon: val.code }))
          dispatch(setCheckoutInfo({ checkout: res.data }))
        } else {
          message.error(res.message)
          setCheckLoading(false)
        }
      })
    } else {
      message.error('Shopping cart is empty')
    }
  }

  const checkoutHandlerShipment = async (val) => {
    if (shoppingCartItems.length > 0) {
      const params = {
        cartList: [],
        point: 0,
        coupon: addCouponInfo.code ?? '',
        customerId: customerId,
        adjustment: addOneInfo.amount,
        adjustmentComment: addOneInfo.note,
        shipmentMethodId: shipmentId,
        shipmentAreaCode: areaCode,
        shipmentGroupId: groupId
      }
      // console.log(params)
      checkout(params).then((res) => {
        if (res.success) {
          addShipmentClickHandler(val)
          dispatch(setCheckoutInfo({ checkout: res.data }))
        } else {
          message.error(res.message)
        }
      })
    }
    else
    {
      message.error('Shopping cart is empty')
    }
    setCheckLoading(false)
  }

  const getPlaceOrderInfoBack = useCallback(
    (data) => {
      const placeOrderParams = {
        cartList: [],
        point: 0,
        coupon: addCouponInfo.code,
        credit: 0,
        shipmentMethodId: addShipmentInfo?.id || data.shipmentMethodId,
        shipmentGroupId: data?.shipmentGroupId,
        shippingAddressId: data.shippingAddressId,
        description: addOneInfo.note,
        customerId: customerId,
        storeId: +sessionStorage.getItem('storeId'),
        adjustment: +addOneInfo.amount,
        adjustmentComment: addOneInfo.note,
        shipmentAreaCode: data?.shipmentAreaCode,
        shipmentCity: data?.shipmentCity,
        shipmentStreet: data?.shipmentStreet,
        shipmentPostcode: data?.shipmentPostcode,
        consigneeFirstName: data?.consigneeFirstName,
        consigneeLastName: data?.consigneeLastName,
        consigneePhone: data?.consigneePhone,
        consigneeAddress: data?.consigneeAddress,
        consigneeID: data?.consigneeID,
        consigneeIDPhoto: data?.consigneeIDPhoto,
        consigneeIDPhoto2: data?.consigneeIDPhoto2,
        shipmentDefaultAddress: data?.shipmentDefaultAddress
      }

      placeOrder(placeOrderParams).then((res) => {
        if (res.success) {
          message.success(res.message)
          dispatch(setOrderIdInfo({ id: res.data.orderId.toString() }))
          history.push(`/payment/${res.data.orderNo}`)
          dispatch(setPlaceOrderPrice({ price: res.data.totalDue }))
          dispatch(setCartIncrease({ num: 1 }))
          dispatch(setPlaceorderSuccess({ success: true }))
          dispatch(
            setOnHoldList({
              list: onHoldList.filter((item) => item.customerId !== customerChoose.id)
            })
          )
        } else {
          message.error(res.message)
        }
      })
    },
    [
      addCouponInfo.code,
      addOneInfo.amount,
      addOneInfo.note,
      addShipmentInfo?.id,
      customerChoose?.id,
      customerId,
      dispatch,
      history,
      onHoldList
    ]
  )

  return {
    searchDropDownList,
    customerSearchValue,
    searchLoading,
    onHoldHandler,
    shoppingCartItems,
    increaseCountHandler,
    decreaseCountHandler,
    deleteItemHandler,
    handleSearch,
    handleChange,
    onHoldList,
    getOrderFromOnHoldList,
    setShowAddOneModal,
    showAddOneModal,
    showAddCouponModal,
    setShowAddCouponModal,
    showAddShipmentModal,
    setShowAddShipmentModal,
    shipmentMethodOptions,
    scrollDown,
    dropDownChange,
    addShipmentClickHandler,
    currencyRate,
    showCustomerModal,
    setShowCustomerModal,
    setCustomerModalType,
    customerModalType,
    checkoutHandler,
    checkoutHandlerCoupon,
    checkoutHandlerShipment,
    getPlaceOrderInfoBack,
    setHasError,
    hasError
  }
}

export default useSliderLayout
