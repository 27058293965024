/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable multiline-ternary */
/* eslint-disable no-template-curly-in-string */
import { Button, Input, Form, Select, Divider, Modal, Col, Row, Typography, message, Upload, Cascader } from 'antd'
import React, { useState, useEffect } from 'react'
import { DeleteOutlined, InboxOutlined, PlusCircleOutlined } from '@ant-design/icons'
import AddAddressForm from './shippingAddress'
// import ImgCrop from 'antd-img-crop'
import { getCustomerAddressArea, getCustomerGroup, getImageIdFromDb, insertCustomerToGroup } from '../../api/response'
import styled from 'styled-components'
import { CustomerCreateAddressResTypes } from '../../models/customerTypes'
import api from '../../config/api'

const DeleteIcon = styled(DeleteOutlined)`
  position: absolute;
  top: 82%;
  right: 60%;
  display: block;
`
const UploadInner = styled.div`
  .ant-upload.ant-upload-drag .ant-upload-drag-container {
    display: table-cell;
    vertical-align: middle;
  }
`
const UploadStyle = styled.div`
  .ant-upload.ant-upload-select-picture-card {
    width: 15rem;
    margin: 0;
    height: 15rem;
  }
  .ant-upload.ant-upload-select-picture-card > .ant-upload > span {
    width: 100%;
    height: 100%;
  }
  .ant-upload-list-picture-card-container {
    width: 15rem;
    height: 15rem;
  }
  .ant-upload-list-picture-card-container img {
    object-fit: cover;
  }
`
interface Props {
  finished?: (value: any, imageList: []) => void
  edit?: boolean
  customerAddressInfo?: CustomerCreateAddressResTypes | undefined
  paymentPage?: boolean
  close?: () => void
}
const CreatForm = (props: Props) => {
  // eslint-disable-next-line no-unused-vars
  const { finished, edit, customerAddressInfo, paymentPage, close } = props
  const { Title } = Typography
  const [addAddress, setAddress] = useState(false)
  const [fileList, setFileList] = useState<any>([])
  const [addressInfo, setAddressInfo] =
    useState<{ country?: string; city?: string; street?: string; zipCode?: string; shipmentareas: any[] }>()
  const [customerGroup, setCustomerGroup] = useState([])
  const [isUploading, setIsUploading] = useState<boolean>()
  const [preview, setPreview] = useState(null)
  const [option, setOption] = useState([])
  const [form] = Form.useForm()

  const onFinish = async (value: any) => {
    const { lastName, firstName, email, nickName, customerGroupId, phone } = value
    const param = {
      active: 1,
      customerGroupId: customerGroupId === 'General' ? 1 : customerGroupId,
      isReferrer: 0,
      nickName,
      firstName,
      lastName,
      email,
      phone,
      areaGroup: value?.shipmentareas ? value?.shipmentareas[0] : addressInfo?.shipmentareas ? addressInfo?.shipmentareas[0] : 0,
      areaCode: value?.shipmentareas ? value?.shipmentareas[1] : addressInfo?.shipmentareas ? addressInfo?.shipmentareas[1] : '',
      country: value?.country || addressInfo?.country || '',
      city: value?.city || addressInfo?.city || '',
      address: value?.street || addressInfo?.street || '',
      zipCode: value?.zipCode || addressInfo?.zipCode || ''
    }

    if (paymentPage) {
      finished(value, fileList)
    } else {
      const res = await insertCustomerToGroup(param)
      if (res.success) {
        message.success(res.message)
        finished({}, [])
      } else {
        message.error(res.message)
      }
    }
    setAddressInfo(undefined)
    setFileList([])
  }
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 }
  }

  /*   const groupChangeHandler = (value) => {
    console.log(value)
  } */
  const validateMessages = {
    required: '${name} is required!',
    types: {
      email: '${label} is not a valid email!'
    }
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setPreview({
      previewImage: file.url || file.preview,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    })
  }

  const onChange = ({ file, fileList: newFileList }) => {
    file = JSON.parse(JSON.stringify(file))
    setIsUploading(file.status === 'uploading')
    setFileList(newFileList)
    if (file.status === 'done') {
      if (file.response.success) {
        form.setFieldsValue({
          cover: fileList
        })
        message.success(file.response.message)
      } else {
        message.error(file.response.message)
      }
    }
  }
  const customerGroupData = async () => {
    const res = await getCustomerGroup()
    if (res.success) {
      const { data } = res
      setCustomerGroup(data)
    } else {
      message.error(res.message)
    }
  }

  useEffect(() => {
    if (paymentPage) {
      return
    }
    customerGroupData()
  }, [])

  useEffect(() => {
    getCustomerAddressArea().then((res) => {
      if (res.success) {
        const shipmentArea = res.data
          .sort((a, b) => b.position - a.position)
          .map((item) => {
            return {
              value: item.shipmentGroupId,
              label: item.name,
              children: [
                ...item.items
                  .map((child) => {
                    return { value: child.code, label: child.name }
                  })
              ]
            }
          })
        setOption(shipmentArea)
      } else {
        message.error(res.message)
      }
    })
    if (customerAddressInfo) {
      if (customerAddressInfo.idCardImage && customerAddressInfo.idCardImage2) {
        // Promise.all([
        //   getImageIdFromDb({ q: customerAddressInfo.idCardImage }),
        //   getImageIdFromDb({ q: customerAddressInfo.idCardImage2 })
        // ]).then((res) => {
        //   if (res[0].data && res[1].data) {
        //     setFileList([
        //       {
        //         uid: res[0].data[0]?.id,
        //         name: customerAddressInfo.idCardImage,
        //         status: 'done',
        //         url: `${api.cdn}/${customerAddressInfo.idCardImage}?x-oss-process=style/640w`
        //       },
        //       {
        //         uid: res[1].data[0]?.id,
        //         name: customerAddressInfo.idCardImage2,
        //         status: 'done',
        //         url: `${api.cdn}/${customerAddressInfo.idCardImage2}?x-oss-process=style/640w`
        //       }
        //     ])
        //   }
        // })
        setFileList([
          {
            uid: 1,
            name: customerAddressInfo.idCardImage,
            status: 'done',
            url: `${api.cdn}/${customerAddressInfo.idCardImage}?x-oss-process=style/640w`
          },
          {
            uid: 2,
            name: customerAddressInfo.idCardImage2,
            status: 'done',
            url: `${api.cdn}/${customerAddressInfo.idCardImage2}?x-oss-process=style/640w`
          }
        ])

        form.setFieldsValue({
          shipmentareas: [customerAddressInfo?.areaGroup, customerAddressInfo?.areaCode],
          lastName: customerAddressInfo?.lastName,
          firstName: customerAddressInfo?.firstName,
          nickName: customerAddressInfo?.alias,
          phone: customerAddressInfo?.phone,
          street: customerAddressInfo?.street,
          city: customerAddressInfo?.city,
          zipCode: customerAddressInfo?.postcode,
          idCard: customerAddressInfo?.idCard,
          cover: fileList
        })
        return
      } else if (customerAddressInfo.idCardImage || customerAddressInfo.idCardImage2) {
        if (customerAddressInfo.idCardImage) {
          // getImageIdFromDb({ q: customerAddressInfo.idCardImage }).then((res) => {
          //   if (res.data) {
          //     setFileList([
          //       {
          //         uid: res.data[0]?.id,
          //         name: res.data[0]?.fullpath,
          //         status: 'done',
          //         url: `${api.cdn}/${customerAddressInfo.idCardImage}?x-oss-process=style/640w`
          //       }
          //     ])
          //   }
          // })
          setFileList([
            {
              uid: 1,
              name: customerAddressInfo.idCardImage,
              status: 'done',
              url: `${api.cdn}/${customerAddressInfo.idCardImage}?x-oss-process=style/640w`
            }
          ])
        }
        if (customerAddressInfo.idCardImage2) {
          setFileList([
            {
              uid: 1,
              name: customerAddressInfo.idCardImage,
              status: 'done',
              url: `${api.cdn}/${customerAddressInfo.idCardImage}?x-oss-process=style/640w`
            }
          ])
        }
      } else {
        setFileList([])
      }

      form.setFieldsValue({
        shipmentareas: [customerAddressInfo?.areaGroup, customerAddressInfo?.areaCode],
        lastName: customerAddressInfo?.lastName,
        firstName: customerAddressInfo?.firstName,
        nickName: customerAddressInfo?.alias,
        phone: customerAddressInfo?.phone,
        street: customerAddressInfo?.street,
        city: customerAddressInfo?.city,
        postcode: customerAddressInfo?.postcode,
        country: customerAddressInfo?.country,
        idCard: customerAddressInfo?.idCard,
        cover: fileList
      })
    }
  }, [customerAddressInfo])

  return (
    <>
      <Form form={form} {...layout} onFinish={onFinish} validateMessages={validateMessages}>
        <Row justify='space-between'>
          <Col>
            <Button type='ghost' onClick={close}>
              取消
            </Button>
          </Col>
          <Col>
            {paymentPage ? (
              edit ? (
                <Title level={4}>Edit Address</Title>
              ) : (
                <Title level={4}>Add New Address</Title>
              )
            ) : (
              <Title level={4}>Create New Customer</Title>
            )}
          </Col>
          <Col>
            <Button type='primary' htmlType='submit'>
              储存
            </Button>
          </Col>
        </Row>
        <Divider />
        <Form.Item name='lastName' label='姓氏'>
          <Input placeholder='姓氏' />
        </Form.Item>
        <Form.Item name='firstName' label='名字' rules={[{ required: true }]}>
          <Input placeholder='名字' />
        </Form.Item>
        <Form.Item name='nickName' label='别名'>
          <Input placeholder='昵称' />
        </Form.Item>
        <Form.Item name='phone' label='Phone' rules={[{ required: !paymentPage }]}>
          <Input placeholder='Phone' />
        </Form.Item>

        {paymentPage ? null : (
          <Form.Item name='email' label='电邮' rules={[{ type: 'email', required: true }]}>
            <Input placeholder='电邮' />
          </Form.Item>
        )}

        {paymentPage ? null : (
          <Form.Item
            name='customerGroupId'
            rules={[{ required: true }]}
            wrapperCol={{ ...layout.wrapperCol, offset: 6 }}
            initialValue='General'
          >
            <Select placeholder='please choose group'>
              {customerGroup.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.groupCode}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {paymentPage ? (
          <>
            <Form.Item
              name='shipmentareas'
              label='shipment Area'
              rules={[{ type: 'array', required: true, message: 'Please select your shipment area!' }]}
            >
              <Cascader options={option} />
            </Form.Item>

            <Form.Item name='city' label='City'>
              <Input placeholder='City' />
            </Form.Item>

            <Form.Item name='street' label='street'>
              <Input placeholder='street' />
            </Form.Item>

            <Form.Item name='zipCode' label='Post Code'>
              <Input placeholder='Zip Code' />
            </Form.Item>

            {/* <Form.Item name='country' initialValue='新西兰' label='country'>
              <Input />
            </Form.Item> */}
          </>
        ) : (
          <>
            <Divider />
            <Form.Item label='Shipping Address'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  fontSize: '20px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setAddress(true)
                }}
              >
                <PlusCircleOutlined />
              </div>
            </Form.Item>
          </>
        )}

        {paymentPage ? (
          <>
            <Form.Item name='idCard' label='idCardNumber' rules={[{ required: !paymentPage }]}>
              <Input placeholder='idCardNumber' />
            </Form.Item>
            <UploadStyle>
              <Form.Item label='id card image(2)' name='cover'>
                <Upload
                  style={{ width: '15rem' }}
                  maxCount={2}
                  action={api.uri + '/upload/venus-idcard'}
                  headers={{
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                  }}
                  listType='picture-card'
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={onChange}
                  name='files'
                  onRemove={async (value) => {
                    value = JSON.parse(JSON.stringify(value))
                    if (value.response) {
                      form.setFieldsValue({
                        cover: fileList.filter((item) => item.status !== 'removed')
                      })
                      setFileList(fileList.filter((item) => item.status !== 'removed'))
                    } else {
                      // await deleteUploadImage(value.uid)
                      form.setFieldsValue({
                        cover: fileList.filter((item) => item.status === 'done')
                      })
                      setFileList([fileList.filter((item) => item.status === 'done')])
                    }
                  }}
                >
                  {fileList.length >= 2 ? null : (
                    <UploadInner>
                      <p className='ant-upload-drag-icon'>
                        <InboxOutlined style={{ color: '#40a9ff', fontSize: '28px' }} />
                      </p>
                      <p>Upload</p>
                    </UploadInner>
                  )}
                </Upload>
              </Form.Item>
            </UploadStyle>
          </>
        ) : null}
        {isUploading && (
          <DeleteIcon
            style={{ color: 'red', position: 'absolute' }}
            onClick={() => {
              setIsUploading(false)
              setFileList([])
            }}
          />
        )}
      </Form>
      <Modal width='50%' visible={!!preview} title={preview?.previewTitle} footer={null} onCancel={() => setPreview(null)}>
        <img alt='example' style={{ width: '100%' }} src={preview?.previewImage} />
      </Modal>

      {paymentPage ? null : (
        <AddAddressForm
          isModalVisible={addAddress}
          cancel={() => {
            setAddress(false)
          }}
          address={(value) => {
            setAddressInfo(value)
            setAddress(false)
          }}
          edit={edit}
          addressInfo={customerAddressInfo}
          paymentPage={paymentPage}
        />
      )}
    </>
  )
}

export default CreatForm
