/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState } from 'react'
import { Row, Col, Layout, Table, Input, DatePicker, Button, message, Image, Form, Select, Spin, Tooltip, Radio } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import styled from 'styled-components'
import { createCoupon, exportCouponListAsExcel, getCouponCodeList, getCouponDetail, getCouponQuery, getCustomers } from '../../api/response'
import dayjs from 'dayjs'
import Modal from 'antd/lib/modal/Modal'
import TextArea from 'antd/lib/input/TextArea'
import { CouponDetailTypes } from '../../models/coupon/couponListTypes'
import { useAppDispatch, useAppSelector } from '../../redux/reduxHooks'
import { shoppingCartData } from '../../redux/reducers/cartItems_reducer'
import { setMenuDisplay } from '../../redux/reducers/menu_reducer'
import { ColumnsType } from 'antd/es/table'
import api from '../../config/api'

const { Header, Content } = Layout
const { RangePicker } = DatePicker

const StyledContent = styled(Content)`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  .ant-empty {
    display: none;
  }
  background-color: white;
  padding: 5px;
`

const ListHeader = styled(Header)`
  position: sticky;
  top: 0;
  z-index: 10;
  color: black;
  height: 64px;
  font-weight: 700;
  text-align: center;
  font-size: 16px;
  background-color: orange !important;
`

const ColStyle = styled(Col)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const ImageStyle = styled.div`
  position: relative;
  width: 1170px;
  margin: 0 auto;
  height: 397px;
`

const ImageBackgroundStyle = styled(Image)`
  width: 100%;
  height: 100%;
`
const ImagePriceData = styled.div`
  position: absolute;
  top: 6rem;
  left: 45rem;
  font-size: 5rem;
`
const ImageNzPriceData = styled.div`
  position: absolute;
  top: 14.1rem;
  right: 26rem;
  width: 7%;
  font-size: 1.5rem;
`

const ImageCouponData = styled.div`
  position: absolute;
  top: 15rem;
  left: 23rem;
  font-size: 2rem;
`
const ImageCouponCurrencyBefore = styled.div`
  position: absolute;
  top: 13.8rem;
  right: 32rem;
  width: 8%;
  font-size: 1rem;
`

const ImageExpDate = styled.div`
  position: absolute;
  top: 19.1rem;
  right: 18rem;
  font-size: 1rem;
`
const ImageUseInfo = styled.div`
  position: absolute;
  top: 21.5rem;
  left: 15rem;
  width: 23%;
  font-size: 0.5rem;
`

const Coupon = () => {
  const [couponHistory, setCouponHistory] = useState<CouponDetailTypes[]>([])
  const [paginator, setPaginator] = useState({ limit: 24, page: 1 })
  const [total, setTotal] = useState(0)
  const [dateFrom, setDateForm] = useState(0)
  const [searchString, setSearchString] = useState('')
  const [dateTo, setDateTo] = useState(0)
  const [couponVisible, setCouponVisible] = useState(false)
  const [couponCard, setCouponCard] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [isCustomerSearching, setIsCustomerSearching] = useState(false)
  const [isRedeemerSearching, setIsRedeemerSearching] = useState(false)
  const [searchResult, setSearchResult] = useState([])
  const [customerSearchResult, setCustomerSearchResult] = useState([])
  const [redeemerSearchResult, setRedeemerSearchResult] = useState([])
  const [couponSelected, setCouponSelected] = useState(null)
  const [customerSelected, setCustomerSelected] = useState(null)
  const [redeemerSelected, setRedeemerSelected] = useState(null)
  const [updateTable, setUpdateTable] = useState(0)
  const [couponDetail, setCouponDetail] = useState<CouponDetailTypes>()
  const [couponLanguage, setCouponLanguage] = useState()
  const [couponId, setCouponId] = useState('')
  const shoppingCartState = useAppSelector(shoppingCartData)
  const dispatch = useAppDispatch()
  const search = useMemo(
    () =>
      debounce(async (value) => {
        if (!value) {
          return
        }
        const params = {
          pageSize: 20,
          currentPage: 1,
          q: value
        }
        setIsSearching(true)
        const res = await getCouponCodeList(params)
        if (res.success) {
          setSearchResult(res.data.items)
          setIsSearching(false)
        }
      }, 100),
    []
  )
  const getCustomerDebounceHandler = useMemo(
    () =>
      debounce(async (value) => {
        const queryRes = await getCustomers({
          q: value,
          pageSize: 20,
          currentPage: 1
        })
        if (queryRes.data) {
          const { items } = queryRes.data

          setCustomerSearchResult(items)
          setIsCustomerSearching(false)
        }
      }, 100),
    []
  )

  const getRedeemerDebounceHandler = useMemo(
    () =>
    debounce(async (value) => {
      const queryRes = await getCustomers({
        q: value,
        pageSize: 20,
        currentPage: 1
      })
      if (queryRes.data) {
        const { items } = queryRes.data

        setRedeemerSearchResult(items)
        setIsRedeemerSearching(false)
      }
    }, 100),
    []
  )

  const onChange = async (e) => {
    const res = await getCouponDetail(couponId)
    if (res.success) {
      setCouponDetail(res.data)
      setCouponLanguage(e.target.value)
    } else {
      message.error(res.message)
    }
  }

  function coupon() {
    if (couponCard) {
      if (couponLanguage === 'zh-CN') {
        dayjs.locale('zh-cn')
      } else {
        dayjs.locale('en')
      }
      return (
        <Modal
          centered
          visible={couponCard}
          onCancel={() => {
            setCouponCard(false)
            setCouponLanguage(undefined)
          }}
          width={couponLanguage ? 1220 : 500}
          footer={null}
        >
          <div style={{ marginTop: '25px' }} />
          {couponLanguage ? (
            <ImageStyle>
              <ImageBackgroundStyle
                src={`${api.cdn}/${couponDetail.display.background}`}
                preview={false}
              />
              <ImagePriceData>
                {Math.round(couponDetail.amount * +(shoppingCartState.currencyRate || sessionStorage.getItem('exchangeRate')))}
              </ImagePriceData>
              <ImageCouponCurrencyBefore>{couponDetail.display.currencyBefore}</ImageCouponCurrencyBefore>
              <ImageNzPriceData>
                {couponDetail.amount} {couponDetail.display.currencyAfter}
              </ImageNzPriceData>
              <ImageCouponData>{couponDetail.code}</ImageCouponData>
              <ImageExpDate>{dayjs(couponDetail.expiredAt).format('D/MM/YYYY')}</ImageExpDate>
              <ImageUseInfo>{couponDetail.display.terms}</ImageUseInfo>
            </ImageStyle>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <Radio.Group onChange={onChange}>
                <Radio value={'zh-CN'}>中文</Radio>
                <Radio value={'en-NZ'} disabled>
                  English
                </Radio>
              </Radio.Group>
            </div>
          )}
        </Modal>
      )
    } else {
      return (
        <Modal
          centered
          destroyOnClose
          visible={couponVisible}
          onCancel={() => {
            setCouponVisible(false)
          }}
          footer={null}
        >
          <div style={{ marginTop: '15px' }} />
          <Form
            onFinish={async (values) => {
              const params = {
                couponId: couponSelected.id,
                customerId: customerSelected?.id || 0,
                redeemerId: redeemerSelected?.id || 0,
                orderNo: values.orderId || '',
                code: values.code || '',
                comment: values.comment || ''
              }
              const res = await createCoupon(params)
              if (res.success) {
                setUpdateTable(updateTable + 1)
                message.success(res.message)
                setCouponVisible(false)
              } else {
                message.error(res.message)
              }

              // setCouponCard(true)
            }}
            {...formItemLayout}
          >
            <Form.Item name='coupon' label='Coupon' rules={[{ required: true }]}>
              <Select
                placeholder='Search Coupon'
                notFoundContent={isSearching ? <Spin size='small' /> : null}
                filterOption={false}
                showSearch
                onSearch={(value) => search(value)}
                style={{ flex: 1 }}
                onSelect={(id) => {
                  const coupon = searchResult.find((item) => item.id === id)

                  setCouponSelected(coupon)
                }}
              >
                {searchResult.map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name='customer' label='Customer' rules={[{ required: couponSelected?.bindCustomer }]}>
              <Select
                placeholder='Search Customer'
                notFoundContent={isCustomerSearching ? <Spin size='small' /> : null}
                filterOption={false}
                showSearch
                allowClear
                onSearch={getCustomerDebounceHandler}
                style={{ flex: 1 }}
                onSelect={(id) => {
                  const customer = customerSearchResult.find((item) => item.id === id)

                  setCustomerSelected(customer)
                }}
              >
                {customerSearchResult.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.userName}-{item.nickName} - {item.email} - {item.phone}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name='redeemer' label='Redeemer' rules={[{ required: couponSelected?.bindRedeemer }]}>
              <Select
                placeholder='Search Customer'
                notFoundContent={isRedeemerSearching ? <Spin size='small' /> : null}
                filterOption={false}
                showSearch
                allowClear
                onSearch={getRedeemerDebounceHandler}
                style={{ flex: 1 }}
                onSelect={(id) => {
                  const customer = redeemerSearchResult.find((item) => item.id === id)

                  setRedeemerSelected(customer)
                }}
              >
                {redeemerSearchResult.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.userName}-{item.nickName} - {item.email} - {item.phone}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name='orderId' label='Order No.' rules={[{ required: couponSelected?.bindOrder }]}>
              <Input />
            </Form.Item>
            {couponSelected?.codeType === 'fixed' ? (
              <Form.Item name='code' label='Code'>
                <Input disabled={couponSelected?.codeType === 'random'} />
              </Form.Item>
            ) : null}

            <Form.Item name='comment' label='Comment'>
              <TextArea />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 18, span: 5 }}>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )
    }
  }

  const formItemLayout = {
    labelCol: {
      span: 5
    },
    wrapperCol: {
      span: 16
    }
  }

  const couponRecordColumns: ColumnsType<CouponDetailTypes> = [
    {
      title: <div>Id</div>,
      dataIndex: 'id',
      width: '8%',
      align: 'center',
    },
    {
      title: <div>Customer</div>,
      align: 'center',
      dataIndex: 'customerId',
      width: '100px'
    },
    {
      title: <div>Manager</div>,
      dataIndex: 'managerId',
      width: '100px',
      align: 'center'
    },
    {
      title: <div>Name</div>,
      dataIndex: 'name',
      align: 'center',
      render: (text, record, index) => {
        return (<div>
          {text} <br /> Code: {record.code} Amount: {record.amount}{record.currency} Subtotal: {record.subtotal > 0 ? record.subtotal: 'None'}
          <br/>Expire At: {record.expiredAt>0 ? dayjs(record.expiredAt).format('D/MM/YYYY h:mm A') : 'None'} 
        </div>)
      }
    },
    {
      title: <div>Status</div>,
      dataIndex: 'status',
      width: '100px',
      align: 'center'
    },
    {
      title: <div>Comment</div>,
      dataIndex: 'comment',
      align: 'center',
      render: (text, record) => {
        return (
          <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')} autoAdjustOverflow>
            <div>{record.orderId>0 ? 'Order ID: ' + record.orderId : ''} {text||''}</div>
          </Tooltip>
        )
      }
    },
    {
      title: <div>Created At</div>,
      dataIndex: 'createdAt',
      align: 'center',
      width: '7%',
      render: (text) => {
        return (
          <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')} autoAdjustOverflow>
            <div>{dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</div>
          </Tooltip>
        )
      }
    },
    {
      title: <div>Updated At</div>,
      dataIndex: 'updatedAt',
      align: 'center',
      width: '7%',
      render: (text) => {
        return (
          <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')} autoAdjustOverflow>
            <div>{dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</div>
          </Tooltip>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: '7%',
      render: (_text, record) => (
        <Button
          block
          type='primary'
          onClick={async () => {
            setCouponId(record.id.toString())
            setCouponCard(true)
          }}
        >
          Display
        </Button>
      )
    }
  ]

  useEffect(() => {
    const getCouponQueryList = () => {
      const params = {
        pageSize: paginator.limit,
        currentPage: paginator.page,
        dateFrom: dateFrom,
        dateTo: dateTo,
        q: searchString,
        query: { updatedAt: 1 }
      }
      getCouponQuery(params).then((res) => {
        if (res.success) {
          const { items } = res.data
          const { totalCount } = res.data.pagination
          const pointArr = [...couponHistory, ...items]
          if (pointArr.length >= totalCount) {
            setTotal(totalCount)
            setCouponHistory(items)
            return
          }
          setCouponHistory(items)
          setTotal(totalCount)
        }
      })
    }
    getCouponQueryList()

    return () => {
      search.cancel()
      getCustomerDebounceHandler.cancel()
      getRedeemerDebounceHandler.cancel()
    }
  }, [paginator, updateTable])

  return (
    <Row>
      <Col span={24}>
        <Layout style={{ height: '100vh', position: 'absolute', width: '100%' }}>
          <ListHeader>
            <Row>
              <ColStyle
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(
                    setMenuDisplay({
                      menu: true
                    })
                  )
                }}
              >
                <img src='/icon/menu.png' width='35px' height='35px' alt='menu' />
              </ColStyle>

              <ColStyle flex={1} style={{ color: 'white', marginRight: '10px' }}>
                <div>Coupon Record</div>
              </ColStyle>
            </Row>
          </ListHeader>
          <StyledContent>
            <Table
              bordered
              dataSource={couponHistory}
              columns={couponRecordColumns}
              rowKey='id'
              title={() => {
                return (
                  <Row justify='space-between'>
                    <Col span={8}>
                      <Input
                        allowClear
                        defaultValue={null}
                        placeholder='Search'
                        autoFocus={true}
                        prefix={<SearchOutlined style={{ color: 'gray' }} />}
                        onChange={(e) => {
                          if (!e.target.value) {
                            setSearchString('')
                            setCouponHistory([])
                            setPaginator({ limit: 25, page: 1 })
                          }
                          setSearchString(e.target.value)
                          setCouponHistory([])
                          setPaginator({ limit: 25, page: 1 })
                        }}
                      />
                    </Col>
                    <Col span={14} offset={2}>
                      <RangePicker
                        style={{ width: '70%' }}
                        onChange={(dates: any) => {
                          if (!dates) {
                            setDateForm(0)
                            setDateTo(0)
                            setCouponHistory([])
                            setPaginator({ limit: 25, page: 1 })

                            return
                          }
                          setDateForm(dayjs(dates[0]).valueOf())
                          setDateTo(dayjs(dates[1]).valueOf())
                          setCouponHistory([])
                          setPaginator({ limit: 25, page: 1 })
                        }}
                        showTime
                      />
                      <Button
                        type='primary'
                        style={{ marginLeft: '24px' }}
                        onClick={() => {
                          const params = {
                            pageSize: paginator.limit,
                            currentPage: paginator.page,
                            dateFrom: dateFrom,
                            dateTo: dateTo,
                            query: { updatedAt: 1 }
                          }
                          exportCouponListAsExcel(params).then((res) => {
                            if (res.success) {
                              window.open(res.data.url)
                            } else {
                              message.error(res.message)
                            }
                          })
                        }}
                      >
                        Export
                      </Button>
                      <Button
                        type='primary'
                        style={{ marginLeft: '24px' }}
                        onClick={() => {
                          setCouponVisible(true)
                        }}
                      >
                        Coupon
                      </Button>
                    </Col>
                  </Row>
                )
              }}
              scroll={{ y: '67vh' }}
              pagination={{
                total,
                defaultPageSize: paginator.limit,
                current: paginator.page,
                onChange: (page, pageSize) => {
                  setPaginator({ page, limit: pageSize })
                }
              }}
            />
          </StyledContent>
        </Layout>
        {coupon()}
      </Col>
    </Row>
  )
}

export default Coupon
