import { Col, Image, Row } from 'antd'
import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import api from '../../config/api'
import { ShoppingCartItemType } from '../../models/shoppingCartItem'
import { breakpointValues } from '../../Theme/breakpoints'
import useMediaQuery from '../../utils/useMediaQuery'
import {
  CartItemSubTitle,
  CartItemTitle,
  StyledRows,
  CartItemPrice,
  CartItemTotalPrice,
  CartItemCount,
  StyledMinusCircleOutlined,
  StyledPlusCircleOutlined,
  StyledCloseCircleOutlined,
  CartItemCountNumber,
  H6PriceSymbol,
  H6Price,
  H6PriceVIP,
  H6PriceSpecial,
  H6Bundled,
  H6PriceWholesale,
  H6PriceTier
} from './CartItem.style'

interface Props {
  cartProduct: ShoppingCartItemType
  increaseCountHandler: () => void
  decreaseCountHandler: () => void
  deleteItem: () => void
}
const CartItem = ({ cartProduct, increaseCountHandler, decreaseCountHandler, deleteItem }: Props) => {
  const isSmallScreen = useMediaQuery(`(max-width: ${breakpointValues.desktop.min})`)
  const imageUrl = (img) => {
    let url = img
    if (!!img && (img.slice(0, 7) == 'http://' || img.slice(0, 8) == 'https://'))
      url = img
    else
      url = `${api.cdn}/${img}?x-oss-process=style/640w`
    return url
  }

  return (
    <>
      <StyledRows align='top' justify='center'>
        <Col span={3} style={{ textAlign: 'center' }}>
          <Image
            preview={false}
            width={isSmallScreen ? 35 : 55}
            src={`${imageUrl(cartProduct.productImage)}`}
          />
        </Col>
        <Col span={15}>
          <CartItemTitle>{cartProduct.isSelfFulfillment ? "[" +(cartProduct.shelf?cartProduct.shelf:'')+ "] " : null}{cartProduct.shippingName}</CartItemTitle>
          <CartItemSubTitle>
            {cartProduct.sku}
            <span style={{ marginLeft: '0.5rem' }}>{cartProduct.locationName}</span>
            {cartProduct.weight ? <span style={{ color: 'green', marginLeft: '0.5rem' }}>{cartProduct.weight.toFixed(3)}kg</span> : null}
            {cartProduct.freeShipping ? <span style={{ color: 'red', marginLeft: '0.5rem' }}> <FormattedMessage id='freeShipment' /> </span> : null}
          </CartItemSubTitle>
          <CartItemPrice>
            <div>
              {cartProduct.finalPriceType === "group" ? <H6PriceVIP>${cartProduct.finalPrice.toFixed(2)}</H6PriceVIP> : null}
              {cartProduct.finalPriceType === "wholesale" ? <H6PriceWholesale>${cartProduct.finalPrice.toFixed(2)}</H6PriceWholesale> : null}
              {cartProduct.finalPriceType === "special" ? <H6PriceSpecial>${cartProduct.finalPrice.toFixed(2)}</H6PriceSpecial> : null}
              {cartProduct.finalPriceType === "origin" ? <H6Price>${cartProduct.finalPrice.toFixed(2)}</H6Price> : null}
              {cartProduct.finalPriceType === "partner" ? <H6Price>${cartProduct.finalPrice.toFixed(2)}</H6Price> : null}
              {cartProduct.finalPriceType === "tier" ? <H6PriceTier>${cartProduct.finalPrice.toFixed(2)}</H6PriceTier> : null}
              {cartProduct.skuName ? <span style={{ marginLeft: '5px', color: 'gray' }}>{cartProduct.skuName}</span> : null}
              {cartProduct.expiration ? <span style={{ marginLeft: '5px', color: 'gray' }}>{cartProduct.expiration}</span> : null}
              <span style={{ marginLeft: '5px', color: 'gray' }}>{cartProduct.isSelfFulfillment ? <FormattedMessage id='store' /> : <FormattedMessage id='warehouse' />}:{cartProduct.storeStock}</span>
            </div>
          </CartItemPrice>
          {cartProduct.productTypeExtraItems?.length>0 ? (
            <CartItemPrice>
              <Col>
              {cartProduct.productTypeExtraItems.map((item)=>{
                return (<Row><H6Bundled>{item.gtin} {item.shippingName}{item.skuName ? ' - ' + item.skuName : ''} * {item.selectionQty}</H6Bundled></Row>)
              })}
              </Col>
            </CartItemPrice>
          ) : null}
        </Col>
        <Col span={4}>
          <CartItemTotalPrice>
            <FormattedMessage id='subtotal' /> {(cartProduct.finalPrice * cartProduct.quantity).toFixed(2)}
          </CartItemTotalPrice>
          <CartItemCount>
            <StyledMinusCircleOutlined onClick={decreaseCountHandler} />
            <CartItemCountNumber>{cartProduct.quantity}</CartItemCountNumber>
            <StyledPlusCircleOutlined onClick={increaseCountHandler} />
          </CartItemCount>
        </Col>
        <Col span={1}>
          <StyledCloseCircleOutlined onClick={deleteItem} />
        </Col>
      </StyledRows>
    </>
  )
}

export default CartItem
