
export const customerInfo = (customerInfo, groupInfo) => {
  groupInfo = JSON.parse(groupInfo)
  const groupName = groupInfo.find((item) => {
    return customerInfo?.customerGroupId === item.id
  })?.name
  const customerNo = customerInfo?.customerNo ? customerInfo?.customerNo : ''

  return ` ${groupName}, ${customerInfo?.customerName||''}(${customerInfo?.customerId}), ${customerNo},  ${customerInfo?.customerEmail||''}`
}
