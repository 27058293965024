import React, { useState } from 'react'
import { Row, Col, Switch, message, Input } from 'antd'
import { withRouter } from 'react-router-dom'
import { addCommentToPayment, resLogging } from '../../api/response'
import ModalForm from './modal-form'
import { paymentHistoryAddComment } from '../../redux/reducers/orders_reducer'
import { setCartIncrease } from '../../redux/reducers/cartItems_reducer'
import { useAppDispatch } from '../../redux/reduxHooks'

const { TextArea } = Input
const NotifyModal = (props) => {
  const { editLoggingInfo, close, paymentNotify, orderId } = props
  const [loggingInfoVisible, setLoggingInfoVisible] = useState(false)
  const [loggingInfoNotify, setLoggingInfoNotify] = useState(false)
  const [loggingInfoComment, setLoggingInfoComment] = useState('')
  const dispatch = useAppDispatch()
  if (paymentNotify) {
    return (
      <ModalForm
        visible={editLoggingInfo}
        width='40%'
        centered
        closable
        okText='Confirm'
        onCancel={() => {
          close()
        }}
        onOk={() => {
          if (paymentNotify) {
            addCommentToPayment({ comment: loggingInfoComment }, orderId).then((res) => {
              if (res.success) {
                dispatch(paymentHistoryAddComment({
                  addComment: 1
                }))
                message.success(res.message)
              } else {
                message.error(res.message)
              }
            })
            close()
          } else {
            const params = {
              visible: +loggingInfoVisible,
              notify: loggingInfoNotify,
              comment: loggingInfoComment
            }
            resLogging(params, props.match.params.id).then((res) => {
              if (res.success) {
                message.success(res.message)
                dispatch(setCartIncrease({
                  num: 1
                }))
                close()
              } else {
                message.error(res.message)
              }
            })
          }
        }}
      >
        <Row style={{ width: '100%', marginLeft: '45px', paddingTop: '15px' }}>
          <Col span={6}>Comment:</Col>
          <Col span={12}>
            <TextArea
              rows={5}
              allowClear
              style={{ height: 'auto' }}
              onChange={(e) => {
                setLoggingInfoComment(e.target.value)
              }}
            />
          </Col>
        </Row>
      </ModalForm>
    )
  } else {
    return (
      <ModalForm
        visible={editLoggingInfo}
        width='40%'
        centered
        closable
        okText='Confirm'
        onCancel={() => {
          close()
        }}
        onOk={() => {
          const params = {
            visible: +loggingInfoVisible,
            notify: loggingInfoNotify,
            comment: loggingInfoComment
          }
          resLogging(params, props.match.params.id).then((res) => {
            if (res.success) {
              message.success(res.message)
              dispatch(setCartIncrease({
                num: 1
              }))
              close()
            } else {
              message.error(res.message)
            }
          })
        }}
      >
        <Row style={{ width: '100%', marginLeft: '45px' }}>
          <Col span={6}>Visible:</Col>
          <Col span={4}>
            <Switch
              onChange={(checked) => {
                setLoggingInfoVisible(checked)
              }}
            />
          </Col>
        </Row>
        <Row style={{ width: '100%', marginLeft: '45px', paddingTop: '15px' }}>
          <Col span={6}>Notify:</Col>
          <Col span={6}>
            <Switch
              onChange={(checked) => {
                setLoggingInfoNotify(checked)
              }}
            />
          </Col>
        </Row>
        <Row style={{ width: '100%', marginLeft: '45px', paddingTop: '15px' }}>
          <Col span={6}>Comment:</Col>
          <Col span={12}>
            <TextArea
              rows={5}
              allowClear
              style={{ height: 'auto' }}
              onChange={(e) => {
                setLoggingInfoComment(e.target.value)
              }}
            />
          </Col>
        </Row>
      </ModalForm>
    )
  }
}

export default withRouter(NotifyModal)
