export const basePath = {}

export const subPath = {}

export const createUrl = (path: string | [], params?: {}) => {
  const paths = typeof path == 'string' ? path : path.join('/')
  let name = ''
  if (!!params) {
    name = Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    return `${paths}?${name}`
  }
  return `${paths}`
}
