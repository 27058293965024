/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button, Col, Divider, Image, InputNumber, Row, Tag, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { findKey, findLastIndex, forEach, forIn, get, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Item, ProductSummaryTypes } from '../../models/productQuery'
import { ProductInfo } from '../../models/shoppingCartItem'
import { productsData } from '../../redux/reducers/product_reducer'
import { priceTirePopup } from './commonFunc'
import { PriceTypes, PriceTypes_en } from '../../data/priceTypes.enum'
import { expirationConvert } from '../../utils/ExpirationConvert'
import api from '../../config/api'
import { FormattedMessage } from 'react-intl'

const Box = styled.div`
  .ant-input-number-lg input {
    height: 45px;
  }
`
const CardStyle = styled.div<{ choose: number }>`
  position: relative;
  transition: all 0.5s ease;
  ::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 6px;
    border: ${(props) => (props.choose ? '18px solid orange' : 'none')};
    border-top-color: transparent;
    border-left-color: transparent;
    z-index: 1000;
  }
  ::after {
    content: '';
    width: 7px;
    height: 14px;
    position: absolute;
    right: 6px;
    bottom: 13px;
    border: ${(props) => (props.choose ? '2px solid white' : 'none')};
    border-top-color: transparent;
    border-left-color: transparent;
    transform: rotate(45deg);
    z-index: 1000;
  }
  border-color: ${(props) => (props.choose ? 'orange' : 'lightgrey')};
  :hover {
    cursor: pointer;
  }
`

const Img = styled.img`
  object-fit: cover;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const ImageStyle = styled(Image) <{ choose: number }>`
  border: ${(props) => (props.choose ? '1px solid orange' : 'none')};
  object-fit: cover;
`

const NonImageStyle = styled.div<{ choose: number }>`
  border: ${(props) => (props.choose ? '1px solid orange' : '1px solid lightgrey')};
  font-size: 0.7em;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  ::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    border: ${(props) => (props.choose ? '21px solid orange' : 'none')};
    border-top-color: transparent;
    border-left-color: transparent;
    z-index: 1000;
  }
  ::after {
    content: '';
    width: 7px;
    height: 14px;
    position: absolute;
    right: 6px;
    bottom: 13px;
    border: ${(props) => (props.choose ? '2px solid white' : 'none')};
    border-top-color: transparent;
    border-left-color: transparent;
    transform: rotate(45deg);
    z-index: 1000;
  }
`

const ImageMask = styled.div<{ attribute: boolean }>`
  :after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    background-color: grey;
    opacity: 0.2;
    z-index: 1;
    width: 100%;
    :hover {
      cursor: ${(props) => (props.attribute ? 'not-allowed' : 'pointer')};
    }
  }
`
interface Props {
  itemChoose?: (productId: number, skuId: number, quantity: number) => void
  addToCart?: () => void
  showAttr?: boolean
  product?: ProductInfo
  setSelectedItemForOther?: (item: Item) => void
  tirePriceSet?: (price: number) => void
  otherQuantity?: number
  hasItemInlist?: (tem: boolean) => void
}

const AttributesProduct = (props: Props) => {
  const { itemChoose, addToCart, showAttr, product, setSelectedItemForOther, tirePriceSet, otherQuantity, hasItemInlist } = props
  const [selectAttribute1, setSelectAttribute1] = useState<{ [key: string]: { value: string } }>()
  const [selectedItem, setSelectedItem] = useState<Item>()
  const [itemImage, setItemImage] = useState<string>()
  const [quantity, setQuantity] = useState(0)
  const [tirePrice, setTirePrice] = useState(0)
  const [hasItemInItemList, setHasItemInItemList] = useState(false)
  const productInfoSate = useSelector(productsData)
  const data = {
    attributes: product?.attributes || productInfoSate?.productSummary?.attributes,
    items: product?.items || productInfoSate?.productSummary?.items
  }

  const filterSelectedItem = (data: ProductInfo | ProductSummaryTypes) => {
    let arr = []

    forIn(selectAttribute1, (value, key) => {
      arr = [...arr, { name: key, value: value.value }]
    })
    data.items.forEach((element) => {
      let a = 0
      element.attributes.forEach((item) => {
        arr.forEach((tem) => {
          if (JSON.stringify(tem) === JSON.stringify(item)) {
            a++
            if (a === element.attributes.length) {
              if (setSelectedItemForOther) {
                setSelectedItemForOther(element)
              }
              if (hasItemInlist) {
                hasItemInlist(true)
              }
              setSelectedItem(element)
              setHasItemInItemList(true)
              setQuantity(element.unitMin)
              return
            }
          }
        })
      })
    })
  }

  useEffect(() => {
    if (!data.items) {
      return
    }
    const defaultProduct = data.items.find((item) => item.isDefault)
    if (!defaultProduct) {
      return
    }
    let arr = {}
    forEach(defaultProduct.attributes, (item) => {
      arr = { ...arr, [item.name]: { value: item.value } }
    })
    setSelectAttribute1({ ...arr })
  }, [productInfoSate.productSummary, product])

  useEffect(() => {
    if (!data.attributes || !data.items || data.attributes.length === 0) {
      return
    }
    if (!!selectAttribute1) {
      setHasItemInItemList(false)
      if (hasItemInlist) {
        hasItemInlist(false)
      }
      if (showAttr) {
        filterSelectedItem(product)
      } else {
        filterSelectedItem(productInfoSate.productSummary)
      }
    }
  }, [selectAttribute1])

  useEffect(() => {
    if (!selectedItem) {
      setItemImage(undefined)
      if (itemChoose) {
        itemChoose(0, 0, 0)
      }
      return
    }
    if (showAttr && tirePriceSet) {
      if (selectedItem.priceTiers.length === 0) {
        tirePriceSet(selectedItem.finalPrice)
      } else if (
        otherQuantity >= selectedItem.priceTiers[findLastIndex(selectedItem.priceTiers, (item) => item.unitMin <= otherQuantity)]?.unitMin
      ) {
        tirePriceSet(selectedItem.priceTiers[findLastIndex(selectedItem.priceTiers, (item) => item.unitMin <= otherQuantity)].price)
      } else {
        tirePriceSet(selectedItem.finalPrice)
      }
    }
    if (selectedItem.priceTiers.length === 0) {
      setTirePrice(selectedItem.finalPrice)
    } else if (quantity >= selectedItem.priceTiers[findLastIndex(selectedItem.priceTiers, (item) => item.unitMin <= quantity)]?.unitMin) {
      setTirePrice(selectedItem.priceTiers[findLastIndex(selectedItem.priceTiers, (item) => item.unitMin <= quantity)].price)
    } else {
      setTirePrice(selectedItem.finalPrice)
    }
    if (itemChoose) {
      itemChoose(productInfoSate.productSummary.productId, selectedItem.skuId, quantity)
    }
  }, [selectedItem, quantity, otherQuantity])


  const imageUrl = (img) => {
    let url = img
    if (!!img && (img.slice(0, 7) == 'http://' || img.slice(0, 8) == 'https://'))
      url = img
    else
      url = `${api.cdn}/${img}?x-oss-process=style/640w`
    return url
  }

  if (showAttr) {
    return (
      <>
        {product.attributes.map((attr, index) => (
          <Row style={{ marginRight: '15px', alignItems: 'center' }} key={index}>
            <Col span={4}>{attr.title}: </Col>
            <Col span={20}>
              <Row gutter={[16, 16]}>
                {attr.values.map((item, index) => (
                  <Col span={6} key={index}>
                    <Tooltip title={item.title}>
                          <CardStyle
                            choose={
                              findKey(selectAttribute1, (att) => att.value === item.value) &&
                                get(selectAttribute1, [attr.name, 'value']) === item.value
                                ? 1
                                : 0
                            }
                            onClick={() => {
                              setSelectAttribute1({ ...selectAttribute1, [attr.name]: { value: item.value } })
                            }}
                          >
                            {isEmpty(item.image) ? (
                              <NonImageStyle
                                choose={
                                  findKey(selectAttribute1, (att) => att.value === item.value) &&
                                    get(selectAttribute1, [attr.name, 'value']) === item.value
                                    ? 1
                                    : 0
                                }
                              >
                                {item.title}
                              </NonImageStyle>
                            ) : (
                              <ImageStyle
                                choose={
                                  findKey(selectAttribute1, (att) => att.value === item.value) &&
                                    get(selectAttribute1, [attr.name, 'value']) === item.value
                                    ? 1
                                    : 0
                                }
                                width='100%'
                                height='5rem'
                                preview={false}
                                placeholder={true}
                                alt={item.title}
                                src={`${imageUrl(item.image)}`}
                              />
                            )}
                            <ImageMask attribute={!!selectAttribute1} />
                          </CardStyle>
                        </Tooltip>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        ))}
      </>
    )
  } else if (data?.attributes?.length === 0 || product) {
    return <></>
  }

  return (
    <>
      <div style={{ marginTop: '15px' }} />
      <Row wrap={false} gutter={[16, 48]}>
        <Col span={8}>
          <div style={{ position: 'relative', paddingTop: 'calc(100%)' }}>
            {itemImage ? (
              <Img src={`${imageUrl(itemImage)}`} />
            ) : (
              <Img src={`${imageUrl(productInfoSate.productSummary?.imageList[0])}`} />
            )}
          </div>
          <Row>
            {productInfoSate.productSummary?.imageList.map((item, index) => (
              <Col span={6} offset={1}>
                <div
                  style={{ width: '100%', marginTop: '20px', cursor: 'pointer' }}
                  key={index}
                  onClick={() => {
                    setItemImage(item)
                  }}
                >
                  <div style={{ position: 'relative', paddingTop: 'calc(100%)' }}>
                    <Img src={`${imageUrl(item)}`} />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
        <Col span={15} offset={1}>
          <Row style={{ fontSize: '15px', fontWeight: 500}}>{productInfoSate.productSummary?.shippingName}</Row>
          {data.attributes.map((attr, index) => (
            <Row style={{ fontSize: '1.2rem', alignItems: 'center' }} key={index} gutter={[16, 48]}>
              <Col span={4}>{attr.title}: </Col>
              <Col span={20} style={{ marginTop: '1rem' }}>
                <Row gutter={[10, 10]}>
                  {attr.values.map((item, index) => (
                    <Col span={8} key={index}>
                      <Row justify='space-between' align='middle'>
                        <Col style={{ width: '100%' }}>
                          <Tooltip title={item.title}>
                            <CardStyle
                              choose={
                                findKey(selectAttribute1, (att) => att.value === item.value) &&
                                  get(selectAttribute1, [attr.name, 'value']) === item.value
                                  ? 1
                                  : 0
                              }
                              onClick={() => {
                                setSelectAttribute1({ ...selectAttribute1, [attr.name]: { value: item.value } })
                              }}
                            >
                              {isEmpty(item.image) ? (
                                <NonImageStyle
                                  choose={
                                    findKey(selectAttribute1, (att) => att.value === item.value) &&
                                      get(selectAttribute1, [attr.name, 'value']) === item.value
                                      ? 1
                                      : 0
                                  }
                                >
                                  {item.title}
                                </NonImageStyle>
                              ) : (
                                <ImageStyle
                                  choose={
                                    findKey(selectAttribute1, (att) => att.value === item.value) &&
                                      get(selectAttribute1, [attr.name, 'value']) === item.value
                                      ? 1
                                      : 0
                                  }
                                  width='100%'
                                  height='5rem'
                                  preview={false}
                                  placeholder={true}
                                  alt={item.title}
                                  src={`${imageUrl(item.image)}`}
                                />
                              )}

                              <ImageMask attribute={!!selectAttribute1} />
                            </CardStyle>
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          ))}

          <Row align='middle' style={{ marginTop: '2rem', marginRight: '15px', fontSize: '1.2rem' }} gutter={[16, 48]}>
            <Col span={5}>
              <FormattedMessage id='amount' />:
            </Col>
            <Col span={19}>
              <Box style={{ display: 'inline-flex', alignItems: 'center' }}>
                <InputNumber
                  size='large'
                  formatter={(value) => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => +value.replace(/\$\s?|(,*)/g, '')}
                  min={selectedItem?.unitMin || 0}
                  max={selectedItem?.unitMax || Number.MAX_VALUE}
                  value={quantity}
                  onChange={(value) => {
                    setQuantity(value)
                  }}
                />
                <span style={{ marginLeft: '15px', flex: '1' }}>
                  <FormattedMessage id='unit' />
                </span>
                {selectedItem?.unitMin > 1 && <span style={{ margin: '0 0.5rem' }}>Min: {selectedItem.unitMin}</span>}
                {!!selectedItem?.unitMax && <span style={{ margin: '0 0.5rem' }}>Max: {selectedItem.unitMax}</span>}
              </Box>
            </Col>
          </Row>
          <Row style={{ marginTop: '10px' }} align='middle' gutter={[16, 48]}>
            <Col span={5}>
              <FormattedMessage id='stock' />:{' '}
            </Col>
            <Col span={19}>
              {selectedItem?.stock}
              {' '}{selectedItem?.stockLocationName}
              {' '}{expirationConvert(selectedItem?.expiration)}
            </Col>
          </Row>

          <Row style={{ marginTop: '10px' }} align='middle' gutter={[16, 48]}>
            <Col span={5}>
              <div>
                <FormattedMessage id='price' />:{' '}
              </div>
            </Col>
            <Col span={19}>
              ${tirePrice.toFixed(2)}
              {localStorage.getItem('lang') === 'zh-CN'
                ? PriceTypes[selectedItem?.finalPriceType] !== '' && (
                  <span style={{ fontSize: '1rem', fontWeight: 300, marginLeft: '0.5rem', color : 'red' }}>
                    {PriceTypes[selectedItem?.finalPriceType]}
                  </span>
                )
                : PriceTypes_en[selectedItem?.finalPriceType] !== '' && (
                  <span style={{ fontSize: '1rem', fontWeight: 300, marginLeft: '0.5rem', color: 'red' }}>
                    {PriceTypes_en[selectedItem?.finalPriceType]}
                  </span>
                )}
            </Col>
          </Row>
          {selectedItem?.priceTiers?.length > 0 && (
          <Row style={{marginTop: '10px'}}>
            <Col span={5}></Col>
            <Col span={19}>
              {selectedItem.priceTiers.map((item, index) => (
                <Tag key={index} color={'red'}>{`${item.unitMin}+ $${item.price}`}</Tag>
              ))}
            </Col>
          </Row>
          )}
          <Row style={{marginTop: '10px'}}>
            <Col span={5}></Col>
            <Col span={10}>
              <Button
                block
                style={{
                  background:
                    hasItemInItemList && selectedItem.enable && !(selectedItem.stockVisible && selectedItem.stock === 0) ? 'orange' : '',
                  color:
                    hasItemInItemList && selectedItem.enable && !(selectedItem.stockVisible && selectedItem.stock === 0) ? 'white' : '',
                  height: '100%',
                  backgroundColor: 'orange',
                  letterSpacing: localStorage.getItem('lang') === 'zh-CN' ? '0.5rem' : 0
                }}
                disabled={!hasItemInItemList || !selectedItem.enable || (selectedItem.stockVisible && selectedItem.stock === 0)}
                onClick={addToCart}
              >
                <FormattedMessage id='purchase' />
              </Button>
            </Col>
            <Col offset={9}>{!hasItemInItemList && <Alert message='Sold Out' type='error' showIcon />}</Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default AttributesProduct
