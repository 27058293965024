/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { Row, Col, Table, Input, DatePicker, Button, Tooltip, message, Image } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { exportPaymentAsExcel, getPaymentHistory } from '../../api/response'
import dayjs from 'dayjs'
import NotifyModal from '../common/notifyModal'
import { useAppSelector } from '../../redux/reduxHooks'
import { homePageData } from '../../redux/reducers/homePage_reducer'
import { PaymentListDetailTypes } from '../../models/paymentTypes'
import { ColumnsType } from 'antd/es/table'
import CommonListLayout from '../common/commonListLayout'
import api from '../../config/api'

const { RangePicker } = DatePicker

const PaymentRecords = () => {
  const [pointHistory, setPointHistory] = useState<PaymentListDetailTypes[]>([])
  const [paginator, setPaginator] = useState({ limit: 25, page: 1 })
  const [total, setTotal] = useState(0)
  const [dateFrom, setDateForm] = useState(0)
  const [searchString, setSearchString] = useState('')
  const [dateTo, setDateTo] = useState(0)
  const [orderId, setOrderId] = useState<string>()
  const [modalVisible, setModalVisible] = useState(false)
  const storeState = useAppSelector(homePageData)


  const imageUrl = (img) => {
    let url = img
    if (!!img && (img.slice(0, 7) == 'http://' || img.slice(0, 8) == 'https://'))
      url = img
    else
      url = `${api.cdn}/${img}?x-oss-process=style/640w`
    return url
  }

  const paymentHistoryRecordColumns: ColumnsType<PaymentListDetailTypes> = [
    {
      title: <div>Id</div>,
      dataIndex: 'id',
      width: '4%',
      align: 'center',
      render: (_text, _record, index) => (
        <>
          <div>{index + 1}</div>
        </>
      )
    },
    {
      title: <div>Order No</div>,
      dataIndex: 'orderId',
      ellipsis: true,
      width: '10%',
      showSorterTooltip: true,
      align: 'center',
      render: (text) => text.toString()
    },
    {
      title: <div>Customer Id</div>,
      align: 'center',
      dataIndex: 'customerId',
      width: '7%'
    },
    {
      title: <div>Manager Id</div>,
      dataIndex: 'managerId',
      width: '7%',
      align: 'center'
    },
    {
      title: <div>Manager</div>,
      dataIndex: 'managerName',
      width: '8%',
      align: 'center'
    },
    {
      title: <div>Store</div>,
      dataIndex: 'storeName',
      width: '7%',
      align: 'center'
    },
    {
      title: <div>Method</div>,
      dataIndex: 'method',
      width: '10%',
      align: 'center'
    },
    {
      title: <div>Amount</div>,
      dataIndex: 'amount',
      width: '7%',
      align: 'center',
      render: (text) => {
        return <div>$ {text}</div>
      }
    },
    {
      title: <div>Comment</div>,
      dataIndex: 'comment',
      width: '10%',
      align: 'center',
      render: (text, record) => {
        if (record.screenshot) {
          return (
            <div style={{ display: 'flex' }}>
              <Image src={`${imageUrl(record.screenshot)}`} height="100px" />
              <div>{text}</div>
            </div>
          )
        } else {
          return <div>{text}</div>
        }
      }
    },
    {
      title: <div>Created At</div>,
      dataIndex: 'createdAt',
      align: 'center',
      width: '7%',
      render: (text) => {
        return (
          <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')} autoAdjustOverflow>
            <div>{dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</div>
          </Tooltip>
        )
      }
    },
    {
      title: <div>Updated At</div>,
      dataIndex: 'updatedAt',
      align: 'center',
      width: '7%',
      render: (text) => {
        return (
          <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')} autoAdjustOverflow>
            <div>{dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</div>
          </Tooltip>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: '15%',
      render: (_text, record) => (
        <Button
          type='primary'
          onClick={() => {
            setModalVisible(true)
            setOrderId(record.id.toString())
          }}
        >
          Add Comment
        </Button>
      )
    }
  ]

  useEffect(() => {
    const params = {
      pageSize: paginator.limit,
      currentPage: paginator.page,
      dateFrom: dateFrom,
      dateTo: dateTo,
      q: searchString,
      query: { updatedAt: 1 }
    }
    getPaymentHistory(params).then((res) => {
      if (res.success) {
        const { items } = res.data
        const { totalCount } = res.data.pagination
        const pointArr = [...pointHistory, ...items]
        if (pointArr.length >= totalCount) {
          setTotal(totalCount)
          setPointHistory(items)
          return
        }
        setPointHistory(items)
        setTotal(totalCount)
      }
    })
  }, [paginator, storeState.comment])

  return (
    <>
      <CommonListLayout title='Payment List'>
        <Table
          bordered
          dataSource={pointHistory}
          columns={paymentHistoryRecordColumns}
          rowKey='id'
          title={() => {
            return (
              <Row justify='space-between'>
                <Col span={8}>
                  <Input
                    allowClear
                    defaultValue={null}
                    placeholder='Search'
                    autoFocus={true}
                    prefix={<SearchOutlined style={{ color: 'gray' }} />}
                    onChange={(e) => {
                      if (!e.target.value) {
                        setSearchString('')
                        setPointHistory([])
                        setPaginator({ limit: 25, page: 1 })
                      }
                      setSearchString(e.target.value)
                      setPointHistory([])
                      setPaginator({ limit: 25, page: 1 })
                    }}
                  />
                </Col>
                <Col span={10}>
                  <RangePicker
                    style={{ width: '80%' }}
                    onChange={(dates: any) => {
                      if (!dates) {
                        setDateForm(0)
                        setDateTo(0)
                        setPointHistory([])
                        setPaginator({ limit: 25, page: 1 })

                        return
                      }
                      setDateForm(dayjs(dates[0]).valueOf())
                      setDateTo(dayjs(dates[1]).valueOf())
                      setPointHistory([])
                      setPaginator({ limit: 25, page: 1 })
                    }}
                    showTime
                  />
                  <Button
                    type='primary'
                    style={{ marginLeft: '24px' }}
                    onClick={() => {
                      const params = {
                        pageSize: paginator.limit,
                        currentPage: paginator.page,
                        dateFrom: dateFrom,
                        dateTo: dateTo,
                        query: { updatedAt: 1 }
                      }
                      exportPaymentAsExcel(params).then((res) => {
                        if (res.success) {
                          window.open(res.data.url)
                        } else {
                          message.error(res.message)
                        }
                      })
                    }}
                  >
                    Export
                  </Button>
                </Col>
              </Row>
            )
          }}
          scroll={{ y: '68vh' }}
          pagination={{
            total,
            defaultPageSize: paginator.limit,
            current: paginator.page,
            onChange: (page, pageSize) => {
              setPaginator({ page, limit: pageSize })
            }
          }}
        />
      </CommonListLayout>

      <NotifyModal
        editLoggingInfo={modalVisible}
        close={() => {
          setModalVisible(false)
        }}
        paymentNotify={true}
        orderId={orderId}
      />
    </>
  )
}

export default PaymentRecords
