import styled from 'styled-components'
import { FormFields } from '../Models/FormFieldsName.enum'

export const Label = styled.div<{ isAddOne: boolean }>`
  width: 6rem;
  text-align: right;
  margin-right: 1rem;
  color: ${(props) => (props.isAddOne ? 'orange' : null)};
`

export const LabelWithInput = styled.div<{ file: FormFields }>`
  display: flex;
  width: 100%;
  align-items: ${(props) => (props.file === FormFields.Note ? 'top' : 'center')} center;
`
export const ErrorMessage = styled.div`
  color: red;
  font-size: 1rem;
`
export const PictureUploadStyle = styled.div`
  display: flex;
  & .ant-upload-list-item-container,
  .ant-upload-select {
    width: 5rem !important;
    height: 5rem !important;
  }
`
