import React from 'react'
import DialogCard from '../../../../components/DialogCard'
import { Button, Col, Input, List, Row, message } from 'antd'
import LabelControl from '../../LabelControl/LabelControl'
import { formFieldsInfo, getLabelControlType } from '../../Hooks/formFieldInfo'
import { FormTypes } from '../../Models/FormTypes.enum'
import { DialogProps } from '../../../../components/DialogCard/DialogCard'
import { useAddress } from '../../Hooks'
import { StyledCheckCircleFilled, StyledList, StyledListIteMeta, StyledListItem, StyledCloseOutlined } from './Address.style'
import { CustomerCreateAddressResTypes } from '../../../../models/customerTypes'
import { SearchOutlined } from '@ant-design/icons'
import { FormFields } from '../../Models/FormFieldsName.enum'
import { FormattedMessage } from 'react-intl'

interface Props extends DialogProps {
  getPlaceOrderInfoBack: (data) => void
}

const Address: React.FC<Omit<Props, 'handleOk'>> = (props: Props) => {
  const { open, handleCancel, headerText, getPlaceOrderInfoBack } = props
  const {
    areaOptions,
    shipmentAddress,
    onCLickHandler,
    clickedItemId,
    hasMore,
    setPagination,
    pagination,
    debouncedChangeHandler,
    debouncedKeyPressHandler,
    deleteHandler,
    submitForm,
    PictureRef,
    PictureRef2,
    totalPage,
    total,
    control
  } = useAddress({ getPlaceOrderInfoBack })

  return (
    <DialogCard
      open={open}
      handleOk={submitForm}
      handleCancel={() => {
        handleCancel()
      }}
      headerText={headerText}
    >
      <Row>
        <Col span={11}>
          <Row align='middle' gutter={[0, 6]}>
            {formFieldsInfo[FormTypes.Address].map((item) => {
              if (item === FormFields.Name) {
                return (
                  <Col span={13} key={item}>
                    <LabelControl
                      key={item}
                      disable={!!clickedItemId}
                      formControl={control}
                      type={getLabelControlType(item)}
                      formName={item}
                      label={item}
                      options={areaOptions}
                      pictureRef={PictureRef}
                      pictureRef2={PictureRef2}
                    />
                  </Col>
                )
              }
              if (item === FormFields.Surname) {
                return (
                  <Col span={11} key={item}>
                    <LabelControl
                      key={item}
                      disable={!!clickedItemId}
                      formControl={control}
                      type={getLabelControlType(item)}
                      formName={item}
                      label={item}
                      options={areaOptions}
                      pictureRef={PictureRef}
                      pictureRef2={PictureRef2}
                    />
                  </Col>
                )
              }
              return (
                <Col span={24} key={item}>
                  <LabelControl
                    key={item}
                    disable={true}
                    formControl={control}
                    type={getLabelControlType(item)}
                    formName={item}
                    label={item}
                    options={areaOptions}
                    pictureRef={PictureRef}
                    pictureRef2={PictureRef2}
                  />
                </Col>
              )
            })}
          </Row>
        </Col>
        <Col span={12} offset={1}>
          <Row align='middle' gutter={[6, 6]}>
            <Col span={5}>
              <FormattedMessage id='searchAddress' />:
            </Col>
            <Col span={15}>
              <Input
                suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
                onBlur={debouncedChangeHandler}
                onKeyPress={(e) => debouncedKeyPressHandler(e)}
              />
            </Col>
            <Col span={4}>
              <Button
                type='default'
                size='small'
                style={{ color: 'orange' }}
                onClick={() => {
                  onCLickHandler('')
                }}
              >
                <FormattedMessage id='add' />
              </Button>
            </Col>
          </Row>
          <StyledList
            bordered
            loading={hasMore}
            // pagination={{ size: 'small', position: 'bottom', showQuickJumper: false, pageSize: 4 }}
            footer={null}
            size='small'
            dataSource={shipmentAddress}
            renderItem={(item: CustomerCreateAddressResTypes, index) => (
              <StyledListItem onClick={() => onCLickHandler(item.id.toString())}>
                <StyledListIteMeta
                  title={<span>{`${item.fullName} ${item.phone}`}</span>}
                  description={`${item.address}`}
                />
                {clickedItemId === item.id.toString() && <StyledCheckCircleFilled />}
                <StyledCloseOutlined onClick={(e) => deleteHandler(e, item)} />
              </StyledListItem>
            )}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.5rem' }}>
            <Button
              style={{ color: 'orange' }}
              size='small'
              disabled={pagination.page === 1}
              onClick={() => {
                if (pagination.page === 1) {
                  return
                }
                setPagination((cur) => {
                  return { page: cur.page - 1, limit: 4 }
                })
              }}
            >
              <FormattedMessage id='up' />
            </Button>
            <div>
              <FormattedMessage id='page' />: {totalPage && pagination.page}/ {totalPage} <FormattedMessage id='totalPage' />: {total}
            </div>
            <Button
              style={{ color: 'orange' }}
              size='small'
              disabled={pagination.page === totalPage || totalPage === 0}

              onClick={() => {
                if (pagination.page === totalPage || totalPage === 0) {
                  return
                }
                setPagination((cur) => {
                  return { page: cur.page + 1, limit: 4 }
                })
              }}
            >
              <FormattedMessage id='down' />
            </Button>
          </div>
        </Col>
      </Row>
    </DialogCard>
  )
}

export default Address
