import React, { Component } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import CashBoxHistoryPage from '../pages/cashBoxHistory';
import Order from '../pages/orderHistory'
import PaymentMethods from '../pages/paymentProcess';
import Print from '../pages/print';
import ReportList from '../pages/reportList';


type MyProps = {
  routerConfig: any[];
  location?:any
};


class FrontendAuth extends Component<MyProps> {
  render () {
    const { routerConfig, location } = this.props
    const { pathname } = location

    const isLogin = sessionStorage.getItem('manager')

    const targetRouterConfig = routerConfig.find((item) => item.path === pathname)
    if (targetRouterConfig && !targetRouterConfig.auth && !isLogin) {
      const { component } = targetRouterConfig
      return <Route exact path={pathname} component={component} />
    }
    if (isLogin) {
      if (pathname === '/login') {
        return <Redirect to='/' />
      } else {
        if (targetRouterConfig) {
          return <Route path={pathname} component={targetRouterConfig.component} exact/>
        } else {
          //{ path: '/report/product', name: 'Product', component: ReportList, auth: true },
          return (
            <Switch>
              <Route path='/order/:id/dispatch' component={Order} exact/>
              <Route path='/payment/:id' component={PaymentMethods} exact/>
              <Route path='/report/:type' component={ReportList} exact/>
              <Route path='/order/:id' component={Order} exact/>
              <Route path='/print/:id' component={Print} exact />
              <Route path='/cashBoxList/:id' component={CashBoxHistoryPage} exact/>
              <Redirect to='/' />
            </Switch>
          )
        }
      }
    } else {
      if ((targetRouterConfig && targetRouterConfig.auth) || pathname === '/login') {
        return <Redirect to='/login' />
      } else {
        return <Redirect to='/' />
      }
    }
  }
}
export default FrontendAuth
