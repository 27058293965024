import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined'
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined'
import { Button, Col, Dropdown, Input, Layout, List, Menu, message, Row, Select, Spin, Tooltip } from 'antd'
import { debounce, flatten } from 'lodash'
import Title from 'antd/lib/typography/Title'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import { setMenuDisplay } from '../../redux/reducers/menu_reducer'
import { useAppDispatch } from '../../redux/reduxHooks'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import { orderHistory } from '../../api/response'
import { useHistory, useRouteMatch } from 'react-router'

const { Header, Content } = Layout
dayjs.extend(isToday)

const ListHeader = styled(Header)`
  position: sticky;
  top: 0;
  z-index: 10;
  color: black;
  font-weight: 700;
  text-align: center;
  font-size: 20px;
  background-color: orange !important;
`
const ColStyle = styled(Col)`
  display: flex;
  justify-content: center;
  align-items:center;
`
const StyledContent = styled(Content)`
  overflow-y: auto;
  overflow-x: auto;
  height: 100vh;
  .ant-empty {
    display: none;
  }
`

const StatusStyle = styled(Row)`
  .statusSelected {
    color: black;
  }
`
const ListHover = styled(List.Item)<{ chooseid: any }>`
  background-color: ${(props) => (props.chooseid ? 'orange' : 'none')};
  &:hover {
    background-color: orange !important;
    color: white;
  }
`
const Div = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  font-size: 15px;
  margin: 5px 0px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

const Circle = styled.span<{ status: string }>`
  content: '';
  margin: 20px;
  margin-right: 0;
  display: table;
  width: 15px;
  height: 15px;
  background: ${(props) =>
    props.status === 'pending'
      ? 'orange'
      : props.status === 'paid'
      ? 'green'
      : props.status === 'awaitpayment'
      ? 'red'
      : props.status === 'processing' || props.status === 'awaitpickup'
      ? 'lightblue'
      : props.status === 'complete'
      ? 'green'
      : props.status === 'cancel'
      ? 'black'
      : props.status === 'closed'
      ? 'grey'
      : null};
  border-radius: 50%;
`

const OrderList = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const match = useRouteMatch<{ id: string }>()
  const [paginator, setPaginator] = useState({ limit: 20, page: 1 })
  const [orderListInfo, setOrderListInfo] = useState([])
  const [source, setSource] = useState({})
  const [hasMore, setHasMore] = useState(true)
  const [dataSource, setDataSource] = useState([])
  const [chooseId, setChooseId] = useState<string>()
  const [scrollHeight, setScrollHeight] = useState('91.5%')
  const [query, setQuery] = useState<string>()
  const [currentStatus, setCurrentStatus] = useState('')

  window.onresize = () => {
    if (document.documentElement.clientWidth < 1800) {
      setScrollHeight('88%')
    }
  }

  const debounceInputChangeHandler = useMemo(() => {
    return debounce((e) => {
      if (!e.target.value) {
        setQuery(undefined)
        setOrderListInfo([])
        setSource({})
        setPaginator({ limit: 20, page: 1 })
      } else {
        setOrderListInfo([])
        setSource({})
        setQuery(e.target.value)
        setPaginator({ limit: 20, page: 1 })
      }
    }, 300)
  }, [])
  const selectBefore = (
    <Select
      defaultValue='sender'
      className='select-before'
    >
      <Select.Option value='sender'>Sender:</Select.Option>
      <Select.Option value='receiver'>Receiver: </Select.Option>
    </Select>
  )

  const status = [
    'All',
    'Paid',
    'Processing',
    'AwaitPickup',
    'Complete',
    'Refunding',
    'Pending',
    'Cancel',
    'Closed'
  ]

  const menu = (
    <Menu
      onClick={(e) => {
        setDataSource([])
        setOrderListInfo([])
        setSource({})
        setPaginator({ limit: 20, page: 1 })
        setCurrentStatus(e.key.toString().replace(/\s*/g, ''))
      }}
      style={{ width: 256 }}
      selectedKeys={[currentStatus]}
    >
      {status.map((item, index) => {
        if (index > 2) {
          return <Menu.Item key={item.replace(/\s*/g, '')}>{item}</Menu.Item>
        }
        return null
      })}
    </Menu>
  )

  function orderList(params) {
    orderHistory(params).then((res) => {
      if (res.success) {
        const { items } = res.data
        const { totalCount } = res.data.pagination
        if (match.path === '/order') {
          history.push(`/order/${items[0].id}`)
          return
        }
        const arr = [...dataSource, ...items]
        const result = items.reduce((acc, cur) => {
          const key = dayjs(cur.createdAt).format('DD/MM/YYYY')
          if (!acc[key]) {
            acc[key] = [cur]
          } else {
            acc[key].push(cur)
          }

          return acc
        }, source)
        const flattenResult = Object.entries(result)
        setSource({ ...result })
        if (arr.length >= totalCount) {
          setHasMore(false)
          setOrderListInfo(flattenResult)
          return
        }
        setOrderListInfo(flattenResult)
        setDataSource(arr)
      } else {
        setDataSource([])
        setOrderListInfo([])
        setSource({})
        setHasMore(false)
        message.error(res.message)
      }
    })
  }

  useEffect(() => {
    let params = {}
    let isMounted = true // note mutable flag

    if (currentStatus !== '' && currentStatus !== 'all') {
      if (isMounted) {
        setHasMore(true)
      }

      if (query) {
        params = {
          q: query,
          pageSize: paginator.limit,
          currentPage: paginator.page,
          status: currentStatus
        }
        if (isMounted) {
          orderList(params)
        }
      } else {
        params = {
          pageSize: paginator.limit,
          currentPage: paginator.page,
          status: currentStatus
        }
        if (isMounted) {
          orderList(params)
        }
      }
    } else {
      if (isMounted) {
        setHasMore(true)
      }
      if (query) {
        params = {
          q: query,
          pageSize: paginator.limit,
          currentPage: paginator.page
        }
        if (isMounted) {
          orderList(params)
        }
      } else {
        params = {
          pageSize: paginator.limit,
          currentPage: paginator.page
        }
        if (isMounted) {
          orderList(params)
        }
      }
    }
    return () => {
      isMounted = false
      debounceInputChangeHandler.cancel()
    }
  }, [paginator, currentStatus])

  useEffect(() => {
    setChooseId(match.params.id)
    return () => {
      debounceInputChangeHandler.cancel()
    }
  }, [match.url])

  return (
    <Layout style={{ height: '100vh', position: 'absolute', width: '100%' }}>
      <ListHeader>
        <Row>
          <ColStyle
            style={{ cursor: 'pointer' }}
            onClick={() => {
              dispatch(
                setMenuDisplay({
                  menu: true
                })
              )
            }}
          >
            <img src='/icon/menu.png' width='35px' height='35px'/>
          </ColStyle>

          <ColStyle flex={1} style={{ color: 'white', marginRight: '10px' }}>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              Orders
            </div>
          </ColStyle>
        </Row>
      </ListHeader>
      <StyledContent style={{ overflowY: 'hidden' }}>
        <div
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <Input
            allowClear
            size='large'
            defaultValue={null}
            placeholder='CustomerNo,Phone,OrderNo,TrackingNo,Name'
            // addonBefore={selectBefore}
            onChange={debounceInputChangeHandler}
            prefix={<SearchOutlined style={{ color: 'gray' }} />}
          />
        </div>
        <StatusStyle>
          {status.map((item, index) => {
            if (index < 3) {
              return (
                <Col key={index}>
                  {
                    <Button
                      className={item.replace(/\s*/g, '').toLowerCase() === currentStatus ? 'statusSelected' : ''}
                      key={7}
                      type='link'
                      onClick={() => {
                        setDataSource([])
                        setOrderListInfo([])
                        setSource({})
                        setPaginator({ limit: 20, page: 1 })
                        setCurrentStatus(item.replace(/\s*/g, '').toLowerCase())
                      }}
                    >
                      {item}
                    </Button>
                  }
                </Col>
              )
            }
            return null
          })}
          <Col>
            <Dropdown overlay={menu}>
              <Button type='link' className={currentStatus === 'More' ? 'statusSelected' : ''}>
                More <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
        </StatusStyle>
        <div
          id='scrollableDiv'
          style={{
            height: scrollHeight,
            overflowY: 'scroll',
            backgroundColor: 'white'
          }}
        >
          <InfiniteScroll
            next={() => {
              setPaginator({
                ...paginator,
                page: paginator.page + 1
              })
            }}
            loader={
              <div style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            }
            hasMore={hasMore}
            dataLength={flatten(Object.values(source)).length}
            endMessage={<div style={{ textAlign: 'center' }}>No more</div>}
            scrollableTarget='scrollableDiv'
          >
            <List
              size='small'
              rowKey='id'
              dataSource={orderListInfo}
              renderItem={([date, values]) => {
                return (
                  <>
                    <Title level={5} style={{ color: 'grey', marginLeft: '10px', marginTop: '10px', fontSize: '1rem' }}>
                      {date}
                    </Title>
                    {values.map((item) => {
                      const phone = item.customerPhone || ''
                      const email = item.customerEmail || ''
                      const description = `${item.customerNo||item.customerId} ${phone} ${email} ${item.customerName||''} `
                      const receiver = `${item.consigneePhone || ''} ${item.consigneeName || ''} ${item.consigneeAddress || ''} `
                      return (
                        <ListHover
                          chooseid={chooseId === item.id.toString() ? 1 : 0}
                          key={item.id}
                          style={{ cursor: 'pointer', padding: '0 0', marginTop: '5px'}}
                          onClick={() => {
                            const path = {
                              pathname: `/order/${item.id.toString()}`
                            }
                            history.push(path)
                          }}
                        >
                          <List.Item.Meta
                            avatar={<Circle style={{ marginTop: '40px'}} status={item.status} />}
                            title={<div style={{ fontSize: '15px', fontWeight: 600, marginTop: '5px' }}>[{item.shippingTerm}] {item.orderNo||item.id}</div>}
                            description={
                              <>
                                <Tooltip title={description}>
                                  <Div>{description}</Div>
                                </Tooltip>
                                <Div>{receiver}</Div>
                              </>
                            }
                          />
                          <div
                            style={{
                              marginRight: '10px',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                              width: 'fit-content'
                            }}
                          >
                            <div style={{ fontWeight: 500, fontSize: '15px' }}>$ {item.total.toFixed(2)}</div>
                            <div style={{ fontSize: '15px' }}>
                              {dayjs(item.createdAt).isToday()
                                ? dayjs(item.createdAt).format('HH:mm:ss')
                                : dayjs(item.createdAt).format('DD/MM/YYYY')}
                            </div>
                          </div>
                        </ListHover>
                      )
                    })}
                  </>
                )
              }}
            />
          </InfiniteScroll>
        </div>
      </StyledContent>
    </Layout>
  )
}

export default OrderList
