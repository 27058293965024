import React, { useState } from 'react'
import PrintLayout from '../components/print'
import { IntlProvider } from 'react-intl' /* react-intl imports */
import ZH_CN from '../locale/zh_CN'
import EN_US from '../locale/en_US'
import styled from 'styled-components'

const PrintStyle = styled.div`
  @page {
    margin: 0mm 30px;
  }
`

const Print = (props) => {
  const [lang, setLang] = useState('zh-CN')
  const messages = {}
  messages['en-NZ'] = EN_US
  messages['zh-CN'] = ZH_CN
  const { id } = props.match.params
  return (
    <PrintStyle>
      <IntlProvider locale={lang} messages={messages[lang]}>
        <PrintLayout id={id} setLang={(language:string)=>setLang(language)}/>
      </IntlProvider>
    </PrintStyle>
  )
}

export default Print
