import axios, { AxiosError, AxiosResponse } from 'axios'
import jsonBig from 'json-bigint'
import { message } from 'antd'
import { createUrl } from './urlService'
import { Account, LoginRequest, LoginResTypes, UpdateAccountRequest } from '../models/loginTypes'
import { CommonQueryTypes, CommonResTypes, ManagerStoreTypes } from '../models/common'
import {
  BundledProductResTypes,
  ProductInventoryResTypes,
  ProductQueryTypes,
  ProductSummaryTypes,
  QueryReqTypes
} from '../models/productQuery'
import { GetShoppingCartReq, ProductInfo, PutShoppingCartReq, ShoppingCartItemType } from '../models/shoppingCartItem'
import { CheckoutReqTypes, CheckoutResTypes } from '../models/checkoutTypes'
import {
  CustomerCreateAddressResTypes,
  CustomerCreateAddressTypes,
  CustomerCreateTypes,
  CustomerGroupTypes,
  CustomerQueryTypes,
  CustomerTypes
} from '../models/customerTypes'
import {
  ShipmentListType,
  ShipmentMethodReq,
  ShipmentMethodTypes,
  ShippingItems,
  ShippingMethodTypes
} from '../models/shipping/shippingMethod'
import { PlaceOrderReqTypes, PlaceOrderResTypes } from '../models/order/placeorder'
import { OrderPaymentReqTypes } from '../models/order/orderPayment'
import { OrderDetailResTypes } from '../models/order/orderDetail'
import {
  OrderCourierTypes,
  OrderSelfPickingOrShipmentItem,
  OrderSelfPickingReqTypes,
  AddOrderShipmentReqTypes,
  UpdateOrderShipmentReqTypes,
  ParcelOrderShipmentReqTypes,
  ParcelShipmentReqTypes,
  CompleteOrderShipmentReqTypes,
  DeleteOrderShipmentReqTypes
} from '../models/order/orderHistory'
import {
  PaymentReportQueryRequestType,
  PaymentReportDailyDataTypes,
  PaymentReportWeeklyDataTypes,
  PaymentReportMonthlyDataTypes,
  OrderReportDailyDataTypes,
  OrderReportWeeklyDataTypes,
  OrderReportMonthlyDataTypes,
  ReportExportRequestType
} from '../models/reporting/reportingTypes'
import { CustomerTopUpReqTypes } from '../models/customerCenterTypes/customerTopUp'
import { CustomerCreditDataTypes } from '../models/customerCenterTypes/customerCreditTypes'
import { CustomerPointTypes } from '../models/customerCenterTypes/customerPointTypes'
import { WechatTypes, WechatUpdateTypes } from '../models/customerCenterTypes/wechatTypes'
import { CouponCarteReqTypes, CouponDetailTypes } from '../models/coupon/couponListTypes'
import { PaymentListDetailTypes } from '../models/paymentTypes'
import api from '../config/api'
import {
  CashBoxCountReqTypes,
  CashBoxInfoTypes,
  CashBoxLogTypes,
  CashBoxQueryReqTypes,
  CashBoxQueryResTypes,
  CashBoxStatus,
  CashBoxStores
} from '../models/cashBoxTypes'
import { SiteInfoTypes } from '../models/siteInfoTypes'
import { Shipments, ShippingTerms } from '../models/shipping/shipment'

const axiosInstance = axios.create({
  baseURL: api.uri,

  transformResponse: [
    function (data) {
      try {
        return jsonBig.parse(data)
      } catch (err) {
        return {
          data
        }
      }
    }
  ]
})

axiosInstance.interceptors.request.use((config) => {
    if (localStorage.getItem('lang') === 'zh-CN') {
      config.url = `${config.url}?lang=zh-CN`
    } else {
      config.url = `${config.url}?lang=en-NZ`
    }
  if (!config.url.includes('login')) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }
  }
  return config
})

axiosInstance.interceptors.response.use(
  (res) => {
    return res
  },
  (error: AxiosError) => {
    const errorInfo = error.response
    if (errorInfo == undefined)
    {
      message.error('HTTP response is undefined');
      return
    }

    if (errorInfo?.config.url.includes('login')) {
      message.error(errorInfo.data.data)
    }
    if (!errorInfo?.data.data) {
      console.log(errorInfo)
      switch (errorInfo?.status) {
        case 400:
          message.error('Request error (400)')
          break
        case 401:
          localStorage.removeItem('token')
          localStorage.removeItem('refreshToken')
          localStorage.removeItem('tokenExpireAt')
          sessionStorage.removeItem('manager')
          sessionStorage.removeItem('storeId')
          sessionStorage.removeItem('storeName')
          sessionStorage.removeItem('customerGroup')
          message.error('Request error (401)')
          setTimeout(() => {
            window.location.reload()
          }, 1000)
          break
        default:
          message.error('HTTP Error: ' + errorInfo.status + ' ' + errorInfo.statusText)
          break
      }
    }

    return new Promise(() => { })
  }
)

const responseBody = <T>(response: AxiosResponse<T>) => response ? response?.data : { success: false, message: 'HTTP response data is null', data: null}

const request = {
  get: <T>(url: string, params?: {}) => axiosInstance.get<T>(createUrl(url, params)).then(responseBody),
  post: <T>(url: string, body: {}) => axiosInstance.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axiosInstance.put<T>(url, body).then(responseBody),
  delete: <T>(url: string) => axiosInstance.delete<T>(url).then(responseBody)
}

export const login = async (param: LoginRequest) => {
  const res = await request.post<CommonResTypes<LoginResTypes>>('/pos/auth/login', param)
  return res
}

export const refreshToken = async (param: { refreshToken: string }) => {
  const res = await request.post<CommonResTypes<LoginResTypes>>('/pos/auth/refreshToken', param)
  return res
}

export const logout = async (param: CashBoxCountReqTypes) => {
  const res = await request.post<CommonResTypes<CashBoxInfoTypes>>('/pos/auth/logout', param)
  return res
}

export const getSiteInfo = async () => {
  const res = await request.get<CommonResTypes<SiteInfoTypes>>('/website/siteinfo')
  return res
}

export const updateAccountInfo = async (param: UpdateAccountRequest) => {
  const res = await request.put<CommonResTypes<Account>>('/pos/auth/info', param)
  return res
}

export const getAccountInfo = async () => {
  const res = await request.get<CommonResTypes<Account>>('/pos/auth/info')
  return res
}

export const cashBoxInfo = async () => {
  const res = await request.get<CommonResTypes<CashBoxInfoTypes>>('/pos/cashbox/info')
  return res
}

export const cashBoxStoresInfo = async () => {
  const res = await request.get<CommonResTypes<CashBoxStores[]>>('/pos/cashbox/codes/stores')
  return res
}

export const cashBoxStatus = async () => {
  const res = await request.get<CommonResTypes<CashBoxStatus[]>>('/pos/cashbox/codes/status')
  return res
}

export const cashBoxLog = async (params: QueryReqTypes, id: number) => {
  const res = await request.post<CommonResTypes<CashBoxLogTypes>>(`/pos/cashbox/${id}/log`, params)
  return res
}

export const cashBoxCount = async (params: CashBoxCountReqTypes) => {
  const res = await request.post<CommonResTypes<CashBoxInfoTypes>>('/pos/cashbox/count', params)
  return res
}

export const cashBoxDeposit = async (params: CashBoxCountReqTypes) => {
  const res = await request.post<CommonResTypes<CashBoxInfoTypes>>('/pos/cashbox/deposit', params)
  return res
}

export const cashBoxWithdraw = async (params: CashBoxCountReqTypes) => {
  const res = await request.post<CommonResTypes<CashBoxInfoTypes>>('/pos/cashbox/withdraw', params)
  return res
}

export const changePassword = async (param: { currentPassword: string; newPassword: string }) => {
  const res = await request.post<CommonResTypes<boolean>>('/pos/auth/password', param)
  return res
}

// export const getStoreInfo = async () => {
//   const res = await request.get<CommonResTypes<ManagerStoreTypes[]>>(createUrl('/pos/managerstore/permission/webpos'))
//   return res
// }

// product types
export const getProducts = async (param: QueryReqTypes) => {
  const res = await request.post<CommonResTypes<ProductQueryTypes>>(createUrl('/pos/catalogue/product/query'), param)
  return res
}

export const groupedProduct = async (id: number) => {
  const res = await request.get<CommonResTypes<ProductInfo[]>>(`/pos/catalogue/product/${id}/grouped`)
  return res
}

export const bundledProduct = async (id: number) => {
  const res = await request.get<CommonResTypes<BundledProductResTypes[]>>(`/pos/catalogue/product/${id}/bundled`)
  return res
}

export const productSummary = async (id: number, params: { customerId: number }) => {
  const res = await request.post<CommonResTypes<ProductSummaryTypes>>(`/pos/catalogue/product/${id}`, params)
  return res
}

export const getProductInventory = async (params: QueryReqTypes) => {
  const res = await request.post<CommonResTypes<ProductInventoryResTypes>>('/inventory/query', params)
  return res
}

//cart
export const putItemToCart = async (param: PutShoppingCartReq) => {
  const res = await request.post<CommonResTypes<ShoppingCartItemType>>(createUrl('/pos/shoppingcart'), param)
  return res
}

export const getItemsFromCart = async (param: GetShoppingCartReq) => {
  const res = await request.post<CommonResTypes<ShoppingCartItemType[]>>('/pos/shoppingcart/list', param)
  return res
}

export const getCustomerGroup = async () => {
  const res = await request.get<CommonResTypes<CustomerGroupTypes[]>>(`/pos/customergroup`)
  return res
}

export const checkout = async (param: Partial<CheckoutReqTypes>) => {
  const res = await request.post<CommonResTypes<CheckoutResTypes>>(createUrl('/pos/shoppingcart/checkout'), param)
  return res
}

export const getCustomerInfo = async (param: number) => {
  const res = await request.get<CommonResTypes<CustomerTypes>>(`/pos/customer/${param}`)
  return res
}

export const deleteAllItemFromCart = async (param: { customerId: number; storeId: number }) => {
  const res = await request.post<CommonResTypes<boolean>>('/pos/shoppingcart/removeall', param)
  return res
}

export const deleteItemFromCart = async (id: string) => {
  const res = await request.delete<CommonResTypes<boolean>>(`/pos/shoppingcart/${id}`)
  return res
}

export const updateItemQuantity = async (param: { quantity: number; id: string }) => {
  const res = await request.put<CommonResTypes<ShoppingCartItemType>>(`/pos/shoppingcart/${param.id}`, param)
  return res
}

export const getCustomers = async (param: QueryReqTypes) => {
  const res = await request.post<CommonResTypes<CustomerQueryTypes>>('/pos/customer/query', param)
  return res
}

export const insertCustomerToGroup = async (param: CustomerCreateTypes) => {
  const res = await request.post<CommonResTypes<CustomerTypes>>('/pos/customer', param)
  return res
}

// address
export const getShipmentsList = async () => {
  const res = await request.get<CommonResTypes<ShipmentListType[]>>('/website/shipments')
  return res
}

export const getShipmentsFromTerm = async (params: { term: string }) => {
  const res = await request.get<CommonResTypes<Shipments[]>>(`/website/shipments/${params.term}`)
  return res
}

export const getShippingTerms = async () => {
  const res = await request.get<CommonResTypes<ShippingTerms[]>>('/website/shipments/codes/shippingterm')
  return res
}

export const addCustomerShippingAddress = async (param: CustomerCreateAddressTypes) => {
  const res = await request.post<CommonResTypes<CustomerCreateAddressResTypes>>('/pos/shippingaddress', param)
  return res
}

export const deleteCustomerAddress = async (id: string) => {
  const res = await request.delete<CommonResTypes<boolean>>(`/pos/shippingaddress/${id}`)
  return res
}

export const shippingAddress = async (param: QueryReqTypes) => {
  const res = await request.post<CommonResTypes<CommonQueryTypes<CustomerCreateAddressResTypes>>>('/pos/shippingaddress/query', param)
  return res
}

export const updateCustomerShippingAddress = async (id: string, param: CustomerCreateAddressTypes) => {
  const res = await request.put<CommonResTypes<CustomerCreateAddressResTypes>>(`/pos/shippingaddress/${id}`, param)
  return res
}

// image
export const getImageIdFromDb = async (param: { q: string }) => {
  const res = await request.get<any>('/upload', param)
  return res
}
// shipment area
export const getCustomerAddressArea = async () => {
  const res = await request.get<CommonResTypes<CustomerGroupTypes[]>>(`/website/shipmentareas`)
  return res
}

export const getShipmentForShippingTerm = async (term: string) => {
  const res = await request.get<
    CommonResTypes<{ id: number; groupId: number; groupName: string; areaCode: string; areaName: string; shippingTerm: string }[]>
  >(`/website/shipments/${term}`)
  return res
}

export const getShipmentMethod = async (param: ShipmentMethodReq) => {
  const res = await request.post<CommonResTypes<ShipmentMethodTypes[]>>(`/website/shipments/method`, param)
  return res
}

export const updateShipmentMethod = async ({ id, storeId, methodId, comment }) => {
  const res = await request.put<CommonResTypes<{ data: string; code: number; success: boolean; message: string }>>(
    `/pos/order/${id}/picking`,
    { storeId, methodId, comment }
  )
  return res
}

// send message
export const sendMessageToCustomer = async (
  param: {
    messageType: string
    messageTemplate: string
    receiver: string
    comment: string
  },
  id: number
) => {
  const res = await request.post<CommonResTypes<string>>(`/pos/order/${id}/messaging`, param)
  return res
}

// currency
export const getCurrency = async () => {
  const res = await request.get<
    CommonResTypes<
      {
        from: string
        to: string
        rate: number
      }[]
    >
  >('/website/currency')
  return res
}

//shipping
export const getShipmentFee = async (id: number, param: any) => {
  const res = await request.post<CommonResTypes<number>>(`/pos/shoppingcart/shipmentfee/${id}`, param)
  return res
}

export const shipmentMethod = async () => {
  const res = await request.get<CommonResTypes<ShippingMethodTypes[]>>(`/website/shipmentmethod`)
  return res
}
export const getCustomerShipmentMethod = async (area: string) => {
  const res = await request.get<CommonResTypes<ShippingItems[]>>(`/website/shipmentmethod/${area}`)
  return res
}

// make order
export const placeOrder = async (param: PlaceOrderReqTypes) => {
  const res = await request.post<CommonResTypes<PlaceOrderResTypes>>(`/pos/shoppingcart/placeorder`, param)
  return res
}

export const makePayment = async (param: OrderPaymentReqTypes, id: string) => {
  const res = await request.post<CommonResTypes<string>>(`/pos/order/${id}/payment`, param)
  return res
}

export const getCashCodes = async () => {
  const res = await request.get<CommonResTypes<{ value: number; name: string; image: string }[]>>('/pos/order/codes/cashes')
  return res
}

// order history

export const orderDetail = async (id: string) => {
  const res = await request.get<CommonResTypes<OrderDetailResTypes>>(`/pos/order/${id}`)
  return res
}

export const orderHistory = async (param: Partial<QueryReqTypes>) => {
  const res = await request.post<CommonResTypes<CommonQueryTypes<OrderDetailResTypes>>>('/pos/order/query', param)
  return res
}

export const updateShippingAddress = async (
  param: {
    shippingAddressId: string
    comment: string
  },
  id: string
) => {
  const res = await request.put<CommonResTypes<string>>(`/pos/order/${id}/shipping`, param)
  return res
}

export const resSelfPicking = async (param: OrderSelfPickingReqTypes, id: string) => {
  const res = await request.post<CommonResTypes<OrderSelfPickingOrShipmentItem[]>>(`/pos/order/${id}/selfpickup`, param)
  return res
}

export const cancelOrderRes = async (id: string) => {
  const res = await request.get<CommonResTypes<string>>(`/pos/order/${id}/cancel`)
  return res
}

export const orderProcessingRes = async (id: string) => {
  const res = await request.get<CommonResTypes<string>>(`/pos/order/${id}/processing`)
  return res
}

export const orderShipmentRes = async (params: AddOrderShipmentReqTypes, id: string) => {
  const res = await request.post<CommonResTypes<OrderSelfPickingOrShipmentItem>>(`/pos/order/${id}/shipment`, params)
  return res
}

export const getCourierInfo = async () => {
  const res = await request.get<CommonResTypes<OrderCourierTypes[]>>(`/website/courier`)
  return res
}

export const updateShipmentInfo = async (param: UpdateOrderShipmentReqTypes, id: string, shipmentId: string) => {
  return await request.put<CommonResTypes<string>>(`/pos/order/${id}/shipment/${shipmentId}`, param)
}
export const parcelShipment2 = async (param: ParcelShipmentReqTypes, id: string, shipmentId: string) => {
  return await request.post<CommonResTypes<string>>(`/pos/order/${id}/shipment/${shipmentId}/parcel`, param)
}
export const parcelShipment = async (param: ParcelOrderShipmentReqTypes, id: string, shipmentId: string) => {
  param.code = "parcel"
  return await request.post<CommonResTypes<string>>(`/pos/order/${id}/shipment/${shipmentId}`, param)
}
export const completeShipment = async (param: CompleteOrderShipmentReqTypes, id: string, shipmentId: string) => {
  param.code = "complete"
  return await request.post<CommonResTypes<string>>(`/pos/order/${id}/shipment/${shipmentId}`, param)
}
export const deleteShipmentInfo = async (param: DeleteOrderShipmentReqTypes, id: string, shipmentId: string) => {
  param.code = "delete"
  return await request.post<CommonResTypes<string>>(`/pos/order/${id}/shipment/${shipmentId}`, param)
}

export const orderRefund = async (
  params: {
    refundAmount: number
    refundTo: string
    comment: string
  },
  id: string
) => {
  const res = await request.post<CommonResTypes<string>>(`/pos/order/${id}/refund`, params)
  return res
}

export const addCommentToPayment = async (
  params: {
    comment: string
  },
  id: string
) => {
  const res = await request.post<CommonResTypes<string>>(`/pos/orderpayment/${id}/comment`, params)
  return res
}

export const resLogging = async (
  param: {
    visible: number
    notify: boolean
    comment: string
  },
  id: string
) => {
  const res = await request.post<CommonResTypes<string>>(`/pos/order/${id}/logging`, param)
  return res
}

// customer center
export const depositApi = async (param: CustomerTopUpReqTypes, id: number) => {
  const res = await request.post<CommonResTypes<string>>(`/pos/customer/${id}/topup`, param)
  return res
}

export const getCreditHistory = async (params: QueryReqTypes) => {
  const res = await request.post<CommonResTypes<CommonQueryTypes<CustomerCreditDataTypes>>>(`/pos/credittransaction/query`, params)
  return res
}

export const getPaymentReportListDaily = async (params: PaymentReportQueryRequestType) => {
  const res = await request.post<CommonResTypes<PaymentReportDailyDataTypes[]>>(`/pos/report/payment/daily`, params)
  return res
}
export const getPaymentReportListWeekly = async (params: PaymentReportQueryRequestType) => {
  const res = await request.post<CommonResTypes<PaymentReportWeeklyDataTypes[]>>(`/pos/report/payment/weekly`, params)
  return res
}
export const getPaymentReportListMonthly = async (params: PaymentReportQueryRequestType) => {
  const res = await request.post<CommonResTypes<PaymentReportMonthlyDataTypes[]>>(`/pos/report/payment/monthly`, params)
  return res
}
export const paymentReportExport = async (params: ReportExportRequestType) => {
  const res = await request.post<CommonResTypes<{ name: string; mimeType: string; url: string; createTime: string }>>(
    `/pos/report/payment/export`,
    params
  )
  return res
}
export const productReportExport = async (params: ReportExportRequestType) => {
  const res = await request.post<CommonResTypes<{ name: string; mimeType: string; url: string; createTime: string }>>(
    `/pos/report/product/export`,
    params
  )
  return res
}
export const orderReportExport = async (params: ReportExportRequestType) => {
  const res = await request.post<CommonResTypes<{ name: string; mimeType: string; url: string; createTime: string }>>(
    `/pos/report/order/export`,
    params
  )
  return res
}
export const getProductsReportListDaily = async (params: PaymentReportQueryRequestType) => {
  const res = await request.post<CommonResTypes<PaymentReportDailyDataTypes[]>>(`/pos/report/product/daily`, params)
  return res
}
export const getProductsReportListWeekly = async (params: PaymentReportQueryRequestType) => {
  const res = await request.post<CommonResTypes<PaymentReportWeeklyDataTypes[]>>(`/pos/report/product/weekly`, params)
  return res
}
export const getProductsReportListMonthly = async (params: PaymentReportQueryRequestType) => {
  const res = await request.post<CommonResTypes<PaymentReportMonthlyDataTypes[]>>(`/pos/report/product/monthly`, params)
  return res
}
export const getOrderReportListDaily = async (params: PaymentReportQueryRequestType) => {
  const res = await request.post<CommonResTypes<OrderReportDailyDataTypes[]>>(`/pos/report/order/daily`, params)
  return res
}
export const getOrderReportListWeekly = async (params: PaymentReportQueryRequestType) => {
  const res = await request.post<CommonResTypes<OrderReportWeeklyDataTypes[]>>(`/pos/report/order/weekly`, params)
  return res
}
export const getOrderReportListMonthly = async (params: PaymentReportQueryRequestType) => {
  const res = await request.post<CommonResTypes<OrderReportMonthlyDataTypes[]>>(`/pos/report/order/monthly`, params)
  return res
}
export const getCustomerDetails = async (id: string) => {
  const res = await request.get<CommonResTypes<CustomerTypes>>(`/pos/customer/${id}`)
  return res
}

export const getPointHistory = async (params: QueryReqTypes) => {
  const res = await request.post<CommonResTypes<CommonQueryTypes<CustomerPointTypes>>>(`/pos/pointtransaction/query`, params)
  return res
}

export const getCashBoxHistory = async (params: CashBoxQueryReqTypes) => {
  const res = await request.post<CommonResTypes<CommonQueryTypes<CashBoxQueryResTypes>>>(`/pos/cashbox/query`, params)
  return res
}

export const updateCustomerInfo = async (params: CustomerTypes, id: number) => {
  const res = await request.put<CommonResTypes<CustomerTypes>>(`/pos/customer/${id}`, params)
  return res
}

export const updateCustomerPassword = async (
  params: {
    password: string
    notify: boolean
    managerPassword: string
  },
  id: number
) => {
  const res = await request.post<CommonResTypes<string>>(`/pos/customer/${id}/password`, params)
  return res
}

export const customerOrderQuery = async (params: QueryReqTypes) => {
  const res = await request.post<CommonResTypes<CommonQueryTypes<OrderDetailResTypes>>>(`/pos/order/query`, params)
  return res
}

export const getWechatOAuth = async () => {
  const res = await request.get<CommonResTypes<{ appID: string; state: string }>>('/OAuth2Login/WeChatAuth')
  return res
}

export const getWechatInfo = async (id: number) => {
  const res = await request.get<CommonResTypes<WechatTypes>>(`/pos/customer/${id}/wechat`)
  return res
}

export const updateWechatInfo = async (params: WechatUpdateTypes, id: number) => {
  const res = await request.put<CommonResTypes<boolean>>(`/pos/customer/${id}/wechat`, params)
  return res
}

// coupon list
export const createCoupon = async (params: CouponCarteReqTypes) => {
  const res = await request.post<CommonResTypes<CouponDetailTypes>>('/pos/couponcode', params)
  return res
}

export const validateCouponCode = async (params: { code: string }) => {
  const res = await request.get<CommonResTypes<CouponDetailTypes>>(`/pos/couponcode/validator/${params.code}`)
  return res
}

export const getCouponCodeList = async (params: QueryReqTypes) => {
  const res = await request.post<CommonResTypes<CommonQueryTypes<CouponDetailTypes>>>(`/pos/coupon/query`, params)
  return res
}

export const getCouponDetail = async (id: string) => {
  const res = await request.get<CommonResTypes<CouponDetailTypes>>(`/pos/couponcode/${id}`)
  return res
}

export const exportCouponListAsExcel = async (params: QueryReqTypes) => {
  const res = await request.post<
    CommonResTypes<{
      name: string
      mimeType: string
      url: string
      createTime: string
    }>
  >(`/pos/couponcode/export`, params)
  return res
}

export const getCouponQuery = async (params: QueryReqTypes) => {
  const res = await request.post<CommonResTypes<CommonQueryTypes<CouponDetailTypes>>>(`/pos/couponcode/query`, params)
  return res
}

// payment list

export const exportPaymentAsExcel = async (params: QueryReqTypes) => {
  const res = await request.post<
    CommonResTypes<{
      name: string
      mimeType: string
      url: string
      createTime: string
    }>
  >(`/pos/orderpayment/export`, params)
  return res
}

export const getPaymentHistory = async (params: QueryReqTypes) => {
  const res = await request.post<CommonResTypes<CommonQueryTypes<PaymentListDetailTypes>>>(`/pos/orderpayment/query`, params)
  return res
}
// point list

export const exportPointAsExcel = async (params: QueryReqTypes) => {
  const res = await request.post<
    CommonResTypes<{
      name: string
      mimeType: string
      url: string
      createTime: string
    }>
  >(`/pos/pointtransaction/export`, params)
  return res
}

export const exportCreditAsExcel = async (params: QueryReqTypes) => {
  const res = await request.post<
    CommonResTypes<{
      name: string
      mimeType: string
      url: string
      createTime: string
    }>
  >(`/pos/credittransaction/export`, params)
  return res
}

export const exportCashBoxAsExcel = async (params: CashBoxQueryReqTypes) => {
  const res = await request.post<
    CommonResTypes<{
      name: string
      mimeType: string
      url: string
      createTime: string
    }>
  >(`/pos/cashbox/export`, params)
  return res
}
