import React from 'react'
import PaymentSuccess from '../components/payment/paymentSuccess'

const paymentSuccess = () => {
  return (
    <PaymentSuccess/>
  )
}

export default paymentSuccess
