import { Form, Input, Button, Typography, message } from 'antd'
import React, {  useState } from 'react'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { RouteComponentProps } from 'react-router-dom'
import { LoginRequest } from '../models/loginTypes'
import { login } from '../api/response'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from '../redux/reduxHooks'
import { setLanguage, lang } from '../redux/reducers/homePage_reducer'

const { Title } = Typography

const StyledTitle = styled(Title)`
  text-align: center;
`

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
`
const Content = styled.div`
  padding: 70px 100px;
  width: 40%;
  border-radius: 5px;
  box-shadow: 0 1px 3px gray;
  text-align: center;
  position: relative;
`
const Login = function (props: RouteComponentProps) {
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()

  const onFinish = async (values: LoginRequest) => {
    setLoading(true)
    const { username, password, cashBox } = values
    const param = { username, password, cashBox }
    const res = await login({ ...param, expireMinutes: 0 })

    if (res.success) {
      localStorage.setItem('token', res.data.token)
      localStorage.setItem('tokenExpireAt', dayjs().add(res.data.expireTime, 'second') + '')
      localStorage.setItem('refreshToken', res.data.refreshToken)
      sessionStorage.setItem('manager', JSON.stringify({ ...res.data.store, ...res.data.account, ...res.data.cashBox }))
      sessionStorage.setItem('storeId', res.data.store.id.toString())
      sessionStorage.setItem('storeName', res.data.store.name)
      setLoading(false)
      localStorage.setItem('lang', res.data.account.lang)
      dispatch(setLanguage({ lang: res.data.account.lang }))
      message.success('success')
      const RedirectUrl = '/'
      props.history.push(RedirectUrl, { from: window.location.pathname })
    } else {
      message.error(res.message)
      setLoading(false)
    }
  }

  return (
    <Container>
      <Content>
        <>
          <Form name='basic' layout='horizontal' className='login-form' onFinish={onFinish}>
            <StyledTitle>Cloud POS</StyledTitle>
            <Form.Item
              name='cashBox'
              rules={[
                {
                  required: true,
                  message: 'Please input cash box id!'
                }
              ]}
            >
              <Input placeholder='Please enter your cash box' prefix={<UserOutlined />} />
            </Form.Item>
            <Form.Item
              name='username'
              rules={[
                {
                  required: true,
                  message: 'Please input your username!'
                }
              ]}
            >
              <Input className='username' placeholder='Please input your username!' prefix={<UserOutlined />} />
            </Form.Item>

            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!'
                }
              ]}
            >
              <Input.Password placeholder='Please enter your Password' prefix={<LockOutlined />} />
            </Form.Item>

            <Form.Item>
              <Button type='primary' htmlType='submit' block loading={loading}>
                Sign In
              </Button>
            </Form.Item>
          </Form>
        </>
      </Content>
    </Container>
  )
}

export default Login
