import { Button, Divider, Modal } from 'antd'
import React from 'react'
import { FooterContainer, HeaderText } from './DialogCard.style'
import { FormattedMessage } from 'react-intl'

export interface DialogProps {
  open: boolean
  handleOk: (edit?) => void
  handleCancel: () => void
  headerText: string
  width?: string
  loading?: boolean
}
const DialogCard: React.FC<DialogProps> = ({ children, ...restProps }) => {
  return (
    <Modal
      title={<HeaderText>{restProps.headerText}</HeaderText>}
      closable={false}
      open={restProps.open}
      width={'50rem'}
      footer={
        <FooterContainer>
          <Button onClick={restProps.handleCancel} type='default' style={{ color: 'orange' }}>
            <FormattedMessage id='cancel' />
          </Button>
          <Button type='primary' onClick={restProps.handleOk} loading={restProps.loading}>
            <FormattedMessage id='confirm' />
          </Button>
        </FooterContainer>
      }
    >
      <Divider style={{ margin: '0.5rem 0 1rem 0', borderColor: 'black' }} />
      {children}
      <Divider style={{ margin: '0.5rem 0 0.5rem 0', borderColor: 'black' }} />
    </Modal>
  )
}

export default DialogCard
