import React, { useEffect } from 'react'
import MenuLayout from '../components/Layout/menuLayout'
import { setMenuDisplay } from '../redux/reducers/menu_reducer';
import { useAppDispatch } from '../redux/reduxHooks';
import CashBoxHistory from '../components/cashBoxHistory';

const CashBoxHistoryPage = (props) => {
  const  dispatch  = useAppDispatch()
  const { id } = props.match.params

  useEffect(() => {
    dispatch(setMenuDisplay({
      menu: false
    }))
  }, [])
  return (
    <div>
      <MenuLayout/>
      <CashBoxHistory id={id}/>
    </div>
  )
}

export default CashBoxHistoryPage
