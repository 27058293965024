import React, { useEffect } from 'react'
import MenuLayout from '../components/Layout/menuLayout'
import { setMenuDisplay } from '../redux/reducers/menu_reducer';
import { useAppDispatch } from '../redux/reduxHooks';
import CashBoxList from '../components/cashBoxList';

const CashBoxListPage = (props) => {
  const  dispatch  = useAppDispatch()
  useEffect(() => {
    dispatch(setMenuDisplay({
      menu: false
    }))
  }, [])
  return (
    <div>
      <MenuLayout/>
      <CashBoxList/>
    </div>
  )
}

export default CashBoxListPage
