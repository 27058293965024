import React from 'react'
import { Modal, message, Upload } from 'antd'
// eslint-disable-next-line no-unused-vars
import { InboxOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import api from '../../config/api'
import { FormattedMessage } from 'react-intl'

const UploadStyle = styled.div`
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    margin: 0;
    height: 100%;
  }
  .ant-upload.ant-upload-select-picture-card > .ant-upload > span {
    width: 100%;
    height: 100%;
  }
  .ant-upload-list-picture-card-container {
    width: 100%;
    height: 150px;
  }
  .ant-upload-list-picture-card-container img {
    object-fit: cover;
  }
`

const UploadInner = styled.div`
  .ant-upload.ant-upload-drag .ant-upload-drag-container {
    display: table-cell;
    vertical-align: middle;
  }
`
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

interface Props {
  number: number
  fileName?: string
  hintName?: string
}
class PicturesWall extends React.Component<Props> {
  state = {
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: []
  }

  handleCancel = () => this.setState({ previewVisible: false })

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    })
  }

  getFileName = () => {
    return this.state.fileList
  }

  setFileList = (imgArr) => {
    const fileList = []
    imgArr.forEach((element, index) => {
      if (element) fileList.push({ uid: -index + '', name: element, url: `${api.cdn}/${element}?x-oss-process=style/640w` })
    })
    this.setState({ fileList })
  }

  render() {
    const { previewVisible, previewImage, fileList, previewTitle } = this.state

    /* const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    ) */
    return (
      <UploadStyle>
        <Upload
          action={`${api.uri}/upload/${this.props.fileName || 'venus-screenshot'}`}
          listType='picture-card'
          headers={{
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }}
          fileList={fileList}
          name='files'
          onPreview={this.handlePreview}
          onChange={({ fileList: newFileList, file }) => {
            file = JSON.parse(JSON.stringify(file))
            if (file.status === 'done') {
              if (file.response.success) {
                message.success(file.response.message)
              } else {
                message.error(file.response.message)
              }
              newFileList[fileList.length - 1].name = file.response.data
            }
            if (file.status === 'removed') {
              newFileList = fileList.filter((item) => item.status !== 'removed')
            }
            this.setState({ fileList: newFileList })
          }}
        >
          {fileList.length >= this.props.number ? null : (
            <UploadInner>
              <p className='ant-upload-drag-icon'>
                <InboxOutlined style={{ color: '#40a9ff', fontSize: '2rem' }} />
              </p>
              <p style={{ fontSize: '12px' }}>{this.props.hintName ?? <FormattedMessage id='upload' />}</p>
            </UploadInner>
          )}
        </Upload>
        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={this.handleCancel}>
          <img alt='screenshot' style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </UploadStyle>
    )
  }
}

export default PicturesWall
