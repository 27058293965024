import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { message } from 'antd'
import {
  deleteAllItemFromCart,
  deleteItemFromCart,
  getCurrency,
  getItemsFromCart,
  putItemToCart,
  updateItemQuantity
} from '../../api/response'
import { GetShoppingCartReq, PutShoppingCartReq, ShoppingCartItemType } from '../../models/shoppingCartItem'
import { RootState } from '../store'
import { ShipmentMethodTypes } from '../../models/shipping/shippingMethod'
import { CouponDetailTypes } from '../../models/coupon/couponListTypes'

type initialStateTypes = {
  cartItems: ShoppingCartItemType[]
  count: number
  cartChanged: boolean
  totalPrice: number
  itemId: number
  storeId: number
  currencyRate: number
  subTotalPrice: number
  shipmentId: number
  chosenShipmentMethod: ShipmentMethodTypes
  couponInfo: { code: string; details: CouponDetailTypes }
  adjustmentInfo: { amount: number; note: string }
}

const initialState: initialStateTypes = {
  cartItems: [],
  count: 0,
  cartChanged: false,
  totalPrice: 0,
  itemId: 0,
  storeId: null,
  currencyRate: 0,
  subTotalPrice: 0,
  shipmentId: 0,
  chosenShipmentMethod: null,
  couponInfo: { code: '', details: null },
  adjustmentInfo: { amount: 0, note: '' }
}

export const incrementShoppingCart = createAsyncThunk('cart/addItemToCart', async (param: PutShoppingCartReq, { dispatch }) => {
  const response = await putItemToCart(param)
  // The value we return becomes the `fulfilled` action payload
  if (response.success) {
    dispatch(setCartIncrease({ num: 1 }))
  } else {
    message.error(response.message)
  }
})

export const updateShoppingCartQuantity = createAsyncThunk(
  'cart/updateShoppingCartQuantity',
  async (param: { quantity: number; id: string }, { dispatch }) => {
    const response = await updateItemQuantity(param)
    // The value we return becomes the `fulfilled` action payload
    if (response.success) {
      dispatch(setCartIncrease({ num: 1 }))
    } else {
      message.error(response.message)
    }
  }
)

export const deleteAllShoppingCart = createAsyncThunk(
  'cart/deleteAllItemFromCart',
  async (param: { customerId: number; storeId: number }, { dispatch }) => {
    const response = await deleteAllItemFromCart(param)
    // The value we return becomes the `fulfilled` action payload
    if (response.success) {
      dispatch(setCartIncrease({ num: 1 }))
      dispatch(setTotalPrice({ totalPrice: 0 }))
    } else {
      message.error(response.message)
    }
  }
)

export const deleteOneItemFromCart = createAsyncThunk('cart/deleteOneItemFromCart', async (param: string, { dispatch }) => {
  const response = await deleteItemFromCart(param)
  // The value we return becomes the `fulfilled` action payload
  if (response.success) {
    dispatch(setCartIncrease({ num: 1 }))
  }
})

export const getAllShoppingCartItems = createAsyncThunk('cart/getAllShoppingCartItems', async (param: GetShoppingCartReq) => {
  const response = await getItemsFromCart(param)
  // The value we return becomes the `fulfilled` action payload
  return response
})

const getTotalPrice = (cartItems: ShoppingCartItemType[]) => {
  let total = 0
  cartItems.forEach((item) => {
    total += item.finalPrice * item.quantity
  })
  return total
}

export const getCurrencyRate = createAsyncThunk('cart/getCurrency', async (param, { dispatch }) => {
  const res = await getCurrency()
  if (res.success) {
    const currencyRate = res.data.find((item) => item.from === 'nzd' && item.to === 'rmb')
    sessionStorage.setItem('exchangeRate', currencyRate.rate.toString())
    dispatch(setCurrencyRate({ rate: currencyRate.rate }))
  } else {
    message.error(res.message)
  }
  // The value we return becomes the `fulfilled` action payload
})

export const shoppingCartSlice = createSlice({
  name: 'shoppingCart',
  initialState,
  reducers: {
    setCartItems: (state, action: PayloadAction<{ cartItems: ShoppingCartItemType[] }>) => {
      state.cartItems = action.payload.cartItems
    },
    setCartIncrease: (state, action: PayloadAction<{ num: number }>) => {
      state.count = state.count + action.payload.num
    },
    setCartChange: (state, action: PayloadAction<{ cartChanged: boolean }>) => {
      state.cartChanged = action.payload.cartChanged
    },
    setTotalPrice: (state, action: PayloadAction<{ totalPrice: number }>) => {
      state.totalPrice = action.payload.totalPrice
    },
    setSubTotalPrice: (state, action: PayloadAction<{ subTotalPrice: number }>) => {
      state.subTotalPrice = action.payload.subTotalPrice
    },
    setItem: (state, action: PayloadAction<{ itemId: number; storeId: number }>) => {
      state.itemId = action.payload.itemId
      state.storeId = action.payload.storeId
    },
    setCurrencyRate: (state, action: PayloadAction<{ rate: number }>) => {
      state.currencyRate = action.payload.rate
    },
    setShipmentId: (state, action: PayloadAction<{ id: number }>) => {
      state.shipmentId = action.payload.id
    },
    setShipmentMethodInfo: (state, action: PayloadAction<{ info: ShipmentMethodTypes }>) => {
      state.chosenShipmentMethod = action.payload.info
    },
    setCouponInfo: (state, action: PayloadAction<{ info: { code: string; details: CouponDetailTypes } }>) => {
      state.couponInfo = action.payload.info
    },
    setAdjustmentInfo: (state, action: PayloadAction<{ info: { amount: number; note: string } }>) => {
      state.adjustmentInfo = action.payload.info
    },
    resetAllAddInfo: (state, action: PayloadAction) => {
      state.adjustmentInfo = { amount: 0, note: '' }
      state.couponInfo = { code: '', details: null }
      state.chosenShipmentMethod = null
    },
    setAllAddInfo: (
      state,
      action: PayloadAction<{
        adjustment: { amount: number; note: string }
        coupon: { code: string; details: CouponDetailTypes }
        shipment: ShipmentMethodTypes
      }>
    ) => {
      state.adjustmentInfo = action.payload.adjustment
      state.couponInfo = action.payload.coupon
      state.chosenShipmentMethod = action.payload.shipment
    }
  },
  extraReducers: (build) => {
    build.addCase(getAllShoppingCartItems.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.cartItems = action.payload.data
        state.totalPrice = getTotalPrice(action.payload.data)
      } else {
        state.cartItems = []
        state.totalPrice = 0
      }
    })
  }
})

export const {
  setCartItems,
  setCartIncrease,
  setCartChange,
  setTotalPrice,
  setItem,
  setCurrencyRate,
  setSubTotalPrice,
  setShipmentId,
  setShipmentMethodInfo,
  setCouponInfo,
  setAdjustmentInfo,
  resetAllAddInfo,
  setAllAddInfo
} = shoppingCartSlice.actions //= connect(dispatch)

export const shoppingCartData = (state: RootState) => state.shoppingCartInfo // = connect(state)
export const getShipmentId = (state: RootState) => state.shoppingCartInfo.shipmentId // = connect(state)
export const getShoppingCartItems = (state: RootState) => state.shoppingCartInfo.cartItems // = connect(state)

export default shoppingCartSlice.reducer
