/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo, ChangeEvent } from 'react'
import { Input, Button, Radio, List, Pagination, message, Modal, Image } from 'antd'
import { DeleteOutlined, PlusCircleFilled, SearchOutlined } from '@ant-design/icons'
import { addCustomerShippingAddress, deleteCustomerAddress, shippingAddress, updateCustomerShippingAddress } from '../../api/response'
import CreateForm from '../addressForms/createForm'
import { debounce } from 'lodash'
import { CustomerCreateAddressResTypes } from '../../models/customerTypes'
import { useAppDispatch } from '../../redux/reduxHooks'
import { setChoosedAddress } from '../../redux/reducers/orders_reducer'
import api from '../../config/api'

const CustomerAddress = (props) => {
  const {
    orderDetails,
    addressId = (id) => { },
    chooseCustomerId,
    placeOrderCustomerid,
    currentAddressId,
    areaCode,
    chosenAreaCode,
    chosenGroupId,
    chosenAreaName,
    chosenGroupName,
    showEditDialog,
    setShowEditDialog,
    setHasAddressData
  } = props
  const customerId = orderDetails?.customerId || chooseCustomerId || placeOrderCustomerid
  const [shipmentAddress, setShipmentAddress] = useState<CustomerCreateAddressResTypes[]>([])
  const [addAddress, setAddAddress] = useState(false)
  const [isEditAddress, setIsEditAddress] = useState(false)
  const [addressInfo, setAddressInfo] = useState<CustomerCreateAddressResTypes | undefined>()
  const [total, setTotal] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [chosenAddressId, setChosenAddressId] = useState<string>()
  const [chooseAddress, setChooseAddress] = useState('')
  const [customerAddressChanged, setCustomerAddressChanged] = useState(0)
  const [pagination, setPagination] = useState({ limit: 5, page: 1 })
  const dispatch = useAppDispatch()
  const debouncedChangeHandler = useMemo(
    () =>
      debounce((e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
          setPagination({ ...pagination })
        }
        const param = {
          customerId: customerId,
          storeId: +sessionStorage.getItem('storeId'),
          q: e.target.value,
          pageSize: pagination.limit,
          currentPage: pagination.page
        }
        shippingAddress(param).then((res) => {
          if (res.success) {
            setShipmentAddress(res.data.items)
          }
        })
      }, 300),
    []
  )
  const debouncedKeyPressHandler = useMemo(
    () =>
      debounce((e) => {
        if (e.code !== 'Enter') {
          return
        }
        if (e.target.value === '') {
          setPagination({ ...pagination })
        }
        const param = {
          customerId: customerId,
          storeId: +sessionStorage.getItem('storeId'),
          q: e.target.value,
          pageSize: pagination.limit,
          currentPage: pagination.page
        }
        shippingAddress(param).then((res) => {
          if (res.data.items) {
            setShipmentAddress(res.data.items)
          }
        })
      }, 300),
    []
  )
  const fetchData = () => {
    const param = {
      customerId: customerId,
      storeId: +sessionStorage.getItem('storeId'),
      pageSize: pagination.limit,
      currentPage: pagination.page
    }
    shippingAddress(param).then((res) => {
      if (res.success) {
        const { totalCount } = res.data.pagination
        const { items } = res.data
        const arr = [...shipmentAddress, ...items]
        if (items.length === 0 && setHasAddressData) {
          setHasAddressData(false)
          return
        }
        if (setHasAddressData) {
          setHasAddressData(items.length > 0)
        }
        if (orderDetails) {
          setChosenAddressId(orderDetails?.shippingAddressId?.toString())
          addressId(orderDetails?.shippingAddressId.toString())
        } else {
          let address =
            items.find((item) => {
              return item.isDefault === 1
            }) || items[0]

          if (address?.areaCode !== chosenAreaCode || address?.areaGroup !== chosenGroupId) {
            address = items.find((item) => {
              return item?.areaCode === chosenAreaCode && item.areaGroup === chosenGroupId
            })
          }
          setChosenAddressId(address?.id + '')
          addressId(address?.id + '')
        }

        if (
          items.find((item) => {
            return item.isDefault === 1
          })?.id
        ) {
          let address =
            items.find((item) => {
              return item.isDefault === 1
            }) || items[0]

          if (chosenAreaCode && chosenGroupId) {
            if (address?.areaCode !== chosenAreaCode && address?.areaGroup !== chosenGroupId) {
              address = items.find((item) => {
                return item?.areaCode === chosenAreaCode && item.areaGroup === chosenGroupId
              })
            }
          }
          setChooseAddress(
            `${address?.firstName || ''} ${address?.phone || ''} ${address?.street || ''}${address?.city || ''} ${address?.state || ''}`
          )
          dispatch(
            setChoosedAddress({
              address: `${address?.firstName || ''} ${address?.phone || ''} ${address?.street || ''}${address?.city || ''} ${address?.state || ''
                }`
            })
          )
          if (areaCode) {
            areaCode(address?.areaCode)
          }
        }
        if (arr.length >= totalCount) {
          setHasMore(false)
          setShipmentAddress(items)
          setTotal(totalCount)
          return
        }
        setShipmentAddress(items)
        setHasMore(false)
        setTotal(totalCount)
      } else {
        setShipmentAddress(undefined)
      }
    })
  }
  useEffect(() => {
    let isUnmount = false

    if (!isUnmount) {
      fetchData()
    }
    return () => {
      isUnmount = true
      debouncedChangeHandler.cancel()
      debouncedKeyPressHandler.cancel()
    }
  }, [pagination, customerAddressChanged, chooseCustomerId])

  useEffect(() => {
    if (showEditDialog) {
      setAddAddress(true)
      setIsEditAddress(false)
      setAddressInfo(undefined)
      setShowEditDialog(false)
    }
  }, [showEditDialog])

  useEffect(() => {
    if (shipmentAddress.length > 0) {
      setChosenAddressId(
        shipmentAddress.find((item) => {
          return item.areaCode === chosenAreaCode && item.areaGroup === chosenGroupId
        })?.id + ''
      )
      addressId(
        shipmentAddress.find((item) => {
          return item?.areaCode === chosenAreaCode && item.areaGroup === chosenGroupId
        })?.id + ''
      )
    }
  }, [chosenAreaCode])

  useEffect(() => {
    if (currentAddressId) {
      addressId(currentAddressId)
    }
    return () => {
      debouncedChangeHandler.cancel()
      debouncedKeyPressHandler.cancel()
    }
  }, [currentAddressId])
  const imageUrl = (img) => {
    let url = img
    if (!!img && (img.slice(0, 7) == 'http://' || img.slice(0, 8) == 'https://'))
      url = img
    else
      url = `${api.cdn}/${img}?x-oss-process=style/640w`
    return url
  }

  function imageGroup(record) {
    if (record.idCardImage && record.idCardImage2) {
      return (
        <div style={{ display: 'flex' }}>
          <Image width={100} src={`${imageUrl(record.idCardImage)}`} alt='image1' />
          <Image width={100} src={`${imageUrl(record.idCardImage2)}`} alt='image2' />
        </div>
      )
    } else if (record.idCardImage2) {
      return (
        <Image width={100} src={`${imageUrl(record.idCardImage2)}`} alt='image2' />
      )
    } else if (record.idCardImage) {
      return <Image width={100} src={`${imageUrl(record.idCardImage)}`} alt='image2' />
    } else {
      return <div></div>
    }
  }
  const warning = () => {
    Modal.warning({
      title: 'Warning',
      content:
        '所选地址与配送区域' +
        (!!orderDetails
          ? `${orderDetails.shipmentGroupName} - ${orderDetails.shipmentAreaName}`
          : `${chosenGroupName} - ${chosenAreaName}`) +
        '不一致.',
      okText: 'Ok',
      width: '30%',
      centered: true
    })
  }

  //'The shipment area of this address is not the same as the one selected'
  if (chooseCustomerId) {
    return (
      <div style={{ marginTop: '25px' }}>
        <List
          loading={hasMore}
          footer={
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Pagination
                total={total}
                defaultPageSize={pagination.limit}
                current={pagination.page}
                onChange={(page, pageSize) => {
                  setPagination({ page, limit: pageSize })
                }}
                pageSizeOptions={['5', '10', '20']}
              />
            </div>
          }
          header={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div
                style={{
                  color: 'orange',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setAddAddress(true)
                  setIsEditAddress(false)
                  setAddressInfo(undefined)
                }}
              >
                <PlusCircleFilled style={{ fontSize: '1rem', marginRight: '10px' }} />
                <span style={{ fontWeight: 700, color: 'orange' }}>ADD NEW ADDRESS</span>
              </div>
              <Input
                allowClear
                size='small'
                defaultValue={null}
                placeholder='Search address'
                style={{
                  width: '50%'
                }}
                autoFocus={true}
                onKeyPress={(event: any) => {
                  if (event.code !== 'Enter') {
                    return
                  }
                  if ((event.target as HTMLInputElement).value === '') {
                    setPagination({ ...pagination })
                  }
                  const param = {
                    customerId: customerId,
                    q: event.target.value,
                    pageSize: pagination.limit,
                    currentPage: pagination.page
                  }
                  shippingAddress(param).then((res) => {
                    if (res.data.items) {
                      setShipmentAddress(res.data.items)
                    }
                  })
                }}
                onBlur={debouncedChangeHandler}
                prefix={<SearchOutlined style={{ color: 'gray' }} />}
              />
            </div>
          }
          dataSource={shipmentAddress}
          bordered
          renderItem={(item) => (
            <List.Item
              onClick={() => {
                setChooseAddress(`${item.firstName} ${item.lastName} ${item.phone} ${item.street}${item.city} ${item.state}`)
              }}
              actions={[
                <Button
                  type='link'
                  style={{ color: 'orange' }}
                  key='list-loadmore-edit'
                  onClick={(e) => {
                    e.stopPropagation()
                    setAddAddress(true)
                    setAddressInfo(item)
                    setIsEditAddress(true)
                  }}
                >
                  EDIT
                </Button>,
                <Button
                  type='link'
                  style={{ color: 'orange' }}
                  key='list-loadmore-edit-address'
                  onClick={(e) => {
                    e.stopPropagation()
                    const address = `${item.firstName} ${item.lastName} ${item.street}
                        ${item.city}
                        ${item.state} ${item.phone}  ${item.idCard}`
                    if (chooseAddress !== '') {
                      if (chooseAddress === address) {
                        setChooseAddress('')
                      }
                    }
                    deleteCustomerAddress(item.id.toString()).then((res) => {
                      if (res.data) {
                        setCustomerAddressChanged(customerAddressChanged + 1)
                      }
                    })
                  }}
                >
                  <DeleteOutlined />
                </Button>
              ]}
            >
              <List.Item.Meta
                description={
                  <div style={{ display: 'flex' }}>
                    <div style={{ fontSize: '1rem', color: 'black' }}>
                      {item.lastName ? item.lastName + ' ' : ''}
                      {item.firstName ? item.firstName + ' ': ''}
                      {item.phone ? item.phone + ' ' : ''}
                      {item.areaGroupName ? item.areaGroupName: ''}
                      {item.areaCodeName ? item.areaCodeName : ''}
                      {item.city ? item.city : ''}
                      {item.street ? item.street + ' ' : ''}
                      {item.idCard ? 'ID No: ' + item.idCard: ''}
                    </div>
                    <div style={{ marginLeft: '15px' }}>
                      {imageGroup(item)}
                    </div>
                  </div>
                }
              />
            </List.Item>
          )}
        />

        <Modal width={'50%'} open={addAddress} destroyOnClose={true} centered closable={false} footer={null}>
          <CreateForm
            finished={(value, imageList: any[]) => {
              // const cover = JSON.parse(JSON.stringify(value.cover))
              imageList = JSON.parse(JSON.stringify(imageList))

              if (isEditAddress) {
                const addressEditParam = {
                  isDefault: 0,
                  firstName: value?.firstName || '',
                  alias: value?.nickName || '',
                  lastName: value?.lastName || '',
                  phone: value?.phone || '',
                  country: value?.country || '',
                  state: value?.state || '',
                  city: value?.city || '',
                  areaGroup: value.shipmentareas[0],
                  areaCode: value.shipmentareas[1],
                  district: value?.district || '',
                  street: value?.street || '',
                  postcode: value?.postcode || '',
                  idCard: value?.idCard || '',
                  idCardImage: imageList[0]?.response ? imageList[0]?.response.data || '' : imageList[0]?.name || '',
                  idCardImage2: imageList[1]?.response ? imageList[1]?.response.data || '' : imageList[1]?.name || ''
                }
                updateCustomerShippingAddress(addressInfo.id.toString(), addressEditParam).then((res) => {
                  if (res.success) {
                    message.success(res.message)
                    setCustomerAddressChanged(customerAddressChanged + 1)
                  }
                })
              } else {
                const addressParam = {
                  customerId: customerId || '',
                  isDefault: 0,
                  firstName: value?.firstName || '',
                  alias: value?.nickName || '',
                  lastName: value?.lastName || '',
                  phone: value?.phone || '',
                  country: value?.country || '',
                  state: value?.state || '',
                  areaGroup: value.shipmentareas[0],
                  areaCode: value.shipmentareas[1],
                  city: value?.city || '',
                  district: value?.district || '',
                  street: value?.street || '',
                  postcode: value?.postcode || '',
                  idCard: value?.idCard || '',
                  idCardImage: imageList[0]?.response ? imageList[0]?.response.data || '' : imageList[0]?.name || '',
                  idCardImage2: imageList[1]?.response ? imageList[1]?.response.data || '' : imageList[1]?.name || ''
                }

                addCustomerShippingAddress(addressParam).then((res) => {
                  if (res.success) {
                    setCustomerAddressChanged(customerAddressChanged + 1)
                    message.success(res.message)
                  } else {
                    message.error(res.message)
                  }
                })
              }

              setAddAddress(false)
            }}
            close={() => {
              setAddAddress(false)
            }}
            edit={isEditAddress}
            customerAddressInfo={addressInfo}
            paymentPage={true}
          />
        </Modal>
      </div>
    )
  }
  return (
    <div style={{ marginTop: '25px' }}>
      {!!chosenAddressId && (
        <Radio.Group
          onChange={(e) => {
            if (
              orderDetails &&
              (shipmentAddress.find((item) => item.id.toString() === e.target.value).areaCode !== orderDetails.shipmentAreaCode ||
                shipmentAddress.find((item) => item.id.toString() === e.target.value).areaGroup !== orderDetails.shipmentGroupId)
            ) {
              warning()
              return
            } else if (
              !orderDetails &&
              (shipmentAddress.find((item) => item.id.toString() === e.target.value).areaCode !== chosenAreaCode ||
                shipmentAddress.find((item) => item.id.toString() === e.target.value).areaGroup !== chosenGroupId)
            ) {
              warning()
              return
            }
            if (!shipmentAddress.find((item) => item.id.toString() === e.target.value).areaCode) {
              setAddAddress(true)
              setAddressInfo(shipmentAddress.find((item) => item.id.toString() === e.target.value))
              setIsEditAddress(true)

              return
            }
            addressId(e.target.value)
            const address = shipmentAddress.find((item) => item.id.toString() === e.target.value)
            if (areaCode) {
              areaCode(shipmentAddress.find((item) => item.id.toString() === e.target.value).areaCode)
            }
            setChosenAddressId(e.target.value)
            dispatch(
              setChoosedAddress({
                address: `${address.areaCodeName || ''} ${address.areaGroupName || ''} ${address.firstName || ''} ${address.phone || ''} ${address.street || ''
                  }${address.city || ''} ${address.state || ''}`
              })
            )
          }}
          style={{ width: '100%' }}
          value={chosenAddressId}
        >
          <List
            loading={hasMore}
            footer={
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Pagination
                  total={total}
                  defaultPageSize={pagination.limit}
                  current={pagination.page}
                  onChange={(page, pageSize) => {
                    setPagination({ page, limit: pageSize })
                  }}
                  pageSizeOptions={['5', '10', '20']}
                />
              </div>
            }
            header={
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{
                    color: 'orange',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setAddAddress(true)
                    setIsEditAddress(false)
                    setAddressInfo(undefined)
                  }}
                >
                  <PlusCircleFilled style={{ fontSize: '1rem', marginRight: '10px' }} />
                  <span style={{ fontWeight: 700, color: 'orange' }}>ADD NEW ADDRESS</span>
                </div>
                <Input
                  allowClear
                  size='small'
                  defaultValue={null}
                  placeholder='Search address'
                  style={{
                    width: '50%'
                  }}
                  autoFocus={true}
                  onKeyPress={debouncedKeyPressHandler}
                  onBlur={debouncedChangeHandler}
                  prefix={<SearchOutlined style={{ color: 'gray' }} />}
                />
              </div>
            }
            dataSource={shipmentAddress}
            bordered
            renderItem={(item) => (
              <List.Item
                onClick={() => {
                  setChooseAddress(
                    `${item.areaCodeName || ''} ${item.areaGroupName || ''} ${item.firstName} ${item.lastName} ${item.phone} ${item.street
                    }${item.city} ${item.state}`
                  )
                }}
                actions={[
                  <Button
                    type='link'
                    style={{ color: 'orange' }}
                    key='list-loadmore-edit'
                    onClick={(e) => {
                      e.stopPropagation()
                      setAddAddress(true)
                      setAddressInfo(item)
                      setIsEditAddress(true)
                    }}
                  >
                    EDIT
                  </Button>,
                  <Button
                    type='link'
                    style={{ color: 'orange' }}
                    key='list-loadmore-edit-address'
                    onClick={(e) => {
                      e.stopPropagation()
                      const address = `${item.areaGroupName || ''} ${item.areaCodeName || ''} |  ${item.firstName} ${item.lastName} ${item.street
                        }
                      ${item.city}
                      ${item.state} ${item.phone}  ${item.idCard}`
                      if (chooseAddress !== '') {
                        if (chooseAddress === address) {
                          setChooseAddress('')
                        }
                      }
                      deleteCustomerAddress(item.id.toString()).then((res) => {
                        if (res.data) {
                          setCustomerAddressChanged(customerAddressChanged + 1)
                        }
                      })
                    }}
                  >
                    <DeleteOutlined />
                  </Button>
                ]}
              >
                <List.Item.Meta
                  description={
                    <Radio value={item.id.toString()}>
                      {item.areaGroupName || ''} {item.areaCodeName || ''} |{item.firstName} {item.lastName} {item.street}
                      {item.city}
                      {item.state} {item.phone} {item.idCard === '' ? '' : `Id: ${item.idCard}`}
                    </Radio>
                  }
                />
              </List.Item>
            )}
          />
        </Radio.Group>
      )}

      <Modal width={'50%'} visible={addAddress} destroyOnClose={true} centered closable={false} footer={null}>
        <CreateForm
          finished={(value, imageList: any[]) => {
            // const cover = JSON.parse(JSON.stringify(value.cover))
            imageList = JSON.parse(JSON.stringify(imageList))

            if (isEditAddress) {
              const addressEditParam = {
                isDefault: 0,
                firstName: value?.firstName || '',
                alias: value?.nickName || '',
                lastName: value?.lastName || '',
                phone: value?.phone || '',
                country: value?.country || '',
                state: value?.state || '',
                city: value?.city || '',
                district: value?.district || '',
                areaGroup: value.shipmentareas[0],
                areaCode: value.shipmentareas[1],
                street: value?.street || '',
                postcode: value?.postcode || '',
                idCard: value?.idCard || '',
                idCardImage: imageList[0]?.response ? imageList[0]?.response.data || '' : imageList[0]?.name || '',
                idCardImage2: imageList[1]?.response ? imageList[1]?.response.data || '' : imageList[1]?.name || ''
              }
              updateCustomerShippingAddress(addressInfo.id.toString(), addressEditParam).then((res) => {
                if (res.success) {
                  message.success(res.message)
                  // setShippingAddressId(res.data.id.toString())
                  if (areaCode) {
                    areaCode(res.data.areaCode)
                  }

                  setCustomerAddressChanged(customerAddressChanged + 1)
                }
              })
            } else {
              const addressParam = {
                customerId: customerId || '',
                isDefault: 0,
                firstName: value?.firstName || '',
                alias: value?.nickName || '',
                lastName: value?.lastName || '',
                phone: value?.phone || '',
                country: value?.country || '',
                state: value?.state || '',
                city: value?.city || '',
                areaGroup: value.shipmentareas[0],
                areaCode: value.shipmentareas[1],
                district: value?.district || '',
                street: value?.street || '',
                postcode: value?.postcode || '',
                idCard: value?.idCard || '',
                idCardImage: imageList[0]?.response ? imageList[0]?.response.data || '' : imageList[0]?.name || '',
                idCardImage2: imageList[1]?.response ? imageList[1]?.response.data || '' : imageList[1]?.name || ''
              }

              addCustomerShippingAddress(addressParam).then((res) => {
                if (res.success) {
                  setCustomerAddressChanged(customerAddressChanged + 1)
                  // setShippingAddressId(res.data.id.toString())
                  /*                   if (areaCode) {
                    areaCode(res.data.areaCode)
                  } */
                  message.success(res.message)
                } else {
                  message.error(res.message)
                }
              })
            }

            setAddAddress(false)
          }}
          close={() => {
            setAddAddress(false)
          }}
          edit={isEditAddress}
          customerAddressInfo={addressInfo}
          paymentPage={true}
        />
      </Modal>
    </div>
  )
}

export default CustomerAddress
