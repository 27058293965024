/* eslint-disable no-lone-blocks */
/* eslint-disable multiline-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Col, Row, Layout, Space, Collapse, Card, Typography, Button, Radio, message, Select, Tag, Divider, Input } from 'antd'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { getShipmentFee, placeOrder, getShipmentsList, getShipmentMethod } from '../../api/response'
import { PlusCircleFilled, SearchOutlined } from '@ant-design/icons'

import { useHistory } from 'react-router-dom'
import { orderData, setChooseDeliver, setOrderIdInfo, setPlaceOrderPrice, setPlaceorderSuccess } from '../../redux/reducers/orders_reducer'
import { customerInfoData } from '../../redux/reducers/customerInfo_reducer'
import { homePageData } from '../../redux/reducers/homePage_reducer'
import { getCurrencyRate, setCartIncrease, shoppingCartData } from '../../redux/reducers/cartItems_reducer'
import { useAppDispatch, useAppSelector } from '../../redux/reduxHooks'
import { forEach, uniq } from 'lodash'
import { ShipmentMethodTypes } from '../../models/shipping/shippingMethod'
import CustomerAddress from '../manageCustomer/customerAddress'
import { ShipmentTerm, ShipmentTerm_eng } from '../../data/shipmentTerm.enum'
import Shipment from '../shipment'

const { Header, Content, Footer } = Layout
const { Panel } = Collapse
const { Text } = Typography

const StyledContent = styled(Content)`
  margin: 16px;
  overflow: auto;
  height: 100vh;
  .ant-empty {
    display: none;
  }
`
const { Option } = Select

const StyledHeader = styled(Header)`
  position: sticky;
  top: 0;
  z-index: 10;
  color: black;
  font-weight: 700;
  text-align: center;
  font-size: 20px;
  background-color: #fff !important;
`
const StyledLayoutFooter = styled(Footer)`
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 10;
  padding: 0 !important;
`
const ListStyle = styled(Row)`
  position: relative;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  padding: 10px;
  :after {
    content: '';
    position: absolute;
    bottom: 0;
    background: #ccc;
    width: 100%;
    height: 100%;
    height: 1px;
  }
`
const ListStyleNoLine = styled(Row)`
  position: relative;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  padding: 10px;
`

const Placeorder = (props: { showCart: (value: number) => void }) => {
  const { showCart } = props

  const storeState = useAppSelector(customerInfoData)
  const configData = useAppSelector(homePageData)
  const { coupon, comment, discount, discountComment, adjustmentComment, adjustment, checkoutPrice } = useAppSelector(homePageData)
  const shoppingCartInfo = useAppSelector(shoppingCartData)
  const addressState = useAppSelector(orderData)
  const dispatch = useAppDispatch()
  const history = useHistory()

  const [shipmentMethodId, setShipmentMethodId] = useState<number>(0)
  const [shipmentMethods, setShipmentMethods] = useState<ShipmentMethodTypes[]>()
  const [shippingAddressId, setShippingAddressId] = useState<string>()
  const [shipPrice, setShipPrice] = useState(0)
  const [loading, setLoading] = useState(false)
  const [areaCode, setAreaCode] = useState('')
  const [groupList, setGroupList] = useState({})
  const [shipmentTypes, setShipmentTypes] = useState([])
  const [areaList, setAreaList] = useState<object | undefined>()
  const [addressArea, setAddressArea] = useState('akl')
  const [addressShipmentType, setAddressShipmentType] = useState('pickup')
  const [addressGroup, setAddressGroup] = useState(4)
  const [addressGroupName, setAddressGroupName] = useState('')
  const [addressAreaName, setAddressAreaName] = useState('')
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [hasAddressData, setHasAddressData] = useState(true)

  const getPlaceOrderInfoBack = () => {
    setLoading(true)
    const placeOrderParams = {
      cartList: [],
      point: 0,
      coupon,
      credit: 0,
      shipmentMethodId,
      shippingAddressId,
      description: comment,
      customerId: storeState?.customerId,
      storeId: +sessionStorage.getItem('storeId'),
      discount: +discount,
      discountComment,
      adjustment: +adjustment,
      adjustmentComment
    }

    placeOrder(placeOrderParams).then((res) => {
      if (res.success) {
        message.success(res.message)
        dispatch(setOrderIdInfo({ id: res.data.orderId.toString() }))
        history.push(`/payment/${res.data.orderNo}`)
        showCart(0)
        dispatch(setPlaceOrderPrice({ price: res.data.totalDue }))
        setLoading(false)
        dispatch(setCartIncrease({ num: 1 }))
        dispatch(setPlaceorderSuccess({ success: true }))
      } else {
        message.error(res.message)
        showCart(1)
        setLoading(false)
      }
    })
  }
  useEffect(() => {
    let isUnmount = false
    if (!isUnmount) {
      showCart(1)

      dispatch(getCurrencyRate())
    }
    return () => {
      isUnmount = true
    }
  }, [])



  const filterShipmentList = async () => {
    const res = await getShipmentsList()
    if (res.success) {
      const shippingTermList = res.data.map((item) => item.shippingTerm)
      const groupNameList = uniq(shippingTermList).map((item) => {
        return {
          [item]: [
            ...res.data.filter((shipment) => {
              return shipment.shippingTerm === item
            })
          ]
        }
      })

      let filterGroupNameList = {}
      forEach(groupNameList, function (value, key) {
        forEach(value, function (value2, key2) {
          let hash = {}
          const newArr = value2.reduce((item, next) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            hash[next.groupId] ? '' : (hash[next.groupId] = true && item.push(next))
            return item
          }, [])
          filterGroupNameList = { ...filterGroupNameList, [key2]: newArr }
        })
      })
      const groupCodeList = res.data.map((item) => {
        return item.groupId
      })
      const areaNameList = groupCodeList
        .map((i) => {
          return { [i]: res.data.filter((tmp2) => tmp2.groupId === i) }
        })
        .flat()
      let filterAreaNameList = {}
      forEach(areaNameList, function (value, key) {
        forEach(value, function (value2, key2) {
          filterAreaNameList = { ...filterAreaNameList, [key2]: value2 }
        })
      })
      setShipmentTypes(uniq(shippingTermList))
      setGroupList(filterGroupNameList)
      setAreaList(filterAreaNameList)
    } else {
      message.error(res.message)
    }
  }
  useEffect(() => {
    filterShipmentList()
  }, [])

  return (
    <>
      <Row wrap={false}>
        <Col flex={1}>
          <Layout style={{ maxHeight: '100vh', position: 'absolute', width: '100%' }}>
            <StyledHeader>
              <div style={{ color: 'orange' }}>Deliver Option</div>
            </StyledHeader>

            <StyledContent>
              <Space direction='vertical' style={{ width: '100%' }}>
                {areaList && (
                  <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Select
                      value={addressShipmentType}
                      style={{ width: 220 }}
                      onChange={(value) => {
                        setAddressShipmentType(value)
                        setAddressGroup(groupList[value][0].groupId)
                        setAddressArea(areaList[groupList[value][0].groupId][0].areaCode)
                        setAddressGroupName(groupList[value][0].groupName)
                        setAddressAreaName(areaList[groupList[value][0].groupId][0].areaName)
                      }}
                    >
                      {shipmentTypes.map((shipment) => (
                        <Option key={shipment} value={shipment}>
                          {localStorage.getItem('lang') === 'zh-CN'
                            ? ShipmentTerm[shipment]
                              ? ShipmentTerm[shipment]
                              : '物流'
                            : ShipmentTerm_eng[shipment]
                            ? ShipmentTerm_eng[shipment]
                            : 'Courier'}
                        </Option>
                      ))}
                    </Select>
                    <Select
                      style={{ width: 220 }}
                      value={addressGroup}
                      onChange={(value) => {
                        setAddressGroup(+value)
                        setAddressArea(areaList[value][0].areaCode)
                        setAddressGroupName(groupList[addressShipmentType].find((item) => item.groupId === +value).groupName)
                        setAddressAreaName(areaList[+value][0].areaName)
                      }}
                    >
                      {groupList[addressShipmentType].map((group) => (
                        <Option key={group.groupId} value={group.groupId}>
                          {group.groupName}
                        </Option>
                      ))}
                    </Select>
                    {areaList[addressGroup] && (
                      <Select
                        style={{ width: 220 }}
                        value={addressArea}
                        onChange={(value) => {
                          setAddressArea(value)
                          setAddressAreaName(areaList[addressGroup].find((item) => item.areaCode === value).areaName)
                        }}
                      >
                        {areaList[addressGroup]
                          .filter((item) => item.shippingTerm === addressShipmentType)
                          .map((area) => (
                            <Option key={area.id} value={area.areaCode}>
                              {area.areaName}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </div>
                )}
                <Collapse defaultActiveKey={['1']} style={{ width: '100%' }} ghost expandIconPosition='right' destroyInactivePanel>
                  <Panel
                    key='1'
                    style={{
                      width: '100%',
                      backgroundColor: '#fff',
                      textAlign: null
                    }}
                    header={
                      <div style={{ textAlign: null }}>
                        <span style={{ fontWeight: 700, color: 'orange' }}><FormattedMessage id='shipmentMethod'/></span>
                        {shipmentMethods && (
                          <span style={{ fontWeight: 700, marginLeft: '30px', color: 'black' }}>{addressState.chooseDeliver}</span>
                        )}
                      </div>
                    }
                  >
                    <Shipment
                      setShipPrice={(data) => setShipPrice(data)}
                      shipmentMethodId={shipmentMethodId}
                      setShipmentMethodId={(data) => setShipmentMethodId(data)}
                      setChooseDeliver={(data) => dispatch(setChooseDeliver({ shipmentMethod: data }))}
                      addressArea={addressArea}
                      addressGroup={addressGroup}
                      addressShipmentType={addressShipmentType}
                      shipmentMethodsList={(data) => setShipmentMethods(data)}
                    />
                  </Panel>
                </Collapse>
                {addressShipmentType !== 'pickup' && (
                  <Collapse defaultActiveKey={['1']} style={{ width: '100%' }} ghost expandIconPosition='right'>
                    <Panel
                      key='1'
                      style={{ width: '100%', backgroundColor: '#fff' }}
                      header={
                        <div style={{ textAlign: null, display: 'flex', justifyContent: 'space-between' }}>
                          <div>
                            <span style={{ fontWeight: 700, color: 'orange' }}>Address</span>
                            <span
                              style={{
                                fontWeight: 700,
                                marginLeft: '30px',
                                color: 'black'
                              }}
                            >
                              {addressState.chooseAddress}
                            </span>
                          </div>
                          {!hasAddressData && (
                            <div
                              style={{
                                color: 'orange',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer'
                              }}
                              onClick={(e) => {
                                e.stopPropagation()
                                setOpenEditDialog(true)
                              }}
                            >
                              <PlusCircleFilled style={{ fontSize: '1rem' }} />
                              <span style={{ fontWeight: 700, color: 'orange' }}>ADD NEW ADDRESS</span>
                            </div>
                          )}
                        </div>
                      }
                    >
                      <CustomerAddress
                        addressId={(value: string) => {
                          setShippingAddressId(value.toString())
                        }}
                        placeOrderCustomerid={storeState.customerId}
                        areaCode={(value: string) => setAreaCode(value || 'other')}
                        chosenAreaCode={addressArea}
                        chosenGroupId={addressGroup}
                        chosenAreaName={addressAreaName}
                        chosenGroupName={addressGroupName}
                        showEditDialog={openEditDialog}
                        setShowEditDialog={(openEditDialog) => {
                          setOpenEditDialog(openEditDialog)
                        }}
                        setHasAddressData={(hasData) => {
                          setHasAddressData(hasData)
                        }}
                      />
                    </Panel>
                  </Collapse>
                )}
              </Space>
            </StyledContent>
            <StyledLayoutFooter>
              <Card
                size='small'
                actions={[
                  <Button
                    style={{
                      backgroundColor: addressShipmentType === 'courier' && shippingAddressId === 'undefined' ? null : 'orange',
                      color: addressShipmentType === 'courier' && shippingAddressId === 'undefined' ? null : 'white'
                    }}
                    key={2}
                    shape='round'
                    size='large'
                    block
                    loading={loading}
                    disabled={addressShipmentType === 'courier' && shippingAddressId === 'undefined'}
                    onClick={() => {
                      getPlaceOrderInfoBack()
                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <FormattedMessage id='order' />
                    </div>
                  </Button>
                ]}
              >
                <>
                  <ListStyle justify='space-between'>
                    <Col>
                      <Text strong>
                        <FormattedMessage id='productsTotalMoney' />
                      </Text>
                    </Col>
                    <Col>
                      <Text strong>
                        NZ$ {checkoutPrice} / ¥ {(checkoutPrice * shoppingCartInfo.currencyRate).toFixed(2)}
                      </Text>
                    </Col>
                  </ListStyle>
                  <ListStyleNoLine justify='space-between'>
                    <Col>
                      <Text strong>
                        <FormattedMessage id='shipmentFee' />
                      </Text>
                    </Col>
                    <Col>
                      <Text strong>
                        NZ$ {shipPrice} / ¥ {(shipPrice * shoppingCartInfo.currencyRate).toFixed(2)}
                      </Text>
                    </Col>
                  </ListStyleNoLine>
                  <ListStyle justify='space-between'>
                    <Col>
                      <Text strong>
                        <FormattedMessage id='totalMoney' />
                      </Text>
                    </Col>
                    <Col>
                      <Text strong>
                        NZ$ {(checkoutPrice + shipPrice).toFixed(2)} / ¥{' '}
                        {((checkoutPrice + shipPrice) * shoppingCartInfo.currencyRate).toFixed(2)}
                      </Text>
                    </Col>
                  </ListStyle>
                </>
              </Card>
            </StyledLayoutFooter>
          </Layout>
        </Col>
      </Row>
    </>
  )
}

export default Placeorder
