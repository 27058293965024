import React, { useState, useEffect } from 'react'
/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import { Col, Layout, Row, Table, Divider } from 'antd'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import { printColumns } from '../../data/tableColumns'
import { orderDetail } from '../../api/response'
import 'dayjs/locale/zh-cn'
import { OrderDetailResTypes, OrderItemsTypes } from '../../models/order/orderDetail'
import { getCurrencyRate, shoppingCartData } from '../../redux/reducers/cartItems_reducer'
import { useAppDispatch, useAppSelector } from '../../redux/reduxHooks'
import api from '../../config/api'

const { Content, Footer } = Layout
dayjs.extend(isToday)
const PrintStyle = styled(Row)`
  @media print {
    margin: 0mm;
  }
`

const StyledContent = styled(Content)`
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  .ant-empty {
    display: none;
  }
`
const StyledHeader = styled.div`
  font-weight: 700;
  text-align: center;
  background-color: #fff;
`

const StyledFooter = styled(Footer)`
  position: sticky;
  top: 0;
  z-index: 10;
  color: black;
  font-weight: 500;
  text-align: center;
  background-color: #fff;
`

interface Props {
  id: string,
  setLang: (lang: string) => void
}

const PrintLayout = (props: Props) => {
  const [orderCreateTime, setOrderCreateTime] = useState(0)
  const [lang, setLang] = useState('')
  const [orderDetails, setOrderDetails] = useState<OrderDetailResTypes>()
  const [itemTableSource, setItemTableSource] = useState<OrderItemsTypes[]>([])

  const dispatch = useAppDispatch()
  const shoppingCartInfo = useAppSelector(shoppingCartData)

  useEffect(() => {
    dispatch(getCurrencyRate())
  }, [])

  useEffect(() => {
    orderDetail(props.id).then((res) => {
      if (res.success) {
        setOrderDetails(res.data)
        setItemTableSource(res.data.items)
        setOrderCreateTime(res.data.createdAt)
        if (res.data.lang === 'zh-CN') {
          dayjs.locale('zh-cn')
        } else {
          dayjs.locale('en')
        }
        setLang(res.data.lang)
        props.setLang(res.data.lang)
        window.print()
      } else {
        setOrderDetails(undefined)
      }
    })
  }, [])

  return (
    <PrintStyle>
      {itemTableSource.length > 0 && (
        <Col span={24} style={{ marginTop: '20px' }}>
          <Layout>
            <StyledHeader>
              <Row>
                <Col span={12} style={{ textAlign: 'left', fontWeight: 'normal', padding: '10px' }}>
                  <span style={{ fontSize: '2.5rem' }}>INVOICE</span>
                  <br />
                  <span>
                    <br />
                    {orderDetails.lang == 'zh-CN' ? api.title : api.title_en} {api.www}
                    <br />
                    {orderDetails.lang == 'zh-CN' ? api.subtitle : api.subtitle_en}
                  </span>
                  <br />
                </Col>
                <Col span={12} style={{ textAlign: 'right', fontWeight: 'normal', padding: '10px' }}>
                  <br />
                  <FormattedMessage id='invoiceNo' />: {orderDetails.orderNo}
                  <br />
                  <FormattedMessage id='invoiceDate' />: {dayjs(orderDetails.createdAt).format('DD/MM/YYYY HH:mm')}
                  {orderDetails.paidAt > 0 ? (
                    <>
                      <br />
                      <FormattedMessage id='invoicePaidAt' />: {dayjs(orderDetails.paidAt).format('DD/MM/YYYY HH:mm')}
                    </>
                  ) : null}
                  <br />
                  <FormattedMessage id='invoiceGST' />: {api.gst}
                  <br />
                  <FormattedMessage id='casher' />: {orderDetails.managerName}
                </Col>
              </Row>
            </StyledHeader>

            <StyledContent>
              <Row>
                <Col span={12} style={{ textAlign: 'left', fontWeight: 'normal', padding: '10px' }}>
                  {orderDetails.shipmentMethod.shippingTerm == 'pickup' ? (
                    <Row>
                      <Col span={24}>
                        {orderDetails.shipmentMethodName}
                      </Col>
                      <Col span={24}>
                        {orderDetails.pickupStoreName} {orderDetails.pickupStorePhone} {orderDetails.pickupStoreAddress}
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      {orderDetails.shipmentGroupId != 4 ? (
                        <Col span={24}>
                          GST exempt for all export orders
                        </Col>
                      ) : null}
                      <Col span={24}>
                        <FormattedMessage id='shipping' />:{' '}{orderDetails.shipmentMethodName}
                      </Col>
                      <Col span={24}>
                        <FormattedMessage id='address' />: {orderDetails.consigneeName} {orderDetails.consigneeAddress} {orderDetails.consigneePhone}
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col span={12} style={{ textAlign: 'right', fontWeight: 'normal', padding: '10px' }}>
                  <span style={{ fontWeight: 'bold' }}><FormattedMessage id='customer' /></span>: #{orderDetails.customerNo}
                  {orderDetails.customerCompany ? (
                    <span style={{ paddingLeft: '5px' }}>{orderDetails.customerCompany}</span>
                  ) : (
                    <span style={{ paddingLeft: '5px' }}>{orderDetails.customerName} {orderDetails.customerPhone}</span>
                  )}
                  {orderDetails.customerAddress ? (
                    <>
                      <br />
                      {orderDetails.customerAddress}
                    </>
                  ) : null}
                </Col>
              </Row>
              <Table
                size='small'
                rowKey='id'
                columns={printColumns}
                dataSource={itemTableSource}
                pagination={false}
                footer={() => {
                  return (
                    <Row justify='space-between' style={{ marginTop: '10px' }}>
                      <Col xs={0} sm={12} lg={16}></Col>
                      <Col xs={24} sm={12} lg={8}>
                        <Row justify='space-between'>
                          <Col>
                            <FormattedMessage id='subtotal' />
                          </Col>
                          <Col>${orderDetails.subtotal.toFixed(2)}{lang == 'zh-CN' && orderDetails.currencyRate != 0 && orderDetails.currencyRate != 1 ? (<span> /￥{(orderDetails.subtotal * orderDetails.currencyRate).toFixed(2)}</span>) : null}</Col>
                        </Row>
                        {orderDetails.totalWeight != 0 ? (
                          <Row justify='space-between'>
                            <Col>
                              <FormattedMessage id='totalWeight' />
                            </Col>
                            <Col>{orderDetails.totalWeight.toFixed(3)}kg</Col>
                          </Row>
                        ) : null}
                        {orderDetails.shipmentMethod.shippingTerm != 'pickup' ? (
                          <Row justify='space-between'>
                            <Col>
                              <FormattedMessage id='shippingFee' />
                            </Col>
                            <Col>${orderDetails.shippingFee.toFixed(2)}{lang == 'zh-CN' && orderDetails.currencyRate != 0 && orderDetails.currencyRate != 1 ? (<span> /￥{(orderDetails.shippingFee * orderDetails.currencyRate).toFixed(2)}</span>) : null}</Col>
                          </Row>
                        ) : null}
                        {orderDetails.discounts.length > 0
                          ? orderDetails.discounts.map((item) => {
                            if (item.discountAmount != 0) {
                              return (
                                <Row justify='space-between' key={item.id}>
                                  <Col>
                                    <FormattedMessage id={item.discountType} />
                                  </Col>
                                  <Col>- ${item.discountAmount.toFixed(2)}{lang == 'zh-CN' && orderDetails.currencyRate != 0 && orderDetails.currencyRate != 1 ? (<span> /￥{(item.discountAmount * orderDetails.currencyRate).toFixed(2)}</span>) : null}</Col>
                                </Row>
                              )
                            }
                          })
                          : null}
                        {orderDetails.surcharge != 0 ? (
                          <Row justify='space-between'>
                            <Col>
                              <FormattedMessage id='surcharge' />
                            </Col>
                            <Col>${orderDetails.surcharge.toFixed(2)}{lang == 'zh-CN' && orderDetails.currencyRate != 0 && orderDetails.currencyRate != 1 ? (<span> /￥{(orderDetails.surcharge * orderDetails.currencyRate).toFixed(2)}</span>) : null}</Col>
                          </Row>
                        ) : null}
                        <Row justify='space-between'>
                          <Col>
                            <span style={{ fontWeight: 'bold' }}><FormattedMessage id='total' /></span>
                          </Col>
                          <Col>${orderDetails.total.toFixed(2)}{lang == 'zh-CN' && orderDetails.currencyRate != 0 && orderDetails.currencyRate != 1 ? (<span> /￥{(orderDetails.total * orderDetails.currencyRate).toFixed(2)}</span>) : null}</Col>
                        </Row>
                        {orderDetails.totalGST > 0 ? (
                          <Row justify='space-between'>
                            <Col>
                              <span style={{ fontWeight: 'bold' }}><FormattedMessage id='invoiceGSTAmount' /></span>
                            </Col>
                            <Col>${orderDetails.totalGST.toFixed(2)}{lang == 'zh-CN' && orderDetails.currencyRate != 0 && orderDetails.currencyRate != 1 ? (<span> /￥{(orderDetails.totalGST * orderDetails.currencyRate).toFixed(2)}</span>) : null}</Col>
                          </Row>
                        ) : null}
                        {orderDetails.totalPaid > 0 ? (
                          <Row justify='space-between'>
                            <Col>
                              <FormattedMessage id='totalPaid' />
                            </Col>
                            <Col>${orderDetails.totalPaid.toFixed(2)}{lang == 'zh-CN' && orderDetails.currencyRate != 0 && orderDetails.currencyRate != 1 ? (<span> /￥{(orderDetails.totalPaid * orderDetails.currencyRate).toFixed(2)}</span>) : null}</Col>
                          </Row>
                        ) : null}

                        <Row justify='space-between'>
                          <Col>
                            <span style={{ fontWeight: 'bold' }}><FormattedMessage id='totalDue' /></span>
                          </Col>
                          <Col>${orderDetails.totalDue.toFixed(2)}{lang == 'zh-CN' && orderDetails.currencyRate != 0 && orderDetails.currencyRate != 1 ? (<span> /￥{(orderDetails.totalDue * orderDetails.currencyRate).toFixed(2)}</span>) : null}</Col>
                        </Row>
                      </Col>
                      {api.payment != '' ? (
                        <Col xs={24} style={{ marginTop: '20px' }}><Row><span style={{ fontWeight: 'bold' }}><FormattedMessage id='payment' /></span></Row><Row>{api.payment}</Row></Col>
                      ) : null}
                    </Row>
                  )
                }
                }
              />
            </StyledContent>
            <StyledFooter>
              <div>---------- ***** ----------</div>
              <div><FormattedMessage id='invoiceFooter' /></div>
            </StyledFooter>
          </Layout>
        </Col>
      )}
    </PrintStyle>
  )
}

export default PrintLayout
