import styled from 'styled-components'
import { CheckCircleFilled } from '@ant-design/icons'

export const MainContainer = styled.div`
  margin: 0 1.5rem;
`
export const ShipmentCard = styled.div<{ chosen: boolean }>`
  position: relative;
  border: ${(props) => (props.chosen ? '1px solid orange' : '1px solid lightgrey')};
  display: inline-block;
  border-radius: 0.5rem;
  width: 80%;
  margin-top: 1rem;
  min-height: 8rem;
  cursor: pointer;
  &:hover {
    background-color: lightyellow;
  }
`
export const StyledCheckCircleFilled = styled(CheckCircleFilled)`
  color: orange;
  position: absolute;
  top: 9px;
  right: 16px;
`
