/* eslint-disable no-template-curly-in-string */
import { Button, Col, Divider, Form, Modal, Row, Typography, Input, Cascader, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { getCustomerAddressArea } from '../../api/response'

const ShippingAddress = (props) => {
  const { Title } = Typography
  const { isModalVisible, cancel, address, addressInfo, edit, paymentPage } = props
  const [option, setOption] = useState([])

  const onFinish = (value) => {
    address(value)
  }

  useEffect(() => {
    getCustomerAddressArea().then((res) => {
      if (res.success) {
        const shipmentArea = res.data
          .sort((a, b) => b.position - a.position)
          .map((item) => {
            return {
              value: item.shipmentGroupId,
              label: item.name,
              children: [
                ...item.items
                  .map((child) => {
                    return { value: child.code, label: child.name }
                  })
              ]
            }
          })
        setOption(shipmentArea)
      } else {
        message.error(res.message)
      }
    })
  }, [])

  const validateMessages = {
    required: '${name} is required!',
    types: {
      email: '${label} is not a valid email!'
    }
  }

  return (
    <Modal visible={isModalVisible} destroyOnClose={true} centered closable={false} footer={null}>
      <Form
        onFinish={onFinish}
        validateMessages={validateMessages}
        initialValues={{ phone: addressInfo?.phone, street: addressInfo?.street, city: addressInfo?.city }}
      >
        <Row justify='space-between'>
          <Col>
            <Button
              type='ghost'
              onClick={() => {
                cancel()
              }}
            >
              取消
            </Button>
          </Col>
          <Col>{edit ? <Title level={4}>Edit Customer</Title> : <Title level={4}>Create New Customer</Title>}</Col>
          <Col>
            <Button type='primary' htmlType='submit'>
              储存
            </Button>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={11}>
            <Form.Item name='phone'>
              <Input placeholder='phone' />
            </Form.Item>
          </Col>

          <Col span={11} offset={2}>
            <Form.Item name='street'>
              <Input placeholder='street' />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <Form.Item name='shipmentareas' rules={[{ type: 'array', required: true, message: 'Please select your shipment area!' }]}>
              <Cascader options={option} />
            </Form.Item>
          </Col>
          {paymentPage ? null : (
            <Col span={11} offset={2}>
              <Form.Item name='zipCode'>
                <Input placeholder='Zip Code' />
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    </Modal>
  )
}

export default ShippingAddress
