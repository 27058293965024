const EN_US = {
  checkout: '',
  hold: 'Hold',
  takeOnHold: 'UnHold',
  cart: 'Cart',
  categories: 'All Categories',
  productTotal: 'Total ',
  productUnit: ' ',
  page: 'Page',
  custom: 'Custom Sale',
  email: 'Email',
  lang: 'Lang',
  logo: 'Logo',
  nickname: 'NickName',
  accountInfo: 'Account Information',
  changePwd: 'Change Password',
  displayName: 'Company',
  displayAddress: 'Address',
  managerComment: 'Comment',
  currPwd: 'Current Password',
  newPwd: 'New Password',
  cnfPwd: 'Confirmation',
  submit: 'Submit',
  search: 'Search by email/name/phone',
  order: 'Submit Order',
  shipping: 'Delivery',
  address: 'Address',
  casher: 'Casher',
  subtotal: 'Subtotal',
  shippingFee: 'Shipping',
  total: 'Total',
  totalPaid: 'Total Paid',
  totalWeight: 'Total Weight',
  invoiceNo: 'Invoice #',
  invoiceDate: 'Created',
  invoicePaidAt: 'Paid',
  invoiceGST: 'GST No',
  invoiceGSTAmount: 'Total GST',
  invoiceFooter: 'Thank for your support',
  customer: 'Customer',
  billto: 'Bill To',
  customerCompany: '',
  customerAddress: 'Address',
  adjustment: 'Adjustment',
  point: 'Points',
  memberShip: 'Member',
  add: 'Add',
  update: 'Update',
  discount: 'Discount',
  customerPurchase: 'Place Order',
  shipmentEdit: 'Edit',
  shipmentComplete: 'Complete',
  shipmentDelete: 'Delete',
  parcelShipmentCreate: 'Create',
  parcelShipmentUpdate: 'Modify',
  parcelShipmentWeight: 'Weight',
  parcelShipmentCancel: 'Destroy',
  parcelShipmentPrint: 'Print',
  parcelShipmentReprint: 'Reprint',
  parcelShipmentCreateComment: 'Comment',
  parcelShipmentUpdateComment: 'Comment',
  parcelShipmentPrintComment: 'Comment',
  parcelShipmentPrintUrlTitle: 'Label Download',
  parcelShipmentPrintButton: 'Print Label',
  parcelShipmentCancelComment: 'Reason',
  parcelShipmentItemsTitle: 'Customized Parcel Items',
  parcelShipmentConsigneeName: 'Consignee Name',
  parcelShipmentConsigneePhone: 'Consignee Phone',
  parcelShipmentConsigneeID: 'Consignee ID',
  parcelShipmentConsigneeAddress: 'Consignee Address',
  parcelShipmentItemsPlaceholder: 'Go Health Fish Oil 1000mg 200c * 2\r\nGood Health Lection 1500mg 100c * 3\r\n Comvita Manuka Honey UMF10+ 500g * 1',
  parcelShipmentWeightTitle: 'Weight(kg)',
  idCardFront: 'Front',
  idCardRear: 'Rear',
  surcharge: 'Surcharge',
  productsTotalMoney: 'Total',
  shipmentFee: 'Shipping Fee',
  totalMoney: 'Total Due',
  specialPrice: 'special',
  wholesale: 'Bulk',
  limited: 'Limited',
  onlyLeft: 'only left',
  unit: 'left',
  outOfStock: 'out of stock',
  market: 'the same as market',
  afterOpen: 'after open',
  day: 'day',
  month: 'month(s)',
  unlimited: 'unlimited',
  minUnit: 'units',
  shipmentMethod: 'Shipment Methods',
  payment: 'Payment',
  invoicePayment: 'Payment',
  cash: 'Cash',
  use: 'Amount',
  weight: 'Weight',
  item: 'Item',
  qty: 'Qty',
  cashAmount: 'Fill in cash received',
  deposit: 'credit',
  available: 'available',
  useAll: 'use all',
  useCard: 'Eftpos',
  other: 'Other',
  offlinePayment: 'Offline',
  orderNumNote: '# please input order number before payment #',
  bankTransfer: 'New Zealand bank transfer',
  anzAccount: 'ANZ account: 06-0229-0894524-01 account name: BUYANZ',
  wechatPay: 'WechatPay: buyanz01, name: Yongxing Lin',
  aliPay: 'AliPay: yvonne@kiwipainting.co.nz, name: Jiliang Zhang',
  paymentScreenshoot: 'Payment ScreenShoot: (please upload your payment screenshoot to avoid the invalidation of your order)',
  attention: 'Note: Don\'t submit order until customer makes payment and payment option is ticked accordingly',
  backToOrder: 'Back To Order',
  paymentReceive: 'Payment Receive',
  remainMoney: 'Remain Money',
  partialShip: 'Partial Shipped',
  printOrder: 'Print Order',
  confirm: 'Confirm',
  cancel: 'Cancel',
  partialReceive: 'Partial Receive',
  changeAmount: 'Fill in change received',
  changeRequired: 'Change',
  changeReceived: 'Changed',
  cashReceived: 'Cash Received',
  totalDue: 'Balance Owed',
  orderNum: 'Order',
  cancelOrder: 'Cancel',
  orderPayment: 'Payment',
  orderRefund: 'Refund',
  orderLock: 'Lock',
  orderPickup: 'Pickup',
  orderDelivery: 'Delivery',
  orderPrint: 'Print',
  reOrder: 'Reorder',
  lockOrder: 'Lock',
  orderNote: 'Notes',
  freeShipment: 'Free Shipping',
  store: 'Store',
  warehouse: 'Warehouse',
  orderSuccessful: 'Order has been created successfully',
  orderId: 'Order Id',
  emailOrder: 'Email order',
  orderDetails: 'Order detail',
  createOrder: 'Create a new order',
  itemsOrder: 'items',
  infinity: 'infinity',
  max: 'Max',
  amount: 'Amount',
  describe: 'Description',
  stock: 'Stock',
  price: 'Price',
  expired: 'Expired',
  purchase: 'Purchase',
  hasStock: 'sufficient stock',
  checkCashBox: 'Check Cash Box',
  checkLater: 'Later',
  checkNow: 'Check now',
  upload: 'Upload',
  oldCashBoxTotal: 'System Cash Box',
  newCashBoxTotal: 'New Cash Box',
  comment: 'Comment',
  wrongCashBox: 'Wrong cash amount, please upload pictures',
  account: 'Account',
  cashBoxInfo: 'Cash Box Info',
  code: 'Cash Box Code',
  status: 'Cash Box Status',
  storeId: 'Store Id',
  managerId: 'Manager Id',
  cashBoxName: 'Cash Box Name',
  currency: 'Cash Box Currency',
  createdAt: 'Cash Box Created Time',
  updatedAt: 'Cash Box Updated Time',
  cashBoxWithdraw: 'CashBox Withdraw',
  cashBoxDeposit: 'CashBox Deposit',
  searchAll: 'Search All',
  depositCashBox: 'Deposit',
  afterDepositCashBox: 'After Deposit',
  withdrawCashBox: 'Withdraw',
  afterWithdrawCashBox: 'After Withdraw',
  remainCashBox: 'Difference',
  currentCash: 'Current',
  managerName: 'Manager Name',
  storeName: 'Store Name',

  // new slider layout 
  addOneFee: 'Adjustment',
  note: 'Note',
  coupon: 'Coupon',
  couponCode: 'Coupon Code',
  couponAmount: 'Coupon Amount',
  name: 'Name',
  customerName: 'Name',
  type: 'Type',
  surname: 'Surname',
  phone: 'Phone',
  shipmentareas: 'Areas',
  street: 'Street',
  city: 'City',
  zipCode: 'PostCode',
  idCard: 'Id Card',
  cover: 'Id Photos',
  defaultAddress: 'Default',
  searchAddress: 'Search',
  up: "Last Page",
  down: 'Next Page',
  totalPage: 'Total',
  pickup: 'Pickup',
  addone: 'Addon',
  fee: 'Fee',

  notice: 'Notice',
  shipmentArea: 'Shipment Area',
  shippingMethod: 'Shipping Method'
};
export default EN_US;
