import React, { useEffect } from 'react'
import MenuLayout from '../components/Layout/menuLayout'
import Coupon from '../components/couponList';
import { setMenuDisplay } from '../redux/reducers/menu_reducer';
import { useAppDispatch } from '../redux/reduxHooks';

const CouponHistory = () => {
  const  dispatch  = useAppDispatch()
  useEffect(() => {
    dispatch(setMenuDisplay({
      menu: false
    }))
  }, [])
  return (
    <div>
      <MenuLayout/>
      <Coupon/>
    </div>
  )
}

export default CouponHistory
