import { Cascader, Input, Select, Switch } from 'antd'
import React from 'react'
import { Controller } from 'react-hook-form'
import { LabelControlType } from '../Models/LabelControlType.enum'
import { resources, resources_eng } from '../Resources/resources'
import { ErrorMessage, Label, LabelWithInput, PictureUploadStyle } from './LabelControl.style'
import { FormFields } from '../Models/FormFieldsName.enum'
import PicturesWall from '../../../components/common/picture_wall'
import { FormattedMessage } from 'react-intl'
import { useAppSelector } from '../../../redux/reduxHooks'
import { getShipmentAreaAndGroupId } from '../../../redux/reducers/customerInfo_reducer'

interface Props {
  formControl: any
  type: LabelControlType
  formName: FormFields
  label: string
  options?: { value: string | number; label: string; children?: { value: string | number; label: string }[] }[]
  pictureRef?: React.MutableRefObject<any>
  pictureRef2?: React.MutableRefObject<any>
  disable?: boolean
}
const { TextArea } = Input
const LabelControl = ({ formControl, type, formName, label, options, pictureRef, pictureRef2, disable }: Props) => {
  const { areaCode, groupId } = useAppSelector(getShipmentAreaAndGroupId)

  if (type === LabelControlType.Upload) {
    return (
      <LabelWithInput style={{ marginLeft: '-1.5rem' }} file={formName}>
        <Label isAddOne={false}>
          <FormattedMessage id={label} />:
        </Label>
        <PictureUploadStyle>
          <PicturesWall
            ref={pictureRef}
            number={1}
            fileName='venus-idcard'
            hintName={`${<FormattedMessage id='idCardFront' />}`}
          />
          <PicturesWall
            ref={pictureRef2}
            number={1}
            fileName='venus-idcard'
            hintName={`${<FormattedMessage id='idCardRear' />}`}
          />
        </PictureUploadStyle>
      </LabelWithInput>
    )
  }
  return (
    <LabelWithInput
      file={formName}
      style={{
        marginLeft:
          formName === FormFields.Name
            ? '0.9rem'
            : formName === FormFields.DefaultAddress || formName === FormFields.Note || formName === FormFields.AddOneFee
            ? '-1.5rem'
            : ''
      }}
    >
      <Label isAddOne={formName === FormFields.AddOneFee || formName === FormFields.Note}>
        {(formName === FormFields.Shipmentareas ||
          formName === FormFields.Name ||
          formName === FormFields.Nickname) && <span style={{ color: 'red', marginRight: '0.5rem' }}>*</span>}
        <FormattedMessage id={label} />:
      </Label>
      <Controller
        control={formControl}
        name={formName}
        rules={{
          pattern: { value: formName === FormFields.AddOneFee ? /^[+-]?\d+(\.\d+)?$/ : /^[\d\w\s\S]+$/, message: '输入数字' },
          required: {
            value:
              formName === FormFields.AddOneFee ||
              formName === FormFields.Name ||
              formName === FormFields.Shipmentareas ||
              formName === FormFields.Nickname 
                ? true
                : false,
            message: 'Required'
          },
          validate: {
            validateEmailAddress: (v) => {
              if (formName === FormFields.Email && v) {
                return (
                  v.match(
                    /^([a-zA-Z0-9_\-.']+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z0-9]{2,20})(\]?)$/
                  ) || 'Email format is not true'
                )
              }

              return true
            }
          }
        }}
        render={({ field, fieldState }) => {
          switch (type) {
            case LabelControlType.Input:
              return (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <Input
                    placeholder={localStorage.getItem('lang') === 'zh-CN' ? resources[label] : resources_eng[label]}
                    addonBefore={formName === FormFields.AddOneFee ? '$' : ''}
                    status={fieldState.error?.message ? 'error' : ''}
                    suffix={formName === FormFields.AddOneFee ? 'NZD' : ''}
                    value={field.value}
                    onChange={(e) => field.onChange(e)}
                  />
                  <ErrorMessage>{fieldState.error?.message}</ErrorMessage>
                </div>
              )
            case LabelControlType.TextArea:
              return <TextArea rows={3} autoSize={false} value={field.value} onChange={(e) => field.onChange(e)} />
            case LabelControlType.Select:
              return <Select value={field.value} options={options ?? []} onChange={(e) => field.onChange(e)} style={{ width: '100%' }} />
            case LabelControlType.Cascader:
              return (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <Cascader
                    disabled={disable}
                    options={options}
                    style={{ width: '100%' }}
                    value={field.value}
                    onChange={(e) => field.onChange(e)}
                    status={fieldState.invalid ? 'error' : ''}
                  />
                  <ErrorMessage>{fieldState.error?.message}</ErrorMessage>
                </div>
              )
            case LabelControlType.Switch:
              return <Switch onChange={(e) => field.onChange(e)} />
            default:
              return <></>
          }
        }}
      />
    </LabelWithInput>
  )
}

export default LabelControl
