import { Row, Col, Button } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { round } from 'lodash'
import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import CashBox from '../cashItemsLayout'
import PicturesWall from '../common/picture_wall'
import NP from 'number-precision'

const PictureUploadStyle = styled.div`
  & .ant-upload-list-item-container,
  .ant-upload-select {
    width: 80px !important;
    height: 80px !important;
  }
`
const DifferenceNumStyle = styled.span<{ error: boolean }>`
  color: ${(props) => (props.error ? 'red' : '')};
  font-size: 1rem;
  margin-left: 1rem;
`

interface Props {
  cashItemsFromSystem: { value: number; quantity: number }[]
  cashItems: { value: number; quantity: number }[]
  setCashItems: (a: { value: number; quantity: number }[]) => void
  setComment: (a: string) => void
  loading: boolean
  checkLaterButtonHandler?: () => void
  checkNowButtonHandler: () => void
  pictureRef: React.MutableRefObject<any>
  type: string
  cashTotalType: string
  cashEditType: string
  remainCashType: string
}
const CashBoxLayout = ({
  cashItemsFromSystem,
  cashItems,
  setComment,
  loading,
  checkLaterButtonHandler,
  checkNowButtonHandler,
  setCashItems,
  pictureRef,
  type,
  cashTotalType,
  cashEditType,
  remainCashType
}: Props) => {
  const computeResult = useMemo(() => {
    const cashItemsFromSystemTotal = cashItemsFromSystem.reduce((pre, nex) => {
      return NP.plus(pre, nex.quantity * nex.value)
    }, 0)
    const cashItemsTotal = cashItems.reduce((pre, nex) => {
      return NP.plus(pre, nex.quantity * nex.value)
    }, 0)
    if (remainCashType === 'afterDepositCashBox') {
      return NP.plus(cashItemsFromSystemTotal, cashItemsTotal)
    }
    return NP.minus(cashItemsFromSystemTotal, cashItemsTotal)
  }, [remainCashType, cashItemsFromSystem, cashItems])

  return (
    <Col>
      <Row justify='center' style={{ fontWeight: 700, fontSize: '1.5rem'}}>
        <FormattedMessage id={type ? type : 'checkCashBox'} />
      </Row>
      <Row justify='center' style={{marginTop: '1rem'}}>
        <Col span={6} style={{ fontSize: '15px' }}>
          <FormattedMessage id={cashTotalType ? cashTotalType : 'oldCashBoxTotal'} />: ${' '}
          {round(
            cashItemsFromSystem.reduce((pre, nex) => {
              return NP.plus(pre, nex.quantity * nex.value)
            }, 0),
            1
          )}
        </Col>

        <Col span={6} style={{ fontSize: '1rem', marginLeft: '1rem' }}>
          <FormattedMessage id={cashEditType ? cashEditType : 'newCashBoxTotal'} />: ${' '}
          {round(
            cashItems.reduce((pre, nex) => {
              return NP.plus(pre, nex.quantity * nex.value)
            }, 0),
            1
          )}
        </Col>
        <Col span={6}>
        <DifferenceNumStyle
          error={
            type === 'checkCashBox' &&
            NP.minus(
              cashItemsFromSystem.reduce((pre, nex) => {
                return NP.plus(pre, nex.quantity * nex.value)
              }, 0) -
                cashItems.reduce((pre, nex) => {
                  return NP.plus(pre, nex.quantity * nex.value)
                }, 0)
            ) !== 0
          }
        >
          <FormattedMessage id={remainCashType} />: ${computeResult}
        </DifferenceNumStyle>
        </Col>
      </Row>
      <Row justify='center' style={{marginTop: '1rem'}}>
        <CashBox type='deposit' setCashItems={(items) => setCashItems(items)} cashItems={cashItems} />
      </Row>
      <Row>
      <Col span={10}>
        <PictureUploadStyle>
          <PicturesWall ref={pictureRef} number={2} />
        </PictureUploadStyle>
      </Col>
      <Col span={14}>
        <TextArea style={{height:'80px'}}
          placeholder={localStorage.getItem('lang') === 'zh-CN' ? '备注...' : 'comments...'}
          rows={2} onChange={(e) => { setComment(e.target.value)}}
          />
      </Col>
      </Row>
      <Row justify='end'>
        <Col span={3} offset={1}>
          <Button type='primary' htmlType='submit' block loading={loading} onClick={checkLaterButtonHandler}>
            <FormattedMessage id='checkLater' />
          </Button>
        </Col>
        <Col span={3} offset={1}>
          <Button type='primary' htmlType='submit' block loading={loading} onClick={checkNowButtonHandler}>
            <FormattedMessage id='checkNow' />
          </Button>
        </Col>
      </Row>
    </Col>
  )
}

export default CashBoxLayout
