import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { message } from 'antd'
import { bundledProduct, getProductInventory, getProducts, groupedProduct, productSummary } from '../../api/response'
import { BundledProductResTypes, Item, ProductInventoryData, ProductSummaryTypes, QueryReqTypes } from '../../models/productQuery'
import { ProductInfo } from '../../models/shoppingCartItem'
import { RootState } from '../store'

type initialStateTypes = {
  products: ProductInfo[]
  totalCount: number
  pageCount: number,
  currentPage: number,
  hasMore: boolean
  groupData: ProductInfo[]
  groupModalVisible: boolean
  selectedGroupData: ProductInfo | null
  bundledProductSummary: ProductSummaryTypes
  twodProductSummary: ProductSummaryTypes
  twodProductSummaryProducts: ProductInfo[]
  bundledProductPrice: number
  bundledProductId: number
  bundledProductData: BundledProductResTypes[]
  bundledProductFinalPrice: number
  bundledProductVisible: boolean
  productAttrVisible: boolean
  productSummary: ProductSummaryTypes
}

const initialState: initialStateTypes = {
  groupData: [],
  products: [],
  groupModalVisible: false,
  selectedGroupData: null,
  totalCount: 0,
  pageCount: 0,
  currentPage: 0,
  hasMore: true,
  bundledProductSummary: null,
  bundledProductPrice: 0,
  bundledProductId: 0,
  bundledProductData: [],
  bundledProductFinalPrice: 0,
  bundledProductVisible: false,
  twodProductSummary: null,
  twodProductSummaryProducts: [],
  productAttrVisible: false,
  productSummary: null
}

export const getGroupProductInfo = createAsyncThunk(
  'product/getGroupProductInfo',
  async (param: { id: number; item: ProductInfo }, { dispatch }) => {
    const response = await groupedProduct(param.id)
    // The value we return becomes the `fulfilled` action payload
    if (response.success) {
      dispatch(setSelectedGroupData({ item: param.item }))
      dispatch(setGroupProducts({ products: response.data }))
    } else {
      message.error(response.message)
    }
    return response
  }
)

export const getBundleProductInfo = createAsyncThunk(
  'product/getBundleProductInfo',
  async (param: { id: number; item: ProductInfo; customerId: number }, { dispatch }) => {
    const summaryResponse = await productSummary(param.id, { customerId: param.customerId })
    const bundledResponse = await bundledProduct(param.id)
    // The value we return becomes the `fulfilled` action payload
    if (summaryResponse.success && bundledResponse.success) {
      dispatch(setBundledPrice({ price: param.item.price }))
      dispatch(setBundledId({ id: param.item.productId }))
      dispatch(setBundledFinalPrice({ finalPrice: param.item.finalPrice }))
    } else {
      if (!summaryResponse.success) {
        message.error(summaryResponse.message)
      } else if (!bundledResponse.success) {
        message.error(bundledResponse.message)
      } else {
        message.error(bundledResponse.message)
        message.error(summaryResponse.message)
      }
    }
    return { summaryResponse, bundledResponse }
  }
)

export const getProductInfo = createAsyncThunk(
  'product/getProductInfo',
  async (param: { id: number; typeId: string; customerId: number }, { dispatch }) => {
    const summaryResponse = await productSummary(param.id, { customerId: param.customerId })

    // The value we return becomes the `fulfilled` action payload
    return { summaryResponse }
  }
)


export const getAllProducts = createAsyncThunk('product/getAllProduct', async (param: QueryReqTypes) => {
  const response = await getProducts(param)
  // The value we return becomes the `fulfilled` action payload

  return response
})

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProductsEmpty: (state) => {
      state.products = []
    },
    changeTotalCount: (state, action: PayloadAction<{ total: number }>) => {
      state.totalCount = action.payload.total
    },
    setHasMore: (state, action: PayloadAction<{ hasmore: boolean }>) => {
      state.hasMore = action.payload.hasmore
    },
    setGroupModalVisible: (state, action: PayloadAction<{ visible: boolean }>) => {
      state.groupModalVisible = action.payload.visible
    },
    setGroupProducts: (state, action: PayloadAction<{ products: ProductInfo[] }>) => {
      state.groupData = action.payload.products
    },
    setSelectedGroupData: (state, action: PayloadAction<{ item: ProductInfo }>) => {
      state.selectedGroupData = action.payload.item
    },
    setBundledProductSummary: (state, action: PayloadAction<{ summary: ProductSummaryTypes }>) => {
      state.bundledProductSummary = action.payload.summary
    },
    setBundledPrice: (state, action: PayloadAction<{ price: number }>) => {
      state.bundledProductPrice = action.payload.price
    },
    setBundleData: (state, action: PayloadAction<{ data: BundledProductResTypes[] }>) => {
      state.bundledProductData = action.payload.data
    },
    setBundledId: (state, action: PayloadAction<{ id: number }>) => {
      state.bundledProductId = action.payload.id
    },
    setBundledFinalPrice: (state, action: PayloadAction<{ finalPrice: number }>) => {
      state.bundledProductFinalPrice = action.payload.finalPrice
    },
    setBundledVisible: (state, action: PayloadAction<{ visible: boolean }>) => {
      state.bundledProductVisible = action.payload.visible
    },
    setProductAttrVisible: (state, action: PayloadAction<{ visible: boolean }>) => {
      state.productAttrVisible = action.payload.visible
    },
    setProductSummary: (state, action: PayloadAction<{ data: ProductSummaryTypes }>) => {
      state.productSummary = action.payload.data
    }
  },
  extraReducers: (build) => {
    build
      .addCase(getGroupProductInfo.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.groupModalVisible = true
        } else {
          state.groupModalVisible = false
        }
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        if (action.payload.success) {
          const { items, pagination } = action.payload.data
          // const arr = [...state.products, ...data]

          state.products = items
          state.totalCount = pagination.totalCount
          state.pageCount = pagination.pageCount
          state.currentPage = pagination.currentPage
          state.hasMore = state.currentPage < state.pageCount
          // if (arr.length <= totalCount) {
          //   state.hasMore = false
          //   return
          // }
        } else {
          message.error(action.payload.message)
        }
      })
      .addCase(getBundleProductInfo.fulfilled, (state, action) => {
        if (action.payload.bundledResponse.success && action.payload.summaryResponse.success) {
          state.bundledProductSummary = action.payload.summaryResponse.data
          state.bundledProductData = action.payload.bundledResponse.data
          state.bundledProductVisible = true
        } else {
          state.bundledProductData = []
          state.bundledProductSummary = null
          state.bundledProductVisible = false
        }
      })
      .addCase(getProductInfo.fulfilled, (state, action) => {
        if (action.payload.summaryResponse.success) {
          const { data } = action.payload.summaryResponse
          state.productSummary = data
          if (data.attributes.length > 0 && action.meta.arg.typeId === 'variant') {
            state.productAttrVisible = true
          }
        } else {
          message.error(action.payload.summaryResponse.message)
        }
      })
  }
})

export const {
  setHasMore,
  setProductsEmpty,
  changeTotalCount,
  setGroupModalVisible,
  setSelectedGroupData,
  setBundledPrice,
  setBundledId,
  setBundledFinalPrice,
  setBundledVisible,
  setGroupProducts,
  setProductSummary,
  setProductAttrVisible
} = productsSlice.actions //= connect(dispatch)

export const productsData = (state: RootState) => state.products // = connect(state)

export default productsSlice.reducer
