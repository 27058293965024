import dayjs from "dayjs";
import { Expiration } from "../models/productQuery";

export const expirationConvert = (text: Expiration | undefined)=>{
  if (!text) {
    return ''
  }
  if(localStorage.getItem('lang') === 'zh-CN'){
    switch (text.type) {
      case 0:
        return '无'
      case 1:
        return '与市场同步'
      case 2:
        return `${dayjs(text.date).format('D/M/YYYY')}`
      case 3:
        return `${dayjs(text.date).format('M/YYYY')}`
      case 4:
        return `开封后${dayjs(text.date).format('D')}天`
      case 5:
        return `开封后${dayjs(text.date).format('MM')}个月`
      default:
        break
    }
  }else{
    switch (text.type) {
      case 0:
        return 'None'
      case 1:
        return 'the same as market'
      case 2:
        return `${dayjs(text.date).format('D/M/YYYY')}`
      case 3:
        return `${dayjs(text.date).format('M/YYYY')}`
      case 4:
        return `After open${dayjs(text.date).format('D')}days`
      case 5:
        return `After open${dayjs(text.date).format('MM')}Months`
      default:
        break
    }
  } 

}