/* eslint-disable import/no-anonymous-default-export */
import Account from '../pages/account';
import CashBoxHistory from '../pages/cashBoxHistory';
import CashBoxListPage from '../pages/cashBoxList';
import CouponHistory from '../pages/couponHistory';
import CreditHistory from '../pages/creditHistory';
import CustomerCenter from '../pages/customerCenter';
import Index from '../pages/index';
import Login from '../pages/login';
import NotFound from '../pages/notFound';
import OrderHistory from '../pages/orderHistory';
import PaymentHistory from '../pages/paymentHistory';
import paymentSuccess from '../pages/paymentSuccess';
import MakeOrder from '../pages/placeorder';
import PointHistory from '../pages/pointHistory';
export default [
  { path: '/', name: 'Index', component: Index, auth: true },
  {
    path: '/settings/account',
    name: 'Account',
    component: Account,
    auth: true
  },
  { path: '/login', name: 'Login', component: Login },
  { path: '/order', name: 'OrderHistory', component: OrderHistory, auth: true },
  { path: '/customer_management', name: 'Deposit', component: CustomerCenter, auth: true },
  { path: '/placeorder', name: 'Placeorder', component: MakeOrder, auth: true},
  { path: '/placeorder/paymentsuccess', name: 'paymentsuccess', component: paymentSuccess, auth: true },
  { path: '/pointHistory', name: 'Point', component: PointHistory, auth: true },
  { path: '/creditHistory', name: 'Credit', component: CreditHistory, auth: true },
  { path: '/paymentHistory', name: 'PaymentHistory', component: PaymentHistory, auth: true },
  { path: '/coupon', name: 'Coupon', component: CouponHistory, auth: true },
  { path: '/cashBoxList', name: 'CashBox', component: CashBoxListPage, auth: true },
  { path: '/404', name: 'notfound', component: NotFound }
];
