/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import SliderLayout from '../components/Layout/siderLayout'
import Placeorder from '../components/payment/placeorder'

const MakeOrder = (props) => {
  
  const [showCart, setShowCart] = useState(1)
  return (
    <div style={{ display: 'flex' }}>
      {showCart
        ? (
        <div style={{ flex: 1 }}>
          <SliderLayout
            isPayment={true}
            query={props.location.query}
          />
        </div>
          )
        : null}

      <div style={{ flex: 2, background: '#EFF2F5' }}>
        <Placeorder
          showCart={(value:number) => {
            setShowCart(value)
          }}
        />
      </div>
    </div>
  )
}

export default MakeOrder
