import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CashBoxInfoTypes } from '../../models/cashBoxTypes'
import { ManagerStoreTypes } from '../../models/common'
import { RootState } from '../store'
import { CheckoutResTypes } from '../../models/checkoutTypes'

type initialStateTypes = {
  lang: string
  comment: string
  coupon: string
  credit: number
  adjustment: number
  adjustmentComment: string
  point: number
  checkoutPrice: number
  discount: number
  discountComment: string
  storesList: ManagerStoreTypes[]
  cashBoxInfo: CashBoxInfoTypes
  onHoldList: any[]
  checkoutInfo: CheckoutResTypes
  walkinId: number[],
  onholdListIds:number[]
}

const initialState: initialStateTypes = {
  lang: 'zh-CN',
  comment: '',
  coupon: '',
  credit: 0,
  adjustment: 0,
  adjustmentComment: '',
  point: 0,
  checkoutPrice: 0,
  discount: 0,
  discountComment: '',
  storesList: [],
  cashBoxInfo: null,
  onHoldList: [],
  checkoutInfo: null,
  walkinId: [1, 2, 3, 4, 5],
  onholdListIds:[]
}

export const homepageSlice = createSlice({
  name: 'homepage',
  initialState,
  reducers: {
    setComment: (state, action: PayloadAction<{ comment: string }>) => {
      state.comment = action.payload.comment
    },
    setCoupon: (state, action: PayloadAction<{ coupon: string }>) => {
      state.coupon = action.payload.coupon
    },
    setCredit: (state, action: PayloadAction<{ credit: number }>) => {
      state.credit = action.payload.credit
    },
    setCheckoutPrice: (state, action: PayloadAction<{ checkoutPrice: number }>) => {
      state.checkoutPrice = action.payload.checkoutPrice
    },
    setPoint: (state, action: PayloadAction<{ point: number }>) => {
      state.point = action.payload.point
    },
    setDiscount: (state, action: PayloadAction<{ discount: number }>) => {
      state.discount = action.payload.discount
    },
    setDiscountComment: (state, action: PayloadAction<{ discountComment: string }>) => {
      state.discountComment = action.payload.discountComment
    },
    setAdjustmentComment: (state, action: PayloadAction<{ adjustmentComment: string }>) => {
      state.adjustmentComment = action.payload.adjustmentComment
    },
    setAdjustment: (state, action: PayloadAction<{ adjustment: number }>) => {
      state.adjustment = action.payload.adjustment
    },
    setStoresList: (state, action: PayloadAction<{ storesList: ManagerStoreTypes[] }>) => {
      state.storesList = action.payload.storesList
    },
    setLanguage: (state, action: PayloadAction<{ lang: string }>) => {
      state.lang = action.payload.lang
    },
    setCashBoxInfo: (state, action: PayloadAction<{ cashBoxInfo: CashBoxInfoTypes }>) => {
      state.cashBoxInfo = action.payload.cashBoxInfo
    },
    setOnHoldList: (state, action: PayloadAction<{ list: any[] }>) => {
      state.onHoldList = action.payload.list
    },
    setCheckoutInfo: (state, action: PayloadAction<{ checkout: CheckoutResTypes }>) => {
      state.checkoutInfo = action.payload.checkout
    },
    setWalkinIds: (state, action: PayloadAction<{ ids: number[] }>) => {
      state.walkinId = action.payload.ids
    },
    setOnHoldListIds: (state, action: PayloadAction<{ ids: number[] }>) => {
      state.onholdListIds = action.payload.ids
    }
  }
})

export const {
  setComment,
  setCoupon,
  setCredit,
  setCheckoutPrice,
  setPoint,
  setDiscount,
  setDiscountComment,
  setAdjustment,
  setAdjustmentComment,
  setStoresList,
  setLanguage,
  setCashBoxInfo,
  setOnHoldList,
  setCheckoutInfo,
  setWalkinIds,
  setOnHoldListIds
} = homepageSlice.actions //= connect(dispatch)
export const lang = (state: RootState) => state.homepageInfo.lang // = connect(state)
export const homePageData = (state: RootState) => state.homepageInfo // = connect(state)
export const getOnHoldList = (state: RootState) => state.homepageInfo.onHoldList // = connect(state)
export const getOnHoldListIds = (state: RootState) => state.homepageInfo.onholdListIds // = connect(state)
export const getWalkinIds = (state: RootState) => state.homepageInfo.walkinId // = connect(state)
export const getCheckoutInfo = (state: RootState) => state.homepageInfo.checkoutInfo // = connect(state)

export default homepageSlice.reducer
