/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */
import {
  Card,
  Col,
  Layout,
  List,
  Row,
  Avatar,
  Button,
  Typography,
  Badge,
  Menu,
  Dropdown,
  Divider,
  Modal,
  Form,
  Input,
  message,
  Tooltip
} from 'antd'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useRouteMatch } from 'react-router-dom'
import {
  EllipsisOutlined,
  CloseOutlined,
  LeftOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  CloseCircleOutlined
} from '@ant-design/icons'
import styled from 'styled-components'
import { checkout, orderDetail } from '../../api/response'
import {
  customerInfoData,
  getOneCustomerInfo,
  getOnHoldListCustomerInfo,
  setCartIsEmpty,
  setCustomerChoose,
  setCustomerId,
  setCustomerInfo,
  setCustomerName
} from '../../redux/reducers/customerInfo_reducer'
import { useAppDispatch, useAppSelector } from '../../redux/reduxHooks'
import {
  homePageData,
  setAdjustment,
  setAdjustmentComment,
  setCheckoutPrice,
  setComment,
  setCoupon,
  setCredit,
  setDiscount,
  setDiscountComment,
  setPoint
} from '../../redux/reducers/homePage_reducer'
import { CheckoutResTypes } from '../../models/checkoutTypes'
import {
  deleteAllShoppingCart,
  deleteOneItemFromCart,
  getAllShoppingCartItems,
  getCurrencyRate,
  setCartChange,
  setCartIncrease,
  setCartItems,
  setSubTotalPrice,
  setTotalPrice,
  shoppingCartData,
  updateShoppingCartQuantity
} from '../../redux/reducers/cartItems_reducer'
import { QueryParams } from '../../models/common'
import CustomerManage from '../manageCustomer'
import { CustomerTypes } from '../../models/customerTypes'
import OnHoldList from '../common/onholdList'
import DiscountCard from '../discountCard'
import { orderData, setChoosedAddress, setPlaceorderSuccess } from '../../redux/reducers/orders_reducer'
import { forEach, isEmpty, isNil } from 'lodash'
import { ProductTypeExtraItem } from '../../models/shoppingCartItem'
import api from '../../config/api'
import { deprecate } from 'util'

const { Header, Content } = Layout
const { Text } = Typography

const EllipsisIcon = styled(EllipsisOutlined)`
  font-size: 1.5rem;
  color: gray;
`

const StyledLayout = styled(Layout)`
  position: sticky;
  top: 0;
  z-index: 10;
  max-height: 100vh;
`
const StyledContent = styled(Content)`
  background-color: #fff;
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  .ant-empty {
    display: none;
  }
`
const StyledHeader = styled(Header)`
  position: sticky;
  margin: 1px;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  background-color: orange !important;
  justify-content: center;
`

const Title = styled(Col)`
  overflow: hidden;
  font-size: 1rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

const ListStyle = styled(Row)`
  position: relative;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  padding: 10px;
  :after {
    content: '';
    position: absolute;
    bottom: 0;
    background: #ccc;
    width: 100%;
    height: 100%;
    height: 1px;
  }
`
const ModalFormSubmit = styled(Form.Item)`
  position: absolute;
  bottom: 0;
  right: 8em;
  margin-bottom: 10px;
`
const Div = styled.div`
  width: 100%;
  box-sizing: border-box;
`
const ColStyle = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ListItemStyle = styled(List.Item)`
  & .ant-list-item-action {
    margin-inline-start: 0 !important;
  }
`

interface Props {
  isPayment: boolean
  query?: QueryParams
}
/**
 * @deprecated This component is no longer maintained. Use the new Slider component instead.
 */
const SliderLayout = (props: Props) => {
  const { isPayment } = props
  const history = useHistory()
  const match = useRouteMatch<{ id: string }>()
  const [addDiscount, setAddDiscount] = useState(false)
  const [addNote, setAddNote] = useState(false)
  const [customerManager, setCustomerManager] = useState(false)
  const [walkinId, setWalkinId] = useState([1, 2, 3, 4, 5])
  const [discountAmount, setDiscountAmount] = useState(0)
  const [adjustAmount, setAdjustAmount] = useState(0)
  const [onHoldIdList, setOnHoldList] = useState([])
  const [holdListVisible, setHoldListVisible] = useState(false)
  const [discountInfo, setDiscountInfo] = useState<
    {
      coupon: string
      amount: string
      description: string
      adjustment: string
      adjustmentComment: string
    }[]
  >([])
  const [loading, setLoading] = useState(false)
  const [checkoutCartInfo, setCheckoutCartInfo] = useState<CheckoutResTypes | undefined>()
  const { customerChoose, customerId, customerGroupId, cartIsEmpty } = useAppSelector(customerInfoData)
  const storeState = useAppSelector(homePageData)
  const { placeorderSuccess } = useAppSelector(orderData)
  const shoppingCartInfo = useAppSelector(shoppingCartData)
  const { cartItems: itemSelected, totalPrice: itemPrice } = useAppSelector(shoppingCartData)
  const dispatch = useAppDispatch()
  const walkinIds = [1, 2, 3, 4, 5]

  useEffect(() => {
    dispatch(getCurrencyRate())
  }, [])
  const menu = (
    <Menu>
      <Menu.Item>
        <div
          onClick={() => {
            setAddNote(true)
          }}
        >
          Add order note
        </div>
      </Menu.Item>
    </Menu>
  )

  function fetchData() {
    const storeId = +sessionStorage.getItem('storeId')
    dispatch(
      getAllShoppingCartItems({
        storeId,
        customerId,
        customerGroupId: customerGroupId
      })
    )
  }

  useEffect(() => {
    let isUnmount = false
    if (!isUnmount) {
      if (match.path.includes('payment')) {
        if (shoppingCartInfo.cartItems.length === 0) {
          orderDetail(match.params.id).then((res) => {
            dispatch(setTotalPrice({ totalPrice: res.data.totalDue }))
            dispatch(setSubTotalPrice({ subTotalPrice: res.data.subtotal }))
            dispatch(setPlaceorderSuccess({ success: true }))
            if (res.success) {
              dispatch(setCartItems({ cartItems: res.data.items as any[] }))
            }
          })
        }

        return
      }
      setCheckoutCartInfo(undefined)
      fetchData()
    }
    return () => {
      isUnmount = true
    }
  }, [shoppingCartInfo.count, localStorage.getItem('lang'), customerId])

  useEffect(() => {
    if (itemSelected.length > 0) {
      dispatch(setCartIsEmpty({ cartIsEmpty: false }))
    } else {
      dispatch(setCartIsEmpty({ cartIsEmpty: true }))

      setOnHoldList(onHoldIdList.filter((item) => item.id !== customerChoose.id))
    }
  }, [itemSelected])

  useEffect(() => {
    dispatch(setCartChange({ cartChanged: true }))
  }, [shoppingCartInfo.count])

  useEffect(() => {
    if (customerId !== 1 && isNil(sessionStorage.getItem('customerIdFromOrder'))) {
      dispatch(getOneCustomerInfo(customerId))
      return
    } else if (!isNil(sessionStorage.getItem('customerIdFromOrder'))) {
      dispatch(getOneCustomerInfo(+sessionStorage.getItem('customerIdFromOrder')))
      return
    }
    dispatch(getOneCustomerInfo(1))
  }, [customerId])
  const imageUrl = (img) => {
    let url = img
    if (!!img && (img.slice(0, 7) == 'http://' || img.slice(0, 8) == 'https://'))
      url = img
    else
      url = `${api.cdn}/${img}?x-oss-process=style/640w`
    return url
  }
  const discountList = () => {
    if (discountInfo?.length > 0 || props.query?.discountList.length > 0) {
      if (discountInfo?.length > 0) {
        return discountInfo.map((item, index) => {
          if (item.coupon !== '' && item.coupon && item.amount !== '' && item.amount && item.adjustment !== '' && item.adjustment) {
            return (
              <div key={index}>
                <ListStyle justify='space-between'>
                  <Col style={{ fontWeight: 500 }}>
                    <FormattedMessage id='discount' />:
                  </Col>
                  <Col
                    style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                    onClick={() => {
                      setDiscountInfo([{ ...discountInfo[0], amount: '', description: '' }])
                      setDiscountAmount(0)
                    }}
                  >
                    {item?.description || ''}$ -{item?.amount}
                    <span style={{ marginRight: '30px' }} />
                    <CloseCircleOutlined />
                  </Col>
                </ListStyle>
                <ListStyle justify='space-between'>
                  <Col style={{ fontWeight: 500 }}>
                    <FormattedMessage id='coupon' />:
                  </Col>
                  <Col
                    style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                    onClick={() => {
                      setDiscountInfo([{ ...discountInfo[0], coupon: '' }])
                    }}
                  >
                    {item?.coupon}
                    <span style={{ marginRight: '30px' }} />
                    <CloseCircleOutlined />
                  </Col>
                </ListStyle>
                <ListStyle justify='space-between'>
                  <Col style={{ fontWeight: 500 }}>
                    <FormattedMessage id='adjustment' />:
                  </Col>
                  <Col
                    style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                    onClick={() => {
                      setDiscountInfo([{ ...discountInfo[0], adjustmentComment: '', adjustment: '' }])
                      setAdjustAmount(0)
                    }}
                  >
                    {item?.adjustmentComment || ''}$ {item?.adjustment}
                    <span style={{ marginRight: '30px' }} />
                    <CloseCircleOutlined />
                  </Col>
                </ListStyle>
              </div>
            )
          }
          if (item.coupon !== '' && item.coupon && item.amount !== '' && item.amount) {
            return (
              <div key={index}>
                <ListStyle justify='space-between'>
                  <Col style={{ fontWeight: 500 }}>
                    <FormattedMessage id='discount' />:
                  </Col>
                  <Col
                    style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                    onClick={() => {
                      setDiscountInfo([{ ...discountInfo[0], amount: '', description: '' }])
                      setDiscountAmount(0)
                    }}
                  >
                    {item?.description || ''}$ -{item?.amount}
                    <span style={{ marginRight: '30px' }} />
                    <CloseCircleOutlined />
                  </Col>
                </ListStyle>
                <ListStyle justify='space-between'>
                  <Col style={{ fontWeight: 500 }}>
                    <FormattedMessage id='discount' />:
                  </Col>
                  <Col
                    style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                    onClick={() => {
                      setDiscountInfo([{ ...discountInfo[0], coupon: '' }])
                    }}
                  >
                    {item?.coupon}
                    <span style={{ marginRight: '30px' }} />
                    <CloseCircleOutlined />
                  </Col>
                </ListStyle>
              </div>
            )
          }
          if (item.adjustment !== '' && item.adjustment && item.amount !== '' && item.amount) {
            return (
              <div key={index}>
                <ListStyle justify='space-between'>
                  <Col style={{ fontWeight: 500 }}>
                    <FormattedMessage id='discount' />:
                  </Col>
                  <Col
                    style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                    onClick={() => {
                      setDiscountInfo([{ ...discountInfo[0], amount: '', description: '' }])
                      setDiscountAmount(0)
                    }}
                  >
                    {item?.description || ''}$ -{item?.amount}
                    <span style={{ marginRight: '30px' }} />
                    <CloseCircleOutlined />
                  </Col>
                </ListStyle>
                <ListStyle justify='space-between'>
                  <Col style={{ fontWeight: 500 }}>
                    <FormattedMessage id='discount' />:
                  </Col>
                  <Col
                    style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                    onClick={() => {
                      setDiscountInfo([{ ...discountInfo[0], adjustmentComment: '', adjustment: '' }])
                      setAdjustAmount(0)
                    }}
                  >
                    {item?.adjustmentComment || ''}$ {item?.adjustment}
                    <span style={{ marginRight: '30px' }} />
                    <CloseCircleOutlined />
                  </Col>
                </ListStyle>
              </div>
            )
          }
          if (item.adjustment !== '' && item.adjustment && item.coupon !== '' && item.coupon) {
            return (
              <div key={index}>
                <ListStyle justify='space-between'>
                  <Col style={{ fontWeight: 500 }}>
                    <FormattedMessage id='coupon' />:
                  </Col>
                  <Col
                    style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                    onClick={() => {
                      setDiscountInfo([{ ...discountInfo[0], coupon: '' }])
                    }}
                  >
                    {item?.coupon}
                    <span style={{ marginRight: '30px' }} />
                    <CloseCircleOutlined />
                  </Col>
                </ListStyle>
                <ListStyle justify='space-between'>
                  <Col style={{ fontWeight: 500 }}>
                    <FormattedMessage id='adjustment' />:
                  </Col>
                  <Col
                    style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                    onClick={() => {
                      setDiscountInfo([{ ...discountInfo[0], adjustmentComment: '', adjustment: '' }])
                      setAdjustAmount(0)
                    }}
                  >
                    {item?.adjustmentComment || ''}$ {item?.adjustment}
                    <span style={{ marginRight: '30px' }} />
                    <CloseCircleOutlined />
                  </Col>
                </ListStyle>
              </div>
            )
          }
          if (item.coupon !== '' && item.coupon) {
            return (
              <ListStyle justify='space-between' key={index}>
                <Col style={{ fontWeight: 500 }}>
                  <FormattedMessage id='coupon' />:
                </Col>

                <Col
                  style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                  onClick={() => {
                    setDiscountInfo([{ ...discountInfo[0], coupon: '' }])
                  }}
                >
                  {item?.coupon}
                  <span style={{ marginRight: '30px' }} />
                  <CloseCircleOutlined />
                </Col>
              </ListStyle>
            )
          } else if (item.amount !== '' && item.amount) {
            if (!item.coupon || item.coupon === '') {
              return (
                <ListStyle justify='space-between' key={index}>
                  <Col style={{ fontWeight: 500 }}>
                    <FormattedMessage id='discount' />:
                  </Col>

                  <Col
                    style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                    onClick={() => {
                      setDiscountInfo([{ ...discountInfo[0], amount: '', description: '' }])
                      setDiscountAmount(0)
                    }}
                  >
                    {item?.description || ''}$ -{item?.amount}
                    <span style={{ marginRight: '30px' }} />
                    <CloseCircleOutlined />
                  </Col>
                </ListStyle>
              )
            }
            return null
          } else if (item.adjustment !== '' && item.adjustment) {
            return (
              <ListStyle justify='space-between' key={index}>
                <Col style={{ fontWeight: 500 }}>
                  <FormattedMessage id='adjustment' />
                  ::
                </Col>

                <Col
                  style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                  onClick={() => {
                    setDiscountInfo([{ ...discountInfo[0], adjustment: '', adjustmentComment: '' }])
                    setAdjustAmount(0)
                  }}
                >
                  {item?.adjustmentComment || ''}$ {item?.adjustment}
                  <span style={{ marginRight: '30px' }} />
                  <CloseCircleOutlined />
                </Col>
              </ListStyle>
            )
          }
          return null
        })
      } else {
        let discountList = []
        if (props.query.discountList[0]?.fromCart) {
          discountList = props.query?.discountList
        } else {
          discountList = props.query?.discountList.map((item) => {
            return {
              coupon: item.discountType === 'coupon' ? item?.coupon : '',
              amount: item.discountType === 'discount' ? item?.discountAmount : '',
              description: item.discountType === 'discount' ? item?.comment : '',
              adjustment: item.discountType === 'adjustment' ? item?.discountAmount : '',
              adjustmentComment: item.discountType === 'adjustment' ? item?.comment : ''
            }
          })
        }
        return discountList.map((item, index) => {
          if (item.coupon !== '' && item.coupon && item.amount !== '' && item.amount && item.adjustment !== '' && item.adjustment) {
            return (
              <div key={index}>
                <ListStyle justify='space-between'>
                  <Col style={{ fontWeight: 500 }}>
                    <FormattedMessage id='discount' />:
                  </Col>
                  <Col style={{ fontSize: '1.2rem', cursor: 'pointer' }}>
                    {item?.description || ''}$ -{item?.amount}
                    <span style={{ marginRight: '30px' }} />
                  </Col>
                </ListStyle>
                <ListStyle justify='space-between'>
                  <Col style={{ fontWeight: 500 }}>
                    <FormattedMessage id='coupon' />:
                  </Col>
                  <Col style={{ fontSize: '1.2rem', cursor: 'pointer' }}>
                    {item?.coupon}
                    <span style={{ marginRight: '30px' }} />
                  </Col>
                </ListStyle>
                <ListStyle justify='space-between'>
                  <Col style={{ fontWeight: 500 }}>
                    <FormattedMessage id='adjustment' />:
                  </Col>
                  <Col style={{ fontSize: '1.2rem', cursor: 'pointer' }}>
                    {item?.adjustmentComment || ''}$ {item?.adjustment}
                    <span style={{ marginRight: '30px' }} />
                  </Col>
                </ListStyle>
              </div>
            )
          }
          if (item.coupon !== '' && item.coupon && item.amount !== '' && item.amount) {
            return (
              <div key={index}>
                <ListStyle justify='space-between'>
                  <Col style={{ fontWeight: 500 }}>
                    <FormattedMessage id='discount' />:
                  </Col>
                  <Col style={{ fontSize: '1.2rem', cursor: 'pointer' }}>
                    {item?.description || ''}$ -{item?.amount}
                    <span style={{ marginRight: '30px' }} />
                  </Col>
                </ListStyle>
                <ListStyle justify='space-between'>
                  <Col style={{ fontWeight: 500 }}>
                    <FormattedMessage id='coupon' />:
                  </Col>
                  <Col style={{ fontSize: '1.2rem', cursor: 'pointer' }}>
                    {item?.coupon}
                    <span style={{ marginRight: '30px' }} />
                  </Col>
                </ListStyle>
              </div>
            )
          }
          if (item.adjustment !== '' && item.adjustment && item.amount !== '' && item.amount) {
            return (
              <div key={index}>
                <ListStyle justify='space-between'>
                  <Col style={{ fontWeight: 500 }}>
                    <FormattedMessage id='discount' />:
                  </Col>
                  <Col style={{ fontSize: '1.2rem', cursor: 'pointer' }}>
                    {item?.description || ''}$ -{item?.amount}
                    <span style={{ marginRight: '30px' }} />
                  </Col>
                </ListStyle>
                <ListStyle justify='space-between'>
                  <Col style={{ fontWeight: 500 }}>
                    <FormattedMessage id='adjustment' />:
                  </Col>
                  <Col style={{ fontSize: '1.2rem', cursor: 'pointer' }}>
                    {item?.adjustmentComment || ''}$ {item?.adjustment}
                    <span style={{ marginRight: '30px' }} />
                  </Col>
                </ListStyle>
              </div>
            )
          }
          if (item.adjustment !== '' && item.adjustment && item.coupon !== '' && item.coupon) {
            return (
              <div key={index}>
                <ListStyle justify='space-between'>
                  <Col style={{ fontWeight: 500 }}>
                    <FormattedMessage id='coupon' />:
                  </Col>
                  <Col style={{ fontSize: '1.2rem', cursor: 'pointer' }}>
                    {item?.coupon}
                    <span style={{ marginRight: '30px' }} />
                  </Col>
                </ListStyle>
                <ListStyle justify='space-between'>
                  <Col style={{ fontWeight: 500 }}>
                    <FormattedMessage id='adjustment' />:
                  </Col>
                  <Col style={{ fontSize: '1.2rem', cursor: 'pointer' }}>
                    {item?.adjustmentComment || ''}$ {item?.adjustment}
                    <span style={{ marginRight: '30px' }} />
                  </Col>
                </ListStyle>
              </div>
            )
          }
          if (item.coupon !== '' && item.coupon) {
            return (
              <ListStyle justify='space-between' key={index}>
                <Col style={{ fontWeight: 500 }}>
                  <FormattedMessage id='coupon' />:
                </Col>

                <Col style={{ fontSize: '1.2rem', cursor: 'pointer' }}>
                  {item?.coupon}
                  <span style={{ marginRight: '30px' }} />
                </Col>
              </ListStyle>
            )
          } else if (item.amount !== '' && item.amount) {
            if (!item.coupon || item.coupon === '') {
              return (
                <ListStyle justify='space-between' key={index}>
                  <Col style={{ fontWeight: 500 }}>
                    <FormattedMessage id='discount' />:
                  </Col>

                  <Col style={{ fontSize: '1.2rem', cursor: 'pointer' }}>
                    {item?.description || ''}$ -{item?.amount}
                    <span style={{ marginRight: '30px' }} />
                  </Col>
                </ListStyle>
              )
            }
            return null
          } else if (item.adjustment !== '' && item.adjustment) {
            return (
              <ListStyle justify='space-between' key={index}>
                <Col style={{ fontWeight: 500 }}>
                  <FormattedMessage id='adjustment' />:
                </Col>

                <Col style={{ fontSize: '1.2rem', cursor: 'pointer' }}>
                  {item?.adjustmentComment || ''}$ {item?.adjustment}
                  <span style={{ marginRight: '30px' }} />
                </Col>
              </ListStyle>
            )
          }
          return null
        })
      }
    }
    return null
  }

  document.onclick = () => {
    setCustomerManager(false)
  }

  const convertProductExtra = (productExtra: ProductTypeExtraItem[], productName: string) => {
    let str = `${productName} = `
    forEach(productExtra, (item, index) => {
      str = str + `<div> ${index + 1}. ${item.productName} - ${item.skuName}</div>`
    })
    return <div dangerouslySetInnerHTML={{ __html: str }}></div>
    // return productExtra.map((item)=>{
    //   return `${item.productName} - ${item.skuName}`
    // })
  }

  return (
    <>
      <StyledLayout>
        <StyledHeader>
          <Row style={{ flex: 1 }}>
            {props.isPayment ? (
              <>
                <ColStyle>
                  <LeftOutlined
                    style={{
                      cursor: 'pointer',
                      fontSize: '1.5rem',
                      color: 'white'
                    }}
                    onClick={() => {
                      setCheckoutCartInfo(undefined)
                      dispatch(setPlaceorderSuccess({ success: false }))
                      dispatch(setChoosedAddress({ address: ' ' }))
                      sessionStorage.removeItem('customerIdFromOrder')
                      history.push('/')
                    }}
                  />
                </ColStyle>
                <ColStyle flex={5}>
                  <h2 style={{ fontSize: '1.5rem', color: 'white', cursor: 'pointer', marginTop: '10px' }}>
                    <FormattedMessage id='cart' /> ({itemSelected.length})
                  </h2>
                </ColStyle>
              </>
            ) : (
              <>
                <ColStyle
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(
                      deleteAllShoppingCart({
                        customerId: customerId,
                        storeId: +sessionStorage.getItem('storeId')
                      })
                    )
                    dispatch(setCartItems({ cartItems: [] }))
                  }}
                >
                  <Tooltip placement='bottomRight' title='Delete ALL'>
                    <img src='/icon/delete.png' width='30px' height='30px' alt='delete' />
                  </Tooltip>
                </ColStyle>
                <ColStyle
                  style={{ marginLeft: '10px', cursor: 'pointer' }}
                  onClick={() => {
                    setHoldListVisible(true)
                  }}
                >
                  <Tooltip placement='bottomRight' title='Hold List'>
                    <img src='/icon/onholdList.png' width='30px' height='30px' alt='onholdList' />
                  </Tooltip>
                </ColStyle>
                <ColStyle flex={5}>
                  <h2 style={{ fontSize: '1.2rem', color: 'white', cursor: 'pointer', marginTop: '10px' }}>
                    <FormattedMessage id='cart' /> (
                    {itemSelected.reduce((pre, next) => {
                      pre = pre + next.quantity
                      return pre
                    }, 0)}
                    )
                  </h2>
                </ColStyle>
                <ColStyle>
                  <Dropdown overlay={menu} placement='bottomLeft' arrow>
                    <EllipsisIcon style={{ fontSize: '26px', color: 'white' }} />
                  </Dropdown>
                </ColStyle>
              </>
            )}
          </Row>
        </StyledHeader>
        <StyledContent>
          <Row align='middle' style={{ height: '20px' }} justify='space-between'>
            {!!customerChoose && (
              <Title style={{ margin: '10px' }}>
                {`${customerChoose.nickName || ''} ${customerChoose.firstName || ''} ${customerChoose.lastName || ''} ${customerChoose.email || ''
                  } ${JSON.parse(sessionStorage.getItem('customerGroup')).find((item) => item.id === customerChoose.customerGroupId)
                    ?.name || ''
                  }`}
              </Title>
            )}
            {isPayment ? null : (
              <Col
                style={{
                  fontSize: '0.8rem'
                }}
              >
                <Dropdown
                  placement='bottomRight'
                  overlayStyle={{
                    background: '#fff',
                    width: '30rem',
                    overflow: 'hidden',
                    border: '1px solid lightgrey',
                    boxShadow: '-10px 10px 5px lightgrey'
                  }}
                  visible={customerManager}
                  overlay={
                    <CustomerManage
                      finished={customerManager}
                      customer={(value: any) => {
                        dispatch(setPoint({ point: value.point }))
                        dispatch(setCredit({ credit: value.credit }))
                        dispatch(setCartIsEmpty({ cartIsEmpty: false }))

                        dispatch(setCustomerChoose({ data: value }))
                        dispatch(
                          setCustomerInfo({
                            customerId: value.id,
                            customerGroupId: value.customerGroupId,
                            customerEmail: value.email
                          })
                        )
                        dispatch(
                          setCustomerName({
                            name: `${value.firstName} ${value.lastName} ${value.phone} ${value.email}`
                          })
                        )
                        dispatch(
                          setCartChange({
                            cartChanged: false
                          })
                        )
                        setCustomerManager(false)
                      }}
                      isUseGest={() => {
                        dispatch(setCustomerId({ id: 1 }))
                      }}
                      isCreateCustomer={(value) => {
                        setCustomerManager(value)
                      }}
                      paymentPage={false}
                    />
                  }
                >
                  <div
                    style={{ marginRight: '20px', cursor: 'pointer' }}
                    onClick={(e) => {
                      setCustomerManager(!customerManager)
                      e.stopPropagation()
                    }}
                  >
                    <img src='/icon/user.png' width='30px' height='30px' alt='user' />
                  </div>
                </Dropdown>
              </Col>
            )}
          </Row>

          <Divider />
          <List
            style={{ maxHeight: '100vh' }}
            size='small'
            itemLayout='horizontal'
            dataSource={checkoutCartInfo?.items || shoppingCartInfo.cartItems}
            renderItem={(item, index) => {
              return (
                <ListItemStyle
                  key={index}
                  actions={
                    isPayment
                      ? null
                      : [
                        <CloseOutlined
                          style={{
                            cursor: 'pointer',
                            fontSize: '0.8rem'
                          }}
                          onClick={async () => {
                            dispatch(deleteOneItemFromCart(item.id.toString()))
                          }}
                        />
                      ]
                  }
                >
                  <List.Item.Meta
                    avatar={
                      <Badge count={item?.quantity}>
                        <div>
                          <Avatar src={`${imageUrl(item.productImage || item.productInfo?.image)}`} onError={() => true} />
                        </div>
                      </Badge>
                    }
                    description={
                      isPayment ? (
                        <div>
                          {isNil(item.skuName) && item.productTypeExtraItems?.length
                            ? convertProductExtra(item.productTypeExtraItems, item.productInfo?.shippingName) || item.shippingName
                            : (item?.productInfo?.shippingName || item.shippingName) + '/' + (item.skuName || '')}
                        </div>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <div
                            style={{
                              textOverflow: 'ellipsis',
                              flex: 2,
                              width: 0
                            }}
                          >
                            {isNil(item.skuName) && item.productTypeExtraItems?.length
                              ? convertProductExtra(item.productTypeExtraItems, item.productInfo?.shippingName)
                              : (item.productInfo?.shippingName || item.shippingName) + '/' + (item?.skuName || '')}
                          </div>
                          <div style={{ flex: 2.8, width: 0 }}>
                            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                              <div style={{ flex: 2.5, lineHeight: '31.2px' }}>
                                <Text strong>
                                  <span>${item.finalPrice.toFixed(2)} </span>
                                </Text>
                              </div>
                              <div
                                style={{
                                  flex: 1,
                                  lineHeight: '31.2px',
                                  textAlign: 'center'
                                }}
                              >
                                <Text strong>x</Text>
                              </div>
                              <div
                                style={{
                                  flex: 2,
                                  lineHeight: '31.2px',
                                  textAlign: 'center'
                                }}
                              >
                                <Text strong style={{ fontSize: '1.5rem' }}>
                                  <MinusCircleOutlined
                                    style={{ color: 'lightblue' }}
                                    onClick={() => {
                                      dispatch(
                                        updateShoppingCartQuantity({
                                          quantity: item.quantity - 1,
                                          id: item.id.toString()
                                        })
                                      )
                                    }}
                                  />
                                </Text>
                              </div>
                              <div
                                style={{
                                  flex: 1,
                                  lineHeight: '31.2px',
                                  textAlign: 'center'
                                }}
                              >
                                <Text strong>{item.quantity}</Text>
                              </div>
                              <div
                                style={{
                                  flex: 2,
                                  lineHeight: '31.2px',
                                  textAlign: 'center'
                                }}
                              >
                                <Text strong style={{ fontSize: '1.5rem' }}>
                                  <PlusCircleOutlined
                                    style={{ color: 'lightblue' }}
                                    onClick={() => {
                                      dispatch(
                                        updateShoppingCartQuantity({
                                          quantity: item.quantity + 1,
                                          id: item.id.toString()
                                        })
                                      )
                                    }}
                                  />
                                </Text>
                              </div>
                              <div style={{ flex: 1, lineHeight: '31.2px' }}>
                                <Text strong>=</Text>
                              </div>
                              <div style={{ flex: 3, lineHeight: '31.2px' }}>
                                <Text strong>
                                  <Div>${(item.finalPrice * item.quantity).toFixed(2)}</Div>
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  />
                  {isPayment ? (
                    <div>
                      <Text strong>
                        <Div>
                          {item.finalPrice} x {item?.quantity || item.qtyOrdered} ={' '}
                          {(item.finalPrice * (item?.quantity || item.qtyOrdered)).toFixed(2)}
                        </Div>
                      </Text>
                    </div>
                  ) : null}
                </ListItemStyle>
              )
            }}
          />
        </StyledContent>

        <Card
          size='small'
          actions={
            isPayment
              ? null
              : [
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    shape='round'
                    size='large'
                    style={{ margin: '0 10px', width: '50%', backgroundColor: 'gray', color: 'white', fontWeight: 500 }}
                    onClick={() => {
                      if (itemSelected.length > 0) {
                        dispatch(
                          setCartIncrease({
                            num: 1
                          })
                        )
                        if (onHoldIdList.filter((item) => item.id === customerChoose.id).length > 0) {
                          if (shoppingCartInfo.cartChanged) {
                            const arr = onHoldIdList.filter((item) => item.id !== customerChoose.id)
                            setOnHoldList([...arr, customerChoose])
                            setWalkinId(walkinId.filter((id) => id !== customerChoose.id))
                            dispatch(getOnHoldListCustomerInfo(walkinId.filter((id) => id !== customerChoose.id)[0]))

                            return
                          } else {
                            message.warning(
                              'The shopping cart has not changed, if you don’t need to change it, please click the hold button again'
                            )
                            return
                          }
                        }

                        if (!walkinIds.includes(customerChoose.id)) {
                          setOnHoldList([...onHoldIdList, customerChoose])
                          dispatch(getOnHoldListCustomerInfo(1))

                          return
                        }
                        if (walkinId.filter((id) => id !== customerChoose.id)[0]) {
                          setWalkinId(walkinId.filter((id) => id !== customerChoose.id))

                          setOnHoldList([...onHoldIdList, customerChoose])
                          dispatch(getOnHoldListCustomerInfo(walkinId.filter((id) => id !== customerChoose.id)[0]))
                        } else {
                          setWalkinId([1, 2, 3, 4, 5])
                          dispatch(getOnHoldListCustomerInfo(1))

                          message.warning('Walk in users run out')
                        }
                      } else {
                        message.warning(' Please add item(s) to cart!')
                      }
                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <FormattedMessage id='hold' />
                    </div>
                  </Button>
                  <Button
                    type='primary'
                    shape='round'
                    size='large'
                    style={{ margin: '0 10px', width: '80%', fontWeight: 500 }}
                    loading={loading}
                    onClick={() => {
                      setLoading(true)
                      if (itemSelected.length > 0) {
                        const params = {
                          cartList: [],
                          point: 0,
                          coupon: discountInfo[0]?.coupon || '',
                          customerId: customerId,
                          discount: +discountInfo[0]?.amount || 0,
                          discountComment: discountInfo[0]?.description || '',
                          adjustment: +discountInfo[0]?.adjustment || 0,
                          adjustmentComment: discountInfo[0]?.adjustmentComment || ''
                        }
                        // dispatch(setCoupon({ coupon: discountInfo[0]?.coupon || '' }))
                        dispatch(setDiscount({ discount: +discountInfo[0]?.amount || 0 }))
                        dispatch(setDiscountComment({ discountComment: discountInfo[0]?.description || '' }))
                        dispatch(setAdjustment({ adjustment: +discountInfo[0]?.adjustment || 0 }))
                        dispatch(setAdjustmentComment({ adjustmentComment: discountInfo[0]?.adjustmentComment || '' }))
                        checkout(params).then((res) => {
                          if (res.success) {
                            setCheckoutCartInfo(res.data)
                            dispatch(setCheckoutPrice({ checkoutPrice: res.data.total }))
                            dispatch(setPlaceorderSuccess({ success: true }))
                            setLoading(false)
                            const data = { subTotal: res.data.subtotal, total: res.data.total, discountList: discountInfo }
                            const path = {
                              pathname: '/placeorder',
                              query: data
                            }
                            history.push(path)
                          } else {
                            message.error(res.message)
                            setLoading(false)
                          }
                        })
                      } else {
                        message.warning(' Please add item(s) to cart!')
                        setLoading(false)
                      }
                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <FormattedMessage id='checkout' />xx
                    </div>
                  </Button>
                </div>
              ]
          }
          style={{
            position: 'static',
            bottom: 0,
            width: '100%'
          }}
        >
          <ListStyle justify='space-between'>
            <Col>
              <Text strong>
                <FormattedMessage id='subtotal' />
              </Text>
            </Col>

            <Col>
              <Text strong>
                $ {Math.abs(+itemPrice.toFixed(2))} / ¥ {Math.abs(+(itemPrice * shoppingCartInfo.currencyRate).toFixed(2))}
              </Text>
            </Col>
          </ListStyle>

          {isPayment ? (
            <>
              {discountList()}
              <ListStyle justify='space-between'>
                <Col>
                  <Text strong>
                    <FormattedMessage id='total' />
                  </Text>
                </Col>
                <Col>
                  <Text strong>
                    $ {placeorderSuccess ? (props.query?.total || props.query?.price || shoppingCartInfo.totalPrice).toFixed(2) || 0 : 0} /
                    ¥{' '}
                    {placeorderSuccess
                      ? ((props.query?.total || props.query?.price || shoppingCartInfo.totalPrice) * shoppingCartInfo.currencyRate).toFixed(
                        2
                      ) || 0
                      : 0}
                  </Text>
                </Col>
              </ListStyle>
            </>
          ) : (
            <>
              {discountList()}
              <ListStyle justify='space-between'>
                <Col>
                  <Text type='secondary'>
                    <FormattedMessage id='discount' />/
                    <FormattedMessage id='coupon' />/
                    <FormattedMessage id='adjustment' />
                  </Text>
                </Col>
                <Col>
                  <PlusCircleOutlined
                    onClick={() => {
                      setAddDiscount(true)
                    }}
                  />
                </Col>
              </ListStyle>
              <ListStyle justify='space-between'>
                <Col>
                  <Text strong>
                    <FormattedMessage id='total' />
                  </Text>
                </Col>

                <Col>
                  <Text strong>
                    $ {Math.abs(+(itemPrice - discountAmount + adjustAmount).toFixed(2))} / ¥{' '}
                    {Math.abs(+((itemPrice - discountAmount + adjustAmount) * shoppingCartInfo.currencyRate).toFixed(2))}
                  </Text>
                </Col>
              </ListStyle>
            </>
          )}
        </Card>

        <DiscountCard
          isModalVisible={addDiscount}
          finished={(value) => {
            setDiscountInfo([{ ...value, fromCart: true }])
            setAddDiscount(false)
          }}
          close={() => {
            setAddDiscount(false)
          }}
          discount={(value) => {
            setDiscountAmount(+value || 0)
          }}
          adjustment={(value) => {
            setAdjustAmount(+value || 0)
          }}
        />
      </StyledLayout>

      <Modal
        open={addNote}
        centered
        onCancel={() => {
          setAddNote(false)
        }}
        footer={
          <Button
            key='back'
            onClick={() => {
              setAddNote(false)
            }}
          >
            Cancel
          </Button>
        }
        title='Order Comments'
        destroyOnClose
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ offset: 1 }}
          onFinish={(values) => {
            dispatch(setComment({ comment: values.note }))
            setAddNote(false)
          }}
        >
          <Form.Item label='Note' name='note'>
            <Input.TextArea style={{ resize: 'none' }} />
          </Form.Item>
          <ModalFormSubmit shouldUpdate={true}>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </ModalFormSubmit>
        </Form>
      </Modal>

      <OnHoldList
        onHoldList={onHoldIdList}
        success={(value: CustomerTypes) => {
          dispatch(setCustomerChoose({ data: value }))
          dispatch(
            setCustomerInfo({
              customerId: value.id,
              customerGroupId: value.customerGroupId,
              customerEmail: value.email
            })
          )
          setHoldListVisible(false)
        }}
        finished={() => {
          setHoldListVisible(false)
        }}
        currentlyChoose={customerChoose}
        isEmpty={cartIsEmpty}
        holdListVisible={holdListVisible}
      />
    </>
  )
}

export default SliderLayout
