import styled from 'styled-components'
import { CheckCircleFilled } from '@ant-design/icons'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const CouponInfo = styled.div`
  gap: 1rem;
  display: flex;
  align-items: center;
  height: 2rem;
`

export const CouponCode = styled.div`
  font-size: 1rem;
  width: 4rem;
`
export const CouponCondition = styled.div`
  font-size: 1rem;
`
export const CouponCard = styled.div<{ itemChosen: boolean }>`
  position: relative;
  border: ${(props) => (props.itemChosen ? '1px solid orange' : '1px solid lightgrey')};
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  height: 7rem;
`
export const CouponPriceInfo = styled.div`
  flex: 1;
  text-align: center;
`
export const CouponDetails = styled.div`
  flex: 2;
  margin-left:1rem;
`
export const StyledCheckCircleFilled = styled(CheckCircleFilled)`
  color: orange;
  position: absolute;
  font-size: 1rem;
  top: 9px;
  right: 16px;
`
