import Modal from 'antd/lib/modal'

export default function ModalForm (prop:any) {
  const { children, cancel, ...others } = prop

  return (
    <Modal {...others} destroyOnClose={true} maskClosable={false} style={{ zIndex: 9999 }}>
      {prop.children}
    </Modal>
  )
}
