import { useEffect, useState } from "react"


const isApiSupported = (api: string): boolean => (typeof window !== 'undefined' ? api in window : false)

const isClient = (typeof window === 'object')


const errorMessage = 'matchMedia is not supported, this could happen both because window.matchMedia is not supported by'

const useMediaQuery = (mediaQuery: string): boolean | null => {
    if (!isClient || !isApiSupported('matchMedia')) {
        // eslint-disable-next-line no-console
        console.warn(errorMessage)
        return null
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isVerified, setIsVerified] = useState(!!window.matchMedia(mediaQuery).matches)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        const mediaQueryList = window.matchMedia(mediaQuery)
        const documentChangeHandler = () => setIsVerified(!!mediaQueryList.matches)

        try {
            mediaQueryList.addEventListener('change', documentChangeHandler)
        } catch (e) {
            mediaQueryList.addListener(documentChangeHandler)
        }

        documentChangeHandler()
        return () => {
            try {
                mediaQueryList.removeEventListener('change', documentChangeHandler)
            } catch (e) {
                mediaQueryList.removeListener(documentChangeHandler)
            }
        }
    }, [mediaQuery])

    return isVerified
}

export default useMediaQuery
