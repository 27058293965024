import React, { useEffect, useState } from 'react'
import { Row, Col, Table, Input, DatePicker, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { cashBoxLog, exportCashBoxAsExcel, getCashBoxHistory } from '../../api/response'
import dayjs from 'dayjs'
import CommonListLayout from '../common/commonListLayout'
import { CashBoxLogItem, CashBoxQueryReqTypes } from '../../models/cashBoxTypes'
import { cashBoxHistoryColumns } from '../../data/tableColumns'

const { RangePicker } = DatePicker

const CashBoxHistory = (props:{id:number}) => {
  const [cashBoxHistory, setCashBoxHistory] = useState<CashBoxLogItem[]>([])
  const [paginator, setPaginator] = useState({ limit: 25, page: 1 })
  const [total, setTotal] = useState(0)
  const [dateFrom, setDateForm] = useState(0)
  const [searchString, setSearchString] = useState('')
  const [dateTo, setDateTo] = useState(0)

  useEffect(() => {
    const params = {
      pageSize: paginator.limit,
      currentPage: paginator.page,
      dateFrom: dateFrom,
      dateTo: dateTo,
      q: searchString,
    }
    cashBoxLog(params,props.id).then((res) => {
      if (res.success) {
        const { items } = res.data
        const { totalCount } = res.data.pagination
        const pointArr = [...cashBoxHistory, ...items]
        if (pointArr.length >= totalCount) {
          setTotal(totalCount)
          setCashBoxHistory(items)
          return
        }
        setCashBoxHistory(items)
        setTotal(totalCount)
      }
    })
  }, [paginator])
  return (
    <CommonListLayout title='Cash Box Log'>
      <Table
        bordered
        dataSource={cashBoxHistory}
        columns={cashBoxHistoryColumns}
        rowKey='id'
        title={() => {
          return (
            <Row justify='space-between'>
              <Col span={8}>
                <Input
                  allowClear
                  defaultValue={null}
                  placeholder='Search'
                  autoFocus={true}
                  prefix={<SearchOutlined style={{ color: 'gray' }} />}
                  onChange={(e) => {
                    if (!e.target.value) {
                      setSearchString('')
                      setCashBoxHistory([])
                      setPaginator({ limit: 25, page: 1 })
                    }
                    setSearchString(e.target.value)
                    setCashBoxHistory([])
                    setPaginator({ limit: 25, page: 1 })
                  }}
                />
              </Col>
              <Col span={10}>
                <RangePicker
                  style={{ width: '75%' }}
                  onChange={(dates: any) => {
                    if (!dates) {
                      setDateForm(0)
                      setDateTo(0)
                      setCashBoxHistory([])
                      setPaginator({ limit: 25, page: 1 })

                      return
                    }
                    setDateForm(dayjs(dates[0]).valueOf())
                    setDateTo(dayjs(dates[1]).valueOf())
                    setCashBoxHistory([])

                    setPaginator({ limit: 25, page: 1 })
                  }}
                  showTime
                />
                <Button
                  type='primary'
                  style={{ marginLeft: '24px' }}
                  onClick={() => {
                    const params = {
                      pageSize: paginator.limit,
                      currentPage: paginator.page,
                      dateFrom: dateFrom,
                      dateTo: dateTo,
                      status: "",
                      q: searchString,
                      cashBox:'0001',
                      storeId:1
                    } as CashBoxQueryReqTypes
                    exportCashBoxAsExcel(params).then((res) => {
                      if (res.success) {
                        window.open(res.data.url)
                      }
                    })
                  }}
                >
                  Export
                </Button>
              </Col>
            </Row>
          )
        }}
        scroll={{ y: '68vh' }}
        pagination={{
          total,
          defaultPageSize: paginator.limit,
          current: paginator.page,
          onChange: (page, pageSize) => {
            setPaginator({ page, limit: pageSize })
          }
        }}
      />
    </CommonListLayout>
  )
}

export default CashBoxHistory
