import { configureStore,ThunkAction,Action } from '@reduxjs/toolkit'

import cartItems_redecer from './reducers/cartItems_reducer';
import customerInfo_reducer from './reducers/customerInfo_reducer';
import homePage_reducer from './reducers/homePage_reducer';
import menu_reducer from './reducers/menu_reducer';
import orders_reducer from './reducers/orders_reducer';
import product_reducer from './reducers/product_reducer';

export const store  = configureStore({  // = connect()+combineReducers()
  reducer: {
    lang: homePage_reducer,
    homepageInfo: homePage_reducer,
    shoppingCartInfo:cartItems_redecer,
    customer:customerInfo_reducer,
    menu:menu_reducer,
    order:orders_reducer,
    products:product_reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
