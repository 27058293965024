export enum LabelControlType {
  MultiSelect,
  Select,
  CheckBox,
  Input,
  TextArea,
  Cascader,
  Upload,
  Option,
  Switch
}
