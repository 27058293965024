import { List } from 'antd'
import styled from 'styled-components'
import { CheckCircleFilled, CloseOutlined } from '@ant-design/icons'

export const StyledList = styled(List)`
  margin-top: 1rem;
  .ant-list-pagination {
    text-align: center !important;
  }
  max-height: 25rem;
  min-height: 25rem;
`
export const StyledCheckCircleFilled = styled(CheckCircleFilled)`
  color: orange;
  position: absolute;
  font-size: 1rem;
  top: 9px;
  right: 16px;
`
export const StyledCloseOutlined = styled(CloseOutlined)`
  color: grey;
  position: absolute;
  font-size: 1rem;
  bottom: 9px;
  right: 16px;
`

export const StyledListItem = styled(List.Item)`
  position: relative;
  &:hover {
    background-color: lightyellow;
  }
  max-height: 5rem;
`

export const StyledListIteMeta = styled(List.Item.Meta)`
  & .ant-list-item-meta-title {
    margin-top: 0;
  }
`
