export enum ShipmentTerm {
  pickup = "门店自提",
  air = "空运",
  sea = "海运",
  freight = "快递"
}

export enum ShipmentTerm_eng {
  pickup = "Pickup",
  air = "Air transportation",
  sea = "Sea transportation",
  freight = "Express"
}