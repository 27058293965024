import React, { useEffect } from 'react'
import MenuLayout from '../components/Layout/menuLayout'
import PaymentLayout from '../components/paymentList'
import { setMenuDisplay } from '../redux/reducers/menu_reducer'
import { useAppDispatch } from '../redux/reduxHooks'

const PaymentHistory = () => {
  const  dispatch  = useAppDispatch()
  useEffect(() => {
    dispatch(setMenuDisplay({
      menu: false
    }))
  }, [])
  return (
    <div>
      <MenuLayout/>
      <PaymentLayout/>
    </div>
  )
}

export default PaymentHistory
