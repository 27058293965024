/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Col, Input, Row, Space, List, Spin, Modal } from 'antd'
import React, { useState, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getCustomers } from '../../api/response'
import { debounce } from 'lodash'
import { SearchOutlined } from '@ant-design/icons'
import CreateForm from '../addressForms/createForm'
import { CustomerGroupTypes } from '../../models/customerTypes'


export default function CustomerManage (props) {
  const { finished, customer, isUseGest, isCreateCustomer, paymentPage } = props
  const [paginator, setPaginator] = useState({ limit: 10, page: 1 })
  const [customerInfo, setCustomerInfo] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [query, setQuery] = useState<string | undefined>('')
  const [creatCustomer, setCreatCustomer] = useState(false)
  // const { storeState } = useProductsContext()

  const getCustomerList = async()=>{
    let group = JSON.parse(sessionStorage.getItem('customerGroup')) as CustomerGroupTypes[]
    if (!query) {
      setHasMore(true)
      const res = await getCustomers({
        perPage: paginator.limit,
        currentPage: paginator.page
      })
      if (res.success) {
        const { items } = res.data
        const { totalCount } = res.data.pagination
        const newData = items.map(item => {
          const customerGroup = group.find(items => items.id === item.customerGroupId).name
          return [{ ...item, customerGroup }]
        })
        const arr = [...customerInfo, ...newData.flat()]
        if (arr.length >= totalCount) {
          setHasMore(false)
          setCustomerInfo([...arr])
          return
        }
        setCustomerInfo([...arr])
      }
      return
    }
    const queryRes = await getCustomers({
      q: query,
      perPage: paginator.limit,
      currentPage: paginator.page
    })

    if (queryRes.data) {
      const { items } = queryRes.data
      const { totalCount } = queryRes.data.pagination
      const arr = [...customerInfo, ...items]

      if (arr.length >= totalCount) {
        setHasMore(false)
        setCustomerInfo([...arr])
        return
      }
      setCustomerInfo([...arr])
    }
  }

  useEffect(() => {
    if (!finished) {
      setQuery(undefined)
      setCustomerInfo([])
      return
    }
    getCustomerList()
  }, [paginator, finished])

  return (
    <>
      <Space size={20} direction='vertical' style={{ width: '100%' }}>
        <Row>
          <Col span={24}>
            <Button
              type='primary'
              block
              onClick={() => {
                setCreatCustomer(true)
                isCreateCustomer(creatCustomer)
              }}
            >
              Create Customer
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Button block onClick={isUseGest}>
              Use Guest
            </Button>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <Input
              allowClear
              size='large'
              defaultValue={null}
              placeholder='Search by email/name/phone'
              style={{
                width: '100%'
              }}
              autoFocus={true}
              onChange={debounce((e) => {
                if (!e.target.value) {
                  setQuery('')
                  setCustomerInfo([])
                  setPaginator({ limit: 10, page: 1 })
                } else {
                  setCustomerInfo([])
                  setQuery(e.target.value)
                  setPaginator({ limit: 10, page: 1 })
                }
              }, 500)}
              prefix={<SearchOutlined style={{ color: 'gray' }} />}
            />
          </Col>
        </Row>
        <div
          id='scrollableDiv'
          style={{
            height: 300,
            overflow: 'auto'
          }}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <InfiniteScroll
            next={() => {
              setPaginator({
                ...paginator,
                page: paginator.page + 1
              })
            }}
            loader={
              <div style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            }
            hasMore={hasMore}
            dataLength={customerInfo.length}
            endMessage={customerInfo ? null : <div style={{ textAlign: 'center' }}>No more</div>}
            scrollableTarget='scrollableDiv'
          >
            <List
              rowKey='id'
              dataSource={customerInfo}
              renderItem={(item) => (
                <List.Item
                  onClick={(e) => {
                    customer(item)
                    e.stopPropagation()
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <List.Item.Meta
                    title={
                      <div style={{ marginLeft: '10px' }}>
                        {item.nickName || ''} {item.firstName || ''} {item.lastName || ''} {item.phone || ''} {item.customerGroup || ''}
                      </div>
                    }
                    description={<div style={{ marginLeft: '10px' }}>{item.email}</div>}
                  />
                  <div style={{ marginRight: '10px' }}>{item.id}</div>
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </div>
      </Space>
      <Modal width={'50%'} visible={creatCustomer} destroyOnClose={true} centered closable={false} footer={null}>
        <CreateForm
          finished={() => {
            setCreatCustomer(false)
          }}
          close={() => {
            setCreatCustomer(false)
          }}
          paymentPage={paymentPage}
        />
      </Modal>
    </>
  )
}
