/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Row, Col, Layout, Table, message, Tabs, DatePicker, Menu, Dropdown, Button, MenuProps } from 'antd'
import { debounce } from 'lodash'
import styled from 'styled-components'
import {
  getPaymentReportListDaily,
  getPaymentReportListWeekly,
  getPaymentReportListMonthly,
  getProductsReportListDaily,
  getProductsReportListWeekly,
  getProductsReportListMonthly,
  getOrderReportListDaily,
  getOrderReportListWeekly,
  getOrderReportListMonthly,
  // getStoreInfo,
  paymentReportExport,
  orderReportExport,
  productReportExport
} from '../../api/response'
import { useAppDispatch, useAppSelector } from '../../redux/reduxHooks'
import { setMenuDisplay } from '../../redux/reducers/menu_reducer'
import {
  orderReportDailyColumns,
  orderReportMonthlyColumns,
  orderReportWeeklyColumns,
  paymentReportDailyColumns,
  paymentReportMonthlyColumns,
  paymentReportWeeklyColumns,
  productReportDailyColumns,
  productReportMonthlyColumns,
  productReportWeeklyColumns
} from '../../data/tableColumns'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { homePageData, setStoresList } from '../../redux/reducers/homePage_reducer'
import dayjs from 'dayjs'

const { Header, Content } = Layout
const { TabPane } = Tabs
const { RangePicker } = DatePicker

const ListHeader = styled(Header)`
  position: sticky;
  top: 0;
  z-index: 10;
  color: black;
  height: 64px;
  font-weight: 700;
  text-align: center;
  font-size: 16px;
  background-color: orange !important;
`

const ColStyle = styled(Col)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Report = () => {
  const storeState = useAppSelector(homePageData)

  const [paymentReportDailyItems, setPaymentReportDailyItems] = useState([])
  const [paymentReportWeeklyItems, setPaymentReportWeeklyItems] = useState([])
  const [paymentReportMonthlyItems, setPaymentReportMonthlyItems] = useState([])
  const [productReportMonthlyItems, setProductReportMonthlyItems] = useState([])
  const [productReportWeeklyItems, setProductReportWeeklyItems] = useState([])
  const [productReportDailyItems, setProductReportDailyItems] = useState([])
  const [orderReportMonthlyItems, setOrderReportMonthlyItems] = useState([])
  const [orderReportWeeklyItems, setOrderReportWeeklyItems] = useState([])
  const [orderReportDailyItems, setOrderReportDailyItems] = useState([])
  const [dateFrom, setDateForm] = useState(0)
  const [dateTo, setDateTo] = useState(0)
  const [reportInterval, setReportInterval] = useState('daily')
  const [loadingDaily, setLoadingDaily] = useState(false)
  const [loadingWeekly, setLoadingWeekly] = useState(false)
  const [loadingMonthly, setLoadingMonthly] = useState(false)
  const [storeId, setStoreId] = useState(0)
  const dispatch = useAppDispatch()
  const params = useParams<{ type: string }>()


  const menu = (
    <Menu
      items={[
        ...storeState.storesList.map((item) => {
          return {
            key: item.id,
            label: (
              <Button
                type='primary'
                style={{ width: '100%' }}
                onClick={() => {
                  setStoreId(item.storeId)
                }}
              >
                {item.storeName}
              </Button>
            )
          }
        }),
        {
          key: 0,
          label: (
            <Button
              type='primary'
              style={{ width: '100%' }}
              onClick={() => {
                setStoreId(0)
              }}
            >
              全部店铺
            </Button>
          )
        }
      ]}
    />
  )

  // const storeInfoRes = async () => {
  //   const res = await getStoreInfo()
  //   if (res) {
  //     const { data } = res
  //     if (data.length === 0) {
  //       message.error('You do not have permission to manage any stores')
  //     }
  //     dispatch(setStoresList({ storesList: data }))
  //   }
  // }

  // useEffect(() => {
  //   if (storeState.storesList.length === 0) {
  //     storeInfoRes()
  //   }
  // }, [storeState.storesList])
  const getPaymentReportDaily = useMemo(
    () =>
      debounce(async (value) => {
        if (!value) {
          return
        }

        const params = {
          storeId,
          dateFrom,
          dateTo
        }
        setLoadingDaily(true)
        const res = await getPaymentReportListDaily(params)
        if (res.success) {
          setPaymentReportDailyItems(res.data.map((item, index) => ({ ...item, key: index })))
        } else {
          message.error(res.message)
        }
        setLoadingDaily(false)
      }, 100),
    [dateFrom, dateTo, storeId]
  )
  const getPaymentReportWeekly = useMemo(
    () =>
      debounce(async (value) => {
        if (!value) {
          return
        }

        const params = {
          storeId,
          dateFrom,
          dateTo
        }
        setLoadingWeekly(true)
        const res = await getPaymentReportListWeekly(params)
        if (res.success) {
          setPaymentReportWeeklyItems(res.data.map((item, index) => ({ ...item, key: index })))
        } else {
          message.error(res.message)
        }
        setLoadingWeekly(false)
      }, 100),
    [dateFrom, dateTo, storeId]
  )
  const getPaymentReportMonthly = useMemo(
    () =>
      debounce(async (value) => {
        if (!value) {
          return
        }

        const params = {
          storeId,
          dateFrom,
          dateTo
        }
        // setIsSearching(true)
        const res = await getPaymentReportListMonthly(params)
        setLoadingMonthly(true)
        if (res.success) {
          setPaymentReportMonthlyItems(res.data.map((item, index) => ({ ...item, key: index })))
        } else {
          message.error(res.message)
        }
        setLoadingMonthly(false)
      }, 100),
    [dateFrom, dateTo, storeId]
  )
  const getProductReportDaily = useMemo(
    () =>
      debounce(async (value) => {
        if (!value) {
          return
        }

        const params = {
          storeId,
          dateFrom,
          dateTo
        }
        setLoadingDaily(true)
        const res = await getProductsReportListDaily(params)
        if (res.success) {
          setProductReportDailyItems(res.data)
        } else {
          message.error(res.message)
        }
        setLoadingDaily(false)
      }, 100),
    [dateFrom, dateTo, storeId]
  )
  const getProductReportWeekly = useMemo(
    () =>
      debounce(async (value) => {
        if (!value) {
          return
        }

        const params = {
          storeId,
          dateFrom,
          dateTo
        }
        setLoadingWeekly(true)
        const res = await getProductsReportListWeekly(params)
        if (res.success) {
          setProductReportWeeklyItems(res.data)
        } else {
          message.error(res.message)
        }
        setLoadingWeekly(false)
      }, 100),
    [dateFrom, dateTo, storeId]
  )
  const getProductReportMonthly = useMemo(
    () =>
      debounce(async (value) => {
        if (!value) {
          return
        }

        const params = {
          storeId,
          dateFrom,
          dateTo
        }
        // setIsSearching(true)
        const res = await getProductsReportListMonthly(params)
        setLoadingMonthly(true)
        if (res.success) {
          setProductReportMonthlyItems(res.data)
        } else {
          message.error(res.message)
        }
        setLoadingMonthly(false)
      }, 100),
    [dateFrom, dateTo, storeId]
  )
  const getOrderReportDaily = useMemo(
    () =>
      debounce(async (value) => {
        if (!value) {
          return
        }

        const params = {
          storeId,
          dateFrom,
          dateTo
        }
        setLoadingDaily(true)
        const res = await getOrderReportListDaily(params)
        if (res.success) {
          setOrderReportDailyItems(res.data.map((item, index) => ({ ...item, key: index })))
        } else {
          message.error(res.message)
        }
        setLoadingDaily(false)
      }, 100),
    [dateFrom, dateTo, storeId]
  )
  const getOrderReportWeekly = useMemo(
    () =>
      debounce(async (value) => {
        if (!value) {
          return
        }

        const params = {
          storeId,
          dateFrom,
          dateTo
        }
        setLoadingWeekly(true)
        const res = await getOrderReportListWeekly(params)
        if (res.success) {
          setOrderReportWeeklyItems(res.data.map((item, index) => ({ ...item, key: index })))
        } else {
          message.error(res.message)
        }
        setLoadingWeekly(false)
      }, 100),
    [dateFrom, dateTo, storeId]
  )
  const getOrderReportMonthly = useMemo(
    () =>
      debounce(async (value) => {
        if (!value) {
          return
        }
        const params = {
          storeId,
          dateFrom,
          dateTo
        }
        // setIsSearching(true)
        const res = await getOrderReportListMonthly(params)
        setLoadingMonthly(true)
        if (res.success) {
          setOrderReportMonthlyItems(res.data.map((item, index) => ({ ...item, key: index })))
        } else {
          message.error(res.message)
        }
        setLoadingMonthly(false)
      }, 100),
    [dateFrom, dateTo, storeId]
  )
  const handleReportIntervalChange = (e) => {
    setReportInterval(e)
    handleReportSearch(e)
  }

  const handleReportSearch = (code: string) => {
    switch (code) {
      case 'daily':
        switch (params.type) {
          case 'payments':
            getPaymentReportDaily(1)

            break
          case 'products':
            getProductReportDaily(1)

            break
          case 'orders':
            getOrderReportDaily(1)

            break
          default:
            break
        }
        break
      case 'weekly':
        switch (params.type) {
          case 'payments':
            getPaymentReportWeekly(1)

            break
          case 'products':
            getProductReportWeekly(1)

            break
          case 'orders':
            getOrderReportWeekly(1)

            break
          default:
            break
        }
        break
      case 'monthly':
        switch (params.type) {
          case 'payments':
            getPaymentReportMonthly(1)

            break
          case 'products':
            getProductReportMonthly(1)

            break
          case 'orders':
            getOrderReportMonthly(1)

            break
          default:
            break
        }
        break
      default:
        setReportInterval('daily')
        break
    }
  }

  useEffect(() => {
    handleReportSearch(reportInterval)
    dispatch(
      setMenuDisplay({
        menu: false
      })
    )
    return () => {
      setOrderReportDailyItems([])
      setOrderReportMonthlyItems([])
      setOrderReportWeeklyItems([])
      setProductReportDailyItems([])
      setProductReportWeeklyItems([])
      setProductReportMonthlyItems([])
      setPaymentReportDailyItems([])
      setPaymentReportMonthlyItems([])
    }
  }, [reportInterval, params.type, dateTo, dateFrom, storeId])

  const relativeTable = () => {
    switch (params.type) {
      case 'payments':
        return (
          <>
            <Tabs defaultActiveKey='daily' onChange={handleReportIntervalChange} type='card'>
              <TabPane tab='Daily' key='daily'>
                <Table
                  loading={loadingDaily}
                  bordered
                  size='middle'
                  rowKey={'key'}
                  dataSource={paymentReportDailyItems}
                  columns={paymentReportDailyColumns}
                />
              </TabPane>
              <TabPane tab='Weekly' key='weekly'>
                <Table
                  loading={loadingWeekly}
                  bordered
                  size='middle'
                  rowKey={'key'}
                  dataSource={paymentReportWeeklyItems}
                  columns={paymentReportWeeklyColumns}
                ></Table>
              </TabPane>
              <TabPane tab='Monthly' key='monthly'>
                <Table
                  loading={loadingMonthly}
                  bordered
                  size='middle'
                  rowKey={'key'}
                  dataSource={paymentReportMonthlyItems}
                  columns={paymentReportMonthlyColumns}
                ></Table>
              </TabPane>
            </Tabs>
          </>
        )
      case 'products':
        return (
          <>
            <Tabs defaultActiveKey='daily' onChange={handleReportIntervalChange} type='card'>
              <TabPane tab='Daily' key='daily'>
                <Table
                  loading={loadingDaily}
                  bordered
                  size='middle'
                  rowKey={'skuId'}
                  dataSource={productReportDailyItems}
                  columns={productReportDailyColumns}
                />
              </TabPane>
              <TabPane tab='Weekly' key='weekly'>
                <Table
                  loading={loadingWeekly}
                  bordered
                  size='middle'
                  rowKey={'skuId'}
                  dataSource={productReportWeeklyItems}
                  columns={productReportWeeklyColumns}
                ></Table>
              </TabPane>
              <TabPane tab='Monthly' key='monthly'>
                <Table
                  loading={loadingMonthly}
                  bordered
                  size='middle'
                  rowKey={'skuId'}
                  dataSource={productReportMonthlyItems}
                  columns={productReportMonthlyColumns}
                ></Table>
              </TabPane>
            </Tabs>
          </>
        )
      case 'orders':
        return (
          <>
            <Tabs defaultActiveKey='daily' onChange={handleReportIntervalChange} type='card'>
              <TabPane tab='Daily' key='daily'>
                <Table
                  loading={loadingDaily}
                  bordered
                  size='middle'
                  rowKey={'key'}
                  dataSource={orderReportDailyItems}
                  columns={orderReportDailyColumns}
                />
              </TabPane>
              <TabPane tab='Weekly' key='weekly'>
                <Table
                  loading={loadingWeekly}
                  bordered
                  size='middle'
                  rowKey={'key'}
                  dataSource={orderReportWeeklyItems}
                  columns={orderReportWeeklyColumns}
                ></Table>
              </TabPane>
              <TabPane tab='Monthly' key='monthly'>
                <Table
                  loading={loadingMonthly}
                  bordered
                  size='middle'
                  rowKey={'key'}
                  dataSource={orderReportMonthlyItems}
                  columns={orderReportMonthlyColumns}
                ></Table>
              </TabPane>
            </Tabs>
          </>
        )
      default:
        break
    }
  }

  return (
    <Row>
      <Col span={24}>
        <Layout style={{ height: '100vh', position: 'absolute', width: '100%' }}>
          <ListHeader>
            <Row>
              <ColStyle
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(
                    setMenuDisplay({
                      menu: true
                    })
                  )
                }}
              >
                <img src='/icon/menu.png' width='35px' height='35px' alt='menu' />
              </ColStyle>

              <ColStyle flex={1} style={{ color: 'white', marginRight: '10px' }}>
                <div style={{ textTransform: 'capitalize' }}>{params.type} Report</div>
              </ColStyle>
            </Row>
          </ListHeader>
          <Content style={{ margin: '20px', display: 'flex' }}>
            {relativeTable()}
            <div>
              <Dropdown overlay={menu} placement='bottomLeft'>
                <Button type='primary' style={{ position: 'absolute', right: '41rem' }}>
                  Stores
                </Button>
              </Dropdown>
              <RangePicker
                style={{ width: '20%', position: 'absolute', right: '10rem' }}
                onChange={(dates: any) => {
                  if (!dates) {
                    setDateForm(0)
                    setDateTo(0)
                    setOrderReportDailyItems([])
                    setOrderReportMonthlyItems([])
                    setOrderReportWeeklyItems([])
                    setProductReportDailyItems([])
                    setProductReportWeeklyItems([])
                    setProductReportMonthlyItems([])
                    setPaymentReportDailyItems([])
                    setPaymentReportMonthlyItems([])
                    setPaymentReportDailyItems([])
                    return
                  }
                  setDateForm(dayjs(dates[0]).valueOf())
                  setDateTo(dayjs(dates[1]).valueOf())
                  setOrderReportDailyItems([])
                  setOrderReportMonthlyItems([])
                  setOrderReportWeeklyItems([])
                  setProductReportDailyItems([])
                  setProductReportWeeklyItems([])
                  setProductReportMonthlyItems([])
                  setPaymentReportDailyItems([])
                  setPaymentReportMonthlyItems([])
                  setPaymentReportDailyItems([])
                }}
                showTime
              />
              <Button
                type='primary'
                style={{ position: 'absolute', right: '2rem' }}
                onClick={async () => {
                  switch (params.type) {
                    case 'payments':
                      const res = await paymentReportExport({ interval: reportInterval, dateFrom, dateTo, storeId })
                      if (res.success) {
                        window.open(res.data.url)
                      } else {
                        message.error(res.message)
                      }
                      break
                    case 'orders':
                      const res2 = await orderReportExport({ interval: reportInterval, dateFrom, dateTo, storeId })
                      if (res2.success) {
                        window.open(res2.data.url)
                      } else {
                        message.error(res2.message)
                      }
                      break
                    case 'products':
                      const res3 = await productReportExport({ interval: reportInterval, dateFrom, dateTo, storeId })
                      if (res3.success) {
                        window.open(res3.data.url)
                      } else {
                        message.error(res3.message)
                      }
                      break
                    default:
                      break
                  }
                }}
              >
                Export
              </Button>
            </div>
          </Content>
        </Layout>
      </Col>
    </Row>
  )
}

export default Report
