import { useForm } from 'react-hook-form'
import React, { useEffect } from 'react'
import DialogCard from '../../../../components/DialogCard'
import { DialogProps } from '../../../../components/DialogCard/DialogCard'
import { formFieldsInfo, getLabelControlType } from '../../Hooks/formFieldInfo'
import LabelControl from '../../LabelControl/LabelControl'
import { FormTypes } from '../../Models/FormTypes.enum'
import { Space } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../redux/reduxHooks'
import { setAdjustmentInfo } from '../../../../redux/reducers/cartItems_reducer'
import { customerInfoData } from '../../../../redux/reducers/customerInfo_reducer'

interface Props extends Omit<DialogProps, 'handleOk'> {
  handleOk: (val?) => void
}

const AddOnes: React.FC<Props> = (props) => {
  const { open, handleCancel, headerText, handleOk } = props
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      addOneFee: '',
      note: ''
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
  })
  const dispatch = useAppDispatch()
  const { customerChoose } = useAppSelector(customerInfoData)

  useEffect(() => {
    if (customerChoose?.id) {
      reset({
        addOneFee: '',
        note: ''
      })
    }
  }, [customerChoose?.id])

  return (
    <DialogCard
      open={open}
      handleOk={handleSubmit((data: { addOneFee: string; note: string }) => {
        dispatch(setAdjustmentInfo({ info: { amount: +data.addOneFee, note: data.note } }))
        handleOk()
      })}
      handleCancel={() => {
        handleCancel()
      }}
      headerText={headerText}
    >
      <Space direction='vertical' style={{ width: '60%', height: '20rem' }}>
        {formFieldsInfo[FormTypes.AddOnes].map((item) => {
          return <LabelControl key={item} formControl={control} type={getLabelControlType(item)} formName={item} label={item} />
        })}
      </Space>
    </DialogCard>
  )
}

export default AddOnes
