/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import MainLayout from '../components/Layout/layout'
import styled from 'styled-components'
import { cashBoxCount, cashBoxInfo, getCustomerGroup } from '../api/response'
import { useAppDispatch } from '../redux/reduxHooks'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { setMenuDisplay } from '../redux/reducers/menu_reducer'
import { setCashBoxInfo } from '../redux/reducers/homePage_reducer'
import { message, Row, Col } from 'antd'
import CashBoxLayout from '../components/cashBoxLayout'
import ModalForm from '../components/common/modal-form'
import { Slider } from '../Features/NewSiderLayout'

const Div = styled.div`
  display: flex;
`

function App(props: RouteComponentProps) {
  const [windowSize, setWindowSize] = useState(window.screen.width)
  const [showCashBoxModal, setShowCashBoxModal] = useState(false)
  const [cashItems, setCashItems] = useState([
    { value: 100, quantity: 0 },
    { value: 50, quantity: 0 },
    { value: 20, quantity: 0 },
    { value: 10, quantity: 0 },
    { value: 5, quantity: 0 },
    { value: 2, quantity: 0 },
    { value: 1, quantity: 0 },
    { value: 0.5, quantity: 0 },
    { value: 0.2, quantity: 0 },
    { value: 0.1, quantity: 0 }
  ])
  const [cashItemsFromSystem, setCashItemsFromSystem] = useState([
    { value: 100, quantity: 0 },
    { value: 50, quantity: 0 },
    { value: 20, quantity: 0 },
    { value: 10, quantity: 0 },
    { value: 5, quantity: 0 },
    { value: 2, quantity: 0 },
    { value: 1, quantity: 0 },
    { value: 0.5, quantity: 0 },
    { value: 0.2, quantity: 0 },
    { value: 0.1, quantity: 0 }
  ])
  const [comment, setComment] = useState('')
  const [loading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const location = useLocation<{ from: string }>()
  const PictureRef = useRef(null)

  const customerGroup = async () => {
    const groupRes = await getCustomerGroup()
    if (groupRes.data) {
      const { data } = groupRes
      sessionStorage.setItem('customerGroup', JSON.stringify(data))
    }
  }

  // const storeInfoRes = async () => {
  //   const res = await getStoreInfo()
  //   if (res) {
  //     const { data } = res
  //     if (data.length === 0) {
  //       message.error('You do not have permission to manage any stores')
  //     }
  //     dispatch(setStoresList({ storesList: data }))
  //   }
  // }
  const getCashBoxInfo = useCallback(async () => {
    const res = await cashBoxInfo()
    if (res.success) {
      dispatch(setCashBoxInfo({ cashBoxInfo: res.data }))
      setCashItemsFromSystem(res.data.items)
      setCashItems(res.data.items)
      if (location.state && location.state?.from === '/login') {
        setShowCashBoxModal(true)
      } else {
        setShowCashBoxModal(false)
      }
    } else {
      message.error(res.message)
    }
  }, [location])

  useEffect(() => {
    getCashBoxInfo()
    dispatch(
      setMenuDisplay({
        menu: false
      })
    )
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem('customerGroup')) {
      return
    }
    customerGroup()
  }, [])
  // useEffect(() => {
  //   storeInfoRes()
  // }, [localStorage.getItem('lang')])
  useEffect(() => {
    window.addEventListener('resize', (e: any) => {
      setWindowSize(e.target.innerWidth)
    })

    return () => {
      window.removeEventListener('resize', (e: any) => {
        setWindowSize(e.target.innerWidth)
      })
    }
  }, [window.screen.width])
  return (
    <>
      <Row style={{minWidth: '1020px'}}>
        <Col md ={12} lg ={14} xl ={15} xxl={16} >
          <MainLayout />
        </Col>
        <Col md ={12} lg ={10} xl ={9} xxl={8}>
          <Slider />
        </Col>
      </Row>
      <ModalForm
        visible={showCashBoxModal}
        onCancel={() => {
          setShowCashBoxModal(false)
        }}
        width='fit-content'
        footer={null}
      >
        <CashBoxLayout
          cashItemsFromSystem={cashItemsFromSystem}
          cashItems={cashItems}
          type='checkCashBox'
          cashTotalType={'oldCashBoxTotal'}
          remainCashType={'remainCashBox'}
          cashEditType={'newCashBoxTotal'}
          setCashItems={(items) => setCashItems(items)}
          setComment={(comment) => setComment(comment)}
          loading={loading}
          pictureRef={PictureRef}
          checkLaterButtonHandler={() => {setShowCashBoxModal(false)}}
          checkNowButtonHandler={async () => {
            setLoading(true)
            const params = {
              items: cashItems,
              comment,
              images: PictureRef.current.getFileName().map((item) => item.name)
            }
            const res = await cashBoxCount(params)
            if (res.success) {
              setLoading(false)
              setShowCashBoxModal(false)

              message.success(res.message)
              return
            }
            setLoading(false)
            message.error(res.message)
          }}
        />
      </ModalForm>
    </>
  )
}

export default App
