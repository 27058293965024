import React, { useEffect, useState } from 'react'
import { Row, Col, Table, Input, DatePicker, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { creditHistoryColumns } from '../../data/tableColumns'
import { exportCreditAsExcel, getCreditHistory } from '../../api/response'
import dayjs from 'dayjs'
import CommonListLayout from '../common/commonListLayout'
import { CustomerCreditDataTypes } from '../../models/customerCenterTypes/customerCreditTypes'

const { RangePicker } = DatePicker

const Credit = () => {
  const [pointHistory, setPointHistory] = useState<CustomerCreditDataTypes[]>([])
  const [paginator, setPaginator] = useState({ limit: 25, page: 1 })
  const [total, setTotal] = useState(0)
  const [dateFrom, setDateForm] = useState(0)
  const [searchString, setSearchString] = useState('')
  const [dateTo, setDateTo] = useState(0)

  useEffect(() => {
    const params = {
      pageSize: paginator.limit,
      currentPage: paginator.page,
      dateFrom: dateFrom,
      dateTo: dateTo,
      q: searchString,
      query: { transactionTime: 1 }
    }
    getCreditHistory(params).then((res) => {
      if (res.success) {
        const { items } = res.data
        const { totalCount } = res.data.pagination
        const pointArr = [...pointHistory, ...items]
        if (pointArr.length >= totalCount) {
          setTotal(totalCount)
          setPointHistory(items)
          return
        }
        setPointHistory(items)
        setTotal(totalCount)
      }
    })
  }, [paginator])
  return (
    <CommonListLayout title='Credit List'>
      <Table
        bordered
        dataSource={pointHistory}
        columns={creditHistoryColumns}
        rowKey='id'
        title={() => {
          return (
            <Row justify='space-between'>
              <Col span={8}>
                <Input
                  allowClear
                  defaultValue={null}
                  placeholder='Search'
                  autoFocus={true}
                  prefix={<SearchOutlined style={{ color: 'gray' }} />}
                  onChange={(e) => {
                    if (!e.target.value) {
                      setSearchString('')
                      setPointHistory([])
                      setPaginator({ limit: 25, page: 1 })
                    }
                    setSearchString(e.target.value)
                    setPointHistory([])
                    setPaginator({ limit: 25, page: 1 })
                  }}
                />
              </Col>
              <Col span={10}>
                <RangePicker
                  style={{ width: '75%' }}
                  onChange={(dates: any) => {
                    if (!dates) {
                      setDateForm(0)
                      setDateTo(0)
                      setPointHistory([])
                      setPaginator({ limit: 25, page: 1 })

                      return
                    }
                    setDateForm(dayjs(dates[0]).valueOf())
                    setDateTo(dayjs(dates[1]).valueOf())
                    setPointHistory([])
                    setPaginator({ limit: 25, page: 1 })
                  }}
                  showTime
                />
                <Button
                  type='primary'
                  style={{ marginLeft: '24px' }}
                  onClick={() => {
                    const params = {
                      pageSize: paginator.limit,
                      currentPage: paginator.page,
                      dateFrom: dateFrom,
                      dateTo: dateTo,
                      query: { transactionTime: 1 }
                    }
                    exportCreditAsExcel(params).then((res) => {
                      if (res.success) {
                        window.open(res.data.url)
                      }
                    })
                  }}
                >
                  Export
                </Button>
              </Col>
            </Row>
          )
        }}
        scroll={{ y: '67vh' }}
        pagination={{
          total,
          defaultPageSize: paginator.limit,
          current: paginator.page,
          onChange: (page, pageSize) => {
            setPaginator({ page, limit: pageSize })
          }
        }}
      />
    </CommonListLayout>
  )
}

export default Credit
