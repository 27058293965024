import { FormFields } from '../Models/FormFieldsName.enum'
import { FormTypes } from '../Models/FormTypes.enum'
import { LabelControlType } from '../Models/LabelControlType.enum'

export const formFieldsInfo: {
  [key in FormTypes]: FormFields[]
} = {
  [FormTypes.AddOnes]: [FormFields.AddOneFee, FormFields.Note],
  [FormTypes.Customer]: [
    FormFields.Type,
    FormFields.Nickname,
    FormFields.CustomerName,
    FormFields.Surname,
    FormFields.Phone,
    FormFields.Email,
    FormFields.DisplayName,
    FormFields.DisplayAddress,
    FormFields.ManagerComment,
  ],
  [FormTypes.Address]: [
    FormFields.Shipmentareas,
    FormFields.City,
    FormFields.Street,
    FormFields.ZipCode,
    FormFields.Name,
    FormFields.Surname,
    FormFields.Phone,
    FormFields.IdCard,
    FormFields.Cover,
    FormFields.DefaultAddress
  ]
}

export const getLabelControlType = (fieldType: FormFields): LabelControlType => {
  switch (fieldType) {
    case FormFields.Note:
    case FormFields.ManagerComment:
      return LabelControlType.TextArea
    case FormFields.Type:
      return LabelControlType.Select
    case FormFields.Cover:
      return LabelControlType.Upload
    case FormFields.Shipmentareas:
      return LabelControlType.Cascader
    case FormFields.DefaultAddress:
    case FormFields.EmailVerified:
    case FormFields.PointUpdateNotification:
    case FormFields.PointExpirationNotification:
      return LabelControlType.Switch
    default:
      return LabelControlType.Input
  }
}
