export enum PriceTypes {
  origin = "",
  special = "促销",
  wholesale = "批发",
  group = "会员",
  partner = "伙伴"
}

export enum PriceTypes_en {
  origin = "",
  special = "On Sale",
  wholesale = "Wholesale",
  group = "VIP",
  partner = "Partner",
}