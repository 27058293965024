import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

type initialStateTypes = {
  addComment: number,
  placeOrderPrice:number,
  orderIdInfo:string,
  placeorderSuccess:boolean,
  chooseDeliver:string
  chooseAddress:string
}

const initialState: initialStateTypes = {
  addComment: 0,
  placeOrderPrice:0,
  orderIdInfo:'',
  placeorderSuccess:false,
  chooseDeliver:'',
  chooseAddress:''
}


export const orderInfo = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    paymentHistoryAddComment: (state, action: PayloadAction<{ addComment:number }>) => {
      state.addComment = action.payload.addComment+state.addComment
    },
    setPlaceOrderPrice: (state, action: PayloadAction<{ price:number }>) => {
      state.placeOrderPrice = action.payload.price
    },
    setOrderIdInfo: (state, action: PayloadAction<{ id:string }>) => {
      state.orderIdInfo = action.payload.id
    },
    setPlaceorderSuccess: (state, action: PayloadAction<{ success:boolean }>) => {
      state.placeorderSuccess = action.payload.success
    },
    setChooseDeliver: (state, action: PayloadAction<{ shipmentMethod:string }>) => {
      state.chooseDeliver = action.payload.shipmentMethod
    },
    setChoosedAddress: (state, action: PayloadAction<{ address:string }>) => {
      state.chooseAddress = action.payload.address
    },
  },

})

export const { paymentHistoryAddComment,setPlaceOrderPrice,setOrderIdInfo,setPlaceorderSuccess,setChooseDeliver,setChoosedAddress } = orderInfo.actions //= connect(dispatch)

export const orderData = (state: RootState) => state.order // = connect(state)

export default orderInfo.reducer
