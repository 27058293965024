import { IntlProvider } from 'react-intl' /* react-intl imports */

import React, { useEffect, useState } from 'react'
import ZH_CN from '../locale/zh_CN'
import EN_US from '../locale/en_US'

import { withRouter } from 'react-router'
import { useAppSelector } from '../redux/reduxHooks'
import { homePageData } from '../redux/reducers/homePage_reducer'

const Header = (props) => {
  const storeState = useAppSelector(homePageData)
  const messages = {}
  messages['en-NZ'] = EN_US
  messages['zh-CN'] = ZH_CN


  return (
    <IntlProvider locale={localStorage.getItem('lang')} messages={messages[localStorage.getItem('lang')]}>
      {props.children}
    </IntlProvider>
  )
}
export default withRouter(Header)
