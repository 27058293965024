import React, { useState } from 'react'
import { Button, Col, Divider, Row, Select, SelectProps } from 'antd'
import {
  CartItemsContent,
  FooterButtons,
  FooterLayout,
  HeaderLayout,
  HeaderLeftSection,
  HeaderRightSection,
  MainLayout,
  SubHeaderLayout,
  SubHeaderLeftPart,
  SubHeaderMiddlePart,
  SubHeaderRightPart,
  FooterButton,
  FooterCheckoutButton
} from './SiderLayout.style'
import { CartItem } from '../../components/CartItem'
import { useSliderLayout } from './Hooks'
import { AddCoupon, AddOnes, AddShipment, Customer } from './Dialogs'
import { CouponDetailTypes } from '../../models/coupon/couponListTypes'
import NP from 'number-precision'
import Address from './Dialogs/Address/Address'
import { CloseCircleFilled, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { getShipmentForShippingTerm, getShipmentMethod } from '../../api/response'
import { FormattedMessage } from 'react-intl'
import {
  getCustomerDisplay,
  getShipmentTypeAndMethod,
  setShipmentCodeAndGroupId,
  setShipmentMethodForCustomer,
  setShipmentType
} from '../../redux/reducers/customerInfo_reducer'
import { useAppDispatch, useAppSelector } from '../../redux/reduxHooks'
import { setAdjustmentInfo, setShipmentMethodInfo, shoppingCartData } from '../../redux/reducers/cartItems_reducer'
import { getCheckoutInfo } from '../../redux/reducers/homePage_reducer'

const SliderLayout = () => {
  const [showAddressModal, setShowAddressModal] = useState<boolean>(false)
  const {
    searchDropDownList,
    searchLoading,
    onHoldHandler,
    shoppingCartItems,
    increaseCountHandler,
    decreaseCountHandler,
    deleteItemHandler,
    handleSearch,
    handleChange,
    onHoldList,
    getOrderFromOnHoldList,
    setShowAddOneModal,
    showAddOneModal,
    showAddCouponModal,
    setShowAddCouponModal,
    showAddShipmentModal,
    setShowAddShipmentModal,
    shipmentMethodOptions,
    scrollDown,
    currencyRate,
    showCustomerModal,
    setShowCustomerModal,
    setCustomerModalType,
    customerModalType,
    checkoutHandler,
    checkoutHandlerCoupon,
    checkoutHandlerShipment,
    getPlaceOrderInfoBack,
    setHasError,
    hasError
  } = useSliderLayout()

  const dispatch = useAppDispatch()
  const { chosenShipmentMethod: addShipmentInfo, couponInfo: addCouponInfo, adjustmentInfo: addOneInfo } = useAppSelector(shoppingCartData)
  const checkoutRes = useAppSelector(getCheckoutInfo)
  const { shipmentMethod, shipmentType } = useAppSelector(getShipmentTypeAndMethod)
  const customerDisplay = useAppSelector(getCustomerDisplay)

  const getCurrencyPrice = () => {
    const price = shoppingCartItems.reduce((pre, nex) => {
      return pre + nex.finalPrice
    }, 0)

    return shoppingCartItems.length === 0
      ? 0
      : !!checkoutRes
        ? NP.round(NP.times(checkoutRes?.total || 0, currencyRate), 2)
        : NP.round(NP.times(price * currencyRate), 2)
  }
  const getPrice = () => {
    const price = shoppingCartItems.reduce((pre, nex) => {
      return pre + nex.finalPrice
    }, 0)
    return shoppingCartItems.length === 0
      ? 0
      : !!checkoutRes
        ? NP.round(checkoutRes?.total || 0, 2)
        : price + addOneInfo?.amount ?? 0 - addCouponInfo?.details?.amount ?? 0
  }
  return (
    <MainLayout>
      <HeaderLayout>
        <HeaderLeftSection>
          <Button onClick={onHoldHandler} disabled={shoppingCartItems.length === 0} style={{ backgroundColor: 'none' }}>
            <FormattedMessage id='hold' />
          </Button>
          <Button onClick={getOrderFromOnHoldList} disabled={onHoldList.length === 0}>
            <FormattedMessage id='takeOnHold' />
            {onHoldList.length > 1 && <>({onHoldList.length})</>}
          </Button>
          <Select
            value={shipmentMethod}
            onChange={(e) => {
              dispatch(setShipmentMethodForCustomer({ method: e }))
              dispatch(setShipmentType({ type: e === 'courier' ? 2 : 1 }))
              dispatch(setShipmentCodeAndGroupId({ shipmentAreaCode: '', shipmentGroupId: 0 }))
              dispatch(setShipmentMethodInfo({ info: null }))
            }}
            options={shipmentMethodOptions.length > 0 && shipmentMethodOptions}
          />
          <Select
            value={shipmentType}
            onChange={(e) => {
              dispatch(setShipmentType({ type: e }))
            }}
            options={
              localStorage.getItem('lang') === 'zh-CN'
                ? [
                  { value: 1, label: '现货' },
                  { value: 2, label: '预售' }
                ]
                : [
                  { value: 1, label: 'In Stock' },
                  { value: 2, label: 'Pre Sale' }
                ]
            }
          />
        </HeaderLeftSection>

        <HeaderRightSection>
          <div className='Title'>
            {`${JSON.parse(sessionStorage.getItem('manager')).name} ${JSON.parse(sessionStorage.getItem('manager')).code}`}
          </div>
          <div className='SubTitle'>{JSON.parse(sessionStorage.getItem('manager')).nickname}</div>
        </HeaderRightSection>
      </HeaderLayout>
      <Row align="middle" wrap={false} justify='space-between' style={{marginTop: '10px'}}>
        <Col span={4}>
          <FormattedMessage id='memberShip' />:
        </Col>
        <Col span={12} id='area'>
          <Select
            disabled={shoppingCartItems.length > 0}
            showSearch
            allowClear
            loading={searchLoading}
            value={customerDisplay}
            placeholder={'编号/姓名/电话/邮箱'}
            style={{width: '100%'}}
            defaultActiveFirstOption={false}
            showArrow={true}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            notFoundContent={searchLoading ? <LoadingOutlined /> : 'no data'}
            options={
              searchDropDownList.length > 0
                ? searchDropDownList.map((d) => ({
                  value: d.value,
                  label: d.text
                }))
                : []
            }
            onPopupScroll={scrollDown}
            listHeight={550}
          />
        </Col>
        <Col span={4} align="right">
          <Button
            type='primary'
            size='small'
            disabled={!customerDisplay}
            onClick={() => {
              setCustomerModalType('edit')
              setShowCustomerModal(true)
            }}
          >
            <FormattedMessage id='update' />
          </Button>
        </Col>
        <Col span={4} align="center">
          <Button
            type='primary'
            size='small'
            style={{ marginLeft: '10px' }}
            onClick={() => {
              setCustomerModalType('add')
              setShowCustomerModal(true)
            }}
          >
            <FormattedMessage id='add' />
          </Button>
        </Col>
      </Row>
      <Divider style={{ margin: '10px 0' }} />
      <CartItemsContent>
        {shoppingCartItems.map((item, index) => {
          return (
            <div key={item.sku}>
              {!!index && <Divider style={{ margin: '0.5rem 0' }} />}

              <CartItem
                cartProduct={item}
                increaseCountHandler={increaseCountHandler.bind(item)}
                decreaseCountHandler={decreaseCountHandler.bind(item)}
                deleteItem={deleteItemHandler.bind(item)}
              />
            </div>
          )
        })}
      </CartItemsContent>
      <Divider style={{ margin: '0.5rem 0' }} />

      <FooterLayout>
        <div style={{ width: '100%' }}>
          {addOneInfo.amount !== 0 && (
            <>
              <Row justify='center' align='middle'>
                <Col span={4} offset={1} style={{ fontSize: '1.2rem', color: 'orange', fontWeight: 600 }}>
                  <FormattedMessage id='addone' />
                </Col>
                <Col span={11}>
                  <span style={{ fontSize: '0.8rem' }}>{addOneInfo.note}</span>
                </Col>
                <Col span={7} offset={1} style={{ fontSize: '1rem', color: 'red' }}>
                  {addOneInfo.amount > 0 && <FormattedMessage id='fee' />}
                  {addOneInfo.amount < 0 && <FormattedMessage id='discount' />}: ${Math.abs(addOneInfo.amount)}
                </Col>
              </Row>
              <Divider style={{ margin: '0.5rem 0' }} />
            </>
          )}
          {!!addCouponInfo?.code && (
            <>
              <Row justify='center' align='middle'>
                <Col span={4} offset={1} style={{ fontSize: '1.2rem', color: 'orange', fontWeight: 600 }}>
                  <FormattedMessage id='coupon' />
                </Col>
                <Col span={11}>
                  <span style={{ fontSize: '1.2rem' }}>{addCouponInfo.details.display.name}</span>
                  <span style={{ fontSize: '1rem', color: 'red' }}>
                    ({`满$${addCouponInfo.details.subtotal}送$${addCouponInfo.details.amount}`})
                  </span>
                  <br />
                  <span>
                    {addCouponInfo.details.scope === 'all'
                      ? '全场可用'
                      : addCouponInfo.details.scopeList.map((item) => {
                        return item.name
                      })}
                  </span>
                </Col>
                <Col span={7} offset={1} style={{ fontSize: '1.1rem', color: 'red' }}>
                  <FormattedMessage id='fee' />: ${addCouponInfo.details.amount}
                  <div style={{ fontSize: '0.8rem', color: 'lightgray' }}>
                    {' '}
                    <FormattedMessage id='couponCode' />: {addCouponInfo.details.code}
                  </div>
                </Col>
              </Row>
              <Divider style={{ margin: '0.5rem 0' }} />
            </>
          )}
          {addShipmentInfo && (
            <>
              <Row justify='center' align='middle'>
                <Col span={4} offset={1} style={{ fontSize: '1.2rem', color: 'orange', fontWeight: 600 }}>
                  {shipmentMethod === 'pickup' ? <FormattedMessage id='pickup' /> : <FormattedMessage id='shippingFee' />}:
                </Col>
                <Col span={11}>
                  <span style={{ fontSize: '1rem' }}>{addShipmentInfo.name}</span>
                  <br />
                  {addShipmentInfo.additionalPrice > 0 && (
                    <span>
                      偏远费${addShipmentInfo.additionalPrice}
                      <br />
                    </span>
                  )}

                  {!!addShipmentInfo.notice && (
                    <span>
                      <FormattedMessage id='notice' />:{addShipmentInfo.notice}
                    </span>
                  )}
                </Col>
                <Col span={7} offset={1} style={{ fontSize: '1.1rem', color: 'red' }}>
                  <FormattedMessage id='fee' />: ${shoppingCartItems.length === 0 ? 0 : checkoutRes?.shippingFee}
                  {/* <div>xxx</div> */}
                </Col>
              </Row>
              <Divider style={{ margin: '0.5rem 0' }} />
            </>
          )}
        </div>
        <FooterButtons>
          <div style={{ height: '100%', marginLeft: '10px' }}>
            <FooterButton
              size='large'
              onClick={() => {
                setShowAddOneModal(true)
              }}
            >
              <FormattedMessage id='adjustment' />
            </FooterButton>
            <FooterButton
              size='large'
              onClick={() => {
                setShowAddCouponModal(true)
              }}
            >
              <FormattedMessage id='coupon' />
            </FooterButton>
            <FooterButton
              size='large'
              onClick={() => {
                setShowAddShipmentModal(true)
              }}
            >
              {shipmentMethod === 'pickup' ? <FormattedMessage id='pickup' /> : <FormattedMessage id='shippingFee' />}
            </FooterButton>
          </div>
          <FooterCheckoutButton
            type='primary'
            size='large'
            $lang={localStorage.getItem('lang')}
            disabled={
              (shipmentMethod !== 'pickup' && !addShipmentInfo) ||
              (shipmentMethod === 'pickup' && shipmentType !== 1 && !addShipmentInfo) ||
              shoppingCartItems.length === 0
            }
            onClick={async () => {
              if (shipmentMethod === 'pickup') {
                const result = await getShipmentForShippingTerm(shipmentMethod)
                if (result.success) {
                  const groupId = result.data.find((r) => r.shippingTerm === 'pickup').groupId
                  const areaCode = result.data.find((r) => r.shippingTerm === 'pickup').areaCode

                  getShipmentMethod({ shippingTerm: shipmentMethod, groupId: groupId, areaCode: areaCode }).then((res) => {
                    if (res.success) {
                      getPlaceOrderInfoBack({
                        shipmentGroupId: groupId,
                        shipmentAreaCode: areaCode,
                        shipmentMethodId: res.data[0].id
                      })
                    }
                  })
                }
                return
              }
              setShowAddressModal(true)
            }}
          >
            {localStorage.getItem('lang') === 'zh-CN' && <div style={{ fontSize: '1rem', fontWeight: 600 }}>结帐</div>}
            <div>
              <div style={{ fontSize: '1.1rem', fontWeight: 600, }}>${getPrice()}</div>
              {localStorage.getItem('lang') === 'zh-CN' && <div style={{ fontSize: '0.7rem', marginTop: '-5px' }}>￥{getCurrencyPrice()}</div>}
            </div>
          </FooterCheckoutButton>
        </FooterButtons>
      </FooterLayout>
      <AddOnes
        open={showAddOneModal}
        handleCancel={() => setShowAddOneModal(false)}
        headerText={localStorage.getItem('lang') === 'zh-CN' ? '附加项' : 'Adjustment'}
        handleOk={(val) => {
          setShowAddOneModal(false)
        }}
      />
      {showAddCouponModal && (
        <AddCoupon
          open={showAddCouponModal}
          handleCancel={() => {
            setHasError('')
            setShowAddCouponModal(false)
          }}
          handleOk={(val: { code: string; details: CouponDetailTypes }) => {
            checkoutHandlerCoupon(val)
          }}
          headerText={localStorage.getItem('lang') === 'zh-CN' ? '优惠卷' : 'Coupon'}
          setHasError={(v) => setHasError(v)}
          hasError={hasError}
        />
      )}

      {showAddShipmentModal && (
        <AddShipment
          open={showAddShipmentModal}
          handleCancel={() => setShowAddShipmentModal(false)}
          handleOk={(val) => {
            console.log(val)
            checkoutHandlerShipment(val)
          }}
          headerText={
            localStorage.getItem('lang') === 'zh-CN'
              ? shipmentMethod === 'pickup'
                ? '自提点'
                : '快递费'
              : shipmentMethod === 'pickup'
                ? 'Pickup'
                : 'Shipping Fee'
          }
          shipmentType={shipmentMethod}
        />
      )}
      {showCustomerModal && (
        <Customer
          open={showCustomerModal}
          handleCancel={() => setShowCustomerModal(false)}
          headerText={
            localStorage.getItem('lang') === 'zh-CN'
              ? customerModalType === 'edit'
                ? `会员管理 - 编辑`
                : `会员管理 - 新增`
              : customerModalType === 'edit'
                ? `Customer - Edit`
                : `Customer - Add`
          }
          openType={customerModalType}
        />
      )}
      {showAddressModal && (
        <Address
          open={showAddressModal}
          handleCancel={() => setShowAddressModal(false)}
          headerText={localStorage.getItem('lang') === 'zh-CN' ? `收货地址` : 'Shipping Address'}
          getPlaceOrderInfoBack={getPlaceOrderInfoBack}
        />
      )}
    </MainLayout>
  )
}

export default SliderLayout
