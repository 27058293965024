import React, { useState, useEffect, useCallback } from 'react'
import { getCouponQuery, validateCouponCode } from '../../../api/response'
import { CouponDetailTypes } from '../../../models/coupon/couponListTypes'
import { useAppSelector } from '../../../redux/reduxHooks'
import { customerInfoData } from '../../../redux/reducers/customerInfo_reducer'
import { message } from 'antd'

export const useAddCoupon = ({ setHasError }: { setHasError: (v) => void }) => {
  const [chosenItem, setChosenItem] = useState<number>(0)
  const [couponDetails, setCouponDetails] = useState<CouponDetailTypes[]>([])
  const [couponInfo, setCouponInfo] = useState<CouponDetailTypes>()
  const [couponCode, setCouponCode] = useState('')
  const customerStore = useAppSelector(customerInfoData)
  const getCouponList = async () => {
    const res = await getCouponQuery({ customerId: customerStore.customerId })
    if (res.success) {
      setCouponDetails(res.data.items)
    }
  }
  useEffect(() => {
    getCouponList()
  }, [customerStore.customerId])

  const validateCoupon = async () => {
    if (couponCode !== '') {
      const validateRes = await validateCouponCode({ code: couponCode })

      if (validateRes.success) {
        setCouponInfo(validateRes.data)
      } else {
        message.error(validateRes.message)
        setHasError(validateRes.message)
      }
    }
  }

  const itemClicked = async (id: number, code: string) => {
    setChosenItem(id)
    const res = await validateCouponCode({ code: code })
    if (res.success) {
      setCouponCode(code)
      setCouponInfo(res.data)
    } else {
      message.error(res.message)
    }
  }
  const insertCouponCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setHasError("")
    }
    setCouponCode(e.target.value)
  }

  return {
    chosenItem,
    couponDetails,
    itemClicked,
    insertCouponCode,
    validateCoupon,
    couponCode,
    couponInfo
  }
}
