import { Card, Col, Divider, message, Radio, Row, Space, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { getShipmentFee, getShipmentMethod } from '../../api/response'
import { ShipmentMethodTypes } from '../../models/shipping/shippingMethod'
import { shoppingCartData } from '../../redux/reducers/cartItems_reducer'
import { customerInfoData } from '../../redux/reducers/customerInfo_reducer'
import { homePageData } from '../../redux/reducers/homePage_reducer'
import { useAppSelector } from '../../redux/reduxHooks'

interface Props {
  setShipPrice?: (data) => void
  shipmentMethodId: number
  setShipmentMethodId: (data) => void
  setChooseDeliver?: (data) => void
  shipmentFee?: (value) => void
  addressArea: string
  addressGroup: number
  addressShipmentType?: string
  shipmentMethodsList?: (data) => void
  shipmentMethod?: (data) => void
}
const Shipment = ({
  addressShipmentType,
  setShipPrice,
  shipmentMethodId,
  setShipmentMethodId,
  setChooseDeliver,
  shipmentFee,
  addressArea,
  addressGroup,
  shipmentMethodsList,
  shipmentMethod
}: Props) => {
  const storeState = useAppSelector(customerInfoData)
  const shoppingCartInfo = useAppSelector(shoppingCartData)
  const configData = useAppSelector(homePageData)

  const [shipmentMethods, setShipmentMethods] = useState<ShipmentMethodTypes[]>([])

  const getCustomerShipments = async () => {
    const res = await getShipmentMethod({ areaCode: addressArea, groupId: addressGroup, shippingTerm: addressShipmentType })
    if (res.success) {
      if (res.data.length > 0) {
        if (shipmentFee) {
          shipmentFee(res.data[0]?.id.toString())
        }
        setShipmentMethodId(res.data[0].id)
        if (shipmentMethod) {
          shipmentMethod(res.data[0])
        }
        if (setChooseDeliver) {
          setChooseDeliver(res.data[0].name)
        }
      }
      setShipmentMethods(res.data)
      if (shipmentMethodsList) {
        shipmentMethodsList(res.data)
      }
    } else {
      message.error(res.message)
      setShipmentMethods([])
    }
  }
  useEffect(() => {
    let isUnmount = false
    console.log(1)
    if (!isUnmount) {
      getCustomerShipments()
    }
    return () => {
      isUnmount = true
    }
  }, [configData.lang, addressArea, addressShipmentType, addressGroup])

  useEffect(() => {
    const param = {
      items: [],
      customerId: storeState.customerId,
      storeId: +sessionStorage.getItem('storeId')
    }
    getShipmentFee(shipmentMethodId, param).then((res) => {
      if (res.success) {
        if (setShipPrice) {
          setShipPrice(res.data)
        }
      }
    })
  }, [shipmentMethodId])
  return (
    <>
      {shipmentMethods.length > 0 ? (
        <Card style={{ maxWidth: '100%' }}>
          <Radio.Group style={{ width: '100%' }} value={shipmentMethodId}>
            <Space direction='vertical' style={{ width: '100%' }}>
              {shipmentMethods.map((item, index) => {
                return (
                  <>
                    <div key={index} style={{ width: '100%' }}>
                      <Radio
                        value={item.id}
                        onClick={(e) => {
                          setShipmentMethodId(item.id)
                          if (shipmentMethod) {
                            shipmentMethod(item)
                          }
                          if (setChooseDeliver) {
                            setChooseDeliver(item.name)
                          }
                          e.stopPropagation()
                        }}
                        style={{ width: '100%' }}
                      >
                        <Row style={{ display: 'inline-flex', width: '100%' }}>
                          <Col style={{ marginRight: '10px' }}>{`${item.name} `}</Col>

                          <Col>
                            {item.freeshipEnable && item.freeshipSubtotal === 0 ? (
                              <Tag color={'red'}>
                                <FormattedMessage id='freeShipment' />
                              </Tag>
                            ) : item.freeshipSubtotal !== 0 ? (
                              <Tag color={'red'}>{`$${item.freeshipSubtotal.toFixed(2)}/￥${(
                                item.freeshipSubtotal * shoppingCartInfo.currencyRate
                              ).toFixed(2)}`}<FormattedMessage id='freeShipment' /></Tag>
                            ) : null}
                            {item.notice ? <Tag color={'red'}>{item.notice}</Tag> : null}
                          </Col>
                          {item.pickup ? (
                            <>
                              <FormattedMessage id='store' />:
                              <span style={{ fontWeight: 700, marginLeft: '5px' }}>{`${item.pickup.name || ''}`}</span>
                              <span>{` ${item.pickup.address || ''}${item.pickup.phone || ''}${item.pickup.description || ''}`}</span>
                              <span style={{ color: 'red', marginLeft: '0.5rem' }}>{`${item.pickup.notice || ''}`}</span>
                            </>
                          ) : null}
                          {item.shipping ? (
                            <>
                              <Col>
                                {localStorage.getItem('lang') === 'zh-CN' ? '承运：' : 'Carrier: '}
                                <span style={{ fontWeight: 700 }}>{`${item.shipping.name || ''}`}</span>
                              </Col>
                              <Col>{` ${item.shipping.url || ''}${item.shipping.description || ''}`}</Col>
                              <Col style={{ color: 'red', marginLeft: '0.5rem' }}>{`${item.shipping.notice || ''}`}</Col>
                            </>
                          ) : null}
                        </Row>
                      </Radio>
                    </div>
                    <Divider style={{ margin: 0 }} />
                  </>
                )
              })}
            </Space>
          </Radio.Group>
        </Card>
      ) : (
        <Card style={{ maxWidth: '70%' }}>No Shipment Methods in this area.</Card>
      )}
    </>
  )
}

export default Shipment
