import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Drawer, message, Typography } from 'antd'
import styled from 'styled-components'
import { menuList } from '../../data/menuList'
import { withRouter } from 'react-router-dom'
import { menuData, setMenuDisplay } from '../../redux/reducers/menu_reducer'
import { Manager } from '../../models/loginTypes'
import { useAppDispatch, useAppSelector } from '../../redux/reduxHooks'
import ModalForm from '../common/modal-form'
import CashBoxLayout from '../cashBoxLayout'
import { cashBoxInfo, logout } from '../../api/response'
import { homePageData } from '../../redux/reducers/homePage_reducer'

const { Text } = Typography
const Div = styled.div`
  height: 50px;
  background-color: #202835;
  display: -webkit-box;
  -webkit-box-pack: center;
  -webkit-box-align: center;
`
const MenuItems = styled.div`
  color: white;
  font-size: 12px;
  margin-top: 5px;
  &:hover {
    background-color: darkgrey;
    font-weight: 700;
  }
`

const DrawerStyle = styled(Drawer)`
  .ant-drawer-title {
    height: 100%;
  }
`

const LeftLayout = (props) => {
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const [showCashBoxModal, setShowCashBoxModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [username, setUsername] = useState<string>()
  const [comment, setComment] = useState<string>('')

  const PictureRef = useRef(null)
  const dispatch = useAppDispatch()
  const storeState = useAppSelector(menuData)
  const homeState = useAppSelector(homePageData)
  const [cashItems, setCashItems] = useState([
    { value: 100, quantity: 0 },
    { value: 50, quantity: 0 },
    { value: 20, quantity: 0 },
    { value: 10, quantity: 0 },
    { value: 5, quantity: 0 },
    { value: 2, quantity: 0 },
    { value: 1, quantity: 0 },
    { value: 0.5, quantity: 0 },
    { value: 0.2, quantity: 0 },
    { value: 0.1, quantity: 0 }
  ])
  const [cashItemsFromSystem, setCashItemsFromSystem] = useState([
    { value: 100, quantity: 0 },
    { value: 50, quantity: 0 },
    { value: 20, quantity: 0 },
    { value: 10, quantity: 0 },
    { value: 5, quantity: 0 },
    { value: 2, quantity: 0 },
    { value: 1, quantity: 0 },
    { value: 0.5, quantity: 0 },
    { value: 0.2, quantity: 0 },
    { value: 0.1, quantity: 0 }
  ])

  useEffect(() => {
    setCollapsed(storeState.menu)
  }, [storeState.menu])

  useEffect(() => {
    const manager: Manager = JSON.parse(sessionStorage.getItem('manager'))
    setUsername(manager?.username)
  }, [])

  const getMenuNodes = (MenuList, name = null) => {
    return MenuList.map((item, index) => {
      if (!item.children) {
        return (
          <div
            key={index}
            style={{
              backgroundColor: item.color,
              textAlign: 'center',
              marginTop: '0px',
              display: 'flex',
              flexDirection: 'column',
              cursor: 'pointer'
            }}
            onClick={async () => {
              if (item.title === 'Logout') {
                setLoading(true)
                const res = await cashBoxInfo()
                if (res.success) {
                  setLoading(false)
                  if (res.data.items.length > 0) {
                    setCashItemsFromSystem(res.data.items)
                    setCashItems(res.data.items)
                  }
                  setShowCashBoxModal(true)
                }

              } else {
                props.history.push(item.path)
              }
            }}
          >
            {name ? (
              index < 1 ? (
                <Div style={{ height: '35px' }}>
                  <Text strong type='secondary' style={{ color: 'white' }}>
                    {name}
                  </Text>
                </Div>
              ) : null
            ) : null}

            <MenuItems>
              <Row justify='start' align='middle' wrap={false} style={{ padding: '5px', height: '35px' }}>
                <Col span={8}>{item.icon}</Col>
                <Col span={16} style={{ textAlign: 'left', color: 'white' }}>
                  <Text style={{ color: 'white' }}>{item.title}</Text>
                </Col>
              </Row>
            </MenuItems>
          </div>
        )
      } else {
        return getMenuNodes(item.children, item.name)
      }
    })
  }

  const onClose = () => {
    dispatch(
      setMenuDisplay({
        menu: false
      })
    )
  }

  return (
    <>
      <div>
        <DrawerStyle
          title={
            !!username && (
              <div style={{ color: 'white', height: '100%' }}>
                <Row align='middle' justify='center' style={{ height: '100%' }}>
                  <Col span={23} offset={1} style={{ textAlign: 'center' }}>
                    <b>{sessionStorage.getItem('storeName')}</b>
                  </Col>
                </Row>
              </div>
            )
          }
          style={{ zIndex: 99999999999 }}
          headerStyle={{ backgroundColor: '#202835', padding: 0, height: '64px' }}
          visible={collapsed}
          placement='left'
          closable={false}
          onClose={onClose}
          bodyStyle={{
            backgroundColor: '#2C3340',
            color: 'white',
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '100vh'
          }}
          width={150}
        >
          {getMenuNodes(menuList)}
        </DrawerStyle>
      </div>
      <ModalForm
        visible={showCashBoxModal}
        onCancel={() => {
          setShowCashBoxModal(false)
        }}
        width='fit-content'
        footer={null}
      >
        <CashBoxLayout
          cashItemsFromSystem={cashItemsFromSystem}
          cashItems={cashItems}
          type='checkCashBox'
          cashEditType='newCashBoxTotal'
          cashTotalType='oldCashBoxTotal'
          remainCashType='remainCashBox'
          setCashItems={(items) => setCashItems(items)}
          setComment={(a) => setComment(a)}
          loading={loading}
          checkLaterButtonHandler={() => {setShowCashBoxModal(false)}}
          checkNowButtonHandler={async () => {
            const params = {
              items: cashItems,
              comment,
              images: PictureRef.current.getFileName().map((item) => item.name)
            }
            const res = await logout(params)
            if (res.success) {
              localStorage.removeItem('token')
              localStorage.removeItem('refreshToken')
              localStorage.removeItem('tokenExpireAt')
              sessionStorage.removeItem('manager')
              sessionStorage.removeItem('storeId')
              sessionStorage.removeItem('storeName')
              sessionStorage.removeItem('customerGroup')
              message.success('Logout successfully')
              props.history.push('/login')
              return
            }
            message.error(res.message)
          }}
          pictureRef={PictureRef}
        />
      </ModalForm>
    </>
  )
}
export default withRouter(LeftLayout)
