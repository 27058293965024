import { Card, Form, Input, Space, Button, Row, Col, Layout, message, DatePicker, Tag, Select, Descriptions } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import MenuLayout from '../components/Layout/menuLayout'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../redux/reduxHooks'
import { setMenuDisplay } from '../redux/reducers/menu_reducer'
import {
  cashBoxCount,
  cashBoxDeposit,
  cashBoxInfo,
  cashBoxWithdraw,
  changePassword,
  getAccountInfo,
  updateAccountInfo
} from '../api/response'
import { setLanguage, homePageData, setCashBoxInfo } from '../redux/reducers/homePage_reducer'
import dayjs from 'dayjs'
import PicturesWall from '../components/common/picture_wall'
import CashBoxLayout from '../components/cashBoxLayout'
import ModalForm from '../components/common/modal-form'
import { Account as AccountType } from '../models/loginTypes'

const { Content, Header } = Layout

const StyledContent = styled(Content)`
  overflow-y: hidden;
  overflow-x: hidden;
  .ant-empty {
    display: none;
  }
`

const ListHeader = styled(Header)`
  position: sticky;
  top: 0;
  z-index: 10;
  color: black;
  font-weight: 700;
  text-align: center;
  font-size: 20px;
  background-color: orange !important;
`

const ColStyle = styled(Col)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Account = () => {
  const [messageApi, contextHolder] = message.useMessage()
  const dispatch = useAppDispatch()
  const [newPassword, setNewPassword] = useState('')
  const [copyNewPassword, setCopyNewPassword] = useState('')
  const [updateInfoName, setUpdateInfoName] = useState('')
  const [comment, setComment] = useState('')
  const [showCashBoxModal, setShowCashBoxModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [accountInfo, setAccountInfo] = useState<AccountType>()
  const [cashItemsFromSystem, setCashItemsFromSystem] = useState<{ value: number; quantity: number }[]>([
    { value: 100, quantity: 0 },
    { value: 50, quantity: 0 },
    { value: 20, quantity: 0 },
    { value: 10, quantity: 0 },
    { value: 5, quantity: 0 },
    { value: 2, quantity: 0 },
    { value: 1, quantity: 0 },
    { value: 0.5, quantity: 0 },
    { value: 0.2, quantity: 0 },
    { value: 0.1, quantity: 0 }
  ])
  const [cashItems, setCashItems] = useState<{ value: number; quantity: number }[]>([
    { value: 100, quantity: 0 },
    { value: 50, quantity: 0 },
    { value: 20, quantity: 0 },
    { value: 10, quantity: 0 },
    { value: 5, quantity: 0 },
    { value: 2, quantity: 0 },
    { value: 1, quantity: 0 },
    { value: 0.5, quantity: 0 },
    { value: 0.2, quantity: 0 },
    { value: 0.1, quantity: 0 }
  ])
  const pictureRef = useRef(null)
  const homeData = useAppSelector(homePageData)
  const cashBoxPictureRef = useRef(null)

  const getCashBoxInfo = async () => {
    const res = await cashBoxInfo()
    if (res.success) {
      dispatch(setCashBoxInfo({ cashBoxInfo: res.data }))
    } else {
      messageApi.error(res.message)
    }
  }

  useEffect(() => {
    dispatch(
      setMenuDisplay({
        menu: false
      })
    )
  }, [dispatch, homeData.cashBoxInfo, updateInfoName])

  const checkPassword = useCallback(
    (_: any, value) => {
      if (value === newPassword) {
        return Promise.resolve()
      }
      return Promise.reject(new Error('Two passwords are not the same '))
    },
    [newPassword]
  )
  useEffect(() => {
    getAccountInfo().then((res) => {
      if (res.success) {
        setAccountInfo(res.data)
        if (!!res.data.image && res.data.image !== '') {
          pictureRef.current?.setFileList([res.data.image])
        }
        if (!homeData.cashBoxInfo) {
          getCashBoxInfo()
        } else {
          setCashItemsFromSystem(homeData.cashBoxInfo.items)

          if (updateInfoName === 'checkCashBox') {
            setCashItems(homeData.cashBoxInfo.items)
          } else {
            setCashItems([
              { value: 100, quantity: 0 },
              { value: 50, quantity: 0 },
              { value: 20, quantity: 0 },
              { value: 10, quantity: 0 },
              { value: 5, quantity: 0 },
              { value: 2, quantity: 0 },
              { value: 1, quantity: 0 },
              { value: 0.5, quantity: 0 },
              { value: 0.2, quantity: 0 },
              { value: 0.1, quantity: 0 }
            ])
          }
        }
      } else {
        messageApi.error(res.message)
      }
    })
  }, [homeData.cashBoxInfo, updateInfoName])

  const onFinish = useCallback(
    async (values) => {
      switch (updateInfoName) {
        case 'accountInfo':
          const params = {
            ...values,
            image: pictureRef.current.getFileName().length > 0 ? pictureRef.current.getFileName()[0].name : ''
          }
          const updateAccount = await updateAccountInfo(params)
          if (updateAccount.success) {
            messageApi.success(updateAccount.message)
            setAccountInfo(updateAccount.data)
            sessionStorage.setItem('manager', JSON.stringify(updateAccount.data))
            // localStorage.setItem('lang', updateAccount.data.lang)
            // dayjs.locale(updateAccount.data.lang === 'zh-CN' ? 'zh-cn' : 'en')
            // dispatch(setLanguage({ lang: updateAccount.data.lang }))
            return
          }
          messageApi.error(updateAccount.message)

          break
        case 'changePwd':
          const res = await changePassword(values)
          if (!res.success) {
            messageApi.error(res.message)
            return
          }
          messageApi.success(res.message)
          break
        case 'cashBoxWithdraw':
        case 'cashBoxDeposit':
        case 'checkCashBox':
          setShowCashBoxModal(true)
          break

        default:
          break
      }
    },
    [updateInfoName]
  )

  return (
    <>
      <Row wrap={false}>
        <MenuLayout />
        <Layout style={{ height: '100vh', position: 'absolute', width: '100%' }}>
          <Col span={24}>
            <ListHeader>
              <Row style={{ height: '100%' }}>
                <ColStyle
                  onClick={() => {
                    dispatch(
                      setMenuDisplay({
                        menu: true
                      })
                    )
                  }}
                >
                  <img src='/icon/menu.png' width='35px' height='35px' alt='menu' />
                </ColStyle>
                <ColStyle flex={1} style={{ color: 'white', marginRight: '10px', fontSize: '1.5rem' }}>
                  <div>
                    <FormattedMessage id='account' />
                  </div>
                </ColStyle>
              </Row>
            </ListHeader>
            <StyledContent>
              <Space direction='vertical' style={{ width: '100%', padding: '10px' }}>
                {accountInfo && (
                  <Form
                    onFinish={onFinish}
                    initialValues={{
                      nickname: accountInfo.nickname,
                      email: accountInfo.email,
                      lang: accountInfo.lang,
                      image: accountInfo.image
                    }}
                  >
                    <Card
                      title={<FormattedMessage id='accountInfo' />}
                    ><Row>
                      <Col span={12}>
                          <Row>
                            <Form.Item label={<FormattedMessage id='nickname' />} name='nickname'>
                              <Input />
                            </Form.Item>
                          </Row>
                          <Row>
                            <Form.Item label={<FormattedMessage id='email' />} name='email' rules={[{ type: 'email' }]}>
                              <Input />
                            </Form.Item>
                          </Row>
                          <Row>
                            <Form.Item label={<FormattedMessage id='lang' />} name='lang'>
                              <Select>
                                <Select.Option value='zh-CN'>中文</Select.Option>
                                <Select.Option value='en-NZ'>English</Select.Option>
                              </Select>
                            </Form.Item>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <Row>
                              <Form.Item label={<FormattedMessage id='logo' />} name='image'>
                                <PicturesWall number={1} ref={pictureRef} fileName='venus-manager' />
                              </Form.Item>
                          </Row>
                          <Row style={{justifyContent: 'flex-end'}}>
                            <Button type='primary' htmlType='submit' onClick={() => { setUpdateInfoName('accountInfo')}}>
                          <FormattedMessage id='submit' />
                        </Button>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Form>
                )}
                {homeData.cashBoxInfo && (
                  <Form onFinish={onFinish}>
                    <Card
                      title={<FormattedMessage id='cashBoxInfo' />}
                      extra={<div>
                        <Button type='primary' htmlType='submit' 
                          onClick={() => { setUpdateInfoName('checkCashBox')}}>
                          <FormattedMessage id='newCashBoxTotal' />
                        </Button>
                        <Button type='primary' htmlType='submit' style={{ marginLeft: '10px' }}
                          onClick={() => { setUpdateInfoName('cashBoxWithdraw') }} >
                            <FormattedMessage id='cashBoxWithdraw' />
                        </Button>
                        <Button type='primary' htmlType='submit' style={{ marginLeft: '10px' }}
                          onClick={() => { setUpdateInfoName('cashBoxDeposit') }} >
                            <FormattedMessage id='cashBoxDeposit' />
                        </Button>
                      </div>}
                    >
                      <Descriptions>
                        {Object.entries(homeData.cashBoxInfo).map(([key, value]) => {
                          if (key !== 'id' && key !== 'items' && key !== 'createdAt' && key !== 'updatedAt') {
                            return (
                              <Descriptions.Item key={key} label={<FormattedMessage id={key === 'name' ? 'cashBoxName' : key} />}>
                                {key === 'status' ? (
                                  <Tag color={value ? 'success' : 'default'}>{value ? 'Working' : 'Stop'}</Tag>
                                ) : (
                                  value
                                )}
                              </Descriptions.Item>
                            )
                          }
                          return null
                        })}
                      </Descriptions>
                    </Card>
                  </Form>
                )}
                <Form onFinish={onFinish}>
                  <Card title={<FormattedMessage id='changePwd' />} >
                    <Row justify={'space-evenly'} gutter={[16, 16]}>
                      <Col>
                        <Form.Item
                          label={<FormattedMessage id='currPwd' />}
                          name='currentPassword'
                          // rules={[{ required: true, message: 'Please input your password' }]}
                        >
                          <Input.Password />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label={<FormattedMessage id='newPwd' />}
                          name='newPassword'
                          // rules={[{ required: true, message: 'Invalid password', pattern: /^\W{0,}[a-zA-Z]{2,}[0-9]{2,}\W{0,}$/g }]}
                        >
                          <Input.Password
                            value={newPassword}
                            onChange={(e) => {
                              setNewPassword(e.target.value)
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label={<FormattedMessage id='cnfPwd' />} name='cnfPwd'
                          // rules={[{ validator: checkPassword }]}
                        >
                          <Input.Password
                            value={copyNewPassword}
                            onChange={(e) => {
                              setCopyNewPassword(e.target.value)
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col style={{justifyContent: 'flex-end'}}>
                        <Button type='primary' htmlType='submit' onClick={() => { setUpdateInfoName('changePwd') }}>
                          <FormattedMessage id='submit' />
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Form>
              </Space>
            </StyledContent>
          </Col>
        </Layout>
      </Row>
      {contextHolder}
      <ModalForm
        visible={showCashBoxModal}
        onCancel={() => {
          setShowCashBoxModal(false)
        }}
        width='fit-content'
        footer={null}
      >
        <CashBoxLayout
          cashItemsFromSystem={cashItemsFromSystem}
          cashItems={cashItems}
          setCashItems={(items) => setCashItems(items)}
          setComment={(a) => setComment(a)}
          loading={loading}
          type={updateInfoName}
          cashTotalType={updateInfoName === 'checkCashBox' ? 'oldCashBoxTotal' : 'currentCash'}
          remainCashType={
            updateInfoName === 'cashBoxWithdraw'
              ? 'afterWithdrawCashBox'
              : updateInfoName === 'checkCashBox'
              ? 'remainCashBox'
              : 'afterDepositCashBox'
          }
          cashEditType={
            updateInfoName === 'cashBoxWithdraw'
              ? 'withdrawCashBox'
              : updateInfoName === 'checkCashBox'
              ? 'newCashBoxTotal'
              : 'depositCashBox'
          }
          checkLaterButtonHandler={() => {setShowCashBoxModal(false)}}
          checkNowButtonHandler={async () => {
            setLoading(true)
            const params = {
              items: cashItems,
              comment,
              images: cashBoxPictureRef.current.getFileName().map((item) => item.name)
            }

            const res =
              updateInfoName === 'cashBoxWithdraw'
                ? await cashBoxWithdraw(params)
                : updateInfoName === 'checkCashBox'
                ? await cashBoxCount(params)
                : await cashBoxDeposit(params)
            if (res.success) {
              setLoading(false)
              setCashItemsFromSystem(res.data.items)
              setCashItems([
                { value: 100, quantity: 0 },
                { value: 50, quantity: 0 },
                { value: 20, quantity: 0 },
                { value: 10, quantity: 0 },
                { value: 5, quantity: 0 },
                { value: 2, quantity: 0 },
                { value: 1, quantity: 0 },
                { value: 0.5, quantity: 0 },
                { value: 0.2, quantity: 0 },
                { value: 0.1, quantity: 0 }
              ])
              setShowCashBoxModal(false)
              dispatch(setCashBoxInfo({ cashBoxInfo: res.data }))
              messageApi.success(res.message)
              return
            }
            setLoading(false)
            messageApi.error(res.message)
          }}
          pictureRef={cashBoxPictureRef}
        />
      </ModalForm>
    </>
  )
}

export default Account
