import React, { useEffect } from 'react'
import MenuLayout from '../components/Layout/menuLayout'
import CreditTable from '../components/creditList'
import { useAppDispatch } from '../redux/reduxHooks'
import { setMenuDisplay } from '../redux/reducers/menu_reducer'

const CreditHistory = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(
      setMenuDisplay({
        menu: false
      })
    )
  }, [])
  return (
    <div>
      <MenuLayout />
      <CreditTable />
    </div>
  )
}

export default CreditHistory
