/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable multiline-ternary */
/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import {
  Col,
  Row,
  Tabs,
  Radio,
  Image,
  InputNumber,
  Layout,
  List,
  Input,
  Spin,
  Button,
  message,
  Form,
  Switch,
  Select,
  Table,
  Modal,
  Tooltip,
  DatePicker,
  Popconfirm
} from 'antd'
import styled from 'styled-components'
import { debounce, round } from 'lodash'
import { SearchOutlined, SyncOutlined, InfoCircleOutlined } from '@ant-design/icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import { FormattedMessage } from 'react-intl'
import {
  depositApi,
  getCreditHistory,
  getCustomerDetails,
  getCustomers,
  getPointHistory,
  updateCustomerInfo,
  updateCustomerPassword,
  customerOrderQuery,
  putItemToCart,
  getWechatOAuth,
  getWechatInfo,
  updateWechatInfo
} from '../../api/response'
import PictureWall from '../common/picture_wall'
import { depositHistoryColumns, pointHistoryColumnsSimple } from '../../data/tableColumns'
import TextArea from 'antd/lib/input/TextArea'
import { withRouter } from 'react-router'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import CreateAddress from '../manageCustomer/customerAddress'
import { setCustomerInfo as storeCustomerDetail } from '../../redux/reducers/customerInfo_reducer'
import { OrderDetailResTypes } from '../../models/order/orderDetail'
import { ColumnsType } from 'antd/es/table'
import { setMenuDisplay } from '../../redux/reducers/menu_reducer'
import { CustomerCreditDataTypes } from '../../models/customerCenterTypes/customerCreditTypes'
import { CustomerPointTypes } from '../../models/customerCenterTypes/customerPointTypes'
import { useAppDispatch } from '../../redux/reduxHooks'
import CashBox from '../cashItemsLayout'
import api from '../../config/api'

const { Option } = Select
const { RangePicker } = DatePicker
const { Header, Content, Footer } = Layout
const { TabPane } = Tabs
const StyledContent = styled(Content)`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  .ant-empty {
    display: none;
  }
  background-color: white;
  padding: 5px;
`

const ListHeader = styled(Header)`
  position: sticky;
  top: 0;
  z-index: 10;
  color: black;
  height: 74px;
  font-weight: 700;
  text-align: center;
  font-size: 15px;
  background-color: orange !important;
`

const ColStyle = styled(Col)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const ListHover = styled(List.Item)`
  &:hover {
    background-color: orange !important;
    color: white;
  }
`

const StyledLayoutFooter = styled(Footer)`
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 10;
  padding: 0;
`

const CardStyle = styled.div<{ choose: any }>`
  border: 1px solid grey;
  position: relative;
  transition: all 0.5s ease;
  ::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    border: ${(props) => (props.choose ? '18px solid orange' : 'none')};
    border-top-color: transparent;
    border-left-color: transparent;
  }
  ::after {
    content: '';
    width: 7px;
    height: 14px;
    position: absolute;
    right: 6px;
    bottom: 6px;
    border: ${(props) => (props.choose ? '2px solid white' : 'none')};
    border-top-color: transparent;
    border-left-color: transparent;
    transform: rotate(45deg);
  }
  border-color: ${(props) => (props.choose ? 'orange' : 'lightgrey')};
  :hover {
    cursor: pointer;
  }
`
const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 10 }
}

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!'
  },
  number: {
    range: '${label} must be between ${min} and ${max}'
  }
}

const DepositLayout = (props) => {
  const [form] = Form.useForm()
  const [visaSelected, setVisaSelected] = useState(false)
  const [aliSelected, setAliSelected] = useState(false)
  const [weChatSelected, setWeChatSelected] = useState(false)
  const [eftPosSelected, setEftPosSelected] = useState(true)
  const [unionPaySelected, setUnionPaySelected] = useState(false)
  const [cashAmount, setCashAmount] = useState(0)
  const [total, setTotal] = useState(0)
  const [pointTotal, setPointTotal] = useState(0)
  const [orderTotal, setOrderTotal] = useState(0)
  const [cardAmount, setCardAmount] = useState(0)
  const [offlineMethod, setOfflineMethod] = useState('')
  const [offlineAmount, setOfflineAmount] = useState(0)
  const [credit, setCredit] = useState(0)
  const [point, setPoint] = useState(0)
  const [customerEmail, setCustomerEmail] = useState('')
  const [paginator, setPaginator] = useState({ limit: 25, page: 1 })
  const [creditPaginator, setCreditPaginator] = useState({ limit: 10, page: 1 })
  const [pointPaginator, setPointPaginator] = useState({ limit: 10, page: 1 })
  const [orderPaginator, setOrderPaginator] = useState({ limit: 10, page: 1 })
  const [creditHistoryData, setCreditHistoryData] = useState<CustomerCreditDataTypes[]>([])
  const [orderHistoryData, setOrderHistoryData] = useState<OrderDetailResTypes[]>([])
  const [pointHistory, setPointHistory] = useState<CustomerPointTypes[]>([])
  const [customerInfo, setCustomerInfo] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [query, setQuery] = useState('')
  const [orderQuery, setOrderQuery] = useState('')
  const [customerName, setCustomerName] = useState<string>()
  const [customerId, setCustomerId] = useState<number>()
  const [customerGroupId, setCustomerGroupId] = useState<number>()
  const [customerDetail, setCustomerDetail] = useState<any>({})
  const [changeProfile, setChangeProfile] = useState(true)
  const [emailNotify, setEmailNotify] = useState(true)
  const [changePassword, setChangePassword] = useState(false)
  const [changeCredit, setChangeCredit] = useState(false)
  const [changeWechat, setChangeWechat] = useState(false)
  const [topUpComment, setTopUpComment] = useState('')
  const [initialCustomerGroupId, setInitialCustomerGroupId] = useState<number>()
  const [scrollHeight, setScrollHeight] = useState('96%')
  const [loading, setLoading] = useState(false)
  const [dateFrom, setDateForm] = useState(0)
  const [dateTo, setDateTo] = useState(0)
  const [totalTo, setTotalTo] = useState(0)
  const [totalFrom, setTotalFrom] = useState(0)
  const [wechatState, setWechatState] = useState<string>()
  const [wechatAppid, setWechatAppid] = useState('')
  const [searchType, setSearchType] = useState('sender')
  const [wechatFormInfo, setWechatFormInfo] = useState({})
  const [cashItems, setCashItems] = useState([
    { value: 100, quantity: 0 },
    { value: 50, quantity: 0 },
    { value: 20, quantity: 0 },
    { value: 10, quantity: 0 },
    { value: 5, quantity: 0 },
    { value: 2, quantity: 0 },
    { value: 1, quantity: 0 },
    { value: 0.5, quantity: 0 },
    { value: 0.2, quantity: 0 },
    { value: 0.1, quantity: 0 }
  ])

  const PictureRef = useRef(null)
  const dispatch = useAppDispatch()
  const selectBefore = (
    <Select
      defaultValue='sender'
      className='select-before'
      onChange={(value) => {
        setSearchType(value)
      }}
    >
      <Option value='sender'>Sender:</Option>
      <Option value='receiver'>Receiver: </Option>
    </Select>
  )

  const customerOrderListColumns: ColumnsType<OrderDetailResTypes> = [
    {
      title: <div>Id</div>,
      dataIndex: 'orderNo',
      width: '10%',
      ellipsis: true,
      showSorterTooltip: true,
      align: 'center',
      render: (text, record) => {
        return <Link to={`/order/${record.id.toString()}`}>{text}</Link>
      }
    },
    {
      title: <div>Shipment</div>,
      dataIndex: 'id',
      width: '10%',
      align: 'center',
      render: (text, record) => {
        if (record.shippingTerm === 'pickup') return record.shipmentMethodName + record.pickupStoreName + record.pickupStorePhone
        return record.shipmentMethodName + record.consigneeName + record.consigneePhone
      }
    },
    // {
    //   title: <div>收件人</div>,
    //   dataIndex: 'shippingAddress',
    //   width: '15%',
    //   ellipsis: true,
    //   showSorterTooltip: true,
    //   align: 'center',
    //   render: (text, record) => {
    //     return record.consigneeName + record.consigneePhone
    //   }
    //   //`${text?.name || ''} -- ${text?.address || ''}`
    // },
    {
      title: <div>Status</div>,
      dataIndex: 'status',
      width: '7%',
      align: 'center'
    },
    {
      title: <div>Price</div>,
      dataIndex: 'total',
      width: '7%',
      align: 'center',
      render: (text) => `$ ${text}`
    },
    {
      title: <div>Items</div>,
      dataIndex: 'items',
      width: '15%',
      align: 'center',
      render: (text) => (
        <List
          size='small'
          dataSource={text}
          renderItem={(item: any) => <List.Item>{`${item.shippingName} * ${item.qtyOrdered}`}</List.Item>}
        />
      )
    },
    {
      title: <div>Update Time</div>,
      dataIndex: 'updatedAt',
      align: 'center',
      width: '5%',
      render: (text) => {
        return (
          <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')} autoAdjustOverflow>
            <div>{dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</div>
          </Tooltip>
        )
      }
    },
    {
      title: <div>Create Time</div>,
      dataIndex: 'createdAt',
      align: 'center',
      width: '5%',
      render: (text) => {
        return (
          <Tooltip title={dayjs(text).format('D/MM/YYYY h:mm A')} autoAdjustOverflow>
            <div>{dayjs(text).format(dayjs(text).isToday() ? 'HH:mm' : 'DD/MM/YYYY')}</div>
          </Tooltip>
        )
      }
    },
    {
      title: <div>Action</div>,
      key: 'Action',
      align: 'center',
      width: '5%',
      render: (_text, record) => {
        return (
          <Button
            type='primary'
            onClick={() => {
              const storeId = sessionStorage.getItem('storeId')
              record.items.forEach((element) => {
                const param = {
                  customerId: record.customerId,
                  customerGroupId: record.customerGroupId,
                  storeId: +storeId,
                  productId: element.productId,
                  quantity: element.qtyOrdered
                }

                putItemToCart(param).then((res) => {
                  if (res.success) {
                    props.history.push('/')
                  }
                })
              })
              dispatch(
                storeCustomerDetail({
                  customerId: record.customerId,
                  customerGroupId: record.customerGroupId,
                  customerEmail: record.customerEmail
                })
              )
            }}
          >
            <FormattedMessage id='reOrder' />
          </Button>
        )
      }
    }
  ]

  window.onresize = () => {
    if (document.documentElement.clientWidth < 1800) {
      setScrollHeight('95%')
    }
  }

  function getRandom(min, max) {
    return Math.round(Math.random() * (max - min) + min)
  }

  function getCode() {
    let code = ''
    for (let i = 0; i < 6; i++) {
      const type = getRandom(1, 3)
      switch (type) {
        case 1:
          code += String.fromCharCode(getRandom(48, 57))
          break
        case 2:
          code += String.fromCharCode(getRandom(65, 90))
          break
        case 3:
          code += String.fromCharCode(getRandom(97, 122))
          break
      }
    }
    form.setFieldsValue({
      password: code
    })
  }

  const onFinish = () => {
    setLoading(true)
    const formValue = form.getFieldsValue()
    const storeId = +sessionStorage.getItem('storeId')
    const picName = PictureRef.current?.getFileName()
    if (changeProfile) {
      const formParams = {
        ...formValue,
        customerGroupId: typeof formValue.customerGroupId === 'string' ? initialCustomerGroupId : formValue.customerGroupId,
        active: !!formValue.active === true ? 1 : 0,
        pointUpdateNotification: !!formValue.pointUpdateNotification === true ? 1 : 0,
        emailVerified: !!formValue.emailVerified === true ? 1 : 0,
        pointExpirationNotification: !!formValue.pointExpirationNotification === true ? 1 : 0
      }
      updateCustomerInfo(formParams, customerId).then((res) => {
        if (res.success) {
          message.success(res.message)
          setLoading(false)
        } else {
          message.error(res.message)
          setLoading(false)
        }
      })
    }

    if (changeWechat) {
      const { wechatOpenIdPc, wechatOpenIdMobile, wechatOpenIdMiniapp, wechatUnionId, wechatToken, wechatRefreshToken, managerPassword } =
        formValue
      const formParams = {
        wechatOpenIdPc,
        wechatOpenIdMobile,
        wechatOpenIdMiniapp,
        wechatUnionId,
        wechatToken,
        wechatRefreshToken,
        managerPassword
      }
      updateWechatInfo(formParams, customerId).then((res) => {
        if (res.success) {
          message.success(res.message)
          setLoading(false)
        } else {
          message.error(res.message)
          setLoading(false)
        }
      })
    }

    if (changeCredit) {
      const params = {
        storeId: storeId,
        cash: +cashAmount,
        nonCash: +cardAmount,
        nonCashMethod: eftPosSelected
          ? 'eftpos'
          : visaSelected
            ? 'credit'
            : weChatSelected
              ? 'wechat'
              : aliSelected
                ? 'alipay'
                : unionPaySelected
                  ? 'unionpay'
                  : '',
        transfer: +offlineAmount,
        transferMethod: offlineMethod,
        transferScreenshot: picName ? picName[0]?.name : '',
        comment: topUpComment
      }
      depositApi(params, customerId).then((res) => {
        if (res.success) {
          let newCredit = credit
          if (cashAmount !== 0 || cardAmount !== 0 || offlineAmount !== 0) {
            if (cashAmount !== 0) {
              newCredit = newCredit + cashAmount
            }
            if (cardAmount !== 0) {
              newCredit = newCredit + cardAmount
            }
            if (offlineAmount !== 0) {
              newCredit = newCredit + offlineAmount
            }
          }
          setCredit(newCredit)
          success()
          setLoading(false)
        } else {
          message.success(res.message)
          setLoading(false)
        }
      })
    }
    if (changePassword) {
      const params = {
        password: formValue.password,
        managerPassword: formValue.managerPassword,
        notify: !!emailNotify
      }
      updateCustomerPassword(params, customerId).then((res) => {
        if (res.success) {
          message.success(res.message)
          setLoading(false)
        } else {
          message.error(res.message)
          setLoading(false)
        }
      })
    }
  }

  useEffect(() => {
    if (!customerId) {
      return
    }
    const params = {
      pageSize: creditPaginator.limit,
      currentPage: creditPaginator.page,
      customerId,
      query: { createdAt: 1, transactionTime: 1 }
    }
    getCreditHistory(params).then((res) => {
      if (res.success) {
        const { items } = res.data
        const { totalCount } = res.data.pagination
        const creditArr = [...creditHistoryData, ...items]
        if (creditArr.length >= totalCount) {
          setTotal(totalCount)
          setCreditHistoryData([...items])
          return
        }
        setCreditHistoryData([...items])
        setTotal(totalCount)
      }
    })
  }, [creditPaginator, customerId])

  useEffect(() => {
    if (!customerId) {
      return
    }
    const params = {
      pageSize: orderPaginator.limit,
      currentPage: orderPaginator.page,
      customerId,
      searchType,
      dateFrom,
      dateTo,
      totalFrom,
      totalTo,
      query: { createdAt: 1 },
      q: orderQuery
    }
    customerOrderQuery(params).then((res) => {
      if (res.success) {
        const { items } = res.data
        const { totalCount } = res.data.pagination
        const orderArr = [...orderHistoryData, ...items]
        if (orderArr.length >= totalCount) {
          setOrderTotal(totalCount)
          setOrderHistoryData([...items])
          return
        }
        setOrderHistoryData([...items])
        setOrderTotal(totalCount)
      } else {
        setOrderHistoryData([])
        setOrderTotal(0)
      }
    })
  }, [orderPaginator, customerId])

  useEffect(() => {
    if (!customerId) {
      return
    }
    const params = {
      customerId,
      pageSize: pointPaginator.limit,
      currentPage: pointPaginator.page,
      dateFrom: 0,
      dateTo: 0,
      q: '',
      query: { changeDate: 1 }
    }
    getPointHistory(params).then((res) => {
      if (res.success) {
        const { items } = res.data
        const { totalCount } = res.data.pagination
        const pointArr = [...pointHistory, ...items]
        if (pointArr.length >= totalCount) {
          setPointTotal(totalCount)
          setPointHistory([...items])
          return
        }
        setPointHistory([...items])
        setPointTotal(totalCount)
      }
    })
  }, [pointPaginator, customerId])

  const getCustomerinfo = async () => {
    if (!query) {
      setHasMore(true)
      const res = await getCustomers({
        pageSize: paginator.limit,
        currentPage: paginator.page
      })
      if (res.data) {
        const { items } = res.data
        const { totalCount } = res.data.pagination
        const arr = [...customerInfo, ...items]

        if (arr.length >= totalCount) {
          setHasMore(false)
          setCustomerInfo([...arr])
          return
        }
        setCustomerInfo([...arr])
      }
      return
    }
    const queryRes = await getCustomers({
      q: query,
      pageSize: paginator.limit,
      currentPage: paginator.page
    })

    if (queryRes.data) {
      const { items } = queryRes.data
      const { totalCount } = queryRes.data.pagination
      const arr = [...customerInfo, ...items]

      if (arr.length >= totalCount) {
        setHasMore(false)
        setCustomerInfo([...arr])
        return
      }
      setCustomerInfo([...arr])
    }
  }
  useEffect(() => {
    getCustomerinfo()
  }, [paginator])

  const handleLoginQrcode = async () => {
    const storeId = +sessionStorage.getItem('storeId')
    let manager = JSON.parse(sessionStorage.getItem('manager'))
    const {
      data: { appID, state }
    } = await getWechatOAuth()
    setWechatState(`customerid-${customerId}-storeid-${storeId}-managerid-${manager.id}-state-${state}`)
    setWechatAppid(appID)
  }
  const getCustomerWechatInfo = async () => {
    const data = await getWechatInfo(customerId)
    if (data.success) {
      // setWechatFormInfo(data.data)
      form.setFieldsValue({ ...form.getFieldsValue, ...data.data })
    } else {
      message.error(data.message)
    }
  }

  useEffect(() => {
    if (!customerId) {
      return
    }
    // handleLoginQrcode()
    getCustomerWechatInfo()
  }, [customerId])

  function success() {
    Modal.success({
      content: 'Topup Successfully'
    })
  }

  return (
    <Row wrap={false}>
      <Col span={7}>
        <Layout style={{ height: '100vh', position: 'absolute', width: '100%' }}>
          <ListHeader>
            <Row>
              <ColStyle
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(
                    setMenuDisplay({
                      menu: true
                    })
                  )
                }}
              >
                <img src='/icon/menu.png' width='35px' height='35px' alt='menu' />
              </ColStyle>

              <ColStyle flex={1} style={{ color: 'white', marginRight: '10px' }}>
                <div>Customer List</div>
              </ColStyle>
            </Row>
          </ListHeader>
          <StyledContent style={{ overflowY: 'hidden' }}>
            <div
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <Input
                allowClear
                size='large'
                defaultValue={null}
                placeholder='Search by email/name/phone'
                style={{
                  width: '100%'
                }}
                autoFocus={true}
                onChange={debounce((e) => {
                  if (!e.target.value) {
                    setQuery('')
                    setCustomerInfo([])
                    setPaginator({ limit: 25, page: 1 })
                  } else {
                    setCustomerInfo([])
                    setQuery(e.target.value)
                    setPaginator({ limit: 25, page: 1 })
                  }
                }, 500)}
                prefix={<SearchOutlined style={{ color: 'gray' }} />}
              />
            </div>
            <div
              id='scrollableDiv'
              style={{
                height: scrollHeight,
                overflowY: 'scroll',
                backgroundColor: 'white'
              }}
            >
              <InfiniteScroll
                next={() => {
                  setPaginator({
                    ...paginator,
                    page: paginator.page + 1
                  })
                }}
                loader={
                  <div style={{ textAlign: 'center' }}>
                    <Spin />
                  </div>
                }
                hasMore={hasMore}
                dataLength={customerInfo.length}
                endMessage={customerInfo.length > 0 ? null : <div style={{ textAlign: 'center' }}>No more</div>}
                scrollableTarget='scrollableDiv'
              >
                <List
                  rowKey='id'
                  size='small'
                  dataSource={customerInfo}
                  renderItem={(item) => (
                    <ListHover
                      onClick={(e) => {
                        setCustomerId(item.id)
                        setCustomerGroupId(item.customerGroupId)
                        getCustomerDetails(item.id).then((res) => {
                          if (res.success) {
                            setCustomerEmail(res.data.email)
                            setCustomerName(`${res.data.firstName} ${res.data.lastName} ${res.data.nickName} ${res.data.phone}`)

                            setCredit(res.data.credit)
                            setPoint(res.data.point)
                            const groupInfo = JSON.parse(sessionStorage.getItem('customerGroup'))
                            const groupName = groupInfo.find((ele) => {
                              return res.data?.customerGroupId === ele.id
                            })?.name
                            setInitialCustomerGroupId(res.data.customerGroupId)
                            form.setFieldsValue({ ...res.data, customerGroupId: groupName })
                            setCustomerDetail({ ...res.data, customerGroupId: groupName })
                            setPointPaginator({ limit: 10, page: 1 })
                            setCreditPaginator({ limit: 10, page: 1 })
                          }
                        })

                        e.stopPropagation()
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <List.Item.Meta
                        title={
                          <div style={{ marginLeft: '10px' }}>
                            {item.nickName || ''} {item.firstName || ''} {item.lastName || ''} {item.phone || ''}
                          </div>
                        }
                        description={<div style={{ marginLeft: '10px' }}>{item.email}</div>}
                      />
                      <div style={{ marginRight: '10px' }}>{item.id}</div>
                    </ListHover>
                  )}
                />
              </InfiniteScroll>
            </div>
          </StyledContent>
        </Layout>
      </Col>
      <Col span={17}>
        <Layout style={{ height: '100vh', position: 'absolute', width: '100%' }}>
          {customerName ? (
            <>
              <ListHeader>
                <Row justify='space-between' align='middle'>
                  <Col style={{ color: 'white' }}>Id: {customerId}</Col>
                  <Col style={{ color: 'white' }}>Name: {customerName}</Col>
                  <Col style={{ color: 'white' }}>Credit: {credit}</Col>
                  <Col style={{ color: 'white' }}>Point: {point}</Col>
                  <Col style={{ color: 'white' }}>Email: {customerEmail}</Col>
                </Row>
              </ListHeader>
              <StyledContent>
                <Tabs
                  size='large'
                  onChange={(value) => {
                    if (value === '1') {
                      setChangeProfile(true)
                      setChangePassword(false)
                      setChangeCredit(false)
                      setChangeWechat(false)
                    } else if (value === '2') {
                      setChangeProfile(false)
                      setChangePassword(true)
                      setChangeCredit(false)
                      setChangeWechat(false)
                    } else if (value === '3') {
                      setChangeProfile(false)
                      setChangePassword(false)
                      setChangeCredit(true)
                      setChangeWechat(false)
                    } else if (value === '7') {
                      setChangeProfile(false)
                      setChangePassword(false)
                      setChangeCredit(false)
                      setChangeWechat(true)
                    }
                    setCashItems([
                      { value: 100, quantity: 0 },
                      { value: 50, quantity: 0 },
                      { value: 20, quantity: 0 },
                      { value: 10, quantity: 0 },
                      { value: 5, quantity: 0 },
                      { value: 2, quantity: 0 },
                      { value: 1, quantity: 0 },
                      { value: 0.5, quantity: 0 },
                      { value: 0.2, quantity: 0 },
                      { value: 0.1, quantity: 0 }
                    ])
                  }}
                >
                  <TabPane tab='User Info' key='1'>
                    <Form {...layout} name='nest-messages' validateMessages={validateMessages} form={form}>
                      <Form.Item name='nickName' label='Nick Name'>
                        <Input />
                      </Form.Item>
                      <Form.Item name='lastName' label='Last Name'>
                        <Input />
                      </Form.Item>
                      <Form.Item name='firstName' label='First Name'>
                        <Input />
                      </Form.Item>
                      <Form.Item name='userName' label='User Name'>
                        <Input />
                      </Form.Item>
                      <Form.Item name='phone' label='Phone'>
                        <Input />
                      </Form.Item>
                      <Form.Item name='customerGroupId' label='组别'>
                        <Select placeholder='please choose group'>
                          {JSON.parse(sessionStorage.getItem('customerGroup')).map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item name='country' label='Country'>
                        <Input />
                      </Form.Item>
                      <Form.Item name='state' label='State'>
                        <Input />
                      </Form.Item>
                      <Form.Item name='city' label='City'>
                        <Input />
                      </Form.Item>
                      <Form.Item name='address' label='address'>
                        <Input />
                      </Form.Item>
                      <Form.Item name='zipCode' label='zip Code'>
                        <Input />
                      </Form.Item>
                      <Form.Item name='email' label='Email' rules={[{ type: 'email' }]}>
                        <Input />
                      </Form.Item>
                      <Form.Item name='emailVerified' label='邮箱验证' valuePropName='checked'>
                        <Switch defaultChecked={customerDetail.emailVerified} />
                      </Form.Item>
                      <Form.Item name='active' label='Active' valuePropName='checked'>
                        <Switch defaultChecked={customerDetail.active} />
                      </Form.Item>
                      <Form.Item name='pointUpdateNotification' label='Point Update Notification' valuePropName='checked'>
                        <Switch defaultChecked={customerDetail.pointUpdateNotification} />
                      </Form.Item>
                      <Form.Item name='pointExpirationNotification' label='Point Expiration Notification' valuePropName='checked'>
                        <Switch defaultChecked={customerDetail.pointExpirationNotification} />
                      </Form.Item>
                    </Form>
                  </TabPane>
                  <TabPane tab='Change Password' key='2'>
                    <Form {...layout} name='changePassword' validateMessages={validateMessages} form={form}>
                      <Form.Item name='password' label='Password'>
                        <Input />
                      </Form.Item>
                      <Form.Item style={{ position: 'absolute', top: '141px', right: '35%' }}>
                        <SyncOutlined
                          onClick={() => {
                            getCode()
                          }}
                        />
                      </Form.Item>
                      <Form.Item label='Send Email'>
                        <Switch
                          onChange={(checked) => {
                            setEmailNotify(checked)
                          }}
                        />
                      </Form.Item>

                      <Form.Item name='managerPassword' label='Admin Password'>
                        <Input.Password />
                      </Form.Item>
                    </Form>
                  </TabPane>
                  <TabPane tab='Topup' key='3'>
                    <Row align='middle' style={{ backgroundColor: 'white', height: '100%' }}>
                      <Col span={24} style={{ height: '100%' }}>
                        <Row>
                          <Tabs
                            size='large'
                            tabPosition='left'
                            onChange={(value) => {
                              if (value === 'cash') {
                                setEftPosSelected(false)
                                setUnionPaySelected(false)
                                setVisaSelected(false)
                                setAliSelected(false)
                                setWeChatSelected(false)
                                setCardAmount(0)
                                setOfflineMethod('')
                                setOfflineAmount(0)
                              } else if (value === 'offline') {
                                setVisaSelected(false)
                                setEftPosSelected(false)
                                setUnionPaySelected(false)
                                setAliSelected(false)
                                setWeChatSelected(false)
                                setCashAmount(0)
                                setCardAmount(0)
                                setCashItems([
                                  { value: 100, quantity: 0 },
                                  { value: 50, quantity: 0 },
                                  { value: 20, quantity: 0 },
                                  { value: 10, quantity: 0 },
                                  { value: 5, quantity: 0 },
                                  { value: 2, quantity: 0 },
                                  { value: 1, quantity: 0 },
                                  { value: 0.5, quantity: 0 },
                                  { value: 0.2, quantity: 0 },
                                  { value: 0.1, quantity: 0 }
                                ])
                              } else if (value === 'card') {
                                setCashAmount(0)
                                setOfflineMethod('')
                                setOfflineAmount(0)
                                setCashItems([
                                  { value: 100, quantity: 0 },
                                  { value: 50, quantity: 0 },
                                  { value: 20, quantity: 0 },
                                  { value: 10, quantity: 0 },
                                  { value: 5, quantity: 0 },
                                  { value: 2, quantity: 0 },
                                  { value: 1, quantity: 0 },
                                  { value: 0.5, quantity: 0 },
                                  { value: 0.2, quantity: 0 },
                                  { value: 0.1, quantity: 0 }
                                ])
                              }
                            }}
                          >
                            <Tabs.TabPane tab='刷卡' key='card'>
                              <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => +value.replace(/\$\s?|(,*)/g, '')}
                                style={{ marginLeft: '10px' }}
                                min={0}
                                value={cardAmount}
                                onBlur={(e) => {
                                  setCardAmount(+e.target.value)
                                }}
                              />

                              <Row style={{ marginTop: '15px' }}>
                                <Col span={3}>
                                  <CardStyle
                                    choose={eftPosSelected ? 1 : 0}
                                    onClick={() => {
                                      setEftPosSelected(true)
                                      setUnionPaySelected(false)
                                      setVisaSelected(false)
                                      setAliSelected(false)
                                      setWeChatSelected(false)
                                    }}
                                  >
                                    <Image
                                      preview={false}
                                      style={{ transform: 'scale(0.7)', height: '70px', objectFit: 'contain' }}
                                      alt='example'
                                      src='/icon/EftposNZ.png'
                                    />
                                  </CardStyle>
                                </Col>
                                <Col span={3}>
                                  <CardStyle
                                    choose={unionPaySelected ? 1 : 0}
                                    onClick={() => {
                                      setUnionPaySelected(true)
                                      setEftPosSelected(false)
                                      setVisaSelected(false)
                                      setAliSelected(false)
                                      setWeChatSelected(false)
                                    }}
                                  >
                                    <Image
                                      preview={false}
                                      style={{ transform: 'scale(0.7)', height: '70px', objectFit: 'contain' }}
                                      alt='example'
                                      src='/icon/unionpay.png'
                                    />
                                  </CardStyle>
                                </Col>
                                <Col span={3}>
                                  <CardStyle
                                    choose={visaSelected ? 1 : 0}
                                    onClick={() => {
                                      setEftPosSelected(false)
                                      setUnionPaySelected(false)
                                      setVisaSelected(true)
                                      setAliSelected(false)
                                      setWeChatSelected(false)
                                    }}
                                  >
                                    <Image
                                      preview={false}
                                      style={{ transform: 'scale(0.7)', height: '70px', objectFit: 'contain' }}
                                      alt='example'
                                      src='/icon/visa.png'
                                    />
                                  </CardStyle>
                                </Col>

                                <Col span={3}>
                                  <CardStyle
                                    choose={aliSelected ? 1 : 0}
                                    onClick={() => {
                                      setEftPosSelected(false)
                                      setUnionPaySelected(false)
                                      setVisaSelected(false)
                                      setAliSelected(true)
                                      setWeChatSelected(false)
                                    }}
                                  >
                                    <Image
                                      preview={false}
                                      style={{ transform: 'scale(0.7)', height: '70px', objectFit: 'contain' }}
                                      alt='example'
                                      src='/icon/alipay.png'
                                    />
                                  </CardStyle>
                                </Col>
                                <Col span={3}>
                                  <CardStyle
                                    choose={weChatSelected ? 1 : 0}
                                    onClick={() => {
                                      setEftPosSelected(false)
                                      setUnionPaySelected(false)
                                      setVisaSelected(false)
                                      setAliSelected(false)
                                      setWeChatSelected(true)
                                    }}
                                  >
                                    <Image
                                      preview={false}
                                      style={{ transform: 'scale(0.7)', height: '70px', objectFit: 'contain' }}
                                      alt='wechat'
                                      src='/icon/wechat-logo.png'
                                    />
                                  </CardStyle>
                                </Col>
                              </Row>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab='现金' key='cash'>
                              <Popconfirm
                                placement='right'
                                title={
                                  <>
                                    <FormattedMessage id='cashAmount' />
                                    <br />

                                    <span style={{ fontSize: '0.8rem', marginLeft: '1rem' }}>
                                      <FormattedMessage id='cashReceived' />: ${' '}
                                    </span>
                                    {round(
                                      cashItems.reduce((pre, nex) => {
                                        return pre + nex.quantity * nex.value
                                      }, 0),
                                      1
                                    )}
                                  </>
                                }
                                description={<CashBox type='income' setCashItems={(items) => setCashItems(items)} cashItems={cashItems} />}
                                icon={<InfoCircleOutlined />}
                                destroyTooltipOnHide
                                onConfirm={() => {
                                  let totalCash = 0
                                  cashItems.forEach((cash) => {
                                    totalCash += cash.value * cash.quantity
                                  })
                                  setCashAmount(round(totalCash, 1))
                                }}
                                onCancel={() => { }}
                              >
                                <InputNumber
                                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  parser={(value) => +value.replace(/\$\s?|(,*)/g, '')}
                                  style={{ marginLeft: '10px' }}
                                  min={0}
                                  readOnly
                                  value={cashAmount}
                                />
                              </Popconfirm>
                            </Tabs.TabPane>

                            <Tabs.TabPane tab='转账/离线支付' key='offline'>
                              <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => +value.replace(/\$\s?|(,*)/g, '')}
                                style={{ marginLeft: '10px' }}
                                min={0}
                                onBlur={(e) => {
                                  setOfflineAmount(+e.target.value)
                                }}
                                value={offlineAmount}
                              />
                              <div style={{ marginLeft: '24px', marginTop: '5px' }}>#付款请备注订单号码#</div>
                              <>
                                <Radio.Group
                                  onChange={(e) => {
                                    setOfflineMethod(e.target.value.toString())
                                  }}
                                >
                                  <Row>
                                    <Col span={24}>
                                      <Radio
                                        value='anz'
                                        style={{
                                          marginLeft: '24px',
                                          marginTop: '10px',
                                          color: 'orange',
                                          fontSize: '1rem',
                                          fontWeight: 700
                                        }}
                                      >
                                        {api.bank}
                                      </Radio>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={24} style={{ marginLeft: '24px' }}>
                                      <Radio value='wechat'>{api.wechat}</Radio>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={24} style={{ marginLeft: '24px' }}>
                                      <Radio value='alipay'>{api.alipay}</Radio>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={24} style={{ marginLeft: '24px' }}>
                                      <Radio value='other'><FormattedMessage id='other' /></Radio>
                                    </Col>
                                  </Row>
                                </Radio.Group>
                                <div style={{ marginLeft: '24px', marginTop: '10px', width: '50px' }}>
                                  <PictureWall ref={PictureRef} number={1} />
                                </div>
                                <div style={{ marginLeft: '24px', marginTop: '10px' }}>
                                  <TextArea
                                    placeholder='Comment'
                                    rows={8}
                                    onChange={(e) => {
                                      const str = e.target.value
                                      setTopUpComment(str)
                                    }}
                                  >
                                    Comment
                                  </TextArea>
                                </div>
                              </>
                            </Tabs.TabPane>
                          </Tabs>
                        </Row>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab='Credits' key='4'>
                    <Table
                      bordered
                      dataSource={creditHistoryData}
                      columns={depositHistoryColumns}
                      rowKey='id'
                      pagination={{
                        total,
                        defaultPageSize: creditPaginator.limit,
                        current: creditPaginator.page,
                        onChange: (page, pageSize) => {
                          setCreditPaginator({ page, limit: pageSize })
                        }
                      }}
                    />
                  </TabPane>
                  <TabPane tab='Points' key='5'>
                    <Table
                      bordered
                      dataSource={pointHistory}
                      columns={pointHistoryColumnsSimple}
                      rowKey='id'
                      pagination={{
                        total: pointTotal,
                        defaultPageSize: pointPaginator.limit,
                        current: pointPaginator.page,
                        onChange: (page, pageSize) => {
                          setPointPaginator({ page, limit: pageSize })
                        }
                      }}
                    />
                  </TabPane>
                  <TabPane tab='Orders' key='6'>
                    <Row justify='end'>
                      <Col style={{ margin: '5px 6px' }}>
                        <Input
                          allowClear
                          defaultValue={null}
                          placeholder='Search by name'
                          addonBefore={selectBefore}
                          onChange={debounce((e) => {
                            if (!e.target.value) {
                              setOrderQuery(undefined)
                              setOrderHistoryData([])
                              setOrderPaginator({ limit: 10, page: 1 })
                            } else {
                              setOrderHistoryData([])
                              setOrderQuery(e.target.value)
                              setOrderPaginator({ limit: 10, page: 1 })
                            }
                          }, 200)}
                          prefix={<SearchOutlined style={{ color: 'gray' }} />}
                        />
                      </Col>
                      <Col style={{ margin: '5px 6px' }}>
                        From
                        <InputNumber
                          min={0}
                          onChange={(e) => {
                            if (!e) {
                              setTotalFrom(0)
                              setOrderHistoryData([])
                              setOrderPaginator({ limit: 10, page: 1 })
                              return
                            }
                            setTotalFrom(+e)
                            setOrderHistoryData([])
                            setOrderPaginator({ limit: 10, page: 1 })
                          }}
                          style={{ marginRight: '5px', marginLeft: '5px' }}
                        />
                        To
                        <InputNumber
                          min={0}
                          onChange={(e) => {
                            if (!e) {
                              setTotalTo(0)
                              setOrderHistoryData([])
                              setOrderPaginator({ limit: 10, page: 1 })
                              return
                            }
                            setTotalTo(+e)
                            setOrderHistoryData([])
                            setOrderPaginator({ limit: 10, page: 1 })
                          }}
                          style={{ marginLeft: '5px' }}
                        />
                      </Col>
                      <Col style={{ margin: '5px 6px' }}>
                        <RangePicker
                          onChange={(dates: any) => {
                            if (!dates) {
                              setDateForm(0)
                              setDateTo(0)
                              setOrderHistoryData([])
                              setOrderPaginator({ limit: 10, page: 1 })
                              return
                            }
                            setDateForm(dayjs(dates[0]).valueOf())
                            setDateTo(dayjs(dates[1]).valueOf())
                            setOrderHistoryData([])
                            setOrderPaginator({ limit: 10, page: 1 })
                          }}
                          showTime
                        />
                      </Col>

                      <Col style={{ margin: '5px 6px' }}>
                        <Button
                          onClick={() => {
                            dispatch(
                              storeCustomerDetail({
                                customerId: customerId,
                                customerGroupId: customerGroupId
                              })
                            )
                            props.history.push('/')
                          }}
                          type='primary'
                          style={{ justifyContent: 'end' }}
                        >
                          <FormattedMessage id='customerPurchase' />
                        </Button>
                      </Col>
                    </Row>
                    <Table
                      bordered
                      dataSource={orderHistoryData}
                      columns={customerOrderListColumns}
                      rowKey='id'
                      pagination={{
                        total: orderTotal,
                        defaultPageSize: orderPaginator.limit,
                        current: orderPaginator.page,
                        onChange: (page, pageSize) => {
                          setOrderPaginator({ page, limit: pageSize })
                        }
                      }}
                    />
                  </TabPane>
                  <TabPane tab='WeChat' key='7'>
                    <Form {...layout} name='nest-messages' validateMessages={validateMessages} form={form}>
                      <Form.Item name='wechatOpenIdMiniapp' label='WechatOpenIdMiniapp'>
                        <Input />
                      </Form.Item>
                      <Form.Item name='wechatOpenIdPc' label='wechatOpenIdPc'>
                        <Input />
                      </Form.Item>
                      <Form.Item name='wechatOpenIdMobile' label='wechatOpenIdMobile'>
                        <Input />
                      </Form.Item>
                      <Form.Item name='wechatUnionId' label='wechatUnionId'>
                        <Input />
                      </Form.Item>
                      <Form.Item name='wechatToken' label='wechatToken'>
                        <Input />
                      </Form.Item>
                      <Form.Item name='wechatRefreshToken' label='wechatRefreshToken'>
                        <Input />
                      </Form.Item>
                      <Form.Item name='managerPassword' label='Admin Password' rules={[{ required: true }]}>
                        <Input.Password />
                      </Form.Item>
                    </Form>
                  </TabPane>
                  <TabPane tab='Address' key='8'>
                    <CreateAddress chooseCustomerId={customerId} />
                  </TabPane>
                </Tabs>
              </StyledContent>
              <StyledLayoutFooter>
                <Button
                  type='primary'
                  size='large'
                  block
                  loading={loading}
                  onClick={() => {
                    onFinish()
                  }}
                >
                  Confirm
                </Button>
              </StyledLayoutFooter>
            </>
          ) : null}
        </Layout>
      </Col>
    </Row>
  )
}

export default withRouter(DepositLayout)
